import React from "react";
import {Farm} from "./farm/Farm";
import {HeaderTabs} from "./common/HeaderTabs";
import {QueryClient, QueryClientProvider} from 'react-query';

const queryClient = new QueryClient();

export const FarmMain = () => {

    const headerLinks = [
        {label: 'Smart Farm', path: 'monitoring'}
    ];

    return (
        <>
            <HeaderTabs links={headerLinks} defaultPath='monitoring'/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">
                    <QueryClientProvider client={queryClient}>
                        <Farm/>
                    </QueryClientProvider>
                </div>
            </div>
        </>
    )
}




export const FarmCellPostTreatment = (props) => {

    const {cellContentClasses} = props;

    return(
        <div className={cellContentClasses}>
            <div className="cell-header">
                <div className="cell-type">Post treatment</div>
            </div>
        </div>
    );
}

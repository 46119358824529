import {FarmModule} from "./FarmModule";
import React, {useEffect} from "react";
import {
    formatDataBySystemCode, formatPrintersDataById,
    getPrintersStatusData,
    getSingleInfluxdbDataBySystemCode
} from "../../../api/apiInfluxdb";
import {FarmControlPanel} from "./FarmControlPanel";
import {
    getFarmStructurePrintersIds,
    getSmartPrinterStatusFromPrintersStatusData,
    isAllBuzzerOff, mergeAndRemoveOldData, SMART_FARM_COMMANDS,
    SMART_FARM_DATA_FIELDS
} from "../../../services/FarmService";
import {fetchData} from "../../../api/useFetch";
import {useIsMounted} from "../../../services/hooks/useIsMounted";
import {getSocketReceivedDataObservable} from "../../../services/socketEventsService";

export const FarmStructure = (props) => {

    const {farmStructure, handleClickOpenRightPart, setIsWeighModalOpen, selectedCell, setSelectedSmartFarmCellData,
        openRightStructurePanel,smartPrintersStatusData, setSmartPrintersStatusData, manualPrintersStatus, setManualPrintersStatus,
        farmSystemCodes, setCommandsInProcess, isGeneralBuzzerOn, setIsGeneralBuzzerOn, setGeneralCommandsInProcess,
        farmMaintenances, smartFarmData, setSmartFarmData} = props;

    const isMounted = useIsMounted();

    useEffect(() => {
        /* First, get all SF data */
        getSmartFarmData();
        getSmartPrintersStatus();
        getManualPrintersStatus();

        /* Check for printer status regularly */
        const printersStatusInterval = setInterval(() => {
            getSmartPrintersStatus();
            getManualPrintersStatus();
            setSmartFarmData(smartFarmData => mergeAndRemoveOldData(smartFarmData, {}));
        }, 60000);

        /* Live received SF data */
        const receivedDataSubscription = getSocketReceivedDataObservable().subscribe({
            next: (data) => {
                // console.log(data)
                setSmartFarmData(smartFarmData => mergeAndRemoveOldData(smartFarmData, data));
            }
        });

        return () => {
            clearInterval(printersStatusInterval);
            receivedDataSubscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if(isMounted) {
            // Compare new smart farm data with commands in process
            const isBuzzerOn = !isAllBuzzerOff(smartFarmData);
            setCommandsInProcess({type: 'update-time', value: smartFarmData});
            let generalCommandsValues = {};
            generalCommandsValues[SMART_FARM_COMMANDS.STOP_BUZZER] = isBuzzerOn
            setGeneralCommandsInProcess({type: 'update-time', value: generalCommandsValues});
            // Update selected cell data
            if(selectedCell !== undefined && selectedCell !== null) {
                if(smartFarmData && selectedCell.system_code && smartFarmData[selectedCell.system_code]) {
                    setSelectedSmartFarmCellData(smartFarmData[selectedCell.system_code]);
                }
            }
            // Update general buzzer value
            setIsGeneralBuzzerOn(isBuzzerOn);
        }
    }, [smartFarmData]);

    const getSmartFarmData = () => {
        getSingleInfluxdbDataBySystemCode(farmSystemCodes,
            [SMART_FARM_DATA_FIELDS.LED_COLOR, SMART_FARM_DATA_FIELDS.DOOR_CLOSED, SMART_FARM_DATA_FIELDS.RELAY_ON,
                SMART_FARM_DATA_FIELDS.DEHUMIDIFIER, SMART_FARM_DATA_FIELDS.COMMAND_TEMPERATURE,
                SMART_FARM_DATA_FIELDS.COMMAND_AIR_EXTRACTION, SMART_FARM_DATA_FIELDS.BUZZER_ON, SMART_FARM_DATA_FIELDS.MAINTENANCE_ACTIVE,
                SMART_FARM_DATA_FIELDS.HEATER_ACTIVE, SMART_FARM_DATA_FIELDS.TEMPERATURE_EXT, SMART_FARM_DATA_FIELDS.HUMIDITY_EXT,
                SMART_FARM_DATA_FIELDS.VOC_EXT
            ],
            'now()', 'STM', '-5m'
        )
            .then(data => {
                setSmartFarmData(formatDataBySystemCode(data));
            })
            .catch(() => null);
    };

    const getSmartPrintersStatus = () => {
        getPrintersStatusData(getFarmStructurePrintersIds(farmStructure))
            .then(data => {
                setSmartPrintersStatusData(getSmartPrinterStatusFromPrintersStatusData(formatPrintersDataById(data)));
            })
            .catch(() => null);
    }

    const getManualPrintersStatus = () => {
        fetchData('printers/status')
            .then(response => response.json())
            .catch(() => setManualPrintersStatus(null))
            .then(status => setManualPrintersStatus(status))
            .catch(() => setManualPrintersStatus(null));
    }

    const isModuleFirstElement = (module) => {
        return module.position === 0 && !farmStructure.is_panel_side_left;
    }

    const isModuleLastElement = (module) => {
        return module.position === (farmStructure.farm_modules.length - 1) && farmStructure.is_panel_side_left;
    }

    return(
        <div>
            <div className="farm__structure">
                {farmStructure.is_panel_side_left &&
                    <FarmControlPanel
                        isPanelSideLeft={farmStructure.is_panel_side_left} openRightStructurePanel={openRightStructurePanel}
                        isGeneralBuzzerOn={isGeneralBuzzerOn}
                    />
                }
                {(farmStructure.farm_modules && farmStructure.farm_modules.length > 0) &&
                    farmStructure.farm_modules.map(module =>
                        <FarmModule
                            key={module.id} farmModule={module} smartFarmData={smartFarmData} smartPrintersStatusData={smartPrintersStatusData}
                            handleClickOpenRightPart={handleClickOpenRightPart}
                            isModuleFirstElement={isModuleFirstElement(module)} isModuleLastElement={isModuleLastElement(module)}
                            isLastModule={module.position === (farmStructure.farm_modules.length - 1)}
                            setIsWeighModalOpen={setIsWeighModalOpen} manualPrintersStatus={manualPrintersStatus}
                            farmMaintenances={farmMaintenances}
                        />
                    )
                }
                {!farmStructure.is_panel_side_left &&
                    <FarmControlPanel
                        isPanelSideLeft={farmStructure.is_panel_side_left} openRightStructurePanel={openRightStructurePanel}
                        isGeneralBuzzerOn={isGeneralBuzzerOn}
                    />
                }
            </div>
        </div>
    );
}

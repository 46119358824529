/* Get sorted data : return data */
export const getSortedData = (data, sortColumn, sortType) => {
    if (sortColumn && sortType) {
        return data.sort((a, b) => {
            // Values to sort
            let x = a[sortColumn];
            let y = b[sortColumn];

            // Case : undefined values
            if(x === undefined) {
                return 1;
            }
            if(y === undefined) {
                return -1;
            }

            // Get values to compare
            let valueX = 0;
            let valueY = 0;
            if (typeof x === 'string' && typeof y === 'string') {
                let i = 0;
                do {
                    valueX = x.charCodeAt(i);
                    valueY = y.charCodeAt(i);
                    i++;
                } while (valueX === valueY && i < x.length && i < y.length);
            } else if (typeof x === 'number' && typeof y === 'number') {
                valueX = x;
                valueY = y;
            }

            if (sortType === 'asc') {
                return valueX - valueY;
            } else {
                return valueY - valueX;
            }
        });
    }
    return data;
};

/* Async get sorted data : return promise (data) */
export const getAsyncSortedData = (data, sortColumn, sortType) => {
    if (sortColumn && sortType) {
        return new Promise((resolve) => {
            resolve (data.sort((a, b) => {
                // Values to sort
                let x = a[sortColumn];
                let y = b[sortColumn];

                // Case : undefined values
                if (x === undefined) {
                    return 1;
                }
                if (y === undefined) {
                    return -1;
                }

                // Get values to compare
                let valueX = 0;
                let valueY = 0;
                if (typeof x === 'string' && typeof y === 'string') {
                    let i = 0;
                    do {
                        valueX = x.charCodeAt(i);
                        valueY = y.charCodeAt(i);
                        i++;
                    } while (valueX === valueY && i < x.length && i < y.length);
                } else if (typeof x === 'number' && typeof y === 'number') {
                    valueX = x;
                    valueY = y;
                }

                if (sortType === 'asc') {
                    return valueX - valueY;
                } else {
                    return valueY - valueX;
                }

            }))});
    }
    return new Promise((resolve) => resolve(data));
};

/*
Check if node target or a parent contains specific class - max parent check = level
Example : check if path or svg node is a child of a specific button
*/
export const isTargetEqualsOrDescendantOfSpecificClassNode = (target, parentClass, level = 5) => {
    if(target === null)
        return false;

    if(target.className && typeof target.className === 'string' && target.className.includes(parentClass)) {
        return true;
    } else {
        let parentNode = target.parentNode;
        let i = 0;
        while (parentNode.className !== null && i < level) {
            if(parentNode.className && typeof parentNode.className === 'string' && parentNode.className.includes(parentClass)) {
                return true;
            }
            parentNode = parentNode.parentNode;
            i++;
        }
        return false;
    }
}

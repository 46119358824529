import React from "react";
import {QueryClient, QueryClientProvider} from 'react-query';
import {HeaderTabs} from "./common/HeaderTabs";
import {getCurrentUser} from "./CommonFunctions";
import {Help} from "./organization/Help";

const queryClient = new QueryClient();

export const HelpMain = () => {

    const headerLinks = [
        {label: 'Profile', path: 'profile'},
        {label: 'Organization', path: 'organization'},
        {label: 'Members', path: 'members', visible: getCurrentUser().admin},
        {label: 'Connectivity', path: 'connectivity'},
        {label: 'Help', path: 'help'}
    ];

    return (
        <>
            <HeaderTabs links={headerLinks} defaultPath='help'/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">
                    <QueryClientProvider client={queryClient}>
                        <Help/>
                    </QueryClientProvider>
                </div>
            </div>
        </>
    )
}



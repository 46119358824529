import {AlertMessage} from "../common/AlertMessage";
import React, {useEffect, useState} from "react";
import {useGetData} from "../../api/useFetch";
import {getCurrentUser} from "../CommonFunctions";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {ConnectivityEiger} from "./ConnectivityEiger";
import {PRINTER_BRANDS} from "../../services/automation/AutomationService";

export const Connectivity = () => {

    const currentUser = getCurrentUser();

    // Get all existing connection for user organization
    const connections = useGetData(
        `controllablePrinterApiConnections`,
        `controllable_printer_api_connections?organization=${currentUser.organization.id}`
    );

    // Get all available Controllable Printer API connection brands
    const printerBrands = useGetData(
        `printer brands`,
        `printer_brands`
    );

    const [alert, setAlert] = useState();
    const [eigerConnectionId, setEigerConnectionId] = useState(null);
    const [eigerConnectionBrandId, setEigerConnectionBrandId] = useState(null);

    const successCallback = (message) => {
        setAlert({message: message, status: "success", date: new Date()});
        connections.refetch();
    }

    const errorCallback = (message) => {
        setAlert({message: message, status: "error", date: new Date()});
    };

    useEffect(() => {
        // Check already existing connections for this user organization
        setEigerConnectionBrandId(null);
        setEigerConnectionId(null);
        if(connections.data !== undefined) {
            connections.data.map(connection => {
                if(connection.brand.name === 'Markforged') {
                    setEigerConnectionId(connection.id);
                }
            })
        }
        // Get available api ids
        if(printerBrands.data !== undefined) {
            printerBrands.data.map(brand => {
                if(brand.printer_brand_data && brand.printer_brand_data.code === PRINTER_BRANDS.MARKFORGED.code) {
                    setEigerConnectionBrandId(brand.id);
                }
            });
        }
    }, [connections, printerBrands]);

    if(connections.isLoading || printerBrands.isLoading) return <Loading/>
    if(connections.isError) return <Error errorMessage={connections.error.message}/>
    if(printerBrands.isError) return <Error errorMessage={printerBrands.error.message}/>

    return (
        <div id="main__connectivity">
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}
            <ConnectivityEiger
                currentUser={currentUser}
                eigerConnectionId={eigerConnectionId}
                setEigerConnectionId={setEigerConnectionId}
                eigerConnectionBrandId={eigerConnectionBrandId}
                successCallback={successCallback}
                errorCallback={errorCallback}
            />
        </div>
    );
}

import {Table} from "rsuite";
import React, {useEffect, useState} from "react";
import {getAsyncSortedData} from "../../../services/TablesService";
import {PicActionsMenuButton} from "./PicActionsMenuButton";
import {isUserGranted} from "../../CommonFunctions";
import {TableWrapper} from "../../common/TableWrapper";

export const PicTemplateTable = (props) => {

    const {data, handleDeletePic, handleCreatePicFromTemplate, handleDuplicateTemplate, isTableDataLoading} = props;

    // Table states
    const { Column, HeaderCell, Cell } = Table;
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(true);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        setLoading(true);
        if(data !== undefined) {
            setSortedData(data);
            setLoading(false);
        }
    }, [data]);

    const handleSortColumn = (column, type) => {
        setSortColumn(column);
        setSortType(type);
        getAsyncSortedData(sortedData, column, type)
            .then((data) => {
                setSortedData([]);
                setSortedData(data);
            });
    }

    return(
        <TableWrapper classNames="main__pic_table">
            <Table data={sortedData}
                   sortColumn={sortColumn}
                   sortType={sortType}
                   onSortColumn={handleSortColumn}
                   autoHeight={false} height={300}
                   rowKey="id"
                   loading={loading || isTableDataLoading}
                   loadAnimation={true}
            >
                <Column flexGrow={3.5} verticalAlign="middle" sortable>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name"/>
                </Column>
                {(isUserGranted('ROLE_CREATE_PIC') || isUserGranted('ROLE_UPDATE_PIC') || isUserGranted('ROLE_DELETE_PIC')) &&
                    <Column flexGrow={1} verticalAlign="middle" align="left">
                        <HeaderCell></HeaderCell>
                        <Cell dataKey="actions">
                            {rowData =>
                                <PicActionsMenuButton elementId={rowData.id} type="template"
                                                      handleDeletePic={handleDeletePic}
                                                      handleCreatePicFromTemplate={handleCreatePicFromTemplate}
                                                      handleDuplicateTemplate={handleDuplicateTemplate}/>
                            }
                        </Cell>
                    </Column>
                }
            </Table>
        </TableWrapper>
    );

}

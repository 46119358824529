import {Input, InputGroup, MultiCascader} from "rsuite";
import {getFormattedDate, isUserGranted} from "../../CommonFunctions";
import React, {useEffect, useState} from "react";
import {useGetData} from "../../../api/useFetch";
import {findElementById} from "../functions/PicManagerFunctions";

export const PicManagerToolbar = (props) => {

    const {activePicsList, templatesList, draftPicsList, projectsList, setIsLoading, currentUser,
        setFilteredActivePicsList, setFilteredTemplatesList, setFilteredDraftPicsList, setFilteredProjectsList} = props;

    const colors = useGetData('colors', 'resource_colors');
    const brands = useGetData('brands', 'resource_brands');
    const materials = useGetData('materials', 'materials');

    const checkedFiltersSections = ['last update', 'user', 'material brands', 'materials', 'material colors', 'projects'];

    // Filters results
    const [activePicsFilterResult, setActivePicsFilterResult] = useState([]);
    const [draftPicsFilterResult, setDraftPicsFilterResult] = useState([]);
    const [templatesFilterResult, setTemplatesFilterResult] = useState([]);
    const [projectsFilterResult, setProjectsFilterResult] = useState([]);

    // Search bar results
    const [activePicsSearchBarResult, setActivePicsSearchBarResult] = useState([]);
    const [draftPicsSearchBarResult, setDraftPicsSearchBarResult] = useState([]);
    const [templatesSearchBarResult, setTemplatesSearchBarResult] = useState([]);
    const [projectsSearchBarResult, setProjectsSearchBarResult] = useState([]);

    const [filtersList, setFiltersList] = useState([]);
    const [checkedFilters, setCheckedFilters] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    // Set default value for filtered data states
    useEffect(() => {
        // Handle filters lists
        if(checkedFilters.length > 0) {
            filterData();
        } else {
            setActivePicsFilterResult(activePicsList);
            setDraftPicsFilterResult(draftPicsList);
            setTemplatesFilterResult(templatesList);
            setProjectsFilterResult(projectsList);
        }
        // Handle search bar lists
        if(searchValue === '') {
            setActivePicsSearchBarResult(activePicsList);
            setDraftPicsSearchBarResult(draftPicsList);
            setTemplatesSearchBarResult(templatesList);
            setProjectsSearchBarResult(projectsList);
        } else {
            getFilteredDataBySearchBar(searchValue);
        }
    }, [activePicsList, templatesList, draftPicsList, projectsList]);

    useEffect(() => {
        filterData();
    }, [checkedFilters]);

    /* Merge lists (filters - search bar) when results lists change */

    useEffect(() => {
        setFilteredActivePicsList(mergeFilteredListsResults(activePicsFilterResult, activePicsSearchBarResult));
    }, [activePicsFilterResult, activePicsSearchBarResult]);

    useEffect(() => {
        setFilteredDraftPicsList(mergeFilteredListsResults(draftPicsFilterResult, draftPicsSearchBarResult));
    }, [draftPicsFilterResult, draftPicsSearchBarResult]);

    useEffect(() => {
        setFilteredTemplatesList(mergeFilteredListsResults(templatesFilterResult, templatesSearchBarResult));
    }, [templatesFilterResult, templatesSearchBarResult]);

    useEffect(() => {
        setSearchFilters();
    }, [projectsFilterResult, projectsSearchBarResult]);

    /* set filteredProjectsList new value */
    const setSearchFilters = () => {
        if(checkedFilters.length === 0 && searchValue === '') {
            // If no filter, display all projects
            setFilteredProjectsList(projectsList);
        } else if(checkedFilters.length === 0) {
            // Case : only search bar
            setFilteredProjectsList(projectsSearchBarResult);
        } else if(searchValue === '') {
            // Case : only filters
            setFilteredProjectsList(projectsFilterResult);
        } else {
            // Find projects and their pics corresponding to filters (if a project has no pic corresponding, hide project)
            let projects = [];
            // Loop on all projects (use projectsList)
            for(let i = 0; i < projectsList.length; i++) {
                // Find corresponding project in filter and search bar results lists
                let projectsFilter = findElementById(projectsFilterResult, projectsList[i].id);
                let projectsSearchBar = null;
                if(projectsFilter !== null) {
                    projectsSearchBar = findElementById(projectsSearchBarResult, projectsList[i].id);
                }
                if(projectsSearchBar !== null) {
                    projectsFilter = JSON.parse(JSON.stringify(projectsFilter));
                    projectsSearchBar = JSON.parse(JSON.stringify(projectsSearchBar));
                    // If project found in both lists, merge lists
                    projectsFilter.children = mergeFilteredListsResults(
                        projectsFilter.children,
                        projectsSearchBar.children);
                    if(projectsFilter.children.length > 0) {
                        // If projects has children, add project to final results list
                        projects.push(projectsFilter);
                    } else if(isCorrespondingNameProject(projectsSearchBar) && projectsSearchBar.children.length === 0) {
                        // If project's name corresponds to search bar value, add project
                        projects.push(projectsSearchBar);
                    }
                } else if(projectsFilter !== null && isCorrespondingNameProject(projectsFilter)
                    && projectsFilter.name.toLowerCase().includes(searchValue)) {
                    // If project corresponds to exact project filter, add project
                    projects.push(projectsFilter);
                }
            }
            setFilteredProjectsList(projects);
        }
    }

    /* Merge results lists with only common elements */
    const mergeFilteredListsResults = (list1, list2) => {
        return list1.filter(pic => {
            for(let i = 0; i < list2.length; i++) {
                if(pic.id === list2[i].id) {
                    return true;
                }
            }
            return false;
        })
    }

    /* FILTERS */

    /* Create filters data for simple section (with values id and name) */
    const createSectionFilters = (data, sectionName) => {
        let dataFilters = {
            label: sectionName.charAt(0).toUpperCase() + sectionName.slice(1),
            value: sectionName,
            children: []
        }
        data.map(item => {
            dataFilters.children.push({label: item.name, value: sectionName + '-' + item.id});
        });
        return dataFilters;
    }

    const createColorsFilters = (colorsData) => {
        let label = 'material colors';
        let colorsFilters = {
            label: label.charAt(0).toUpperCase() + label.slice(1),
            value: label,
            children: []
        }
        colorsData.map(color => {
            colorsFilters.children.push({label: color.color_name, value: label + '-' + color.id});
        });
        return colorsFilters;
    }

    const createDateSectionFilters = (label) => {
        return {
            label: label.charAt(0).toUpperCase() + label.slice(1),
            value: label,
            children: [
                {label: '< 1 week', value: label + '-1w'},
                {label: '< 1 month', value: label + '-1m'},
                {label: '< 6 months', value: label + '-6m'},
                {label: '> 6 months', value: label + '-else'},
            ]
        }
    }

    const createUserSectionFilters = () => {
        let label = 'user';
        return {
            label: label.charAt(0).toUpperCase() + label.slice(1),
            value: label,
            children: [
                {label: 'Me (' + currentUser.firstname + ' ' + currentUser.lastname + ')', value: label + '-me'},
                {label: 'Other users', value: label + '-others'}
            ]
        }
    }

    useEffect(() => {
        // If all needed data available, create filters data
        if(colors.data !== undefined && brands.data !== undefined && materials.data !== undefined) {
            setIsLoading(true);
            let newFiltersList = [];
            newFiltersList.push(createUserSectionFilters());
            newFiltersList.push(createDateSectionFilters('last update'));
            newFiltersList.push(createSectionFilters(projectsList, 'projects'));
            newFiltersList.push(createSectionFilters(brands.data, 'material brands'));
            newFiltersList.push(createColorsFilters(colors.data));
            newFiltersList.push(createSectionFilters(materials.data, 'materials'));
            setFiltersList(newFiltersList);
            setIsLoading(false);
        }
    }, [colors.isLoading, brands.isLoading, materials.isLoading]);

    /* Get checked filters for specific section with id value */
    const organizeCheckedFiltersWithId = (filtersSection) => {
        let filters = [];
        checkedFilters.map(checkedFilter => {
            if(checkedFilter.includes(filtersSection)) {
                filters.push(parseInt(checkedFilter.substring(filtersSection.length + 1)));
            }
        });
        return filters;
    }

    /* Get checked filters for specific section with text value */
    const organizeCheckedFiltersWithoutId = (filtersSection) => {
        let filters = [];
        checkedFilters.map(checkedFilter => {
            if(checkedFilter.includes(filtersSection)) {
                filters.push(checkedFilter);
            }
        });
        return filters;
    }

    /* Filter data */
    const filterData = () => {
        if(checkedFilters.length === 0) {
            // Case no filter : get resourcesList data
            setActivePicsFilterResult(activePicsList);
            setDraftPicsFilterResult(draftPicsList);
            setTemplatesFilterResult(templatesList);
            setProjectsFilterResult(projectsList);
        } else {
            // Create a copy of each data list
            let activePicsListData = JSON.parse(JSON.stringify(activePicsList));
            let draftPicsListData = JSON.parse(JSON.stringify(draftPicsList));
            let templatesListData = JSON.parse(JSON.stringify(templatesList));
            let projectsListData = JSON.parse(JSON.stringify(projectsList));
            // Filter
            setActivePicsFilterResult(filterSingleDataList(activePicsListData));
            setDraftPicsFilterResult(filterSingleDataList(draftPicsListData));
            setTemplatesFilterResult(filterSingleDataList(templatesListData));
            setProjectsFilterResult(filterProjectDataList(projectsListData));
        }
    }

    /* Filter one pics list */
    const filterSingleDataList = (list) => {
        return list.filter(item => {
            return isCorrespondingMaterial(item) &&
                isCorrespondingUser(item) &&
                isCorrespondingProject(item) &&
                isCorrespondingLastUpdate(item);
        });
    }

    /* Filter one projects list */
    const filterProjectDataList = (projects) => {
        return projects.filter(project => {
            if(isCorrespondingProjectForProjects(project)) {
                if(isCorrespondingNameProject(project)) {
                    return true;
                } else {
                    if(project.children && project.children.length > 0) {
                        project.children = filterProjectChildren(project.children);
                        if(project.children.length > 0) {
                            return true;
                        }
                    }
                }
            }
            return false;
        });
    }

    /* Filter pics in project element */
    const filterProjectChildren = (list) => {
        return list.filter(item => {
            return isCorrespondingMaterial(item) &&
                isCorrespondingUser(item) &&
                isCorrespondingLastUpdate(item);
        });
    }

    /* Filter materials */
    const isCorrespondingMaterial = (pic) => {
        const materialFilters = organizeCheckedFiltersWithId('materials');
        const materialBrandFilters = organizeCheckedFiltersWithId('material brands');
        const materialColorFilters = organizeCheckedFiltersWithId('material colors');
        if(materialFilters.length === 0 && materialBrandFilters.length === 0 && materialColorFilters.length === 0) {
            return true;
        }
        if(pic.slicerData && pic.slicerData.brand1 && pic.slicerData.material1) {
            // Check material
            if(materialFilters.length === 0 || materialFilters.indexOf(pic.slicerData.material1.id) >= 0) {
                // Check material brand
                if(materialBrandFilters.length === 0 || (pic.slicerData.brand1 && materialBrandFilters.indexOf(pic.slicerData.brand1.id) >= 0)) {
                    // Check color
                    if(materialColorFilters.length === 0 || (pic.slicerData.color1 && materialColorFilters.indexOf(pic.slicerData.color1.id) >= 0)) {
                        return true;
                    }
                }
            }
        } else if(pic.slicerData && pic.slicerData.brand2 && pic.slicerData.material2) {
            // Check material
            if(materialFilters.length === 0 || materialFilters.indexOf(pic.slicerData.material2.id) >= 0) {
                // Check material brand
                if(materialBrandFilters.length === 0 || (pic.slicerData.brand2 && materialBrandFilters.indexOf(pic.slicerData.brand2.id) >= 0)) {
                    // Check color
                    if(materialColorFilters.length === 0 || (pic.slicerData.color2 && materialColorFilters.indexOf(pic.slicerData.color2.id) >= 0)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const isCorrespondingUser = (pic) => {
        if(currentUser) {
            const userFilters = organizeCheckedFiltersWithoutId('user');
            return (userFilters.length === 0) ||
                (userFilters.indexOf('user-me') >= 0 && pic.user.id === currentUser.id) ||
                (userFilters.indexOf('user-others') >= 0 && pic.user.id !== currentUser.id);
        }
    }

    /* Check projects in pics */
    const isCorrespondingProject = (pic) => {
        if(pic.project_pics) {
            const projectFilters = organizeCheckedFiltersWithId('projects');
            if(projectFilters.length === 0) {
                return true;
            } else {
                for(let i = 0; i < pic.project_pics.length; i++) {
                    if(projectFilters.indexOf(pic.project_pics[i].project.id) >= 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /* Check projects in projects table list */
    const isCorrespondingProjectForProjects = (project) => {
        const projectFilters = organizeCheckedFiltersWithId('projects');
        return projectFilters.length === 0 || projectFilters.indexOf(project.id) >= 0;
    }

    /* Check if project is in projects filters */
    const isCorrespondingNameProject = (project) => {
        const projectFilters = organizeCheckedFiltersWithId('projects');
        return projectFilters.indexOf(project.id) >= 0;
    }

    const isCorrespondingLastUpdate = (pic) => {
        const lastUpdateFilters = organizeCheckedFiltersWithoutId('last update');
        const today = Math.floor(new Date().getTime() / 1000);
        return (lastUpdateFilters.length === 0) ||
            (lastUpdateFilters.indexOf('last update-1w') >= 0 && (pic.lastUpdate >= getDateToCompare(today, 7, 'day'))) ||
            (lastUpdateFilters.indexOf('last update-1m') >= 0 && (pic.lastUpdate >= getDateToCompare(today, 1))) ||
            (lastUpdateFilters.indexOf('last update-6m') >= 0 && (pic.lastUpdate >= getDateToCompare(today, 6))) ||
            (lastUpdateFilters.indexOf('last update-else') >= 0 && (pic.lastUpdate < getDateToCompare(today, 6)));
    }

    /* Get new date depending on today minus days or months */
    const getDateToCompare = (date, number, type = 'month') => {
        date = new Date(date * 1000);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        if(type === 'day') {
            return Math.floor(date.setDate(date.getDate() - number) / 1000);
        } else {
            return Math.floor(date.setMonth(date.getMonth() - number) / 1000);
        }
    }

    /* reset checked filters if all deleted */
    const handleOnFiltersClean = () => {
        setCheckedFilters([]);
    }

    /* Get checked filters */
    const handleOnFiltersCheck = (value) => {
        setCheckedFilters(value);
    }

    /* SEARCH BAR */

    const handleSearchBarChange = (value) => {
        setSearchValue(value);
        getFilteredDataBySearchBar(value);
    }

    /* Filter all data lists by search bar */
    const getFilteredDataBySearchBar = (value) => {
        value = value.toLowerCase();
        if(value === '') {
            setActivePicsSearchBarResult(activePicsList);
            setDraftPicsSearchBarResult(draftPicsList);
            setTemplatesSearchBarResult(templatesList);
            setProjectsSearchBarResult(projectsList);
        } else {
            setActivePicsSearchBarResult(filterListDataBySearchBar(JSON.parse(JSON.stringify(activePicsList)), value));
            setDraftPicsSearchBarResult(filterListDataBySearchBar(JSON.parse(JSON.stringify(draftPicsList)), value));
            setTemplatesSearchBarResult(filterListDataBySearchBar(JSON.parse(JSON.stringify(templatesList)), value));
            let newProjectsList = JSON.parse(JSON.stringify(projectsList));
            setProjectsSearchBarResult(filterProjectsBySearchBar(newProjectsList, value));
        }
    }

    /* Filter projects list by search bar value (project name and pics in project) */
    const filterProjectsBySearchBar = (list, value) => {
        return list.filter(project => {
            if(project.name.toLowerCase().includes(value)) {
                return true;
            } else {
                if(project.children && project.children.length > 0) {
                    project.children = filterListDataBySearchBarForProjects(project.children, value);
                    if(project.children.length > 0) {
                        return true;
                    }
                }
                return false;
            }
        })
    }


    /* Filter specific data list by search bar for pics */
    const filterListDataBySearchBar = (list, value) => {
        return list.filter(pic => {
            return(
                filterPicsBySearchBarWithoutProjectFilter(pic, value) ||
                isProjectCorrespondingToSearchBarValue(pic.project_pics.project, value)
            );
        });
    }

    /* Filter specific data list by search bar for pics in projects */
    const filterListDataBySearchBarForProjects = (list, value) => {
        return list.filter(pic => {
            return filterPicsBySearchBarWithoutProjectFilter(pic, value);
        });
    }

    const filterPicsBySearchBarWithoutProjectFilter = (pic, value) => {
        return (
            pic.name.toLowerCase().includes(value) ||
            pic.version.toString().includes(value) ||
            getFormattedDate(pic.lastUpdate).includes(value) ||
            pic.user.initials.toLowerCase().includes(value) ||
            pic.material1.toLowerCase().includes(value) ||
            pic.material2.toLowerCase().includes(value)
        );
    }

    /* Check if search bar value corresponds to one project in list */
    const isProjectCorrespondingToSearchBarValue = (projects, value) => {
        if(projects) {
            for(let i = 0; i < projects.length; i++) {
                if(projects[i].name.toLowerCase().includes(value)) {
                    return true;
                }
            }
        }
        return false;
    }

    return(
        <div className="main__lists_toolbars">
            <div className="toolbar-left">
                <div className="filter-list">
                    <MultiCascader
                        data={filtersList}
                        value={checkedFilters}
                        uncheckableItemValues={checkedFiltersSections}
                        onCheck={(value) => handleOnFiltersCheck(value)}
                        onClean={() => handleOnFiltersClean()}
                        menuWidth={350} menuHeight={220} size="md" placeholder={'Filter...'}
                    />
                </div>
                <div className="search-bar">
                    <InputGroup inside size="md">
                        <Input size="md" placeholder="Search" type="text" defaultValue="" onChange={(value) => handleSearchBarChange(value)}/>
                        <InputGroup.Addon><i className="fa fa-search"/></InputGroup.Addon>
                    </InputGroup>
                </div>
            </div>
            {isUserGranted('ROLE_CREATE_PIC') &&
                <div className="toolbar-right">
                    <a href="/builder" className="button-link">
                        <i className="fa fa-plus" /> Build PIC
                    </a>
                </div>
            }
        </div>
    );
}

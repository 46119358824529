import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {useGetData} from "../../api/useFetch";
import {AppBar, Toolbar, IconButton, Avatar } from '@material-ui/core';
import { useStyles } from "../../css/pic/pic.js";
import handddle_logo from "../../images/logos/logo_handddle_small.svg";
import {getCurrentUser} from "../CommonFunctions";
import {PicBuilderComponent} from "./PicBuilderComponent";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";

export const EditPicMain = () => {
    let {id} = useParams();

    const pic = useGetData('picData','pics/' + id);

    if(pic.isLoading) return <Loading/>
    if(pic.isError) return <Error errorMessage={pic.error}/>

    return (
        <PicBuilderComponent picData={pic.data}/>
    )
}


import React, {useEffect, useState} from "react";
import {fetchData, useGetData} from "../../../api/useFetch";
import {Controller, useForm} from "react-hook-form";
import {
    getConvertedDuration,
    getCurrentUser,
    getFormattedDuration,
    getFormattedLongDuration
} from "../../CommonFunctions";
import printing_datas_manual from "../../../images/icons/pic/PrintingDatasManual.png";
import printing_datas_brand_1 from "../../../images/icons/pic/PrintingDatasBrand1.png";
import printing_datas_brand_2 from "../../../images/icons/pic/PrintingDatasBrand2.png";
import printing_datas_brand_3 from "../../../images/icons/pic/PrintingDatasBrand3.png";
import {
    Checkbox, Dialog, DialogContent,
    FormControl, InputLabel, ListItemText, makeStyles, MenuItem,
    OutlinedInput, Select, TextField
} from "@material-ui/core";
import {Loading} from "../../common/Loading";
import {Error} from "../../common/Error";
import InputMask from "react-input-mask";
import {Alert} from "@material-ui/lab";
import {CardSlicerBuildTable} from "./CardSlicerBuildTable";
import {CELL_TYPES} from "../../../services/FarmService";
import {FarmProfileModal} from "../../farm/farm-profiles/FarmProfileModal";
import {MaterialIcon} from "../../common/icons/MaterialIcon";
import {fetchAutomationData} from "../../../api/apiConnectivity";

const useStyles = makeStyles({
    dialog_paper: {
        maxWidth: "none",
        padding: "0 15px"
    }
});

export const CardSlicer = (props) => {

    const classes = useStyles();

    const {picCard, setIsActive, refreshGlobalState, globalCardData, isScheduled, handleContinueCard, setPrinterData} = props;

    const card = picCard.card;

    const [, refreshState] = useState(false);

    const currentUser = getCurrentUser();
    const apiConnections = useGetData(
        'controllable_printer_api_connections',
        'controllable_printer_api_connections?organization=' + currentUser.organization.id
    );

    const [controllablePrinterApiSelected, setControllablePrinterApiSelected] = useState(null);

    const printers = useGetData(
        'printer',
        'printers/connectivity',
        {
            organization_group: currentUser.organization.organization_group_id
        }
    );
    const resources = useGetData(
        'resources',
        'resource_items/essentials',
        {
            organization_group: currentUser.organization.organization_group_id,
            archived: false
        }
    );
    const printerBrands = useGetData(
        'brands',
        'printer_brands'
    );

    const farmProfiles = useGetData('farm_profiles', 'farm_profiles?system_type=' + CELL_TYPES.PRINTER);

    // Init form management
    const {handleSubmit, setValue, control} = useForm();

    const [selectedPrinterBrandId, setSelectedPrinterBrandId] = useState(card.printer_brand ? card.printer_brand.id : null);
    const [selectedPrinterBrand, setSelectedPrinterBrand] = useState(card.printer_brand ? card.printer_brand : null);

    const [selectedPrintersId, setSelectedPrintersId] = useState([]);
    const [selectedPrinters, setSelectedPrinters] = useState(card.printers ? card.printers : []);

    const [mainPrinter, setMainPrinter] = useState(card.main_printer ? card.main_printer : null);

    // Material/Brand/Color
    const [selectedMaterialId1, setSelectedMaterialId1] = useState(card.material1 ? card.material1.id : null);
    const [selectedMaterialId2, setSelectedMaterialId2] = useState(card.material2 ? card.material2.id : null);

    const [selectedBrandId1, setSelectedBrandId1] = useState(card.brand1 ? card.brand1.id : null);
    const [selectedBrandId2, setSelectedBrandId2] = useState(card.brand2 ? card.brand2.id : null);

    const [selectedColor1, setSelectedColor1] = useState(card.color1 ? card.color1.id : null);
    const [selectedColor2, setSelectedColor2] = useState(card.color2 ? card.color2.id : null);

    const [isPrinterDatasSelected, setIsPrinterDatasSelected] = useState(card.duration > 0);

    const [isManualPrintingData, setIsManualPrintingData] = useState(true);
    const [printDuration, setPrintDuration] = useState(card.duration ? card.duration : null);
    const [materialQuantity1, setMaterialQuantity1] = useState(card.quantity1 ? card.quantity1 : 0);
    const [materialQuantity2, setMaterialQuantity2] = useState(card.quantity2 ? card.quantity2 : 0);

    const [selectedBuild, setSelectedBuild] = useState(null);
    const [isSelectedBuildRequestDone, setIsSelectedBuildRequestDone] = useState(false);
    const [isSelectedBuildError,setIsSelectedBuildError] = useState(false);

    const [isBuildTableOpen, setIsBuildTableOpen] = useState(false);

    const [isFiberUsed, setIsFiberUsed] = useState(card.is_fiber_used ? card.is_fiber_used : false);

    const [selectedFarmProfileId, setSelectedFarmProfileId] = useState((card.farm_profile && card.farm_profile.id) ? card.farm_profile.id : null);

    const [isFarmProfileModalOpen, setIsFarmProfileModalOpen] = useState(false);

    useEffect(() => {
        if(apiConnections.data !== undefined && selectedPrinterBrand !== null) {
            let apiConnection = getControllablePrinterApiSelected(selectedPrinterBrand)
            // if(!isSelectedBuildRequestDone) {
            //     setIsSelectedBuildError((apiConnection === null && card.build_id !== undefined && card.build_id !== null));
            // }
            setControllablePrinterApiSelected(getControllablePrinterApiSelected(selectedPrinterBrand));
        }
    }, [apiConnections, selectedPrinterBrand]);

    useEffect(() => {
        if(card.duration){
            globalCardData['print_duration'] = card.duration;
            refreshGlobalState({});
        }
    }, []);

    useEffect(() => {
        /* update selectedPrintersId */
        let printersId = [];
        selectedPrinters.forEach(printer => {
            if(printer && printer.id) {
                printersId.push(printer.id);
            }
        });
        card.printers = printersId;
        setSelectedPrintersId(printersId);
        refreshGlobalState({});

        if(mainPrinter)
            preSettingMaterialResourcesField();

    }, [selectedPrinters]);

    useEffect(() => {
        if(mainPrinter) {
            card.main_printer = mainPrinter.id;
            setPrinterData(mainPrinter);
            picCard.is_complete = true;
            card.is_fiber_used = mainPrinter.is_fiber ? card.is_fiber_used : false;
            setIsFiberUsed(card.is_fiber_used);
        } else {
            card.main_printer = null;
            picCard.is_complete = false;
            // Remove selected build
            setSelectedBuild(null);
            card.build_id = null;
            card.is_fiber_used = false;
            setIsFiberUsed(false);
        }
        refreshGlobalState({});
    }, [mainPrinter]);

    useEffect(() => {
        if(controllablePrinterApiSelected !== null && (card.build_id !== null && card.build_id !== undefined)) {
            const brandCode = controllablePrinterApiSelected.brand.printer_brand_data ? controllablePrinterApiSelected.brand.printer_brand_data.code : null;
            fetchAutomationData('controllable_printers/builds/' + card.build_id,{brand_code: brandCode})
                .then(response => {
                    if(response.ok) {
                        response.json().then(savedBuild => {
                            setIsSelectedBuildRequestDone(true);
                            setSelectedBuild(savedBuild.data);
                            if(savedBuild.data === null) {
                                setIsSelectedBuildError(true);
                                if(savedBuild.status_code === 404) {
                                    card.build_id = null;
                                }
                            }
                        });
                    } else {
                        card.build_id = null;
                        setIsSelectedBuildRequestDone(true);
                        setSelectedBuild(null);
                        setIsSelectedBuildError(true);
                    }
                })
                .catch(() => {
                    card.build_id = null;
                    setIsSelectedBuildRequestDone(true);
                    setSelectedBuild(null);
                    setIsSelectedBuildError(true);
                });
        }
    }, [controllablePrinterApiSelected]);

    useEffect(() => {
        // If pic is not already schedule, handle printing data infos coming from selected build
        if(!isScheduled) {
            handlePrintingDataDependingOnBuild();
        }
        // Check if saved build has not been found
        // setIsSelectedBuildError(isSelectedBuildRequestDone && selectedBuild === null && card.build_id !== undefined && card.build_id !== null);
    }, [selectedBuild]);

    const getControllablePrinterApiSelected = (brand) => {
        for(let i = 0; i < apiConnections.data.length; i++) {
            if(apiConnections.data[i].brand.name.toLowerCase() === brand.name.toLowerCase()) {
                return apiConnections.data[i];
            }
        }
        return null;
    }

    const handleBuildChange = (buildData) => {
        setSelectedBuild(buildData);
        if(buildData !== null) {
            card.build_id = buildData.id;
        } else {
            card.build_id = null;
        }
    }

    const handlePrinterBrandChange = (printerBrandId) => {
        setSelectedPrinterBrandId(printerBrandId);
        const selectedPrinterBrand = printerBrands.data.filter(brand => brand.id === printerBrandId)[0];
        setSelectedPrinterBrand(selectedPrinterBrand);
        resetPrinterInfos();

        card.printer_brand = printerBrandId;
        card.printerBrandData = selectedPrinterBrand;

        setControllablePrinterApiSelected(getControllablePrinterApiSelected(selectedPrinterBrand));
        handleBuildChange(null);
        setIsManualPrintingData(true);

        refreshGlobalState({});
    }

    /* Check if selected printer brand is connected to api */
    const checkIsPrinterBrandConnected = (brand) => {
        for(let i = 0; i < apiConnections.data.length; i++) {
            if(apiConnections.data[i].brand.name.toLowerCase() === brand.name.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    const handleSelectedPrintersChange = (printersIdSelected) => {
        /* Get selected printers complete object */
        let newSelectedPrinters = [];
        printersIdSelected.forEach((printerSelectedId) => {
            newSelectedPrinters.push(findPrinterById(printerSelectedId));
        });

        setSelectedPrinters(newSelectedPrinters);
        handleMainPrinter(newSelectedPrinters);
        refreshGlobalState({});
    }

    const findPrinterById = (printerId) => {
        let i = 0;
        while (printers.data[i].id !== printerId) {
            i++;
        }
        return printers.data[i];
    }

    const handleMainPrinter = (newSelectedPrinters) => {
        if(mainPrinter) {
            // Check if main printer is still in list of selected printers
            let mainPrinterExists = false;
            for(let i = 0; i < newSelectedPrinters.length; i++) {
                if (newSelectedPrinters[i].id === mainPrinter.id) {
                    mainPrinterExists = true;
                    break;
                }
            }
            // If main printer has been removed, define new mainPrinter value
            if (!mainPrinterExists) {
                if (newSelectedPrinters.length > 0) {
                    setMainPrinter(newSelectedPrinters[0]);
                    resetResourceInfos();
                } else {
                    setMainPrinter(null);
                    resetResourceInfos();
                }
            }
        } else {
            if (newSelectedPrinters.length > 0) {
                setMainPrinter(newSelectedPrinters[0]);
                resetResourceInfos();
            }
        }
    }

    const resetPrinterInfos = () => {
        setSelectedPrintersId([]);
        setSelectedPrinters([]);
        setMainPrinter(null);
        card.printers = [];
        resetResourceInfos();
    }

    const resetResourceInfos = () => {
        // Reset material/brand/color selected fields
        setSelectedMaterialId1(null); setSelectedMaterialId2(null);
        setSelectedBrandId1(null); setSelectedBrandId2(null);
        setSelectedColor1(null); setSelectedColor2(null);

        // Reset form datas when unselected
        card.material1 = null;
        card.material2 = null;
        card.brand1 = null;
        card.brand2 = null;
        card.color1 = null;
        card.color2 = null;
    }

    const handleMaterialSelect = (materialId, index) => {
        if(index === 1) {
            card.material1 = materialId;
            if(materialId !== null) {
                card.brand1 = null;
                setSelectedBrandId1(null);
                card.color1 = null;
                setSelectedColor1(null);
            }
            setSelectedMaterialId1(materialId)
        } else {
            // card.material2 = process.env.REACT_APP_API_URI + `materials/${materialId}`;
            card.material2 = materialId;
            if(materialId !== null) {
                card.brand2 = null;
                setSelectedBrandId2(null);
                card.color2 = null;
                setSelectedColor2(null);
            }
            setSelectedMaterialId2(materialId)
        }
        preSettingMaterialResourcesField(index);
    }

    const handleBrandSelect = (brandId, index) => {
        if(index === 1)
            card.brand1 = brandId;
        else
            card.brand2 = brandId;

        index === 1 ? setSelectedBrandId1(brandId) : setSelectedBrandId2(brandId);
        preSettingMaterialResourcesField(index);
    };

    const handleColorSelect = (colorId, index) => {
        if(index === 1)
            card.color1 = colorId;
        else
            card.color2 = colorId;

        index === 1 ? setSelectedColor1(colorId) : setSelectedColor2(colorId);
    };

    const uniqueMaterials = () => {
        if(mainPrinter && mainPrinter.technology) {
            let filteredResources = resources.data.filter(resource => resource.material.technology.id === mainPrinter.technology.id);

            return new Map(
                filteredResources.map((resource) => [resource.material.id, resource.material.name])
            );
        }

        return new Map();
    }

    const uniqueBrands = (index) => {
        let filteredResources;

        if (index === 1)
            filteredResources = resources.data.filter(resource => resource.material.id === selectedMaterialId1);
        else
            filteredResources = resources.data.filter(resource => resource.material.id === selectedMaterialId2);

        return new Map(
            filteredResources.map((resource) => [resource.brand.id, resource.brand.name])
        );
    }

    const uniqueColors = (index) => {
        let filteredResources;

        if (index === 1)
            filteredResources = resources.data.filter(resource => resource.material.id === selectedMaterialId1 && resource.brand.id === selectedBrandId1);

        else
            filteredResources = resources.data.filter(resource => resource.material.id === selectedMaterialId2 && resource.brand.id === selectedBrandId2);

        return new Map(
            filteredResources.map((resource) => [resource.color.id, resource.color.color_name])
        );
    }

    const handleClickContinue = () => {
        handleContinueCard(picCard);
        setIsActive(false);
    }

    /* Display list of selected printers (format "printer1, printer 2, ...") */
    const displaySelectedPrinters = () => {
        let display = '';
        for(let i = 0; i < selectedPrinters.length; i++) {
            display += selectedPrinters[i].name;
            if(i < selectedPrinters.length - 1) {
                display += ', ';
            }
        }
        return display;
    }

    /* Pre-filling materials resources field if available */
    const preSettingMaterialResourcesField = (index = null) => {
        if (mainPrinter && resources.data) {
            const hasColor = (mainPrinter && mainPrinter.technology) ? mainPrinter.technology.has_color : false;
            const availableResources = resources.data.filter(resource => mainPrinter && resource.material.technology.id === mainPrinter.technology.id);

            // First extruder change
            if(!index || index === 1){
                const materials = [...new Set(availableResources.map(r => r.material.id))];
                if(materials.length === 1){setSelectedMaterialId1(materials[0]); card.material1 = materials[0];}

                const brands = [...new Set(availableResources.filter(r => !card.material1 || r.material.id === card.material1).map(r => r.brand.id))];
                if(card.material1 && brands.length === 1){setSelectedBrandId1(brands[0]); card.brand1 = brands[0];}
                else{setSelectedColor1(null); card.color1 = null;}

                const colors = hasColor ? [...new Set(availableResources
                    .filter(r => card.brand1 === undefined || card.brand1 === null || (r.material.id === getElementId(card.material1) && r.brand.id === getElementId(card.brand1)))
                    .map(r => r.color.id))] : [];
                if(hasColor && colors.length === 1){setSelectedColor1(colors[0]); card.color1 = colors[0];}
            }

            // Second extruder change
            else if(!index || index === 2){
                const materials = [...new Set(availableResources.map(r => r.material.id))];
                if(materials.length === 1){setSelectedMaterialId2(materials[0]); card.material2 = materials[0];}

                const brands = [...new Set(availableResources.filter(r => !card.material2 || r.material.id === card.material2).map(r => r.brand.id))];
                if(card.material2 && brands.length === 1){setSelectedBrandId2(brands[0]); card.brand2 = brands[0];}
                else{setSelectedColor2(null); card.color2 = null;}

                const colors = hasColor ? [...new Set(availableResources
                    .filter(r => card.brand2 === undefined || card.brand2 === null || (r.material.id === getElementId(card.material2) && r.brand.id === getElementId(card.brand2)))
                    .map(r => r.color.id))] : [];
                if(hasColor && colors.length === 1){setSelectedColor2(colors[0]); card.color2 = colors[0];}
            }

        }
    }

    /* Get id from element (element type of id / path / object) */
    const getElementId = (element) => {
        if(typeof element === "string") {
            let arrayElement = element.split('/');
            return parseInt(arrayElement[arrayElement.length - 1]);
        } else if(element.id) {
            return element.id;
        }
        return element;
    }

    /* Check if printer is selected (= if belongs to selectedPrinters) */
    const isPrinterSelected = (printer) => {
        for(let i = 0; i < selectedPrintersId.length; i++) {
            if(selectedPrintersId[i] === printer.id) {
                return true;
            }
        }
        return false;
    }

    /* Check if selected printer has a smaller printable volume than mainPrinter */
    const isSmallerPrinter = (printer) => {
        return (
            mainPrinter &&
            mainPrinter.id !== printer.id &&
            isPrinterSelected(printer) &&
            (printer.printable_height < mainPrinter.printable_height ||
            printer.printable_width < mainPrinter.printable_width ||
            printer.printable_length < mainPrinter.printable_length)
        );
    }

    /* Check if specific value is smaller than same value on main printer */
    const isSmallerSpecificValue = (printer, volumeValue) => {
        let isSmaller = mainPrinter && mainPrinter.id !== printer.id && isPrinterSelected(printer);
        if(isSmaller) {
            if(volumeValue === 'height') {
                return isSmaller && printer.printable_height < mainPrinter.printable_height;
            } else if(volumeValue === 'width') {
                return isSmaller && printer.printable_width < mainPrinter.printable_width;
            } else if(volumeValue === 'length') {
                return isSmaller && printer.printable_length < mainPrinter.printable_length;
            }
        } else {
            return isSmaller;
        }
    }

    /* Check if printer is selectable depending on main printer */
    const isSelectablePrinter = (printer) => {
        if(!mainPrinter) {
            return false;
        } else {
            return printer.extruders_number !== mainPrinter.extruders_number;
        }
    }

    /* Get formatted string description for selected build */
    const getFormattedSelectedBuild = () => {
        return(
            (selectedBuild.name ? selectedBuild.name :  '') + ' (' +
            (selectedBuild.estimated_print_duration !== undefined? getFormattedDuration(selectedBuild.estimated_print_duration) + ' - ' : '') +
            (selectedBuild.material_1 ? selectedBuild.material_1 + ' - ' : '') +
            (selectedBuild.material_2 ? selectedBuild.material_2 : '') + ')'
        )
    }

    const handlePrintingDataDependingOnBuild = () => {
        // Set data in form - printing data
        if(!selectedBuild) {
            if(card.build_id === undefined || card.build_id === null) {
                if(card.duration === undefined || card.duration === null) {
                    globalCardData['print_duration'] = 0;
                    card.duration = 0;
                    setPrintDuration(null);
                } else {
                    setPrintDuration(card.duration);
                    setValue('duration', card.duration);
                }
                if(card.quantity1 === undefined || card.quantity1 === null || card.quantity1 === 0) {
                    setMaterialQuantity1(0);
                } else {
                    setMaterialQuantity1(card.quantity1);
                    setValue('quantity1', card.quantity1);
                    setIsPrinterDatasSelected(true);
                }
                if(card.quantity2 === undefined || card.quantity2 === null || card.quantity2 === 0) {
                    setMaterialQuantity2(0);
                } else {
                    setMaterialQuantity2(card.quantity2);
                    setValue('quantity2', card.quantity2);
                    setIsPrinterDatasSelected(true);
                }
            } else {
                setPrintDuration(card.duration);
                setValue('duration', card.duration);
                setMaterialQuantity1(card.quantity1);
                setValue('quantity1', card.quantity1);
                setMaterialQuantity2(card.quantity2);
                setValue('quantity2', card.quantity2);
            }

        } else {
            setIsPrinterDatasSelected(true);
            if(selectedBuild.estimated_print_duration !== undefined) {
                setPrintDuration(selectedBuild.estimated_print_duration);
                setValue('duration', selectedBuild.estimated_print_duration);
                globalCardData['print_duration'] = selectedBuild.estimated_print_duration;
                card.duration = selectedBuild.estimated_print_duration;
            }
            if(selectedBuild.required_material_1 !== undefined) {
                setMaterialQuantity1(selectedBuild.required_material_1);
                setValue('quantity1', selectedBuild.required_material_1);
                card.quantity1 = selectedBuild.required_material_1;
            }
            if(selectedBuild.required_material_2 !== undefined) {
                setMaterialQuantity2(selectedBuild.required_material_2);
                setValue('quantity2', selectedBuild.required_material_2);
                card.quantity2 = selectedBuild.required_material_2;
            }
            // Check if printing data is automatic or manual
            if(selectedBuild.estimated_print_duration !== undefined &&
                selectedBuild.required_material_1 !== undefined &&
                selectedBuild.required_material_2 !== undefined) {
                setIsManualPrintingData(false);
            }
        }
    }

    /* Check if a printer is selected, and if there is minimum one controllable printer */
    const enableBuildSelection = () => {
        // Check if there is a selected printer
        if(!mainPrinter || !controllablePrinterApiSelected.brand || !controllablePrinterApiSelected.brand.printer_brand_data ||
            !controllablePrinterApiSelected.brand.printer_brand_data.has_api_get_all_builds) {
            return false;
        }
        // Check if printers are controllable printers
        for (const printer of selectedPrinters) {
            if(printer.controllable_printer_id) {
                return true;
            }
        }
        return false;
    }

    /* Get filtered farm profiles depending on selected printer and materials in card */
    const getFarmProfilesListDependingOnPrinterOrMaterial = () => {
        if(mainPrinter && farmProfiles.data) {
            return farmProfiles.data.filter(profile =>
                // Filter by printer brand, printer model and material (if they exist in profile)
                (!profile.printer_brand || (profile.printer_brand && mainPrinter.brand && profile.printer_brand.id === mainPrinter.brand.id)) &&
                (!profile.printer_model || (profile.printer_model && mainPrinter.model && profile.printer_model.id === mainPrinter.model.id)) &&
                (!profile.material || (profile.material && (selectedMaterialId1 || selectedMaterialId2) &&
                    profile.material.id === selectedMaterialId1 || profile.material.id === selectedMaterialId2))
            );
        }
        return [];
    }

    const handleFarmProfileChange = (profileId) => {
        if(profileId && parseInt(profileId) > 0) {
            card.farm_profile = profileId;
            setSelectedFarmProfileId(profileId);
        } else {
            card.farm_profile = null;
            setSelectedFarmProfileId(null);
        }
    }

    const handleEditFarmProfilesButtonClick = (e) => {
        e.preventDefault();
        setIsFarmProfileModalOpen(true);
    }

    if(printerBrands.isLoading || printers.isLoading || resources.isLoading || apiConnections.isLoading || farmProfiles.isLoading) return <Loading/>
    if(printers.isError || resources.isError || printerBrands.isError || apiConnections.isError || farmProfiles.isError) return <Error errorMessage={printers.error}/>

    return(
        <div className="main__card-slicer">

            {/* Farm profiles edit modal */}
            {isFarmProfileModalOpen &&
                <FarmProfileModal
                    isOpen={isFarmProfileModalOpen} closeModal={() => setIsFarmProfileModalOpen(false)}
                    systemType={CELL_TYPES.PRINTER} farmProfiles={farmProfiles.data ? farmProfiles.data : []}
                    getFarmProfiles={() => farmProfiles.refetch()}
                />
            }

            <Dialog open={isBuildTableOpen} onClose={() => setIsBuildTableOpen(false)} classes={{paper: classes.dialog_paper}}>
                <DialogContent>
                    <CardSlicerBuildTable
                        setIsBuildTableOpen={setIsBuildTableOpen}
                        apiConnection={controllablePrinterApiSelected}
                        setSelectedBuild={setSelectedBuild}
                        handleBuildChange={handleBuildChange}
                        selectedPrinters={selectedPrinters}
                    />
                </DialogContent>
            </Dialog>

            <form onSubmit={handleSubmit(handleClickContinue, () => {alert('Please fill all fields.')})}>
                <br/>
                {
                    isScheduled &&
                        <Alert severity="warning">
                            This PIC is already scheduled, so the attributed resources cannot be updated.
                        </Alert>
                }
                <div className="block">
                    <h2>Attributed resources</h2>
                    <br/>
                    {/* Printer manufacturer */}
                    <Controller
                        control={control}
                        name="printer_brand"
                        rules={{required: false}}
                        render={() => (
                            <TextField
                                select
                                label='Select a machine manufacturer'
                                value={selectedPrinterBrandId ? selectedPrinterBrandId : ''}
                                onChange={(e) => handlePrinterBrandChange(e.target.value)}
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                disabled={isScheduled}
                            >
                                {printerBrands.data.map((brand, index) => (
                                    <MenuItem key={index} value={brand.id}>
                                        {brand.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                    { controllablePrinterApiSelected !== null &&
                        <p className="machine-manufacturer-connected"><i className='fa fa-check'/> Connection enabled</p>
                    }
                    {selectedPrinterBrandId &&
                    <FormControl className="form-control-select" disabled={isScheduled}>
                        <InputLabel id="printersLabel" className="label-select">Select printers</InputLabel>
                        <Select labelId="printersLabel" id="printersSelect" className="select-multiple"
                                multiple value={selectedPrintersId} input={<OutlinedInput label="Select printers"/>}
                                renderValue={() => displaySelectedPrinters()}
                                onChange={(event) => handleSelectedPrintersChange(event.target.value)}>
                            {printers.data.map((printer, index) => (
                                parseInt(printer.brand.id) === parseInt(selectedPrinterBrandId) &&
                                <MenuItem key={printer.name + printer.id} value={printer.id} disabled={isSelectablePrinter(printer)}>
                                    <Checkbox checked={isPrinterSelected(printer)}/>
                                    <ListItemText>
                                        <span className={"printers-select-text-name"}>{printer.name}</span>
                                        { printer.controllable_printer_id &&
                                            <span className={"printers-select-text-connected"}><i className='fa fa-check-circle'/></span>
                                        }
                                        <span className={"printers-select-text-volume"}>
                                            <span> (</span>
                                            <span className={isSmallerSpecificValue(printer, 'length') ?
                                                "card-slicer__list-item-warning-message" : ""}>
                                                {printer.printable_length}
                                            </span>
                                            <span> x </span>
                                            <span className={isSmallerSpecificValue(printer, 'width') ?
                                                "card-slicer__list-item-warning-message" : ""}>
                                                {printer.printable_width}
                                            </span>
                                            <span> x </span>
                                            <span className={isSmallerSpecificValue(printer, 'height') ?
                                                "card-slicer__list-item-warning-message" : ""}>
                                                {printer.printable_height}
                                            </span>
                                            {((mainPrinter && (printer.id === mainPrinter.id)) || (!mainPrinter && index === 0) ) &&
                                                <span> - L x W x H</span>
                                            }
                                            <span>)</span>
                                        </span>
                                    </ListItemText>
                                    {(mainPrinter && (printer.id === mainPrinter.id)) &&
                                        <span>main printer</span>
                                    }
                                    {(isSmallerPrinter(printer)) &&
                                        <span className="card-slicer__list-item-warning-message">
                                            <i className="fa fa-exclamation-triangle card-slicer__list-item-warning-icon"/> smaller printable volume
                                        </span>
                                    }
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    }
                    {selectedBuild &&
                    <div className="selected-build">
                        <p className="selected-build-description">{'Selected build: ' + getFormattedSelectedBuild()}</p>
                        <button onClick={() => handleBuildChange(null)} className="selected-build-delete-button">
                            <i className="fa fa-times" />
                        </button>
                    </div>
                    }
                    {(selectedPrinterBrandId && controllablePrinterApiSelected !== null) &&
                        <button type="button" className="select-build-button" onClick={() => setIsBuildTableOpen(true)} disabled={!enableBuildSelection()}>
                            {selectedBuild ? 'Change build' : 'Select a build'}
                        </button>
                    }
                    {isSelectedBuildError &&
                        <p className="selected-build-error">
                            <i className='fa fa-exclamation-triangle'/>
                            {controllablePrinterApiSelected === null &&
                                <span> Could not find build linked to this printer (API credentials not found)</span>
                            }
                            {controllablePrinterApiSelected !== null &&
                                <span> Saved build could not be found and has been deleted</span>
                            }
                        </p>
                    }
                    {(mainPrinter && mainPrinter.is_fiber) &&
                        <div className="checkbox-is-fiber">
                            <input
                                type="checkbox" id="chk-fiber" checked={isFiberUsed}
                                onChange={e => {
                                    card.is_fiber_used = e.target.checked;
                                    setIsFiberUsed(e.target.checked);
                                }}
                            />
                            <label htmlFor="chk-fiber"><span className="chk"></span>
                                Use fiber
                            </label>
                        </div>
                    }

                    {/* Material 1 selection */}
                    {
                        mainPrinter &&
                        <Controller
                            control={control}
                            name='material1'
                            rules={{required: false}}
                            render={() => (
                                <TextField
                                    select
                                    label='Select a material'
                                    value={selectedMaterialId1 ? selectedMaterialId1 : ''}
                                    onChange={(e) => handleMaterialSelect(e.target.value, 1)}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    disabled={isScheduled}
                                >
                                    {[...uniqueMaterials()].map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    }

                    {/* Brand 1 selection */}
                    {
                        selectedMaterialId1 &&
                        <Controller
                            control={control}
                            name='brand1'
                            rules={{required: false}}
                            render={() => (
                                <TextField
                                    select
                                    label='Select a manufacturer'
                                    value={selectedBrandId1 ? selectedBrandId1 : ''}
                                    onChange={(e) => handleBrandSelect(e.target.value, 1)}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    disabled={isScheduled}
                                >
                                    {[...uniqueBrands(1)].map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    }

                    {/* Color 1 selection */}
                    {
                        selectedBrandId1 && mainPrinter.technology.has_color &&
                        <Controller
                            control={control}
                            name='color1'
                            rules={{required: false}}
                            render={() => (
                                <TextField
                                    select
                                    label='Select a color'
                                    value={selectedColor1 ? selectedColor1 : ''}
                                    onChange={(e) => handleColorSelect(e.target.value, 1)}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    disabled={isScheduled}
                                >
                                    {[...uniqueColors(1)].map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    }

                    {/* Material 2 selection */}
                    {
                        mainPrinter && mainPrinter.extruders_number > 1 &&
                        <Controller
                            control={control}
                            name='material2'
                            rules={{required: false}}
                            render={() => (
                                <TextField
                                    select
                                    label='Select a second material'
                                    value={selectedMaterialId2 ? selectedMaterialId2 : ''}
                                    onChange={(e) => handleMaterialSelect(e.target.value, 2)}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    disabled={isScheduled}
                                >
                                    {[...uniqueMaterials()].map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    }

                    {/* Brand 2 selection */}
                    {
                        selectedMaterialId2 &&
                        <Controller
                            control={control}
                            name='brand2'
                            rules={{required: false}}
                            render={() => (
                                <TextField
                                    select
                                    label='Select a second manufacturer'
                                    value={selectedBrandId2 ? selectedBrandId2 : ''}
                                    onChange={(e) => handleBrandSelect(e.target.value, 2)}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    disabled={isScheduled}
                                >
                                    {[...uniqueBrands(2)].map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    }

                    {/* Color 2 selection */}
                    {
                        selectedBrandId2 && mainPrinter.technology.has_color &&
                        <Controller
                            control={control}
                            name='color2'
                            rules={{required: false}}
                            render={() => (
                                <TextField
                                    select
                                    label='Select a second color'
                                    value={selectedColor2 ? selectedColor2 : ''}
                                    onChange={(e) => handleColorSelect(e.target.value, 2)}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    disabled={isScheduled}
                                >
                                    {[...uniqueColors(2)].map(([id, name]) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    }


                    {/* Profile selection */}
                    {(mainPrinter && selectedMaterialId1) &&
                        <div>
                            <Controller
                                control={control}
                                name='farm_profile'
                                rules={{required: false}}
                                render={() => (
                                    <TextField
                                        select
                                        label='Select a farm profile'
                                        value={selectedFarmProfileId !== null ? selectedFarmProfileId : ''}
                                        onChange={(e) => handleFarmProfileChange(e.target.value)}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        disabled={isScheduled}
                                    >
                                        <MenuItem key="no-farm-profile" value={null}>-- No farm profile --</MenuItem>
                                        {getFarmProfilesListDependingOnPrinterOrMaterial().map(profile => (
                                            <MenuItem key={profile.id} value={profile.id}>
                                                {profile.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            <button className="farm-profile-edit-button" onClick={(e) => handleEditFarmProfilesButtonClick(e)}>
                                <MaterialIcon label="edit"/>
                                <span>Edit profiles</span>
                            </button>
                        </div>
                    }
                </div>

                <div className="block">
                    <h2>Printing data</h2>
                    <div>
                        <img src={printing_datas_manual}
                             className={(isManualPrintingData && isPrinterDatasSelected)? 'enable printing-data-mode-selected' : 'enable'}
                             onClick={() => {
                            setIsPrinterDatasSelected(true);
                            setIsManualPrintingData(true);
                        }} alt="manual"/>
                        &nbsp;<img src={printing_datas_brand_1} alt="brand_1"/>
                        &nbsp;<img src={printing_datas_brand_2} alt="brand_2"
                                   className={
                                       (controllablePrinterApiSelected && selectedPrinterBrand.name === 'Markforged' && selectedBuild) ?
                                           (!isManualPrintingData ? 'enable printing-data-mode-selected' : 'enable') : ''
                                   }
                                   onClick={() => {
                                       if (controllablePrinterApiSelected && selectedPrinterBrand.name === 'Markforged' && selectedBuild) {
                                           setIsManualPrintingData(false)
                                       }}}
                              />
                        &nbsp;<img src={printing_datas_brand_3} alt="brand_3"/>
                    </div>
                    {
                        isPrinterDatasSelected &&
                        <>
                            <h3>Estimated printing time</h3>
                            <p>Get the value on your slicer</p>
                            {
                                // The condition is needed because the InputMask has a problem
                                // with the disabled attribute
                                !isScheduled && isManualPrintingData?
                                    <Controller
                                        control={control}
                                        name="duration"
                                        defaultValue={printDuration}
                                        rules={{required: false}}
                                        render={() =>
                                            <InputMask
                                                mask="aaa:ba"
                                                onChange={(e) => {
                                                    setPrintDuration(getConvertedDuration(e.target.value));
                                                    globalCardData['print_duration'] = getConvertedDuration(e.target.value);
                                                    card.duration = getConvertedDuration(e.target.value);
                                                    refreshGlobalState({});
                                                }}
                                                formatChars={{
                                                    'a': '[0-9]',
                                                    'b': '[0-5]',
                                                }}
                                                alwaysShowMask={true}
                                                maskChar="0"
                                                value={getFormattedLongDuration(printDuration, ':')}
                                            >
                                                {(inputProps) =>
                                                    <TextField
                                                        label="Duration (hhh:mm)"
                                                        variant="outlined"
                                                        size="small"
                                                        {...inputProps}
                                                    />}
                                            </InputMask>
                                        }
                                    />
                                :
                                    <TextField
                                        label="Duration (hhh:mm)"
                                        value={getFormattedLongDuration(printDuration, ':')}
                                        variant="outlined"
                                        size="small"
                                        disabled={isScheduled || !isManualPrintingData}
                                    />
                            }

                            <h3>Estimated material needs</h3>
                            <p>Get the value on your slicer</p>
                            <Controller
                                control={control}
                                name='quantity1'
                                value={materialQuantity1}
                                rules={{required: false}}
                                render={() => (
                                    <TextField
                                        label="Material quantity (g)"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={materialQuantity1}
                                        onChange={(e) => {
                                            setMaterialQuantity1(Math.floor(parseInt(e.target.value)));
                                            card.quantity1 = Math.floor(parseInt(e.target.value));
                                            refreshGlobalState({});
                                        }}
                                        disabled={isScheduled || !isManualPrintingData}
                                    />
                                )}
                            />

                            &nbsp;

                            {
                                mainPrinter && mainPrinter.extruders_number > 1 &&
                                <Controller
                                    control={control}
                                    name='quantity2'
                                    defaultValue={materialQuantity2}
                                    rules={{required: false}}
                                    render={() => (
                                        <TextField
                                            label="Second material quantity (g)"
                                            type="number"
                                            variant="outlined"
                                            size="small"
                                            defaultValue={materialQuantity2}
                                            onChange={(e) => {
                                                setMaterialQuantity2(Math.floor(parseInt(e.target.value)));
                                                card.quantity2 = Math.floor(parseInt(e.target.value));
                                                refreshGlobalState({});
                                            }}
                                            disabled={isScheduled || !isManualPrintingData}
                                        />
                                    )}
                                />
                            }
                        </>
                    }
                </div>

                <div className="button-zone">
                    <button><h2>Continue</h2></button>
                </div>
            </form>

        </div>
    )
}

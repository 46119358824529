import {getCurrentUser, isUserGranted} from "../../CommonFunctions";
import React, {useEffect, useState} from "react";
import {fetchData, useGetData} from "../../../api/useFetch";
import {Loading} from "../../common/Loading";
import {Error} from "../../common/Error";
import {AlertMessage} from "../../common/AlertMessage";
import {PicManagerBlock} from "./PicManagerBlock";
import {PicActiveTable} from "./PicActiveTable";
import {PicTemplateTable} from "./PicTemplateTable";
import {PicDraftTable} from "./PicDraftTable";
import {PicProjectTable} from "./PicProjectTable";
import {PicDeleteModal} from "./PicDeleteModal";
import {findElementById} from "../functions/PicManagerFunctions";
import {postPic} from "../../../api/apiPic";
import {PicManageProjectsModal} from "./PicManageProjectsModal";
import {PicCreateProjectModal} from "./PicCreateProjectModal";
import {PicManagerToolbar} from "./PicManagerToolbar";
import {formatPic, formatPicsList} from "../../../services/PicsService";

export const PicManager = () => {

    const currentUser = getCurrentUser();

    const pics = useGetData(
        'picData',
        'pics/pic_manager'
    );

    const templates = useGetData(
        'picTemplates',
        'pics/templates/pic_manager'
    );

    const ownedProjects = useGetData('ownedProjectsData', `projects?user.id=${currentUser.id}`);
    const userInProjects = useGetData('userInProjectsData', `projects?usersInProject.user.id=${currentUser.id}`);

    // Formatted pics list
    const [generalPicsList, setGeneralPicsList] = useState([]);
    const [generalTemplatesList, setGeneralTemplatesList] = useState([]);

    // Data lists
    const [activePicsList, setActivePicsList] = useState([]);
    const [templatesList, setTemplatesList] = useState([]);
    const [draftPicsList, setDraftPicsList] = useState([]);
    const [projectsList, setProjectsList] = useState([]);

    // Filtered data lists
    const [filteredActivePicsList, setFilteredActivePicsList] = useState([]);
    const [filteredTemplatesList, setFilteredTemplatesList] = useState([]);
    const [filteredDraftPicsList, setFilteredDraftPicsList] = useState([]);
    const [filteredProjectsList, setFilteredProjectsList] = useState([]);

    // Interaction states (modal, alert, ...)
    const [alert, setAlert] = useState();
    const [showDeletePicModal, setShowDeletePicModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [selectedDataType, setSelectedDataType] = useState(null);
    const [showManagePicProjectsModal, setShowManagePicProjectsModal] = useState(false);
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isActivePicDataLoading, setIsActivePicDataLoading] = useState(true);
    const [isTemplateDataLoading, setIsTemplateDataLoading] = useState(true);
    const [isDraftPicDataLoading, setIsDraftPicDataLoading] = useState(true);
    const [isProjectDataLoading, setIsProjectDataLoading] = useState(true);

    useEffect(() => {
        if(pics.data !== undefined) {
            buildPicsListData(pics.data);
        }
    }, [pics.isLoading]);

    useEffect(() => {
        if(templates.data !== undefined) {
            buildTemplatesListData(templates.data);
        }
    }, [templates.isLoading]);

    useEffect(() => {
        if(ownedProjects.data !== undefined && userInProjects.data !== undefined && pics.data !== undefined) {
            buildProjectsListData(ownedProjects.data, userInProjects.data);
        }
    }, [ownedProjects.isLoading, userInProjects.isLoading, pics.isLoading]);

    useEffect(() => {
        if(pics.isFetching) {
            setIsActivePicDataLoading(true);
            setIsTemplateDataLoading(true);
            setIsDraftPicDataLoading(true);
            setIsProjectDataLoading(true);
        }
        else{
            setIsActivePicDataLoading(false);
            setIsTemplateDataLoading(false);
            setIsDraftPicDataLoading(false);
            setIsProjectDataLoading(false);
        }
    }, [pics.isFetching]);

    useEffect(() => {
        if(ownedProjects.isFetching || userInProjects.isFetching) {
            setIsProjectDataLoading(true);
        }
        else{
            setIsProjectDataLoading(false);
        }
    }, [ownedProjects.isFetching, userInProjects.isFetching]);

    const successCallback = (message) => {
        let promises = [];
        promises.push(
            pics.refetch().then(data => {
                pics.data = data.data;
            })
        );
        promises.push(
            templates.refetch().then(data => {
                templates.data = data.data;
            })
        );
        promises.push(
            ownedProjects.refetch().then(data => {
                ownedProjects.data = data.data;
                userInProjects.refetch().then(data => {
                    userInProjects.data = data.data;
                });
            })
        );
        Promise.all(promises).then(() => {
            buildPicsListData(pics.data);
            buildTemplatesListData(templates.data);
            buildProjectsListData(ownedProjects.data, userInProjects.data);
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        let promises = [];
        promises.push(pics.refetch());
        promises.push(templates.refetch());
        promises.push(ownedProjects.refetch());
        promises.push(userInProjects.refetch());
        Promise.all(promises).then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    const isScheduled = (picId) => {
        return pics.data.filter(pic => pic.id === picId && pic.tasks.length > 0).length > 0;
    };

    // Build pic tables data (active and draft)
    const buildPicsListData = (pics) => {
        let newActivePicsList= [];
        let newDraftPicsList = [];
        // Format pics to get last edited pic as principal pic foreach pic
        let newPicsList = formatPicsList(pics);
        // Sort pics: active pics or draft pics
        newPicsList.map(pic => {
            let newPic = formatPicDataForTable(pic);
               if(pic.complete) {
                   newActivePicsList.push(newPic);
               } else {
                   newDraftPicsList.push(newPic);
               }
        });
        setActivePicsList(newActivePicsList);
        setDraftPicsList(newDraftPicsList);
        setGeneralPicsList(newPicsList);
        setIsActivePicDataLoading(false);
        setIsDraftPicDataLoading(false);
    }

    // Build template table data
    const buildTemplatesListData = (templates) => {
        let newTemplatesList = [];
        let formattedTemplatesList = formatPicsList(templates);
        formattedTemplatesList.map(template => {
            newTemplatesList.push(formatPicDataForTable(template));
        });
        setTemplatesList(newTemplatesList);
        setGeneralTemplatesList(formattedTemplatesList);
        setIsTemplateDataLoading(false);
    }

    // Build projects table data (with owned projects and projects including user)
    const buildProjectsListData = (ownedProjects, userInProjects) => {
        setProjectsList([
            ...buildProjectsListFromOneList(ownedProjects),
            ...buildProjectsListFromOneList(userInProjects)
        ]);
        setIsProjectDataLoading(false);
    }

    // Build projects from one projects list
    const buildProjectsListFromOneList = (projectsList) => {
        let newProjectsList = [];
        projectsList.map(project => {
            let actualProject = {
                key: project.id,
                id: project.id,
                name: project.name,
                color: project.color ? project.color.hex_code : '#FFFFFF',
                children: []
            }
            if(project.project_pics && project.project_pics.length > 0) {
                project.project_pics.map(projectPic => {
                    const picData = projectPic.pic;
                    if(picData && !picData.original_pic) {
                        // let newPic = findElementById(pics.data, picData.id);
                        // newPic = formatPic(newPic);
                        // let actualPic = JSON.parse(JSON.stringify(newPic));
                        let actualPic = findElementById(pics.data, picData.id);
                        
                        actualPic = formatPicDataForTable(actualPic);
                        actualPic.isActive = picData.complete === true;
                        actualPic.key = project.id + '-' + actualPic.id;
                        actualProject.children.push(actualPic);
                    }
                });
            }
            newProjectsList.push(actualProject);
        });
        return newProjectsList;
    }

    // Format one PIC data
    const formatPicDataForTable = (pic) => {
        return {
            // Data for tables
            id: pic.id,
            name: pic.name,
            version: pic.version,
            material1: (pic.slicer && pic.slicer.brand1 && pic.slicer.material1) ?
                getFormattedMaterialName(pic.slicer.brand1, pic.slicer.material1, pic.slicer.color1 ? pic.slicer.color1 : null) : '--',
            material2: (pic.slicer && pic.slicer.brand2 && pic.slicer.material2) ?
                getFormattedMaterialName(pic.slicer.brand2, pic.slicer.material2, pic.slicer.color2 ? pic.slicer.color2 : null) : '--',
            lastUpdate: pic.last_edit,
            user: pic.user,
            project_pics: pic.project_pics,
            // Data for filters
            slicerData: pic.slicer
        };
    }

    const getFormattedMaterialName = (brand, material, color) => {
       let formattedMaterial = material.name + ' - ' + brand.name;
       if(color !== null) {
           formattedMaterial += ' (' + color.color_name + ')';
       }
       return formattedMaterial;
    }

    /* Reset pic data for duplicate actions */
    const resetPicData = (pic) => {
        pic.id = null;
        pic.original_pic = null;
        pic.copies = [];
        pic.date_add = null;
        pic.last_edit = null;
        pic.edit_number = 0;
        return pic;
    }

    const handleDeletePic = (id, type) => {
        if(type === 'pic') {
            setSelectedData(findElementById(generalPicsList, id));
        } else {
            setSelectedData(findElementById(generalTemplatesList, id));
            setSelectedDataType('template');
        }
        setShowDeletePicModal(true);
    }

    const handleDuplicatePic = async (picId) => {
        let errorMessage = 'An error occurred while duplicating pic';
        await fetchData('pics/' + picId)
            .then(response => response.json())
            .catch(() => errorCallback(errorMessage))
            .then(pic => {
                pic = resetPicData(pic);
                pic.name = pic.name + ' - copy';
                postPic(pic)
                    .then((picData) => {
                        if(picData.id) {
                            window.location = '/builder/' + picData.id
                        }
                    })
                    .catch(() => errorCallback(errorMessage));
            })
            .catch(() => errorCallback(errorMessage));
    }

    const handleManagePicProjects = (picId) => {
        let pic = findElementById(generalPicsList, picId);
        setSelectedData(pic);
        setShowManagePicProjectsModal(true);
    }

    const handleCreatePicFromTemplate = async (templateId) => {
        let errorMessage = 'An error occurred while creating PIC from template';
        await fetchData('pics/' + templateId)
            .then(response => response.json())
            .catch(() => errorCallback(errorMessage))
            .then(template => {
                template = resetPicData(template);
                template.name = template.name + ' - PIC';
                template.is_template = false;
                postPic(template)
                    .then((templateData) => window.location = '/builder/' + templateData.id)
                    .catch(() => errorCallback(errorMessage));
            })
            .catch(() => errorCallback(errorMessage));
    }

    const handleDuplicateTemplate = async (templateId) => {
        let errorMessage = 'An error occurred while duplicating template';
        await fetchData('pics/' + templateId)
            .then(response => response.json())
            .catch(() => errorCallback(errorMessage))
            .then(template => {
                template = resetPicData(template);
                template.is_template = true;
                template.name = template.name + ' - copy';
                postPic(template)
                    .then((templateData) => window.location = '/builder/template/' + templateData.id)
                    .catch(() => errorCallback(errorMessage));
            })
            .catch(() => errorCallback(errorMessage));
    }

    const handleCreateProjectClick = () => {
        setShowCreateProjectModal(true);
    }
    const handleCreateTemplateClick = () => {
        window.location = '/builder/template';
    }

    const setDataLoading = () => {
        setIsActivePicDataLoading(true);
        setIsTemplateDataLoading(true);
        setIsDraftPicDataLoading(true);
        setIsProjectDataLoading(true);
    }

    if(pics.isLoading || templates.isLoading || ownedProjects.isLoading || userInProjects.isLoading || isLoading) return <Loading/>
    if(pics.isError) return <Error errorMessage={pics.error}/>
    if(templates.isError) return <Error errorMessage={templates.error}/>
    if(ownedProjects.isError) return <Error errorMessage={ownedProjects.error}/>
    if(userInProjects.isError) return <Error errorMessage={userInProjects.error}/>

    return(
        <div>
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}
            {showDeletePicModal &&
                <PicDeleteModal successCallback={successCallback} errorCallback={errorCallback} isScheduled={isScheduled}
                                setShowDeletePicModal={setShowDeletePicModal} selectedData={selectedData}
                                selectedDataType={selectedDataType} setSelectedDataType={setSelectedDataType}/>
            }
            {showManagePicProjectsModal &&
                <PicManageProjectsModal pic={selectedData} projects={projectsList} setLoading={setDataLoading}
                                        cancelCallback={() => setShowManagePicProjectsModal(false)}
                                        setShowManagePicProjectsModal={setShowManagePicProjectsModal}
                                        successCallback={successCallback} errorCallback={errorCallback}/>
            }
            {showCreateProjectModal &&
                <PicCreateProjectModal cancelCallback={() => setShowCreateProjectModal(false)}
                                       setShowCreateProjectModal={setShowCreateProjectModal}
                                       successCallback={successCallback} errorCallback={errorCallback}/>
            }
            <PicManagerToolbar activePicsList={activePicsList} templatesList={templatesList} draftPicsList={draftPicsList}
                               projectsList={projectsList} setFilteredActivePicsList={setFilteredActivePicsList}
                               setFilteredTemplatesList={setFilteredTemplatesList} setFilteredDraftPicsList={setFilteredDraftPicsList}
                               setFilteredProjectsList={setFilteredProjectsList} setIsLoading={setIsLoading} currentUser={currentUser}/>
            <div>
                <PicManagerBlock title="Active PIC">
                    <PicActiveTable data={filteredActivePicsList} handleDeletePic={handleDeletePic} isTableDataLoading={isActivePicDataLoading}
                                    handleDuplicatePic={handleDuplicatePic} handleManagePicProjects={handleManagePicProjects}/>
                </PicManagerBlock>
                <div className="multi-blocks-inline">
                    <div className="block-inline-small">
                        <PicManagerBlock title="Templates" actionAdd={isUserGranted('ROLE_CREATE_PIC') ? handleCreateTemplateClick : undefined}>
                            <PicTemplateTable setSelectedData={setSelectedData} setShowDeletePicModal={setShowDeletePicModal}
                                              data={filteredTemplatesList} handleDeletePic={handleDeletePic}
                                              handleCreatePicFromTemplate={handleCreatePicFromTemplate}
                                              isTableDataLoading={isTemplateDataLoading}
                                              handleDuplicateTemplate={handleDuplicateTemplate}/>
                        </PicManagerBlock>
                    </div>
                    <div className="block-inline-large">
                        <PicManagerBlock title="Drafts">
                            <PicDraftTable data={filteredDraftPicsList} handleDeletePic={handleDeletePic} isTableDataLoading={isDraftPicDataLoading}
                                           handleDuplicatePic={handleDuplicatePic} handleManagePicProjects={handleManagePicProjects}/>
                        </PicManagerBlock>
                    </div>
                </div>
                <PicManagerBlock title="Projects" actionAdd={isUserGranted('ROLE_CREATE_PROJECT') ? handleCreateProjectClick : undefined}>
                    <PicProjectTable data={filteredProjectsList} handleDeletePic={handleDeletePic} isTableDataLoading={isProjectDataLoading}
                                     handleDuplicatePic={handleDuplicatePic} handleManagePicProjects={handleManagePicProjects}/>
                </PicManagerBlock>
            </div>
        </div>
    );
}

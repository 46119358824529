import {DISPLAY_MODES} from "../Calendar";

export const ToolbarDisplayMode = (props) => {
    const {displayMode, setDisplayMode} = props;

    const handleClickMode = (e, mode) => {
        setDisplayMode(mode);
        localStorage.setItem('displayMode', mode);
    };

    const getButtonClassNames = (mode) => {
        let className = "gc-toolbar-display-mode__button";
        if (displayMode === mode) {
            className += " gc-toolbar-display-mode__button--selected";
        }
        return className;
    };

    return (
        <div className="gc-toolbar__display-mode gc-toolbar-display-mode">
            {Object.entries(DISPLAY_MODES).map(([displayModeKey, displayModeValue]) => {
                return (
                    <button
                        key={displayModeKey}
                        className={getButtonClassNames(displayModeValue)}
                        onClick={(e) => handleClickMode(e, displayModeValue)}
                    >
                        {displayModeValue}
                    </button>
                );
            })}
        </div>
    );
};

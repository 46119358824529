import React, {useState} from "react";
import {useGetData} from "../../api/useFetch";
import {getCurrentUser} from "../CommonFunctions";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {Avatar} from "../common/Avatar";
import {ProfileData} from "./ProfileData";
import {ChangePasswordComponent} from "./ChangePasswordComponent";
import {AlertMessage} from "../common/AlertMessage";
import {NotificationManager} from "./NotificationManager";

export const Profile = () => {
    let currentUser = getCurrentUser();

    const [shouldRefreshEdit, refreshEdit] = useState(0);
    const profile = useGetData(`profileData-${shouldRefreshEdit}`,'users/' + currentUser.id);

    const [alert, setAlert] = useState();

    const successCallback = (message) => {
        profile.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        profile.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    if(profile.isLoading) return <Loading/>
    if(profile.isError) return <Error errorMessage={profile.error.message}/>

    return (
        <>
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}

            <div id="main__profile">
                <div className="flex">
                    <Avatar
                        user={profile.data}
                        isAdmin={profile.data.admin}
                        size="huge"
                    />
                    <div className="block">
                        <ProfileData user={profile.data} successCallback={successCallback} errorCallback={errorCallback}/>
                    </div>
                </div>

                <div className="flex">
                    <div className="block">
                        <ChangePasswordComponent successCallback={successCallback} errorCallback={errorCallback}/>
                    </div>

                    <NotificationManager user={profile.data} successCallback={successCallback} errorCallback={errorCallback}/>
                </div>
            </div>
        </>
    )
}

import React, {useEffect} from 'react';
import {Dialog, DialogContent, TextField, IconButton, Grid} from '@material-ui/core';
import time_picker_icone from "../../images/icons/pic/TimePickerIcone.png";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {getConvertedDuration} from "../CommonFunctions";
import InputMask from "react-input-mask";
import {getCurrentDateAtMidnight} from "../../utils/date";

export const DateTimePickerComponent = (props) => {

    const {isDateTimePickerOpen, handleClose, selectedDate, setSelectedDate, selectedTime, setSelectedTime, setConvertedSelectedTime, messageTimePicker, validateIcon, showTime} = props;


    const handleChangeTime = (time) => {
        if(time){
            setSelectedTime(time);
            setConvertedSelectedTime(getConvertedDuration(time));
        }
        else {
            setSelectedTime('00:00');
            setConvertedSelectedTime(0);
        }
    }

    const handleDateChange = (date) => {
        date.setHours(0);
        date.setMinutes(0);
        setSelectedDate(date);
    }

    useEffect(() => {
        if(isDateTimePickerOpen)
            handleDateChange(getCurrentDateAtMidnight());
    }, [isDateTimePickerOpen]);

    return(
        <Dialog open={isDateTimePickerOpen} onClose={handleClose}>
            <DialogContent>
                <div className="date-time-dialog">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="static"
                            disableToolbar
                            format="MM/dd/yyyy"
                            InputProps={{ classes: { root: {fontFamily: "Cabin"} } }}
                            onChange={handleDateChange}
                            value={selectedDate}
                        />
                    </MuiPickersUtilsProvider>
                    {showTime &&
                        <>
                            <hr/>
                            <div className="time-picker">
                                <img src={time_picker_icone} alt="time_picker"/>
                                <h3>{messageTimePicker}</h3>
                                <InputMask
                                    mask="ab:cb"
                                    onChange={(e) => handleChangeTime(e.target.value)}
                                    formatChars={{
                                        'a': '[0-2]',
                                        'b': '[0-9]',
                                        'c': '[0-5]',
                                    }}
                                    alwaysShowMask={true}
                                    value={selectedTime}
                                    maskChar="0"
                                >
                                    {(inputProps) =>
                                        <TextField
                                            label="Duration (hh:mm)"
                                            variant="outlined"
                                            size="small"
                                            {...inputProps}
                                        />}
                                </InputMask>
                            </div>
                        </>
                    
                    }
                    
                    {validateIcon &&
                        <Grid 
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            <IconButton aria-label="delete" onClick={handleClose}>
                                <i className="fa fa-check" aria-hidden="true"></i>
                            </IconButton>
                        </Grid>
                    }
                    
                </div>
            </DialogContent>
        </Dialog>
    )
}
DateTimePickerComponent.defaultProps = {
    messageTimePicker: 'Print start time',
    validateIcon: false,
    showTime: true,
}
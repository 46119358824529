import React from 'react';
import {HeaderTabs} from './common/HeaderTabs';
import {BrowserRouter as Router, Route, Switch, useRouteMatch} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {Brands} from "./backoffice/client-data/Brands";
import {Technologies} from "./backoffice/client-data/Technologies";
import {Printers} from "./backoffice/client-data/Printers";
import {Models} from "./backoffice/client-data/Models";
import {Materials} from "./backoffice/client-data/Materials";
import {BrandsData} from "./backoffice/handddle-data/BrandsData";
import {useGetData} from "../api/useFetch";
import {MaterialsData} from "./backoffice/handddle-data/MaterialsData";
import {ResourceEmptyWeightsData} from "./backoffice/handddle-data/ResourceEmptyWeightsData";

const queryClient = new QueryClient();

export const BackofficeMain = () => {
    let {path} = useRouteMatch();

    const headerLinks = [
        {label: 'Backoffice', path: 'Backoffice'}
    ];

    const brandsData = useGetData('brand_datas', 'resource_brand_datas');
    const materialsData = useGetData('materials_data', 'material_datas');
    const resourceEmptyWeightsData = useGetData('resource_empty_weight_datas', 'resource_empty_weight_datas');

    return (
        <Router>
            <HeaderTabs links={headerLinks}/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">

                    <h2 style={{"marginBottom":"15px"}}>Client data</h2>
                    <Switch>
                        <Route exact path={path}>
                            <QueryClientProvider client={queryClient}>
                                <Brands brandsData={brandsData}/>
                                <br />
                                <Technologies />
                                <br />
                                <Printers />
                                <br/>
                                <Models />
                                <br />
                                <Materials materialsData={materialsData}/>
                            </QueryClientProvider>
                        </Route>
                    </Switch>

                    <h2 style={{"marginBottom":"15px", "marginTop":"15px"}}>Handddle data</h2>
                    <BrandsData brandsData={brandsData} resourceEmptyWeightsData={resourceEmptyWeightsData}/>
                    <br/>
                    <MaterialsData materialsData={materialsData} resourceEmptyWeightsData={resourceEmptyWeightsData}/>
                    <br/>
                    <ResourceEmptyWeightsData
                        brandsData={brandsData} materialsData={materialsData}
                        resourceEmptyWeightsData={resourceEmptyWeightsData}
                    />
                </div>
            </div>
        </Router>
    )
}

import {getCurrentTimestamp, getCurrentUser} from "../components/CommonFunctions";
import {fetchData} from "./useFetch";
import {TASK_STATUS} from "../services/gantt/GanttService";

export const requestAutoScheduling = async (existingTasks, newTaskDuration, quantity=1) => {
    const currentTimestamp = getCurrentTimestamp();
    let currentUser = getCurrentUser();

    let input_tasks = [];
    let fixed_tasks = [];
    let maxStart = 0;
    let organization = await fetchData(`organizations`, {
        id: currentUser.organization.id
    });
    organization = await organization.json();
    for(let task of existingTasks){
        // Waiting tasks can have a NULL start date
        const startDate = task.date > 0 ? task.date : 0;
        // Tasks to schedule: not fixed and not priority, which start in the future or less than 5 minutes in the past
        // Otherwise, they are fixed
        if(!task.is_fixed_scheduling && !task.priority && startDate > (currentTimestamp - 300)){ // current date - 5 minutes
            input_tasks.push(JSON.stringify({id: task.id, start: startDate, duration: task.print_duration ? task.print_duration : 0}));
            maxStart = Math.max(maxStart, startDate);
        }
        else{
            fixed_tasks.push(JSON.stringify({id: task.id, start: startDate, duration: task.print_duration ? task.print_duration : 0}));
        }
    }

    // Add new tasks
    for(let i=1; i<=quantity; i++)
        input_tasks.push(JSON.stringify({id:-i, start: maxStart + i, duration: newTaskDuration}))

    input_tasks = '[' + input_tasks.join(',') + ']';
    fixed_tasks = '[' + fixed_tasks.join(',') + ']';

    let smartSchedulingUrl = process.env.REACT_APP_BASE_PROTOCOL + '://' + process.env.REACT_APP_BASE_URL;
    if(process.env.REACT_APP_SMART_SCHEDULING_PORT)
        smartSchedulingUrl += ':' + process.env.REACT_APP_SMART_SCHEDULING_PORT;
    smartSchedulingUrl += process.env.REACT_APP_SMART_SCHEDULING_URI;

    let scheduleResponse = await fetch(smartSchedulingUrl +
        '?input_tasks=' + input_tasks +
        '&fixed_tasks=' + fixed_tasks +
        '&off_days=[6,0]' +
        `&start_hour=${Math.floor(organization[0].open_time/3600)}` +
        `&end_hour=${Math.floor(organization[0].close_time/3600)}` +
        '&margin=0' +
        '&preserve_order=1' +
        '&precision=120' +
        '&start_time=' + currentTimestamp,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    );

    scheduleResponse = await scheduleResponse.json();

    return scheduleResponse;
}

import React, {useState} from 'react';
import {getConvertedDuration, getFormattedDuration} from "../../CommonFunctions";
import {TextField} from "@material-ui/core";
import InputMask from "react-input-mask";

export const CardPostTreatment = (props) => {

    const {picCard, setIsActive, refreshGlobalState, globalCardData, handleContinueCard} = props;

    const card = picCard.card;

    const [, refreshState] = useState();

    const [selectedMethod, setSelectedMethod] = useState(card.method ? card.method : '');
    const [description, setDescription] = useState(card.description ? card.description : '');
    const [selectedTime, setSelectedTime] = useState(card.duration ? getFormattedDuration(card.duration, ':'): '00:00');
    const [convertedSelectedTime, setConvertedSelectedTime] = useState(card.duration ? card.duration : 0);

    const handleClickMachine = (e) => {
        setSelectedMethod(e.target.innerHTML);
        card.method = e.target.innerHTML;
        refreshState({});
    }

    const handleChangeTime = (duration) => {
        if(duration){
            setConvertedSelectedTime(getConvertedDuration(duration));
            card.duration = getConvertedDuration(duration);
        }
        else {
            setConvertedSelectedTime(0);
            card.duration = 0;
        }

        refreshState({});
    }

    const handleClickContinue = () => {
        handleContinueCard(picCard);
        setIsActive(false);
    }

    return (
        <div className="main__card-post-treatment">

            <div className="block select-method">
                <h2>Choose your method</h2>

                <div className="select-method">
                    <button onClick={(e) => handleClickMachine(e)} className={selectedMethod === 'Manual' ? 'selected' : ''}>Manual</button>
                    <button onClick={(e) => handleClickMachine(e)} className={selectedMethod === 'Other' ? 'selected' : ''}>Other</button>
                    {
                        selectedMethod &&
                        <>
                            <p>Indicate time process</p>

                            <InputMask
                                mask="aa:ba"
                                onBlur={e => handleChangeTime(e.target.value)}
                                formatChars={{
                                    'a': '[0-9]',
                                    'b': '[0-5]',
                                }}
                                value={selectedTime}
                                onChange={(e) => setSelectedTime(e.target.value)}
                                alwaysShowMask={true}
                            >
                                {(inputProps) =>
                                    <TextField
                                        label="Duration (hh:mm)"
                                        variant="outlined"
                                        size="small"
                                        {...inputProps}
                                    />}
                            </InputMask>
                        </>
                    }
                </div>
            </div>

            <div className="block">
                <h2>Additionnal instructions</h2>
                <TextField
                    label="Write here..."
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth={true}
                    size="small"
                    defaultValue={description}
                    onBlur={(e) => {
                        setDescription(e.target.value)
                        card.description = e.target.value;
                        refreshState({});
                    }}
                />
            </div>

            <div className="button-zone">
                <button onClick={handleClickContinue}><h2>Continue</h2></button>
            </div>
        </div>
    )
}
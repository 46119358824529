import React from "react";
import {History} from "./history/History";
import {HeaderTabs} from "./common/HeaderTabs";
import {QueryClient, QueryClientProvider} from 'react-query';

const queryClient = new QueryClient();

export const HistoryMain = () => {

    const headerLinks = [
        {label: 'Gantt view', path: 'gantt'},
        {label: 'Print queue', path: 'history'}
    ];

    return (
        <>
            <HeaderTabs links={headerLinks} defaultPath='history'/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">
                    <QueryClientProvider client={queryClient}>
                        <History/>
                    </QueryClientProvider>
                </div>
            </div>
        </>
    )
}




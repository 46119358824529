import React, {useEffect, useState}  from "react";
import {fetchWeatherData} from "../../../services/weatherService";
import {Loading} from "../../common/Loading";

export const WeatherBlock = (props) => {

    const {selectedOrganization} = props;

    const [isLoading, setIsLoading] = useState(true);
    const [organizationWeather, setOrganizationWeather] = useState();

    useEffect(() => {
        if(selectedOrganization && selectedOrganization.longitude && selectedOrganization.latitude) {
            fetchWeatherData(selectedOrganization.longitude, selectedOrganization.latitude)
                .then(data => setOrganizationWeather(data))
                .catch(() => null)
                .finally(() => setIsLoading(false));
        }
    }, [selectedOrganization]);

    return (
        <div className="farm-header-solid-block weather-block">
            {isLoading ?
                <div className="weather-loading">
                    <Loading inline={true}/>
                </div> :
                <>
                    {(!organizationWeather || !organizationWeather.current) &&
                        <p>No weather data</p>
                    }
                    {(organizationWeather && organizationWeather.current) &&
                        <div className="weather-data">
                            {/* Left part */}
                            <div>
                                <div className="weather-condition">
                                    <svg className="weather-icon" viewBox="0 0 30 30" height="30" width="30">
                                        <path d={organizationWeather.current.icon} className="weather-icon-path"/>
                                    </svg>
                                    <span className="weather-description">{organizationWeather.current.conditions}</span>
                                </div>
                                <div className="weather-temperature">
                                    {organizationWeather.current.temperature.current}C°
                                </div>
                            </div>

                            {/* Right part */}
                            <div className="weather-block-right-part">
                                <div className="weather-location">{selectedOrganization.location}</div>
                                <div className="weather-details">
                                    <div>
                                        <div className="weather-info-label">Humidity:</div>
                                        <div className="weather-info-label">Wind:</div>
                                    </div>
                                    <div>
                                        <div className="weather-info-value">{organizationWeather.current.humidity}%</div>
                                        <div className="weather-info-value">{organizationWeather.current.wind}km/h</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
};

import {useEffect, useMemo, useState} from "react";
import {
    dateToHourMinuteString,
    dateToShortDayMonthString,
    dateToTimestampInSec,
    dateToUtcTimestampInSec,
} from "../../../../utils/date";
import {DISPLAY_MODES} from "../Calendar";

export const TasksTimeBar = (props) => {
    const {displayMode, firstDisplayedDate, lastDisplayedDate} = props;
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 50);
        return () => {
            clearInterval(interval);
        };
    }, []);

    /* UTC dates are used to properly handle time changes. */
    const blockWidth = useMemo(() => {
        let firstDateInSec;
        let lastDateInSec;
        let currentDateInSec;
        if (displayMode === DISPLAY_MODES.DAY) {
            firstDateInSec = dateToTimestampInSec(firstDisplayedDate);
            lastDateInSec = dateToTimestampInSec(lastDisplayedDate);

                currentDateInSec = dateToTimestampInSec(currentDateTime);
            
        } else {
            firstDateInSec = dateToUtcTimestampInSec(firstDisplayedDate);
            lastDateInSec = dateToUtcTimestampInSec(lastDisplayedDate);

                currentDateInSec = dateToUtcTimestampInSec(currentDateTime);

        }
        if (currentDateInSec > lastDateInSec) {
            return 100;
        }
        if (currentDateInSec <= firstDateInSec) {
            return 0;
        }
        return ((currentDateInSec - firstDateInSec) / (lastDateInSec - firstDateInSec)) * 100;
    }, [currentDateTime, displayMode, firstDisplayedDate, lastDisplayedDate]);

    const blockText = useMemo(() => {
            switch (displayMode) {
                case DISPLAY_MODES.MONTH:
                    return dateToShortDayMonthString(currentDateTime);
                case DISPLAY_MODES.DAY:
                case DISPLAY_MODES.WEEK:
                default:
                    return dateToHourMinuteString(currentDateTime);

            }
        

    }, [currentDateTime, displayMode]);

    return (
        <div className="gc-tasks-container__time-bar gc-tasks-time-bar">
            <div className="gc-tasks-time-bar__text-container"></div>

            {/* Current date or past dates displayed */}
            {blockWidth > 0 && (
                <div className="gc-tasks-time-bar__block gc-tasks-time-bar-block" style={{width: `${blockWidth}%`}}>
                    <div className="gc-tasks-time-bar-block__past"></div>

                    {/* Current date displayed */}
                    {blockWidth < 100 && (
                        <>
                            <div className="gc-tasks-time-bar-block__text">{blockText}</div>
                            <div className="gc-tasks-time-bar-block__line"></div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

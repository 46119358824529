import React, {useEffect, useState} from 'react';
import {deleteResourceEmptyWeightData} from "../../../api/apiBackoffice";
import {Drawer} from "@material-ui/core";
import {Loading} from "../../common/Loading";
import {useGetData} from "../../../api/useFetch";
import {ResourceEmptyWeightDataForm} from "./ResourceEmptyWeightDataForm";
import {getSortedData} from "../../../services/TablesService";

export const ResourceEmptyWeightsData = (props) => {

    const {brandsData, materialsData, resourceEmptyWeightsData} = props;

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [alert, setAlert] = useState();
    const [actualResourceEmptyWeightData, setActualResourceEmptyWeightData] = useState(null);
    const [emptyWeightsDataSort, setEmptyWeightsDataSort] = useState([]);

    useEffect(() => {
        if(resourceEmptyWeightsData.data !== undefined) {
            resourceEmptyWeightsData.data.map(emptyWeight => {
               emptyWeight.brandName = emptyWeight.brand.name;
               emptyWeight.materialName = emptyWeight.material ? emptyWeight.material.name : null;
            });
            let data = getSortedData(resourceEmptyWeightsData.data, 'initial_quantity', 'asc');
            data = getSortedData(data, 'materialName', 'asc');
            data = getSortedData(data, 'brandName', 'asc');
            setEmptyWeightsDataSort(data);
        }
    }, [resourceEmptyWeightsData]);

    const successCallback = (message) => {
        resourceEmptyWeightsData.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        resourceEmptyWeightsData.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    const handleDelete = async (emptyWeightId) => {
        if(window.confirm('Are you sure you want to delete this Resource Empty Weight Data ?'))
            await deleteResourceEmptyWeightData(emptyWeightId)
                .then(() => successCallback('This Resource Empty Weight Data has been deleted.'))
                .catch(() => errorCallback('Something went wrong.'));
    };

    const handleClose = () => {
        setActualResourceEmptyWeightData(null);
        setRightPartIsOpen(!rightPartIsOpen);
    }

    if(resourceEmptyWeightsData.isLoading) return <Loading/>

    return (
        <div className="backoffice_block" style={{display: "block", border: "2px solid lightgrey", padding: "15px"}}>
            <div className="resource_header">
                <h2>Resources Empty Weights Data</h2>
                <button className="button-link" onClick={() => {
                    setRightPartIsOpen(!rightPartIsOpen);
                }}>
                    <i className="fa fa-plus"/> Add a resource empty weight data
                </button>
            </div>

                <table>
                    <thead>
                        <tr>
                            <th>Brand data</th>
                            <th>Material data</th>
                            <th>Initial Quantity</th>
                            <th>Empty weight</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {emptyWeightsDataSort.map((emptyWeight) => (
                            <tr key={emptyWeight.id}>
                                <td>{emptyWeight.brand.name}</td>
                                <td>{emptyWeight.material ? emptyWeight.material.name : '--'}</td>
                                <td>{emptyWeight.initial_quantity ? emptyWeight.initial_quantity : '--'}</td>
                                <td>{emptyWeight.empty_weight ? emptyWeight.empty_weight : '--'}</td>
                                <td>
                                    <button className="button-link" onClick={()=> {
                                        setActualResourceEmptyWeightData(emptyWeight);
                                        setRightPartIsOpen(!rightPartIsOpen);
                                    }}>
                                        <i className="fas fa-edit" />
                                    </button>
                                    &nbsp;
                                    <button className="button-link" onClick={()=> handleDelete(emptyWeight.id)}><i className="fas fa-trash-alt" /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => handleClose()}>
                <div className="backoffice_drawer_content" style={{width:"500px", "marginTop":"30px"}}>
                    <ResourceEmptyWeightDataForm
                        actualResourceEmptyWeightData={actualResourceEmptyWeightData}
                        setActualResourceEmptyWeightData={setActualResourceEmptyWeightData}
                        setRightPartIsOpen={setRightPartIsOpen}
                        successCallback={successCallback} errorCallback={errorCallback}
                        brandsData={brandsData} materialsData={materialsData}
                    />
                </div>
            </Drawer>
        </div>
    )
}

import React, {useEffect, useReducer, useRef, useState} from "react";
import {fetchData} from "../../api/useFetch";
import {AlertMessage} from "../common/AlertMessage";
import {FarmHeader} from "./header/FarmHeader";
import {Loading} from "../common/Loading";
import {FarmStructure} from "./farmStructure/FarmStructure";
import {FarmDialog} from "./right-panel/FarmDialog";
import {Drawer, makeStyles} from "@material-ui/core";
import {isTargetEqualsOrDescendantOfSpecificClassNode} from "../../services/TablesService";
import {
    getSmartFarmDataGeneralMaintenance,
    getFarmVersion,
    SMART_FARM_COMMANDS,
    getFanSchedulingLocalStorage, removeFanSchedulingLocalStorage
} from "../../services/FarmService";
import {FarmDialogCell} from "./right-panel/FarmDialogCell";
import {FarmDialogStructure} from "./right-panel/FarmDialogStructure";
import {commandsInProcessReducer} from "../../services/farm/CommandsInProcessService";
import {generalCommandsInProcessReducer} from "../../services/farm/generalCommandsInProcessService";
import {farmMaintenanceReducer, farmMaintenancesReducerActions} from "../../services/farm/FarmMaintenancesReducer";
import {useIsMounted} from "../../services/hooks/useIsMounted";
import {getCurrentTimestamp} from "../CommonFunctions";
import {sendFarmCommandEvent} from "../../services/socketEventsService";
import {AlstomEHS} from "./AlstomEHS";

const useStyles = makeStyles({
    paper: {
        left: "50%"
    }
});
export const Farm = () => {

    const isMounted = useIsMounted();

    const [isFarmLoading, setIsFarmLoading] = useState(true);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [farmStructure, setFarmStructure] = useState(null);
    const [farmSystemCodes, setFarmSystemCodes] = useState([]);
    const [alert, setAlert] = useState();
    const [isRightPartOpen, setIsRightPartOpen] = useState(false);
    const [selectedCell, setSelectedCell] = useState(null);
    const [selectedSmartFarmCellData, setSelectedSmartFarmCellData] = useState(null);
    const [smartPrintersStatusData, setSmartPrintersStatusData] = useState(null);
    const [manualPrintersStatus, setManualPrintersStatus] = useState(null);
    const [isWeighModalOpen, setIsWeighModalOpen] = useState(false);
    const [farmProfiles, setFarmProfiles] = useState([]);
    const [isGeneralBuzzerOn, setIsGeneralBuzzerOn] = useState(null);
    const [showDoorOpeningSoon, setShowDoorOpeningSoon] = useState(false);
    const doorScheduleSystemCodes = useRef([]);

    // seconds without receiving environment data
    const [commandsInProcess, setCommandsInProcess] = useReducer(commandsInProcessReducer, {});
    const [generalCommandsInProcess, setGeneralCommandsInProcess] = useReducer(generalCommandsInProcessReducer, {});

    // Maintenances
    const [farmMaintenances, setFarmMaintenances] = useReducer(farmMaintenanceReducer, {});

    // Data coming from physical smart farm
    const [smartFarmData, setSmartFarmData] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        isMounted && setIsFarmLoading(true);
        if(selectedOrganization) {
            getFarmProfiles();
        }
    }, [selectedOrganization]);

    useEffect(() => {
        if(isFarmLoading) {
            isMounted && getFarmData();
        }
    }, [isFarmLoading]);

    useEffect(() => {
        if(isMounted) {
            let systemCodes = [];
            // Get all smart farm cells system codes
            if(farmStructure !== null && farmStructure.farm_modules) {
                farmStructure.farm_modules.map(module => {
                    if(module.farm_cells) {
                        module.farm_cells.map(cell => systemCodes.push(cell.system_code));
                    }
                });
                setFarmSystemCodes(systemCodes);
            }
            setFarmMaintenances({type: farmMaintenancesReducerActions.SET_MAINTENANCES, value: farmStructure});
        }
    }, [farmStructure]);

    useEffect(() => {
        if(!isRightPartOpen && isMounted) {
            setSelectedCell(null);
            setSelectedSmartFarmCellData(null);
        }
    }, [isRightPartOpen]);

    // Get farm profiles depending on selected organization
    const getFarmProfiles = () => {
        return fetchData('farm_profiles?organization=' + selectedOrganization.id)
            .then(response => response.json())
            .catch(() => isMounted && setFarmProfiles([]))
            .then(profiles => isMounted && setFarmProfiles(profiles))
            .catch(() => isMounted && setFarmProfiles([]));
    }

    // Get farm structures depending on selected organization
    const getFarmData = () => {
        if(selectedOrganization && selectedOrganization.id) {
            fetchData('farm_structures?organization=' + selectedOrganization.id)
                .then(response => response.json())
                .catch(() => isMounted && setFarmStructure(null))
                .then(data => isMounted && setFarmStructure((data !== undefined && data !== null && data.length > 0) ? data[0] : null))
                .catch(() => isMounted && setFarmStructure(null))
                .finally(() => isMounted && setIsFarmLoading(false));
        } else {
            isMounted && setIsFarmLoading(false);
        }
    }

    const handleClickOpenRightPart = (event, farmCell, smartFarmCellData) => {
        if(!isTargetEqualsOrDescendantOfSpecificClassNode(event.target, 'material-scale', 1)) {
            setSelectedCell(farmCell);
            setSelectedSmartFarmCellData(smartFarmCellData);
            setIsRightPartOpen(true);
        }
    }

    const handleRightPartClose = () => {
        setIsRightPartOpen(false);
    }

    /* Get right panel component depending on data to display (cell panel / structure panel) */
    const getRightPanelContent = () => {
        if(selectedCell && selectedCell.id) {
            // Cell content
            return (
                <FarmDialogCell
                    farmCell={selectedCell} smartFarmCellData={selectedSmartFarmCellData}
                    smartPrintersStatusData={smartPrintersStatusData} manualPrintersStatus={manualPrintersStatus}
                    farmProfiles={farmProfiles} getFarmProfiles={getFarmProfiles} setAlert={setAlert}
                    commandsInProcess={commandsInProcess} setCommandsInProcess={setCommandsInProcess}
                    farmVersion={farmStructure !== null && getFarmVersion(farmStructure.version)}
                    structureMaintenance={farmMaintenances.structure} farmStructureId={farmStructure ? farmStructure.id : null}
                    cellMaintenance={farmMaintenances.cells ? farmMaintenances.cells[selectedCell.id] : null}
                />
            );
        } else if(farmStructure && farmStructure.id) {
            // General Smart Farm content
            return (
                <FarmDialogStructure
                    farmVersion={farmStructure && farmStructure.version} isGeneralBuzzerOn={isGeneralBuzzerOn}
                    setGeneralCommandsInProcess={setGeneralCommandsInProcess} generalCommandsInProcess={generalCommandsInProcess}
                    structureMaintenance={farmMaintenances.structure} structureId={farmStructure.id}
                    isFarmDataGeneralMaintenance={getSmartFarmDataGeneralMaintenance(smartFarmData)}
                />
            );
        }
    }

    /* Get system code (cell / structure) that will be used in right panel */
    const getRightPanelSystemCode = () => {
        if(selectedCell && selectedCell.id) {
            return selectedCell.system_code;
        } else if(farmStructure && farmStructure.id) {
            return farmStructure.external_system_code;
        }
        return null;
    }

    const openRightStructurePanel = () => {
        setSelectedCell(false);
        setIsRightPartOpen(true);
    }

    /* Door opening after filtration */
    useEffect(() => {
        scheduleDoorOpeningAfterFan();

        window.addEventListener('fan_scheduling', () => {
            scheduleDoorOpeningAfterFan();
        });
    }, []);

    const scheduleDoorOpeningAfterFan = () => {
        const fanScheduling = getFanSchedulingLocalStorage();

        for(const [systemCode, time] of Object.entries(fanScheduling)){
            if(doorScheduleSystemCodes.current.includes(systemCode))
                continue;

            const openDoorRemainingTime = time - getCurrentTimestamp();

            if (openDoorRemainingTime < 0) {
                removeFanSchedulingLocalStorage(systemCode);
                continue;
            }

            setShowDoorOpeningSoon(true);
            doorScheduleSystemCodes.current.push(systemCode);

            setTimeout(() => {
                sendFarmCommandEvent(systemCode, SMART_FARM_COMMANDS.AIR_EXTRACTION, 0);
                const hasRemainingSchedules = removeFanSchedulingLocalStorage(systemCode);
                if(!hasRemainingSchedules)
                    setShowDoorOpeningSoon(false);
                doorScheduleSystemCodes.current = doorScheduleSystemCodes.current.filter(e => e !== systemCode);
            }, openDoorRemainingTime * 1000);

            setTimeout(() => {
                sendFarmCommandEvent(systemCode, SMART_FARM_COMMANDS.DOOR_CLOSED, 0);
            }, (openDoorRemainingTime + 1) * 1000);
        }
    };

    return (
        <div id="main__farm">
            {/* alert */}
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}

            {/* TODO Link with the balance */}
            {/*{isWeighModalOpen &&*/}
            {/*    <WeighResource*/}
            {/*        resource={null}*/}
            {/*        successCallback={() => console.log('success')}*/}
            {/*        errorCallback={() => console.log('error')}*/}
            {/*        dialogOpen={isWeighModalOpen} setDialogOpen={setIsWeighModalOpen}*/}
            {/*    />*/}
            {/*}*/}

            {/* Door opening after fan activation */}
            {
                showDoorOpeningSoon &&
                    <div className="modal__overlay corner" onClick={() => alert('out')}>
                        <div className="modal__dialog" onClick={(e) => e.stopPropagation()}>
                            <div className="dialog__content">
                                <h2 className="content__title">The door will open in a few seconds (depollution in progress).</h2>
                            </div>
                        </div>
                    </div>
            }

            {/* right part panel */}
            <Drawer anchor="right" open={isRightPartOpen} onClose={() => handleRightPartClose()} classes={{paper: classes.paper}}>
                {(selectedCell && selectedCell.id) || (farmStructure && farmStructure.id) ?
                    <FarmDialog
                        render={(children, parentProps) => React.Children.map(children, child =>
                            React.cloneElement(child, parentProps )
                        )}
                        isRightPartOpen={isRightPartOpen} systemCode={getRightPanelSystemCode()}
                        setAlert={setAlert} farmVersion={farmStructure !== null && getFarmVersion(farmStructure.version)}
                        isCellPanel={(selectedCell && selectedCell.id)} setFarmMaintenances={setFarmMaintenances}
                    >
                        {getRightPanelContent()}
                    </FarmDialog> :
                    <p className="no-environment-dialog">No environment selected</p>
                }

            </Drawer>

            {/* header */}
            <div className="farm-header">
                <FarmHeader
                    selectedOrganization={selectedOrganization} setSelectedOrganization={setSelectedOrganization}
                    farmVersion={(farmStructure !== null && farmStructure.version) ? farmStructure.version : null}
                    externalSystemCode={(farmStructure !== null && farmStructure.external_system_code) ? farmStructure.external_system_code : null}
                    smartFarmData={smartFarmData}
                />
            </div>

            <div className="farm__zone">
                {/* smart farm */}
                {isFarmLoading ?
                    <Loading/> :
                    <>
                        {(farmStructure !== null) ?
                            <>
                                <FarmStructure
                                    farmStructure={farmStructure} handleClickOpenRightPart={handleClickOpenRightPart}
                                    setIsWeighModalOpen={setIsWeighModalOpen} selectedCell={selectedCell}
                                    setSelectedSmartFarmCellData={setSelectedSmartFarmCellData}
                                    smartPrintersStatusData={smartPrintersStatusData} setSmartPrintersStatusData={setSmartPrintersStatusData}
                                    manualPrintersStatus={manualPrintersStatus} setManualPrintersStatus={setManualPrintersStatus}
                                    farmSystemCodes={farmSystemCodes} openRightStructurePanel={openRightStructurePanel}
                                    setCommandsInProcess={setCommandsInProcess} isGeneralBuzzerOn={isGeneralBuzzerOn}
                                    setIsGeneralBuzzerOn={setIsGeneralBuzzerOn} setGeneralCommandsInProcess={setGeneralCommandsInProcess}
                                    farmMaintenances={farmMaintenances} smartFarmData={smartFarmData} setSmartFarmData={setSmartFarmData}
                                />
                                {
                                    process.env.REACT_APP_SHOW_EHS_DOCS === 'true' &&
                                        <AlstomEHS/>
                                }
                            </> :
                            <p>This organization is not linked to a Handddle Smart Farm.</p>
                        }
                    </>
                }
            </div>
        </div>
    )
}

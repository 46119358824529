import React, {useState} from "react";
import {Tooltip} from "@material-ui/core";
import {archiveResource} from "../../../api/apiResource";
import Modal from "../../common/Modal";
import {isUserGranted} from "../../CommonFunctions";

export const ResourceManagement = (props) => {

    const {resource, successCallback, errorCallback} = props;

    const [archivedResource, setArchivedResource] = useState();
    const [showModalArchive, setShowModalArchive] = useState(false);
    const [isWeighOpen, setIsWeighOpen] = useState(false);

    /* Send archive request */
    const handleArchive = async (resource) => {
        resource.archived = true;

        await archiveResource(resource)
            .then(() => {
                successCallback('The resource has been archived.');
            })
            .catch(() => errorCallback('Something went wrong.'));

        cancelArchiveModal();
    }

    /* Close archive modal if archive action cancelled */
    const cancelArchiveModal= () => {
        setShowModalArchive(false);
        setArchivedResource(null);
    }

    /* Handle archive action */
    const handleArchiveButtonClick = () => {
        setArchivedResource(resource);
        setShowModalArchive(true)
    }

    return (
        <div id="main__resource_management">

            <h2>Material management</h2>

            <div className="action-button-list">
                {isUserGranted('ROLE_UPDATE_RESOURCE') &&
                    <Tooltip title="Removes resource from application">
                        <button className="btn-white action-button" onClick={() => handleArchiveButtonClick()}
                            data-tip="Archive resource" data-for="resources-help">
                            <i className="fa fa-archive"/> Archive
                        </button>
                    </Tooltip>
                }
                {/* TODO Link with the balance */}
                {/*<Tooltip title="Weigh resource on balance">*/}
                {/*    <button className="btn-white" onClick={() => setIsWeighOpen(!isWeighOpen)}>*/}
                {/*        <i className="fa fa-circle"/> Weigh*/}
                {/*    </button>*/}
                {/*</Tooltip>*/}
            </div>

            {showModalArchive && archivedResource &&
            <Modal
                title="Archive a resource"
                description={`Are you sure you want to archive the resource <strong>${archivedResource.serial_number}</strong>?`}
                buttons={[
                    {label: `Yes, archive <strong>${archivedResource.serial_number}</strong>`, callback: () => handleArchive(archivedResource)}
                ]}
                cancelCallback={() => cancelArchiveModal()}
            />
            }

            {/* TODO Link with the balance */}
            {/*{isWeighOpen &&*/}
            {/*    <WeighResource*/}
            {/*        resource={resource}*/}
            {/*        successCallback={successCallback}*/}
            {/*        errorCallback={errorCallback}*/}
            {/*        dialogOpen={isWeighOpen} setDialogOpen={setIsWeighOpen}*/}
            {/*    />*/}
            {/*}*/}
        </div>
    )
}

const token = localStorage.getItem('token');


export const linkProject = async (dataPieces, dataProject) => {

    let projectPieces = [];

    //Get project pieces if project has pieces
    if(dataProject.pieces_in_project.length) {
        dataProject.pieces_in_project.map((piece_in_project) => {
            projectPieces.push(piece_in_project.piece.id)
        })
    }


    dataPieces.forEach(async (piece) => {

        //If piece is not already in project
        if(!projectPieces.length > 0 || !projectPieces.includes(piece)){
            await fetch(process.env.REACT_APP_API_ADDRESS + 'piece_in_projects', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    project: process.env.REACT_APP_API_URI + `projects/${dataProject.id}`,
                    piece: process.env.REACT_APP_API_URI + `pieces/${piece}`,
                    code: "123",
                    add_date: Math.floor(Date.now() / 1000)
                })
            }).then((response) => {
                if(!response.ok)
                    throw Error;
            })
        }
    })
}

export const unlinkProject = async (dataPieces, dataProject, callback) => {

    let piecesInProject = [];

    //Get project pieces if project has pieces
    if(dataProject.pieces_in_project.length) {
        dataProject.pieces_in_project.map((piece_in_project) => {
            if(dataPieces.includes(piece_in_project.piece.id))
                piecesInProject.push(piece_in_project)
        })
    }

    piecesInProject.forEach(async (pieceInProject) => {
        await fetch(process.env.REACT_APP_API_ADDRESS + `piece_in_projects/${pieceInProject.id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        }).then((response) => {
            if(!response.ok)
                throw Error;
        })
    })
}

export const deletePiece = async (pieceId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `pieces/${pieceId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}
import {Drawer, makeStyles} from "@material-ui/core";
import {ResourceManagement} from "./ResourceManagement";
import React from "react";
import {isUserGranted} from "../../CommonFunctions";
import {EditResourceComponent} from "./EditResource";

import {ResourceHistory} from "./ResourceHistory";

const useStyles = makeStyles({
    paper: {
        left: "50%"
    }
});

export const ResourceDetailsComponent = (props) => {

    const classes = useStyles();

    const {rightPartDetailsIsOpen, setRightPartDetailsIsOpen, colors, brands, materials, technologies,
        resource, successCallback, errorCallback, printers, selectedReference, resourceEmptyWeightsData} = props;

    const handleCloseRightPanelDetails = () => {
        setRightPartDetailsIsOpen(false)
    }

    return(
        <Drawer anchor="right" open={rightPartDetailsIsOpen}
                onClose={() => handleCloseRightPanelDetails()} classes={{paper: classes.paper}}>

            {resource !== null &&
                <div id="right-part__container" className="right-part__container">
                    <div id="main__add-resource">
                        {isUserGranted('ROLE_UPDATE_RESOURCE') &&
                            <div className="block">
                                <EditResourceComponent
                                    colors={colors} brands={brands} materials={materials}
                                    technologies={technologies} resource={resource}
                                    successCallback={successCallback} errorCallback={errorCallback}
                                    setRightPartDetailsIsOpen={setRightPartDetailsIsOpen} printers={printers}
                                    resourceEmptyWeightsData={resourceEmptyWeightsData}
                                />
                            </div>
                        }

                        <div className="block">
                            <ResourceManagement
                                resource={resource} successCallback={successCallback} errorCallback={errorCallback}
                            />
                        </div>

                        <ResourceHistory resource={resource} reference={selectedReference}/>
                    </div>
                </div>
            }
        </Drawer>
    );
}

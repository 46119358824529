import React, {useEffect, useState} from "react";
import {getFormattedDate} from "../../CommonFunctions";
import {getSortedData} from "../../../services/TablesService";

export const ResourceHistoryTable = (props) => {

    const {resource} = props;

    const [history, setHistory] = useState([]);

    useEffect(() => {
        sortResourceHistoryData();
    }, [resource]);

    /* Create sorted (desc) history table data */
    const sortResourceHistoryData = () => {
        let historyData = [];

        // Add tasks data (except future tasks)
        resource.task_resources.map(taskResource => {
            const today = Math.floor(new Date().getTime()/1000);
            if(taskResource.task.date <= today) {
                historyData.push({
                    id: 'taskResource-' + taskResource.id,
                    date: taskResource.task.date,
                    user: taskResource.task.user.firstname + ' ' + taskResource.task.user.lastname,
                    printer: taskResource.task.printer.name,
                    extruder: taskResource.slot + 1,
                    action: 'Job print'
                });
            }
        });

        // Add edit actions
        if(resource.resource_edits) {
            resource.resource_edits.map(edit => {
                historyData.push({
                   id: 'edit-' + edit.id,
                   date: edit.date,
                   user: edit.user.firstname + ' ' + edit.user.lastname,
                   action: 'Resource update'
                });
            });
        }

        setHistory(getSortedData(historyData, 'date', 'desc'));
    }

    return (
        <div id="main__resource_history">
            <h2>Resource history</h2>

            {history.length > 0 &&
                <div className="history-table">
                    <table>
                        <thead>
                            <tr>
                                <th style={{width: '15%'}}>Date</th>
                                <th style={{width: '20%'}}>User</th>
                                <th style={{width: '20%'}}>Printer</th>
                                <th style={{width: '12%'}}>Extruder</th>
                                <th style={{width: '25%'}}>Action</th>
                                <th style={{width: '25%'}}>Weight (g)</th>
                            </tr>
                        </thead>
                        <tbody>
                        {history.map(historyLine => (
                            <tr key={historyLine.id}>
                                <td>{getFormattedDate(historyLine.date).toString()}</td>
                                <td>{historyLine.user ? historyLine.user : '--'}</td>
                                <td>{historyLine.printer ? historyLine.printer : '--'}</td>
                                <td>{historyLine.extruder ? historyLine.extruder : '--'}</td>
                                <td>{historyLine.action}</td>
                                <td>{historyLine.weight ? historyLine.weight : '--'}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }
            {history.length === 0 &&
                <p>No history data</p>
            }
        </div>
    );
}

import React, {Component} from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

class ViewerComponent extends Component {

    /*
    TODO: Add rendering enabled/disabled option
     */

    constructor(props) {
        super(props);

        const {loader, backgroundColor, initialZoomFactor} = props;

        this.state = {
            _loader: loader,
            _renderer: null,
            _width: null,
            _height: null,
            _camera: null,
            _initialZoomFactor: initialZoomFactor,
            _backgroundColor: backgroundColor,
            _scene: null,
            _controls: null
        }
    }

    renderScene = () => {
        this.state._renderer.setSize(this.state._width, this.state._height);
        this.state._renderer.render(this.state._scene, this.state._camera);

        if(this.mount){
            this.mount.appendChild(this.state._renderer.domElement);

            requestAnimationFrame(this.renderScene);
            // console.log('Rendering scene')
        }
    }

    animate = () => {
        let loader = this.state._loader;
        loader.mesh.rotation.x += 0.01;
        loader.mesh.rotation.y += 0.01;

        this.setState({
            _loader: loader
        }, () => {
            this.renderScene()
            requestAnimationFrame(this.animate);
        })
    };

    componentDidMount() {

        /* Size of the viewer */
        const width = this.mount.parentNode.clientWidth;
        const height = this.mount.parentNode.clientHeight;

        /* Renderer */
        const renderer = new THREE.WebGLRenderer({antialias: true});

        /* Camera */
        let camera = new THREE.PerspectiveCamera(
            45, // fov
            width / height, // aspect
            0.01, // near
            1000 // far
        );

        const boundingBox = this.state._loader.mesh.geometry.boundingBox;
        const boundingBoxSize = boundingBox.getSize();

        const maxDim = Math.max(boundingBoxSize.x, boundingBoxSize.y, boundingBoxSize.z);
        const fov = camera.fov * Math.PI / 180; // fov in rad

        const entireViewDistance = maxDim / (2 * Math.tan(fov / 2)) * this.state._initialZoomFactor;

        camera.position.set(
            -entireViewDistance, entireViewDistance, -entireViewDistance
        );
        // camera.lookAt(0,0,0);

        /* Orbit Controls - TODO */
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.center = new THREE.Vector3(0,0,0);

        /* Scene */
        let scene = new THREE.Scene();
        scene.background = new THREE.Color(this.state._backgroundColor);

        /* Add piece */
        scene.add(this.state._loader.mesh);

        // Hemisphere light
        const hemisphereColorSky = 0xFFFFFF;
        const hemisphereColorGround = 0x555555;
        const hemisphereIntensity = 0.8;

        const hemisphereLight = new THREE.HemisphereLight(hemisphereColorSky, hemisphereColorGround, hemisphereIntensity);
        scene.add(hemisphereLight);

        // Directional lights
        const directionalLightsColor = 0xFFFFFF;
        const directionalLightsIntensity = 0.3;

        const directionalLight1 = new THREE.DirectionalLight(directionalLightsColor, directionalLightsIntensity);
        directionalLight1.position.set(0, 10, 0);
        directionalLight1.target.position.set(0, 0, 0);
        scene.add(directionalLight1);
        scene.add(directionalLight1.target);


        this.setState({
            _width: width,
            _height: height,
            _renderer: renderer,
            _camera: camera,
            _scene: scene,
            _controls: controls
        }, () => {
            // this.animate()
            this.renderScene()
        })
    }


    render() {
        return (
            <>
                <div ref={ref => (this.mount = ref)} />
            </>
        )
    }
}

ViewerComponent.defaultProps = {
    renderingEnabled: true,
    backgroundColor: '#e4e4e4',
    initialZoomFactor: 1.5
}

export {ViewerComponent};

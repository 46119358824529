import {COMMAND_TIMEOUT_LIMIT} from "../FarmService";
import {getCurrentTimestamp} from "../../components/CommonFunctions";

export const generalCommandsInProcessReducer = (state, action) => {
    if(action.type === 'add-command') {
        return addCommandToGeneralCommandsInProcess(state, action.value);
    }
    if(action.type === 'remove-command') {
        return deleteCommandInGeneralCommandsInProcess(state, action.value);
    }
    if(action.type === 'update-time') {
        return updateGeneralCommandsInProcessTime(state, action.value);
    }
    return state;
}

/**
 * Add command by system code in commands in process object
 * @param state
 * @param newCommand
 * @returns {*}
 */
export const addCommandToGeneralCommandsInProcess = (state, newCommand) => {
    state[newCommand.commandType] = {value: newCommand.commandValue, time: getCurrentTimestamp()};
    return state;
}

/**
 * Delete system code command in commands in process object
 * @param state
 * @param commandToDelete
 * @returns {*}
 */
export const deleteCommandInGeneralCommandsInProcess = (state, commandToDelete) => {
    if(state[commandToDelete.commandType]) {
        delete state[commandToDelete.commandType];
    }
    return state;
}

/**
 * Update all commands stored in commands in process depending on their success / failure
 * @param state
 * @param generalData
 * @returns {*}
 */
export const updateGeneralCommandsInProcessTime = (state, generalData) => {
    // For all system codes with commands running
    for(let [commandType, commandContent] of Object.entries(state)) {
        // Delete command in state if command has been processed or command has been timeout (30 seconds since command has been sent)
        if(generalData[commandType] === commandContent.value || ((getCurrentTimestamp() - commandContent.time) > COMMAND_TIMEOUT_LIMIT)) {
            delete state[commandType];
        }
    }
    return state;
}
import {Input, InputGroup, Table} from 'rsuite';
import React, {useEffect, useState} from "react";
import {getFormattedDate, getFormattedDuration} from "../CommonFunctions";
import {getSortedData} from "../../services/TablesService";

export const PicScheduleTable = (props) => {

    const {formattedPics, setSelectedPic, selectedPrinterSchedule} = props;
    const { Column, HeaderCell, Cell } = Table;
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [picsData, setPicsData] = useState([]);
    const [picsDataFilter, setPicsDataFilter] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inputValueSearchBar, setInputValueSearchBar] = useState('');

    useEffect(() => {
        setInputValueSearchBar(selectedPrinterSchedule);
    }, []);

    useEffect(() => {
        setPicsData(getFormattedPicsData());
    }, [formattedPics])

    useEffect(() => {
        setLoading(true);
        setPicsDataFilter(picsData);
        setLoading(false);
    }, [picsData]);

    useEffect(() => {
        setPicsDataFilter(getFilteredData(inputValueSearchBar.toLowerCase()));
    }, [inputValueSearchBar, picsData]);

    useEffect(() => {
        //console.log(picsDataFilter)
    }, [picsDataFilter])

    /* Get data */
    const getFormattedPicsData = () => {
        const data = [];
        formattedPics.forEach((pic) => {
            if(pic.complete) {
                data.push({
                    id: pic.id,
                    name: pic.name,
                    duration: getFormattedDuration(pic.duration),
                    last_edit: pic.last_edit,
                    printersList: getFormattedPrintersListData(pic.slicer.main_printer, pic.slicer.printers),
                    material1: getFormattedMaterial1(pic),
                    material2: getFormattedMaterial2(pic)
                })
            }
        });
        return data;
    }

    /* Get printers list as string with main printer first */
    const getFormattedPrintersListData = (mainPrinter, printers) => {
        let printersList = mainPrinter.name;
        for(let printer of printers) {
            if(printer.id !== mainPrinter.id) {
                printersList += ' | ' + printer.name;
            }
        }
        return printersList;
    }

    const getFormattedMaterial1 = (rowData) => {
        let str = rowData.slicer.material1.name + ' - ' + rowData.slicer.brand1.name;

        if(rowData.slicer.color1)
            str += ' (' + rowData.slicer.color1.color_name + ')';

        return str;
    }

    const getFormattedMaterial2 = (rowData) => {
        if(rowData.slicer.material2) {
            let str = rowData.slicer.material2.name + ' - ' + rowData.slicer.brand2.name;

            if(rowData.slicer.color2)
                str += ' (' + rowData.slicer.color2.color_name + ')';

            return str;
        }
    }

    /* Sorting */
    const handleSortColumn = async (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    /* Filtering */
    const handleSearchBarChange = (value) => {
       setInputValueSearchBar(value);
    }

    const getFilteredData = (value) => {
        return picsData.filter(pic => {
            return (
                pic.name.toLowerCase().includes(value)
                || pic.printersList.toLowerCase().includes(value)
                || pic.material1.toLowerCase().includes(value)
                || (pic.material2 && pic.material2.toLowerCase().includes(value))
                || pic.duration.includes(value)
                || getFormattedDate(pic.last_edit).includes(value)
            );
        });
    }

    /* Pic selection */
    const handleRowClick = (data) => {
        setSelectedPic(findPicById(data.id));
    }

    const findPicById = (id) => {
        let selectedPic = null;
        let i = 0;
        do {
            if(formattedPics[i].id === id) {
                selectedPic = formattedPics[i];
            }
            i++;
        } while (i < formattedPics.length && selectedPic === null);
        return selectedPic;
    }

    return (
        <div className="main__schedule_pic_table">
            <div className="flex">
                <h2 className="title">Choose an existing PIC</h2>
                <div className="search-bar">
                    <InputGroup inside className="search-bar-input">
                        <Input placeholder="Search" value={inputValueSearchBar} onChange={(value) => handleSearchBarChange(value)}/>
                        <InputGroup.Addon className="search-bar-addon-icon">
                            <i className="fa fa-search"/>
                        </InputGroup.Addon>
                    </InputGroup>
                </div>
            </div>

            <Table data={getSortedData(picsDataFilter, sortColumn, sortType)} height={510}
                   onRowClick={(data) => handleRowClick(data)}
                   sortColumn={sortColumn}
                   sortType={sortType}
                   onSortColumn={handleSortColumn}
                   loading={loading}
                   autoHeight={false}
            >
                <Column flexGrow={4} verticalAlign="middle" sortable>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name"/>
                </Column>
                <Column flexGrow={2} verticalAlign="middle" sortable>
                    <HeaderCell>Duration</HeaderCell>
                    <Cell dataKey="duration"/>
                </Column>
                <Column flexGrow={4} verticalAlign="middle" sortable>
                    <HeaderCell>Printers</HeaderCell>
                    <Cell dataKey="printersList"/>
                </Column>
                <Column flexGrow={4} verticalAlign="middle" sortable>
                    <HeaderCell>Material 1</HeaderCell>
                    <Cell dataKey="material1"/>
                </Column>
                <Column flexGrow={4} verticalAlign="middle" sortable>
                    <HeaderCell>Material 2</HeaderCell>
                    <Cell dataKey="material2"/>
                </Column>
                <Column flexGrow={2} verticalAlign="middle" sortable>
                    <HeaderCell>Last update</HeaderCell>
                    <Cell dataKey="last_edit">
                        {rowData => getFormattedDate(rowData.last_edit)}
                    </Cell>
                </Column>
            </Table>
        </div>
    )
}

import React from "react";
import {CircularProgressWithLabel} from "./CircularProgressWithLabel";
import {CircularProgress} from "@material-ui/core";

export const ProjectData = (props) => {

    const {project} = props;

    return (
        <>
            <h2>Project data</h2>
            <div id="figures__global-progression" style={{position: "relative"}}>
                <div className="global-progression__block">
                    <h3>Global progression</h3>
                    <div className="circular">
                        <div className="bg">
                            {project.total_pieces_number > 0 ?
                                Math.floor(project.done_pieces_number/project.total_pieces_number * 100) : 0}%
                        </div>
                        <CircularProgress
                            variant="determinate"
                            size={135}
                            thickness={4}
                            value={project.total_pieces_number > 0 ? Math.floor(project.done_pieces_number/project.total_pieces_number * 100) : 0}
                        />
                    </div>
                </div>

                <div className="global-progression__block">
                    <h3>Printed pieces</h3>
                    <p className="block__stat">{project.done_pieces_number}/{project.total_pieces_number}</p>
                </div>

                <div className="global-progression__block">
                    <h3>Printing time</h3>
                    <p className="block__stat">{project.done_duration}</p>
                </div>
            </div>
        </>
    )
}

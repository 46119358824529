import {EnvironmentDonutChart} from "../charts/EnvironmentDonutChart";
import {getDurationInHours, getFormattedDate, isUserGranted} from "../../../CommonFunctions";
import {ClickAwayListener, createMuiTheme, Slider, ThemeProvider, Tooltip} from "@material-ui/core";
import {OutlinedCard} from "../../common/OutlinedCard";
import React, {useEffect, useMemo, useState} from "react";
import {FarmDialogGraph} from "../charts/FarmDialogGraph";
import {
    getFilterChangeGapFromToday,
    isMaintenanceRunning,
    PM_TYPES,
    SMART_FARM_DATA_FIELDS
} from "../../../../services/FarmService";
import {Popover, SelectPicker, Whisper} from "rsuite";
import {getMaxChartValue} from "../../../../services/farm/FarmChartsService";
import {getFanUsageInSeconds} from "../../../../api/apiInfluxdb";
import {MaterialIcon} from "../../../common/icons/MaterialIcon";

export const PollutionBlock = (props) => {

    const {pollution, pollutionList, tvoc, tvocList, unitPeriod, airExtractionCommandData, systemCode,
        handleExtractionChange, vocType, selectedPollutionType, setSelectedPollutionType, filterChange, isCommandEnabled,
        structureMaintenance, cellMaintenance} = props;

    const DATA_TYPES = {TVOC: 'tvoc', POLLUTION: 'pollution'};

    const tvocLabel = (vocType === SMART_FARM_DATA_FIELDS.TVOC) ? 'TVOC' : 'VOC index';
    const pollutionLabel = 'Concentration';

    const [airExtractionSlider, setAirExtractionSlider] = useState(airExtractionCommandData);
    const [fanUsageInSeconds, setFanUsageInSeconds] = useState(null);

    const isInMaintenance = isMaintenanceRunning(cellMaintenance) || isMaintenanceRunning(structureMaintenance);

    useEffect(() => {
        if(filterChange > 0) {
            const startDate = (filterChange - (3600 * 24 * 30 * 6)).toString(); // filter change date - 6 months
            getFanUsageInSeconds(systemCode, startDate)
                .then(data => (data && data.length > 0 && data[0].elapsed_abs > 0) && setFanUsageInSeconds(data[0].elapsed_abs))
                .catch(() => null);
        }
    }, []);

    useEffect(() => {
        setAirExtractionSlider(airExtractionCommandData);
    }, [airExtractionCommandData]);

    const pollutionColor = '#BB70EE';
    const TVOCColor = '#A8A8A8';

    const marksPollution = [
        {value: 0, label: '0%'},
        {value: 30, label: '30%'},
        {value: 50, label: '50%'},
        {value: 100, label: '100%'}
    ];

    const muiSliderTheme = createMuiTheme({
        overrides:{
            MuiSlider:{
                root: {
                    color: '#333',
                },
                thumb:{
                    zIndex:2,
                    backgroundColor: '#333',
                },
                active: {},
                valueLabel: {
                    top: -10,
                    left: -11,
                    zIndex:1,
                    '& *': {
                        textAlign: 'center',
                        fontSize: '1rem',
                        margin: '1px',
                        width: '33px',
                        height: '23px',
                        borderRadius: '8px',
                        transform:'none',
                        backgroundColor: 'rgba(128,128,128,.2)',
                        color: 'grey',
                    },
                },
                markLabel:{
                    color:'grey',
                },

                track: {
                    color: pollutionColor
                },
            }
        }
    });

    function valueLabelFormat(value) {
        return marksPollution.findIndex((mark) => mark.value === value) + 1;
    }

    const getDonutChartLabel = (dataTypeToDisplay) => {
        if(dataTypeToDisplay === DATA_TYPES.POLLUTION) {
            return(
                <div>
                    <div className="donut-chart-value-line">{pollution !== undefined && pollution !== null ? Math.floor(pollution) : '--'}</div>
                    <div className="donut-chart-value-line donut-chart-value-line-unit"><span>μg/m</span><span><sup>3</sup></span></div>
                </div>
            );
        } else {
            return(
                <div>
                    <div className="donut-chart-value-line">{tvoc !== undefined && tvoc !== null ? Math.floor(tvoc) : '--'}</div>
                    <div className="donut-chart-value-line donut-chart-value-line-unit">
                        {(vocType === SMART_FARM_DATA_FIELDS.VOC || vocType === SMART_FARM_DATA_FIELDS.VOC_EXT) ? '(index)' :
                            <><span>μg/m</span><span><sup>3</sup></span></>
                        }
                    </div>
                </div>
            );
        }
    }

    const isFilterChangeNeeded = (filterChangeDate) => {
        // Today at midnight
        const todayAtMidnight = (new Date()).setHours(0, 0, 0, 0);
        // filter change date at midnight
        const filterChangeAtMidnight = (new Date(filterChangeDate * 1000)).setHours(0, 0, 0, 0);

        // need change if difference < 1 day
        return filterChangeAtMidnight - todayAtMidnight < 3600 * 24 * 1000;
    }

    const needFilterChange = filterChange ? isFilterChangeNeeded(filterChange) : false;

    /* Set selected pollution type */
    const handlePollutionTypeChange = (value) => {
        for(let item of PM_TYPES) {
            if(item.value === value) {
                setSelectedPollutionType(item);
                break;
            }
        }
    }

    /* Get voc chart backrgound (colors corresponding to official voc levels) */
    const getVocChartBackground = () => {
        if(vocType === SMART_FARM_DATA_FIELDS.VOC || vocType === SMART_FARM_DATA_FIELDS.VOC_EXT) {
            return {
                position: "back",
                yaxis: [
                    {y: 0, y2: 150, fillColor: '#93cf6188', borderColor: '#93cf6188'},
                    {y: 150, y2: 250, fillColor: '#f7e16688', borderColor: '#f7e16688'},
                    {y: 250, y2: 400, fillColor: '#ec9a4b88', borderColor: '#ec9a4b88'},
                    {y: 400, y2: 500, fillColor: '#d16e7788', borderColor: '#d16e7788'}
                ]
            };
        }
        return null;
    }

    const pollutionChart = useMemo(() => {
        return(
            <FarmDialogGraph
                data={pollutionList} label={pollutionLabel} color={pollutionColor}
                ymin={0} ymax={getMaxChartValue(pollutionList)} unitPeriod={unitPeriod}
            />
        );
    }, [pollutionList, unitPeriod]);

    const tvocChart = useMemo(() => {
        return(
            <FarmDialogGraph
                data={tvocList} label={tvocLabel} color={TVOCColor}
                ymin={0} ymax={(vocType === SMART_FARM_DATA_FIELDS.VOC || vocType === SMART_FARM_DATA_FIELDS.VOC_EXT) ? 500 : getMaxChartValue(tvocList)} unitPeriod={unitPeriod}
                annotations={(vocType === SMART_FARM_DATA_FIELDS.VOC || vocType === SMART_FARM_DATA_FIELDS.VOC_EXT) ? getVocChartBackground() : undefined}
            />
        );
    }, [tvocList, unitPeriod]);

    const [isPollutionTooltipOpen, setIsPollutionTooltipOpen] = useState(false);

    return(
        <OutlinedCard>
            <div className="environment-pollution-info">
                {/* Pollution description tooltip */}
                <ClickAwayListener onClickAway={() => setIsPollutionTooltipOpen(false)}>
                    <Tooltip title={
                        <div className="environment-pollution-info-tooltip">
                            <div className="environment-pollution-info-tooltip-title">Pollution data:</div>
                            <div className="environment-pollution-info-tooltip-description">
                                <div className="environment-pollution-info-tooltip-description-title">Concentration:</div>
                                <div>Amount of particles in the air in μg/m<span><sup>3</sup></span>, depending on their max size</div>
                                <div>{' (PM1: <1μm, PM2.5: <2,5μm, PM4: <4µm, PM10: <10μm)'}</div>
                            </div>
                            {(vocType === SMART_FARM_DATA_FIELDS.VOC || vocType === SMART_FARM_DATA_FIELDS.VOC_EXT) &&
                                <div className="environment-pollution-info-tooltip-description">
                                    <div className="environment-pollution-info-tooltip-description-title">VOC index:</div>
                                    <div>Index used to represent volatile organic compounds quantity in the air.</div>
                                    <div>A VOC index > 100 means VOC quantity is above average</div>
                                </div>
                            }
                            {vocType === SMART_FARM_DATA_FIELDS.TVOC &&
                                <div className="environment-pollution-info-tooltip-description">
                                    <div className="environment-pollution-info-tooltip-description-title">TVOC:</div>
                                    <div>VOC quantity in the air in μg/m<span><sup>3</sup></span></div>
                                </div>
                            }

                        </div>
                    }
                             arrow={false} placement="left-start" open={isPollutionTooltipOpen} disableHoverListener
                             id="environment-pollution-info-tooltip-tooltip">
                        <span onClick={() => setIsPollutionTooltipOpen(!isPollutionTooltipOpen)}>
                            <MaterialIcon label="help"/>
                        </span>
                    </Tooltip>
                </ClickAwayListener>
            </div>
            <div className="farm__dialog_environment_blocks">
                <div className="environment-pollution-left-block">
                    <div className="environment-pollution-left-block-left-donuts">
                        <div className="environment-left-block-line">
                            <h2>{pollutionLabel}</h2>
                            <EnvironmentDonutChart
                                colorData={pollutionColor} percentageData={100}
                                label={getDonutChartLabel(DATA_TYPES.POLLUTION)} labelSize="small"
                            />
                            <div className="environment-pollution-type-select">
                                <SelectPicker
                                    block cleanable={false} searchable={false} size="sm"
                                    data={PM_TYPES}
                                    value={selectedPollutionType.value}
                                    onChange={(data) => handlePollutionTypeChange(data)}
                                    renderValue={() => selectedPollutionType.label}
                                />
                            </div>
                        </div>
                        <div className="environment-left-block-line">
                            <h2>{tvocLabel}</h2>
                            <EnvironmentDonutChart
                                colorData={TVOCColor} percentageData={100}
                                label={getDonutChartLabel(DATA_TYPES.TVOC)} labelSize="small"
                            />
                        </div>
                    </div>
                    <div className="environment-pollution-slider-block">
                        {(isUserGranted('ROLE_MANAGE_SMART_FARM') && isCommandEnabled &&
                                (isInMaintenance || process.env.REACT_APP_HIDE_SF_SLIDERS_NO_MAINTENANCE === 'false')) &&
                            <div className="environment-pollution-slider">
                                <div className="environment-pollution-slider-content">
                                    <ThemeProvider theme={muiSliderTheme}>
                                        <Slider
                                            marks={marksPollution}
                                            step={null}
                                            value={airExtractionSlider}
                                            valueLabelDisplay="off"
                                            valueLabelFormat={valueLabelFormat}
                                            min={0}
                                            max={100}
                                            onChange={(e, value) => setAirExtractionSlider(value)}
                                            onChangeCommitted={(e, value) => handleExtractionChange(value)}
                                            aria-labelledby="continuous-slider"
                                        />
                                    </ThemeProvider>
                                </div>
                                <div className="environment-slider-legend">Manage air extraction</div>
                            </div>
                        }
                    </div>
                    {filterChange > 0 &&
                        <div className="environment-pollution-change-filter-block">
                            <p className="need-filter-label">Need filter change: {needFilterChange ? 'Yes' : 'No'}</p>
                            <p className="need-filter-change-date">
                                {'Change required: ' + getFilterChangeGapFromToday(filterChange) +
                                    ' (' + getFormattedDate(filterChange) + ')'}
                            </p>
                            <p className="need-filter-change-date">
                                {'Fan usage: ' + (fanUsageInSeconds !== null ? getDurationInHours(fanUsageInSeconds) : '--')}
                                <Whisper placement="bottom" trigger="hover" speaker={<Popover><span>
                                    Computed on a quarter-hour basis.<br/>
                                    The fan is considered in use for a quarter-hour if the average fan
                                    utilization is 15% or higher,<br/>
                                    equivalent to a minimum usage of 30% over half the time.
                                    The final result is rounded down to the nearest hour.
                                </span></Popover>}>
                                    <span>&nbsp;<MaterialIcon classes="environment-graph-legend-icon" label="info"/></span>
                                </Whisper>
                            </p>
                        </div>
                    }
                </div>
                <div className="environment-graph-block">
                    <div className="environment-graph">
                        {pollutionChart}
                        {tvocChart}
                        {(vocType === SMART_FARM_DATA_FIELDS.VOC || vocType === SMART_FARM_DATA_FIELDS.VOC_EXT) &&
                            <div className="environment-graph-legend">
                                <MaterialIcon classes="environment-graph-legend-icon" label="info"/>
                                <span>If VOC index > 100: above average</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </OutlinedCard>
    );
}

import {getSortedData} from "./TablesService";

/*
Format a pic's list (using formatPic())
*/
export const formatPicsList = (pics) => {
    let newPicsList = [];
    pics.map(pic => {
        if(pic.original_pic === undefined || pic.original_pic === null) {
            newPicsList.push(formatPic(pic));
        }
    });
    return newPicsList;
}

/*
Format a pic : put last edited pic as principal pic, and other versions as copies
Get data only existing in original pic's data for new principal pic (ex: projects)
*/
export const formatPic = (pic) => {
    if(pic.original_pic === undefined || pic.original_pic === null) {
        if (!pic.copies || pic.copies.length === 0) {
            // Case : pic has no copy
            pic.version = 1;
            return pic;
        } else {
            // Case pic has copies : find last edited pic
            let picsList = JSON.parse(JSON.stringify(pic.copies));
            picsList.push(pic);
            let lastPic = getSortedData(picsList, 'last_edit', 'desc')[0];
            // Find last edited pic version number
            picsList = getSortedData(picsList, 'date_add', 'asc');
            lastPic.version = picsList.indexOf(lastPic) + 1;
            // Get last pic data
            pic.projects = lastPic.projects;
            pic.slicer = lastPic.slicer;
            pic.original_pic = lastPic;
            picsList.splice(picsList.indexOf(lastPic), 1);
            lastPic.copies = picsList;
            // Add pic to new list
            return lastPic;
        }
    }
}

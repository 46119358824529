import React, {useState} from "react";
import {useGetData} from "../../api/useFetch";
import {useForm, Controller} from "react-hook-form";
import {patchProject} from "../../api/apiProject"
import {Drawer, makeStyles} from '@material-ui/core';
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";

const useStyles = makeStyles({
    paper: {
        left: "50%",
        background: "white"
    },
});

export const EditProjectComponent = (props) => {
    const classes = useStyles();

    const {project, successCallback, errorCallback} = props;

    const colors = useGetData('colorsData', 'resource_colors', false);

    const {handleSubmit, control} = useForm();
    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);

    const onSubmit = async (dataProject) => {
        await patchProject(dataProject)
            .then(() => {
                setRightPartIsOpen(false);
                successCallback('The project has been updated.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    }


    return (
        <>
            <button className="button-link" onClick={() => setRightPartIsOpen(!rightPartIsOpen)}><i className="fa fa-pencil-alt"/> Update</button>
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)} classes={{paper: classes.paper}}>
                <div id="main__edit-project">

                    <div className="block">
                        <h2>Update a project</h2>
                        {
                            colors.isLoading ? <Loading/> :
                            colors.isError ? <Error errorMessage={colors.error.message}/> :

                            <form id="edit-project__form" onSubmit={handleSubmit(onSubmit)}>

                                <Controller
                                    control={control}
                                    name="id"
                                    defaultValue={project.id}
                                    render={({field, value}) =>
                                        <input type="hidden"{...field} />}
                                />

                                <label htmlFor="f-name">Name of the project</label>
                                <Controller
                                    control={control}
                                    name="name"
                                    defaultValue={project.name}
                                    rules={{required: true}}
                                    render={({field}) => (
                                        <input type="text" id="f-name" placeholder="Name of the project" {...field}/>
                                    )}
                                />

                                <label htmlFor="f-color-id">Color</label>
                                <Controller
                                    control={control}
                                    name="color"
                                    rules={{required: true}}
                                    defaultValue={project.color.id}
                                    render={({field}) => (
                                        <select id="f-color-id" {...field}>
                                            {colors.data.map((color) => (
                                                <option key={color.id} value={color.id} >{color.color_name}</option>
                                            ))}
                                        </select>
                                    )}
                                />

                                <label htmlFor="f-notes">Description</label>
                                <Controller
                                    control={control}
                                    name="notes"
                                    defaultValue={project.notes}
                                    render={({field}) => (
                                        <textarea id="f-notes" placeholder="Write notes on this project..." {...field}>{project.notes}</textarea>
                                    )}
                                />

                                <button><i className="fa fa-check"></i> Update</button>
                            </form>
                        }
                    </div>
                </div>
            </Drawer>
        </>
    )
}

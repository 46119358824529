import {useState, useEffect} from "react";
import {ResourcesTable} from "./ResourcesTable";
import { SelectPicker } from 'rsuite';

export const Resources = (props) => {
    const {
        printers, tasksSelected, setTasksSelected, setAlert, setIsWorkloadDialogOpen, externalPrintTasks,
        setPrinterTaskWorkload, selectedBrand, setSelectedBrand, arrayFilteredPrinter, onBrandChangeHandler, firstDisplayedDate,
        reloadCalendar} = props;
    const [brandArrayForSelectInput, setBrandArrayForSelectInput] = useState([]);

    useEffect(() => {
        printerBrandData();
    }, []);

    /** Fires when a new printer brand is selected from the select input */
    useEffect(() => {
        onBrandChangeHandler();
    }, [selectedBrand]);

    /** Filter all the brand available and filter them for the select picker */
    const printerBrandData = () => {
        /* Build the set of data for the select picker component */
        let brand = [];
        printers.map(printer => {
            brand.push({label: printer.brand.name, value: printer.brand.name});
        })

        /* Remove duplicate values */
        const seen = new Set();
        const filteredArr = brand.filter(el => {
            const duplicate = seen.has(el.value);
            seen.add(el.value);
            return !duplicate;
        });
        setBrandArrayForSelectInput(filteredArr);
    }

    return (
        <div className="gc-main-container__resources gc-resources">
            <h2 className="gc-resources__title">Resources</h2>
            <SelectPicker 
                size="md"
                placeholder="Choose a brand"
                data={brandArrayForSelectInput}
                value={selectedBrand}
                onChange={setSelectedBrand}
            />
            <ResourcesTable
                printers={printers}
                tasksSelected={tasksSelected}
                setTasksSelected={setTasksSelected}
                setAlert={setAlert}
                setIsWorkloadDialogOpen={setIsWorkloadDialogOpen}
                setPrinterTaskWorkload={setPrinterTaskWorkload}
                arrayFilteredPrinter={arrayFilteredPrinter}
                firstDisplayedDate={firstDisplayedDate}
                externalPrintTasks={externalPrintTasks}
                reloadCalendar={reloadCalendar}
            />
        </div>
    );
};

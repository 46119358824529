import {Table} from "rsuite";
import React, {useEffect, useState} from "react";
import {getAsyncSortedData, isTargetEqualsOrDescendantOfSpecificClassNode} from "../../../services/TablesService";
import {getFormattedDate, isUserGranted} from "../../CommonFunctions";
import {Avatar} from "../../common/Avatar";
import {PicActionsMenuButton} from "./PicActionsMenuButton";
import {TableWrapper} from "../../common/TableWrapper";

export const PicProjectTable = (props) => {

    const {data, handleDeletePic, handleDuplicatePic, handleManagePicProjects, isTableDataLoading} = props;

    // Table states
    const { Column, HeaderCell, Cell } = Table;
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(true);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        setLoading(true);
        if(data !== undefined) {
            setSortedData(data);
            setLoading(false);
        }
    }, [data]);

    const handleSortColumn = (column, type) => {
        setSortColumn(column);
        setSortType(type);
        getAsyncSortedData(sortedData, column, type)
            .then((data) => {
                setSortedData([]);
                setSortedData(data);
            });
    }

    const handleRowClick = (data, event) => {
        if(data.children && !isTargetEqualsOrDescendantOfSpecificClassNode(event.target, 'action-buttons')) {
            // Case parent row and target is not an action button : handle table expand
            if (expandedRowKeys.indexOf(data.id) < 0) {
                // Open line
                setExpandedRowKeys(expandedRowKeys => [...expandedRowKeys, data.id]);
            } else {
                // Close line
                setExpandedRowKeys(expandedRowKeys => expandedRowKeys.filter(item => item !== data.id));
            }
        }
    }

    return(
        <TableWrapper classNames="main__pic_table">
            <Table data={sortedData}
                   onRowClick={(data, event) => handleRowClick(data, event)}
                   expandedRowKeys={expandedRowKeys}
                   sortColumn={sortColumn}
                   sortType={sortType}
                   onSortColumn={handleSortColumn}
                   autoHeight={false} height={460}
                   rowKey="key"
                   isTree
                   loading={loading || isTableDataLoading}
                   loadAnimation={true}
            >
                <Column flexGrow={3.5} verticalAlign="middle" sortable>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name">
                        {rowData => {
                            if(rowData.children) {
                                return(
                                    <>
                                        <span className="project-color" style={{background: rowData.color}}/>
                                        <span>{rowData.name}</span>
                                    </>
                                );
                            } else {
                                return(<span>{rowData.name}</span>);
                            }
                        }}
                    </Cell>
                </Column>
                <Column flexGrow={1.2} verticalAlign="middle">
                    <HeaderCell>Version</HeaderCell>
                    <Cell dataKey="version"/>
                </Column>
                <Column flexGrow={2} verticalAlign="middle" sortable>
                    <HeaderCell>Last update</HeaderCell>
                    <Cell dataKey="lastUpdate">
                        {rowData => {
                            if(!rowData.children) {
                                return(getFormattedDate(rowData.lastUpdate));
                            }
                        }}
                    </Cell>
                </Column>
                <Column flexGrow={1.2} verticalAlign="middle" sortable>
                    <HeaderCell>User</HeaderCell>
                    <Cell dataKey="user">
                        {rowData => {
                            if(!rowData.children) {
                                return(
                                    <div className="table-user-avatar">
                                        <Avatar user={rowData.user} size="small"/>
                                    </div>
                                );
                            }
                        }}
                    </Cell>
                </Column>
                <Column flexGrow={3} verticalAlign="middle" sortable>
                    <HeaderCell>Material 1</HeaderCell>
                    <Cell dataKey="material1"/>
                </Column>
                <Column flexGrow={3} verticalAlign="middle" sortable>
                    <HeaderCell>Material 2</HeaderCell>
                    <Cell dataKey="material2"/>
                </Column>
                <Column flexGrow={2} verticalAlign="middle" sortable>
                    <HeaderCell>Status</HeaderCell>
                    <Cell dataKey="isActive">
                        {rowData => {
                            if(!rowData.children) {
                                return(rowData.isActive ?
                                    <span className="table-status table-status-active">active</span> :
                                    <div className="table-status table-status-draft">draft</div>
                                );
                            }
                        }}
                    </Cell>
                </Column>
                {(isUserGranted('ROLE_CREATE_PIC') || isUserGranted('ROLE_UPDATE_PIC') || isUserGranted('ROLE_DELETE_PIC')) &&
                    <Column flexGrow={1} verticalAlign="middle" align="left">
                        <HeaderCell></HeaderCell>
                        <Cell dataKey="actions">
                            {rowData => {
                                if (!rowData.children) {
                                    return (
                                        <PicActionsMenuButton elementId={rowData.id} type="pic"
                                                              handleManagePicProjects={handleManagePicProjects}
                                                              handleDeletePic={handleDeletePic}
                                                              handleDuplicatePic={handleDuplicatePic}/>
                                    );
                                } else {
                                    return (
                                        <PicActionsMenuButton elementId={rowData.id} type="project"/>
                                    );
                                }
                            }}
                        </Cell>
                    </Column>
                }
            </Table>
        </TableWrapper>
    );
}

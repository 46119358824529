import React from "react";
import {getCurrentTimestamp, getCurrentUser} from "../components/CommonFunctions";
import {fetchData} from "./useFetch";

const token = localStorage.getItem('token');
const currentUser = getCurrentUser();

/* POST function to add new task */
export const postTask = (data) => {
    
    let startDate = new Date(data.date);

    let duration = data.duration.hours * 3600 + data.duration.minutes * 60 + data.duration.seconds;

    let project = data.projectId ? process.env.REACT_APP_API_URI + `projects/${data.projectId}` : null;

    fetch(process.env.REACT_APP_API_ADDRESS + 'tasks', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            user: process.env.REACT_APP_API_URI + `users/${currentUser.id}`,
            project: project,
            name: data.name,
            printer: process.env.REACT_APP_API_URI + `printers/${data.printerId}`,
            comments: data.comments,
            duration: parseInt(duration),
            date_add: getCurrentTimestamp(),
            date: parseInt(startDate/1000),
            is_error: false
        })
    })
}

export const postTaskFastPrint = (user, printerId, name, build=null) => {
    const now = getCurrentTimestamp();

    return fetch(process.env.REACT_APP_API_ADDRESS + 'tasks', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            user: process.env.REACT_APP_API_URI + `users/${user.id}`,
            name: name,
            printer: process.env.REACT_APP_API_URI + `printers/${printerId}`,
            date_add: now,
            date: now,
            start_date_detected: build !== null ? null : now,
            end_date: build !== null ? now + build.estimated_print_duration : null,
            start_validated_by: process.env.REACT_APP_API_URI + `users/${user.id}`,
            is_fast_print: true
        })
    })
};

export const patchTaskDate = async (taskId, date) => {
    const response = await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({
            date: date,
        }),
    });
    if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
    }
    return response;
};

export const patchStartTask = async (taskId, date) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            date: date,
            is_fixed_scheduling: true
        })
    })
}

export const patchTaskSmartScheduling = (task) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${task.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(task)
    });
}

export const validateStartTask = async (task, printerData, adjustStartingHour, user) => {
    let taskId = task.data.id;

    await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            date: adjustStartingHour ? parseInt(new Date().getTime()/1000) : task.data.date,
            is_fixed_scheduling: true,
            start_validated_by: process.env.REACT_APP_API_URI + `users/${user.id}`
        })
    }).then(async (response) => {
        if(!response.ok)
            throw Error;
    });
}

export const validateEndTask = async (task, adjustEndHour, isError, user) => {
    let taskId = task.data.id;

    await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            done: true,
            end_date: adjustEndHour ? getCurrentTimestamp() : task.data.date + task.data.print_duration,
            end_validated_by: process.env.REACT_APP_API_URI + `users/${user.id}`,
            is_error: isError
        })
    }).then(async (response) => {
        if(!response.ok)
            throw Error;
    });
}

export const fixUnfixTaskScheduling = async (needAutoScheduling, taskId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            is_fixed_scheduling: needAutoScheduling
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const deleteTask = async (taskId) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchTaskComments = async (taskId, notes) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            comments: notes,
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const postTaskInterruption = async (task, user) => {
    let taskId = task.data.id;
    await fetch(process.env.REACT_APP_API_ADDRESS + `task_interruptions`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            task: process.env.REACT_APP_API_URI + `tasks/${taskId}`,
            date_start: getCurrentTimestamp(),
            date_end: null,
            user: process.env.REACT_APP_API_URI + `users/${user.id}`
        })
    }).then(async (response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchTaskInterruption = async (taskId, interruptionId) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `task_interruptions/${interruptionId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            date_end: getCurrentTimestamp()
        })
    }).then(async (response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchTaskInterruptionNotes = async (interruptionId, note) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `task_interruptions/${interruptionId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            note: note
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

/* Update external task id coming from controllable printer */
export const patchTaskUpdateControllablePrinterTaskId = async (taskId, externalTaskId) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            controllable_printer_task_id: externalTaskId,
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

/* Dissociate existing task from current print */
export const patchDissociateTaskFromExternalPrint = async (taskId) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}/dissociate`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({})
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

/* Get all external tasks = tasks with start_date_detected but without pic */
export const getAllExternalTasks = async () => {
    return fetchData('tasks?exists[startDateDetected]&exists[pic]=false')
        .then(response => response.json())
        .catch(() => null);
}

/* Update task to validate resource choice (no chosen resource) */
export const patchTaskValidateNoChosenResource = async (taskId) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            is_resource_validated: true
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

/* Update task to validate resources choices */
export const patchTaskValidateChosenResource = async (taskId, slot1ResourcesIds, slot2ResourcesIds, material1, material2) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            task_resources: getTaskResources(slot1ResourcesIds, slot2ResourcesIds),
            is_resource_validated: true,
            material1: material1.materialId ? process.env.REACT_APP_API_URI + 'materials/' + material1.materialId : null,
            brand1: material1.brandId ? process.env.REACT_APP_API_URI + 'resource_brands/' + material1.brandId : null,
            color1: material1.colorId ? process.env.REACT_APP_API_URI + 'resource_colors/' + material1.colorId : null,
            material2: material2.materialId ? process.env.REACT_APP_API_URI + 'materials/' + material2.materialId : null,
            brand2: material2.brandId ? process.env.REACT_APP_API_URI + 'resource_brands/' + material2.brandId : null,
            color2: material2.colorId ? process.env.REACT_APP_API_URI + 'resource_colors/' + material2.colorId : null
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

/* Get task resources list for task patch */
const getTaskResources = (slot1ResourcesIds, slot2ResourcesIds) => {
    let taskResources1 = [];
    let taskResources2 = [];
    if(slot1ResourcesIds.length > 0) {
        taskResources1 = formatTaskResource(0, slot1ResourcesIds);
    }
    if(slot2ResourcesIds.length > 0) {
        taskResources2 = formatTaskResource(1, slot2ResourcesIds);
    }
    return taskResources1.concat(taskResources2);
}

/* Format task resources to patch task with new task resources list */
const formatTaskResource = (slot, resourcesIds) => {
    let taskResources = [];
    for(let id of resourcesIds) {
        taskResources.push({slot: slot, resource: process.env.REACT_APP_API_URI + 'resource_items/' + id})
    }
    return taskResources;
}

/* Get tasks for resource choice (tasks not validated, older than now) */
export const getTasksForResourceChoice = (date) => {
    return fetchData('tasks/resource_choice?is_resource_validated=false&date[lt]=' + date)
        .then(response => response.json())
        .catch(() => null);
}

export const patchTaskName = async (taskId, name) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: name
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}
import React, {useEffect} from "react";
import ReactHtmlParser from "html-react-parser";
import {Loading} from "../common/Loading";

export const ControllablePrinterModal = (props) => {

    const {title, description, buttons, cancelCallback, loading, isCancelButton} = props;

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <div className="modal__overlay">
            <div className="modal__dialog">
                <div className="dialog__content">
                    <h2 className="content__title">{title}</h2>
                    {
                        description && <p>{ReactHtmlParser(description)}</p>
                    }
                    {loading &&
                        <Loading/>
                    }
                </div>
                {
                    (isCancelButton || (buttons && buttons.length > 0)) &&
                        <div className="dialog__footer">
                            {isCancelButton &&
                                <button className="button-error" onClick={cancelCallback}>Cancel</button>
                            }
                            {
                                buttons && buttons.map((button, index) =>
                                    <button key={index} onClick={button.callback} className="btn-white">{ReactHtmlParser(button.label)}</button>
                                )
                            }
                        </div>
                }
            </div>
        </div>
    );
}

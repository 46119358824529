import {Controller, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {deleteProfile, patchFarmProfile, postFarmProfile} from "../../../api/apiFarmCommand";
import {CELL_TYPES} from "../../../services/FarmService";
import {FarmProfileFormPrinters} from "./FarmProfileFormPrinters";
import {MaterialIcon} from "../../common/icons/MaterialIcon";

export const FarmProfileForm = (props) => {

    const {selectedProfile, saveSuccessCallback, saveErrorCallback, deleteSuccessCallback, deleteErrorCallback, systemType} = props;

    const {handleSubmit, control, reset, setValue} = useForm();

    const [updateForm, setUpdateForm] = useState(0);
    const [tolerancePercentage, setTolerancePercentage] = useState(selectedProfile.tolerance_percentage);

    /* Save profile */
    const onSubmit = (profile) => {
        let promise;
        if(profile.id === undefined || profile.id === null || profile.id === -1) {
            promise = postFarmProfile(profile);
        } else {
            promise = patchFarmProfile(profile);
        }
        promise
            .then((profileData) => saveSuccessCallback(profileData))
            .catch(() => saveErrorCallback());
    }

    /* Delete selected profile */
    const handleDeleteProfile = (profile) => {
        if(profile.id && profile.id !== -1) {
            deleteProfile(profile.id)
                .then(() => deleteSuccessCallback())
                .catch(() => deleteErrorCallback());
        }
    }

    /* Reset data in form */
    const resetForm = () => {
        reset(selectedProfile);
        setUpdateForm(updateForm + 1);
    }

    const buttonDeleteProfil = () => {
        if(selectedProfile.id >= 0){
            if(selectedProfile.farm_profile_used){
                return (
                    <div id="warn-message-profile">
                        <p><MaterialIcon label="report"/> Cannot delete used profile</p>
                    </div>
                )
            } else {
                return (
                    <button type="button" onClick={() => handleDeleteProfile(selectedProfile)} className="simple-profile-button simple-error-button">
                        <i className="fas fa-trash-alt" />
                        <span>Delete profile</span>
                    </button>
                )
            }
        }
    }

    useEffect(() => {
        resetForm();
    }, [selectedProfile]);

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* id */}
            <Controller
                control={control} name="id"
                value={(selectedProfile && selectedProfile.id) ? selectedProfile.id : -1}
                render={({field, value}) =>
                    <input type="hidden" {...field} id="farm-profile-id" value={value}/>
                }
            />

            <div>
                {/* name */}
                <label htmlFor="farm-profile-name">Profile name</label>
                <div id="delete-profil">
                    <Controller
                        control={control} name="name" rules={{required: true}}
                        defaultValue={(selectedProfile && selectedProfile.name) ? selectedProfile.name : ''}
                        render={({field}) => (
                            <input type="text" id="farm-profile-name" name="name" {...field} placeholder="Profile name"/>
                        )}
                    />

                    {buttonDeleteProfil()}
                </div>
            </div>

            {systemType === CELL_TYPES.PRINTER &&
                <FarmProfileFormPrinters
                    selectedProfile={selectedProfile} control={control} setValue={setValue}
                />
            }

            <div>
                <div className="profile-data-inputs">
                    {/* temperature */}
                    <div>
                        <label htmlFor="farm-profile-temperature">Temperature (between 10 and 50°C)</label>
                        <Controller
                            control={control} name="temperature" rules={{required: true}}
                            defaultValue={(selectedProfile && selectedProfile.temperature) ? selectedProfile.temperature : 20}
                            render={({field}) => (
                                <input type="number" id="farm-profile-temperature" name="temperature" {...field} min={10} max={50}/>
                            )}
                        />
                    </div>
                    {/* humidity */}
                    <div>
                        <label htmlFor="farm-profile-humidity">Humidity (in %) [Not controlled by the Smart Farm]</label>
                        <Controller
                            control={control} name="humidity" rules={{required: true}}
                            defaultValue={(selectedProfile && selectedProfile.humidity) ? selectedProfile.humidity : 20}
                            render={({field}) => (
                                <input type="number" id="farm-profile-humidity" name="humidity" {...field} min={0} max={100}/>
                            )}
                        />
                    </div>
                </div>
                <div>
                    <div className="farm-profile-form-section-info">Tolerance percentage to be considered in process</div>
                    <Controller
                        control={control} name="printer_brand" rules={{required: false}}
                        render={() => (
                            <select
                                id="select-printer-brand"
                                onChange={(e) => {
                                    setTolerancePercentage(e.target.value);
                                    setValue('tolerance_percentage', e.target.value);
                                }}
                                value={tolerancePercentage}
                            >
                                <option value={5} key={5}>5%</option>
                                <option value={10} key={10}>10%</option>
                                <option value={20} key={20}>20%</option>
                            </select>
                        )}
                    />
                </div>
            </div>

            {/* Buttons */}
            <div className="farm-profile-form-buttons">
                <button type="button" onClick={() => resetForm()} className="farm-profile-form-button button-outlined">
                    Cancel changes
                </button>
                <button type="submit" className="farm-profile-form-button button-main">
                    <i className="fa fa-check" aria-hidden="true"/>
                    <span>Save changes</span>
                </button>
            </div>
        </form>
    );
}

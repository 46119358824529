import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Checkbox} from "rsuite";
import {patchPicProjects} from "../../../api/apiPic";

export const PicManageProjectsModal = (props) => {

    const {pic, projects, cancelCallback, successCallback, errorCallback, setShowManagePicProjectsModal, setLoading} = props;
    const {handleSubmit} = useForm();

    const getDefaultCheckedProjectsIds = () => {
        let projectsIds = [];
        for(const projectPic of pic.project_pics) {
            if(pic && pic.project_pics && pic.project_pics.length > 0) {
                projectsIds.push(projectPic.project.id);
            }
        }
        return projectsIds;
    }

    const getDefaultQuantities = () => {
        let quantities = {};
        if(pic && pic.project_pics && pic.project_pics.length > 0) {
            for(const projectPic of pic.project_pics) {
                if(projectPic.project && projectPic.project.id && projectPic.quantity) {
                    quantities[projectPic.project.id] = projectPic.quantity;
                }
            }
        }
        return quantities;
    }

    const [selectedProjectsId, setSelectedProjectsId] = useState(getDefaultCheckedProjectsIds);
    const [selectedQuantities, setSelectedQuantities] = useState(getDefaultQuantities);

    // Escape Listener Hook
    useEffect(() => {
        const escape = (e) => {
            if (e.key === 'Escape')
                cancelCallback();
        };
        document.addEventListener('keydown', escape, false);
        document.body.style.overflow = 'hidden';

        return () => {
            document.removeEventListener('keydown', escape, false);
            document.body.style.overflow = 'unset';
        };
    }, []);

    const onSubmit = async (data, event) => {
        setLoading(true);
        cancelCallback();
        event.preventDefault();
       const projectPics = getProjectPicsForSubmit();
        // Update original pic instead of pic if pic has original pic
        if(pic.original_pic) {
            pic.original_pic.project_pics = projectPics;
        } else {
            pic.project_pics = projectPics;
        }
        await patchPicProjects(pic.original_pic ? pic.original_pic : pic)
            .then(() => {
                successCallback('PIC projects updated');
                setShowManagePicProjectsModal(false);
            })
            .catch(() => errorCallback('An error occurred while updating PIC projects'));
    }

    /* Format project pics list for pic submit */
    const getProjectPicsForSubmit = () => {
        let projectPics = [];
        for(let projectId of selectedProjectsId) {
            let isProjectAlreadyInPic = false;
            let projectPic;
            if(pic.project_pics) {
                for(let projectPicInPic of pic.project_pics) {
                    if(projectPicInPic.project.id === projectId) {
                        isProjectAlreadyInPic = true;
                        projectPic = projectPicInPic;
                        break;
                    }
                }
            }
            if(!isProjectAlreadyInPic) {
                projectPic = {project: {id: projectId}};
            }
            projectPic.quantity = selectedQuantities[projectId] ? selectedQuantities[projectId] : 1;
            projectPics.push(projectPic);
        }
        return projectPics;
    }

    const isProjectChecked = (projectId) => {
        for(let project of selectedProjectsId) {
            if (project === projectId) {
                return true;
            }
        }
        return false;
    }

    /* Update selected projects list */
    const handleProjectChange = (projectId) => {
        if(selectedProjectsId.indexOf(projectId) < 0) {
            setSelectedProjectsId([...selectedProjectsId, projectId]);
            handleProjectQuantityChange(projectId, 1);
        } else {
            setSelectedProjectsId(selectedProjectsId.filter(id => id !== projectId));
            handleProjectQuantityChange(projectId, 0);
        }
    }

    const handleProjectQuantityChange = (projectId, quantity) => {
        if(quantity && quantity >= 0) {
            let newSelectedQuantities = selectedQuantities;
            newSelectedQuantities[projectId] = parseInt(quantity);
            setSelectedQuantities(newSelectedQuantities);
        }
    }

    return (
        <div
            className="modal__overlay main__pic-manage-projects-modal"
            onClick={(e) => {
                if (e.target === e.currentTarget)
                    cancelCallback();
            }}
        >
            <div className="modal__dialog">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="dialog__content">
                        <h2 className="content__title">Manage PIC Projects</h2>
                        <h3>{pic.name}</h3>
                        {(projects && projects.length > 0) ?
                            <div className="projects-list">
                                {projects.map(project =>
                                    <Checkbox key={project.id} id={'project-' + project.id} value={project.id}
                                              defaultChecked={isProjectChecked(project.id)}
                                              onChange={() => handleProjectChange(project.id)}
                                              className="projects-list-item">
                                        <div className="projects-list-item-line">
                                            <span className="projects-list-item-label">{project.name}</span>
                                            {isProjectChecked(project.id) &&
                                                <span className="projects-list-item-quantity">
                                                    <span>Quantity:</span>
                                                    <input
                                                        type="number" className="projects-list-item-quantity-input" min={1}
                                                        defaultValue={selectedQuantities[project.id] ? selectedQuantities[project.id] : 1}
                                                        onChange={e => handleProjectQuantityChange(project.id, e.target.value)}
                                                        disabled={!isProjectChecked(project.id)}
                                                    />
                                                </span>
                                            }
                                        </div>
                                    </Checkbox>
                                )}
                            </div>
                            :
                            <p>No projects available</p>
                        }
                    </div>
                    <div className="dialog__footer">
                        <button type="button" className="button-error" onClick={cancelCallback}>Cancel</button>
                        <button type="submit" className="btn-white" disabled={!projects || projects.length <= 0}>Save</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

import {OutlinedCard} from "../../common/OutlinedCard";
import React, {useEffect, useState} from "react";
import {Toggle} from "rsuite";
import {sendFarmCommandEvent} from "../../../../services/socketEventsService";
import {CirclePicker} from "react-color";
import {StopCurrentModal} from "../modals/StopCurrentModal";
import {DigitCode} from "../../../common/DigitCode";
import {
    CELL_TYPES,
    FARM_EVENT_TYPES,
    getLedColorsColorsWithoutOff,
    getLedColorsIndexesWithoutOff,
    LED_COLORS, SMART_FARM_COMMANDS, SMART_FARM_DATA_FIELDS,
    FAN_ACTIVATION_TIME_BEFORE_DOOR_OPENING, addFanSchedulingLocalStorage
} from "../../../../services/FarmService";
import {MaterialIcon} from "../../../common/icons/MaterialIcon";
import {getCurrentTimestamp} from "../../../CommonFunctions";

export const EnvironmentManagerBlock = (props) => {

    const {environmentData, setEnvironmentData, farmCell, saveFarmEvent, smartFarmCellData, isReceivingData, setCommandsInProcess,
        commandsInProcess, isPrinterPrinting} = props;

    // On/Off
    const [isOn, setIsOn] = useState(isReceivingData);

    // Stop printer
    const [showStopCurrentModal, setShowStopCurrentModal] = useState(false);
    const cancelModal = () => setShowStopCurrentModal(false);

    const getLedColor = () => {
        if(commandsInProcess[farmCell.system_code] && commandsInProcess[farmCell.system_code][SMART_FARM_DATA_FIELDS.LED_COLOR]) {
            return commandsInProcess[farmCell.system_code][SMART_FARM_DATA_FIELDS.LED_COLOR].value;
        } else if(smartFarmCellData && smartFarmCellData[SMART_FARM_DATA_FIELDS.LED_COLOR]){
            return parseInt(smartFarmCellData[SMART_FARM_DATA_FIELDS.LED_COLOR]);
        }
        return LED_COLORS.OFF.index;
    }

    const getIsLighting = () => {
        return getLedColor() !== LED_COLORS.OFF.index;
    }

    // Lighting and colors
    const [isLighting, setIsLighting] = useState(getIsLighting());
    const [selectedColorIndex, setSelectedColorIndex] = useState(getLedColor());
    const COLORS_VALUES = getLedColorsColorsWithoutOff();
    const COLORS_INDEXES = getLedColorsIndexesWithoutOff();

    const getDoorStatus = () => {
        if(!smartFarmCellData || smartFarmCellData.door_closed === undefined || smartFarmCellData.door_closed === null) {
            return null;
        }
        return smartFarmCellData.door_closed === 1;
    }

    // Door
    const [isDoorClosed, setIsDoorClosed] = useState(getDoorStatus());
    const [isOpenDoorDigitCodeVisible, setIsOpenDoorDigitCodeVisible] = useState(false);

    useEffect(() => {
        if(isReceivingData !== isOn) {
            setIsOn(isReceivingData);
        }
    }, [isReceivingData]);

    useEffect(() => {
        // Update isDoorClosed value with influxdb data if value has changed
        if(smartFarmCellData && smartFarmCellData.door_closed !== undefined &&
            smartFarmCellData.door_closed !== null && ((smartFarmCellData.door_closed === 1) !== isDoorClosed)) {
            setIsDoorClosed(smartFarmCellData.door_closed === 1);
        }
        // Update led color
        setIsLighting(getIsLighting());
        setSelectedColorIndex(getLedColor());
    }, [smartFarmCellData]);

    const handleOnOff = (is_on) => {
        setIsOn(is_on);
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.ON_OFF, is_on);
        saveFarmEvent(FARM_EVENT_TYPES.ON_OFF, is_on ? 1 : 0);
    };

    const handleLighting = (is_lighting) => {
        const colorIndex = is_lighting ? LED_COLORS.WHITE.index : LED_COLORS.OFF.index;
        setIsLighting(is_lighting);
        setSelectedColorIndex(colorIndex);
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.LED_COLOR, colorIndex);
        saveFarmEvent(FARM_EVENT_TYPES.LED_COLOR, colorIndex);
        setCommandsInProcess({type: 'add-command', value: {systemCode: farmCell.system_code, commandType: SMART_FARM_DATA_FIELDS.LED_COLOR, commandValue: colorIndex}});
    }

    const handleColorChange = (newColor) => {
        const colorIndex = COLORS_INDEXES[COLORS_VALUES.indexOf(newColor.hex)];
        setEnvironmentData({...environmentData, 'led': colorIndex});
        setSelectedColorIndex(colorIndex);
        setIsLighting(true);
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.LED_COLOR, colorIndex);
        saveFarmEvent(FARM_EVENT_TYPES.LED_COLOR, colorIndex);
        setCommandsInProcess({type: 'add-command', value: {systemCode: farmCell.system_code, commandType: SMART_FARM_DATA_FIELDS.LED_COLOR, commandValue: colorIndex}});
    };

    const manageFiltrationBeforeOpenDoor = () => {
        addFanSchedulingLocalStorage(farmCell.system_code);
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.AIR_EXTRACTION, 100);
    };

    const handleOpenDoor = (user) => {
        const userId = user.id ? user.id : null;

        // If print running, activate fan before opening the door
        if(isPrinterPrinting())
            manageFiltrationBeforeOpenDoor();

        // Else, open the door directly
        else
            sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.DOOR_CLOSED, 0);

        saveFarmEvent(FARM_EVENT_TYPES.DOOR_CLOSED, 0, userId);
    };

    return(
        <OutlinedCard>
            <DigitCode
                visible={isOpenDoorDigitCodeVisible} setVisible={setIsOpenDoorDigitCodeVisible}
                callback={handleOpenDoor} errorCallback={() => null}
            />

            <div className="farm__dialog_environment_manager farm__dialog_common">
                {/* Header */}
                <div className="header-block">
                    <h2>Environment Manager</h2>
                    {farmCell.system_type === CELL_TYPES.PRINTER &&
                        <button
                            className="button-outlined-danger"
                            type="button" disabled={smartFarmCellData && smartFarmCellData.relay_on === 0}
                            onClick={() => setShowStopCurrentModal(true)}>
                            <MaterialIcon label="warning" classes="button-icon-left"/>
                            <span>Stop printer</span>
                        </button>
                    }
                </div>

                {/* On / off */}
                <div className="toggle-label-right farm-command-line">
                    <Toggle checked={isOn} onChange={is_on => handleOnOff(is_on)}/>
                    <span className="toggle-label">Off/On</span>
                </div>

                {/* Lighting */}
                <div className="lighting-choices farm-command-line">
                    <div className="toggle-label-right">
                        <Toggle checked={isLighting} onChange={is_lighting => handleLighting(is_lighting)}/>
                        <span className="toggle-label">Module lighting</span>
                    </div>
                    <div className="flex">
                        <span className="light-color-label">Light color:</span>
                        <div>
                            <CirclePicker
                                colors={COLORS_VALUES}
                                color={COLORS_VALUES[COLORS_INDEXES.indexOf(selectedColorIndex)]}
                                circleSize={20}
                                onChange={(color) => handleColorChange(color)}
                                width="auto"
                            />
                        </div>
                    </div>
                </div>

                {/* Door */}
                {farmCell.system_code.startsWith('T') &&
                    <div className="door-block">
                        <button className="button-outlined-main"
                            type="button" onClick={() => setIsOpenDoorDigitCodeVisible(true)}>
                            Open door
                        </button>
                        <div className="door-status">
                            <span className="door-status-label">Door status:</span>
                            {isDoorClosed === null ?
                                <span className="font-bold">--</span> :
                                <span className="font-bold">{isDoorClosed ? 'Closed' : 'Opened'}</span>
                            }
                        </div>
                    </div>
                }
            </div>

            {/* Modal */}
            {showStopCurrentModal &&
                <StopCurrentModal farmCell={farmCell} cancelModal={cancelModal} saveFarmEvent={saveFarmEvent}/>
            }
        </OutlinedCard>
    );
}

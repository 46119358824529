import {getCurrentTimestamp, getCurrentUser} from "../components/CommonFunctions";
import {postAPIData} from "./useFetch";
import {CELL_TYPES} from "../services/FarmService";

const currentUser = getCurrentUser();
const token = localStorage.getItem('token');

export const sendFarmCommand = (systemCode, action, data, successCallback, errorCallback) => {

    postAPIData(
        'farm_commands',
        {
            organization_group: process.env.REACT_APP_API_URI + `organization_groups/${currentUser.organization.organization_group.id}`,
            system_code: systemCode,
            action: action,
            data: data,
            sent_date: Math.floor(Date.now() / 1000)
        },
        successCallback,
        errorCallback
    );

};

export const postFarmEvent = async (farmCellId, eventType, eventValue, userId = null, isAutomatic = false, farmProfileId = null) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + 'farm_events', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            farm_cell: process.env.REACT_APP_API_URI + 'farm_cells/' + farmCellId,
            type: eventType,
            value: eventValue,
            user: (userId !== undefined && userId !== null) ? (process.env.REACT_APP_API_URI + 'users/' + userId) : null,
            date: getCurrentTimestamp(),
            is_automatic: isAutomatic,
            farm_profile: farmProfileId !== null ? process.env.REACT_APP_API_URI + 'farm_profiles/' + farmProfileId : null
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

const getFarmProfileBody = (profile) => {
    return {
        name: profile.name ? profile.name : 'New profile',
        system_type: (profile.system_type !== undefined && profile.system_type !== null) ? parseInt(profile.system_type) : CELL_TYPES.PRINTER,
        temperature: (profile.temperature !== undefined && profile.temperature !== null) ? parseInt(profile.temperature) : null,
        humidity: (profile.humidity !== undefined && profile.humidity !== null) ? parseInt(profile.humidity) : null,
        organization: process.env.REACT_APP_API_URI + `organizations/${currentUser.organization.id}`,
        printer_brand: (profile.printer_brand && profile.printer_brand.id > 0) ? (process.env.REACT_APP_API_URI + 'printer_brands/' + profile.printer_brand.id) : null,
        printer_model: (profile.printer_model && profile.printer_model.id > 0) ? (process.env.REACT_APP_API_URI + 'models/' + profile.printer_model.id) : null,
        material: (profile.material && profile.material.id > 0) ? (process.env.REACT_APP_API_URI + 'materials/' + profile.material.id) : null,
        tolerance_percentage: (profile.tolerance_percentage !== undefined && profile.tolerance_percentage !== null) ? parseInt(profile.tolerance_percentage) : null,
    };
}

export const postFarmProfile = async (profile) => {
    return await fetch(process.env.REACT_APP_API_ADDRESS + 'farm_profiles', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(getFarmProfileBody(profile))
    }).then((response) => {
        if(!response.ok)
            throw Error;
        return response.json();
    });
}

export const patchFarmProfile = async (profile) => {

    return await fetch(process.env.REACT_APP_API_ADDRESS + 'farm_profiles/' + profile.id, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(getFarmProfileBody(profile))
    }).then((response) => {
        if(!response.ok)
            throw Error;
        return response.json();
    }).catch(() => {
        throw Error;
    });
}

export const deleteProfile = async (profileId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + 'farm_profiles/' + profileId, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

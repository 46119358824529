import React from "react";
import {postMaterial} from "../../../api/apiBackoffice";
import {useForm, Controller} from "react-hook-form";

export const MaterialAdd = (props) => {

    const {successCallback, errorCallback, materials, materialsData, technologies,
        isCustomMaterialValue, setIsCustomMaterialValue, setRightPartIsOpen, rightPartIsOpen} = props;

    const {reset, handleSubmit, control, setValue} = useForm();

    const handleCreate = async (data) => {
        const material = addNewDataToMaterial({}, data);
        await postMaterial(material)
            .then(() => {
                reset();
                setIsCustomMaterialValue(false);
                setRightPartIsOpen(!rightPartIsOpen);
                successCallback('This Material has been created.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    };

    const handleMaterialSelectChange = (e) => {
        if(e.target.value === 'custom') {
            setIsCustomMaterialValue(true);
        } else {
            setValue('name', e.target.value);
            return e.target.value;
        }
    }

    // Add data from form to material object
    const addNewDataToMaterial = (material, data) => {
        material.technology = data.technology;
        if(isCustomMaterialValue) {
            material.name = data.name_custom;
            material.material_data = null;
        } else {
            const materialData = JSON.parse(data.name);
            material.name = materialData.name;
            material.material_data = materialData.id;
        }
        return material;
    }

    // Check if material data is already used by another material
    const checkIfMaterialDataAlreadyUsed = (materialData) => {
        for(let i = 0; i < materials.data.length; i++) {
            if(materials.data[i].material_data && materialData.id === materials.data[i].material_data.id) {
                return true;
            }
        }
        return false;
    }

    return(
        <>
            <h2 style={{"paddingLeft":"10px"}}>Add Material</h2>
            <form onSubmit={handleSubmit(handleCreate)}>
                <label>Material name :</label>
                <div style={{"display": "flex", "justifyContent": "center", "marginBottom": "20px"}}>
                    {!isCustomMaterialValue &&
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                required: !isCustomMaterialValue,
                                validate: (value) => (value !== 0 || isCustomMaterialValue)
                            }}
                            defaultValue={0}
                            render={({field}) => (
                                <select id="material-name" {...field}
                                        onChange={(e) => handleMaterialSelectChange(e)}>
                                    <option disabled value={0}>--Select a material--</option>
                                    {materialsData.data.map(material => (
                                        <option key={material.id} value={JSON.stringify(material)}
                                                disabled={checkIfMaterialDataAlreadyUsed(material)}>{material.name}</option>
                                    ))}
                                    <option value="custom">Add a custom value</option>
                                </select>
                            )}
                        />
                    }
                    {isCustomMaterialValue &&
                        <Controller
                            control={control}
                            name="name_custom"
                            rules={{required: isCustomMaterialValue}}
                            defaultValue={''}
                            render={({field}) => (
                                <input type="text" {...field}/>
                            )}
                        />
                    }
                </div>
                <Controller
                    control={control}
                    name="technology"
                    rules={{required: true, validate: (value) => value !== 0}}
                    defaultValue={0}
                    render={({field}) => (
                        <label>Technology used:
                            <select name="technology" {...field}>
                                <option value={0}>-- Choose a Technology --</option>
                                {technologies.data && technologies.data.map((techno) => (
                                    <option key={techno.id} value={techno.id}>{techno.name}</option>
                                ))}
                            </select>
                        </label>
                    )}
                />
                <br/>
                <input type="submit" value="Save" style={{display: "block", margin: "auto"}} />
            </form>
        </>
    );
}

import React from "react";

export const EnvironmentDonutChart = (props) => {

    const {colorData, percentageData, label, labelSize} = props;

    let color = colorData;
    let percentage = percentageData;
    let base = '#D8D8D8';

    let color2 = color;
    let base2 = base;
    let deg = (percentage/100*360) + 'deg';
    let deg1 = '90deg';
    let deg2 = deg;

    // If percentage is less than 50%
    if (percentage < 50) {
        base = color;
        color = base2;
        color2 = base2;
        deg1 = (percentage/100*360+90) + 'deg';
        deg2 = '0deg';
    }

    const radialGradient = 'radial-gradient(' + base + ' 0, ' + base + ' 67%, transparent 90%)';
    const rotateDeg1 = 'rotate(' + deg1 + ')';
    const rotateDeg2 = 'rotate(' + deg2 + ')';

    const donutChartStyle = {
        background: radialGradient
    }

    const oneStyle = {
        background: color,
        WebkitTransform: rotateDeg1,
        transform: rotateDeg1
    }

    const twoStyle = {
        background: color2,
        WebkitTransform: rotateDeg2,
        transform: rotateDeg2
    }

    return(
        <div className="farm__dialog_environment_blocks">
            <div className="donut-chart chart" style={donutChartStyle}>
                <div className="slice one" style={oneStyle}></div>
                <div className="slice two" style={twoStyle}></div>
                <div className="chart-center">
                    <span className={labelSize === 'small' ? 'donut-chart-value-small' : 'donut-chart-value'}>{label}</span>
                </div>
            </div>
        </div>
    );
}

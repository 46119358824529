import {OutlinedCard} from "../../common/OutlinedCard";
import {getFormattedRoundedDate} from "../../../CommonFunctions";
import React, {useEffect, useState} from "react";
import {getSortedData} from "../../../../services/TablesService";
import {FARM_EVENT_TYPES} from "../../../../services/FarmService";

export const getLedColorEventType = (value) => {
    switch(value) {
        case 0: return 'Light off';
        case 2: return 'Red light';
        case 3: return 'Green light';
        case 4: return 'Orange light';
        case 5: return 'Blue light';
        case 7: return 'White light';
        default: return 'Light change';
    }
}

export const getEventType = (type, value) => {
    switch (type) {
        case FARM_EVENT_TYPES.ON_OFF: return value === 0 ? 'System start' : 'System stop';
        case FARM_EVENT_TYPES.LED_COLOR: return getLedColorEventType(value);
        case FARM_EVENT_TYPES.DOOR_CLOSED: return value === 0 ? 'Open door' : 'Close door';
        case FARM_EVENT_TYPES.TEMPERATURE: return (value > 0 ? 'Temperature change: ' + value + '°C' : 'Heater off');
        case FARM_EVENT_TYPES.AIR_EXTRACTION: return 'Air extraction change: ' + value + '%';
        case FARM_EVENT_TYPES.RELAY_OFF: return 'Stop printer';
        case FARM_EVENT_TYPES.DEHUMIDIFIER: return 'Dehumidifier ' + (value === 1 ? 'on' : 'off');
        default: return '--';
    }
};

export const HistoryEventsBlock = (props) => {

    const {historyEvents} = props;

    const [sortedHistoryEvents, setSortedHistoryEvents] = useState([]);

    useEffect(() => {
        // Sort events
        setSortedHistoryEvents(getSortedData(historyEvents, 'date', 'desc'));
    }, [historyEvents]);

    const getEventType = (type, value) => {
        switch (type) {
            case FARM_EVENT_TYPES.ON_OFF: return value === 0 ? 'System start' : 'System stop';
            case FARM_EVENT_TYPES.LED_COLOR: return getLedColorEventType(value);
            case FARM_EVENT_TYPES.DOOR_CLOSED: return value === 0 ? 'Open door' : 'Close door';
            case FARM_EVENT_TYPES.TEMPERATURE: return (value > 0 ? 'Temperature change: ' + value + '°C' : 'Heater off');
            case FARM_EVENT_TYPES.AIR_EXTRACTION: return 'Air extraction change: ' + value + '%';
            case FARM_EVENT_TYPES.RELAY_OFF: return 'Stop printer';
            case FARM_EVENT_TYPES.DEHUMIDIFIER: return value === 1 ? 'Start dehumidifier' : 'Stop dehumidifier';
            default: return '--';
        }
    }

    const getLedColorEventType = (value) => {
        switch(value) {
            case 0: return 'Light off';
            case 2: return 'Red light';
            case 3: return 'Green light';
            case 4: return 'Orange light';
            case 5: return 'Blue light';
            case 7: return 'White light';
            default: return 'Light change';
        }
    }

    /* Get user value depending on if event is an automatic event or if event has a user */
    const getEventUser = (event) => {
        if(event.is_automatic) {
            return 'Automatic';
        }
        if(event.type === FARM_EVENT_TYPES.DOOR_CLOSED && event.user) {
            return event.user.firstname + ' ' + event.user.lastname;
        }
        return '--';
    }

    return(
        <OutlinedCard>
            <div className="farm__dialog_history_events">
                <h2>History events</h2>
                {sortedHistoryEvents.length === 0 &&
                    <p>No history event</p>
                }
                {sortedHistoryEvents.length > 0 &&
                    <div className="history-events-table">
                        <table>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Event type</th>
                                <th>User</th>
                            </tr>
                            </thead>
                            <tbody>
                            {sortedHistoryEvents.map(event =>
                                <tr key={event.id}>
                                    <td>{getFormattedRoundedDate(new Date(event.date)).toString()}</td>
                                    <td>{getEventType(event.type, event.value)}</td>
                                    <td>{getEventUser(event)}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </OutlinedCard>
    );
}

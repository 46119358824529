import {OutlinedCard} from "../../common/OutlinedCard";
import {Toggle} from "rsuite";
import React, {useEffect, useState} from "react";
import {sendFarmCommandEvent} from "../../../../services/socketEventsService";
import {SMART_FARM_COMMANDS} from "../../../../services/FarmService";
import {isScannerModeEnabled} from "../../../CommonFunctions";

export const FarmManagerBlock = (props) => {

    const {isGeneralBuzzerOn, generalCommandsInProcess, setGeneralCommandsInProcess} = props;

    const getIsBuzzerOn = () => {
        if(generalCommandsInProcess[SMART_FARM_COMMANDS.STOP_BUZZER]) {
            return generalCommandsInProcess[SMART_FARM_COMMANDS.STOP_BUZZER].value > 0;
        } else {
            return isGeneralBuzzerOn > 0;
        }
    }

    const [isScannerModeOn, setIsScannerModeOn] = useState(isScannerModeEnabled());
    const [isBuzzerOn, setIsBuzzerOn] = useState(getIsBuzzerOn());

    useEffect(() => {
        setIsBuzzerOn(getIsBuzzerOn());
    }, [isGeneralBuzzerOn, generalCommandsInProcess[SMART_FARM_COMMANDS.STOP_BUZZER]]);

    const handleStopBuzzer = (isOn) => {
        sendFarmCommandEvent('broadcast', SMART_FARM_COMMANDS.STOP_BUZZER, isOn ? 1 : 0);
        setGeneralCommandsInProcess({type: 'add-command', value: {commandType: SMART_FARM_COMMANDS.STOP_BUZZER, commandValue: isOn ? 1 : 0}})
        setIsBuzzerOn(isOn);
    };

    /* Store scanner mode activation in local storage */
    const handleScannerModeChange = (isScannerMode) => {
        if(isScannerMode) {
            localStorage.setItem('scanner_mode', 'true');
        } else {
            localStorage.removeItem('scanner_mode');
        }
        setIsScannerModeOn(isScannerMode);
    }

    return(
        <OutlinedCard>
            <div className="farm__dialog_farm_manager farm__dialog_common">
                <h2>General Manager</h2>

                <div className="toggle-label-right farm-command-line scanner-toggle">
                    <Toggle checked={isScannerModeOn} onChange={isScannerMode => handleScannerModeChange(isScannerMode)}/>
                    <span className="toggle-label">Receive data from scanner</span>
                </div>

                <div className="toggle-label-right farm-command-line scanner-toggle">
                    <Toggle checked={isBuzzerOn} onChange={isOn => handleStopBuzzer(isOn)}/>
                    <span className="toggle-label">Buzzer sound</span>
                </div>
            </div>
        </OutlinedCard>
    );
}
import {ResourceHistoryTable} from "./ResourceHistoryTable";
import React from "react";
import {ResourceLineChartUsage} from "./ResourceLineChartUsage";

export const ResourceHistory = (props) => {

    const {resource, reference} = props;

    return (
        <div>
            <div className="block">
                <ResourceHistoryTable resource={resource}/>
            </div>

            {/* TODO Link with the balance */}
            {/*<div className="block last-block">*/}
            {/*    <h2>Resource reference quantity (history and prevision)</h2>*/}
            {/*    <ResourceLineChartUsage reference={reference} resource={resource}/>*/}
            {/*</div>*/}
        </div>
    );
}

import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {EditProjectComponent} from "./EditProject";
import {addProjectMember, archiveProject, deleteProject} from "../../api/apiProject";
import {React, useState} from "react";
import {useGetData} from "../../api/useFetch";
import {getCurrentUser, getFormattedDate, isUserGranted} from "../CommonFunctions";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {Avatar} from "../common/Avatar";
import Modal from "../common/Modal";

export const ProjectInformation = (props) => {

    const {project, successCallback, errorCallback} = props;

    const [showModal, setShowModal] = useState(false);

    // Get organization group members to add in project
    const organizationGroupMembers = useGetData(
        'memberData',
        'users',
        {
            'organization.organizationGroup.id': getCurrentUser().organization.organization_group.id
        }
    )

    const handleSelect = async (value) => {
        /* TODO Add callback */
        await addProjectMember(value.id, project.id)
            .then(() => {
                organizationGroupMembers.refetch()
                    .then(() => successCallback('The member has been added to the project.'));
            })
            .catch(() => errorCallback('Something went wrong.'));
    }

    const handleDelete = async (projectId) => {
        await deleteProject(projectId)
            .then(() => {
                window.location = '/projects';
            })
            .catch(() => errorCallback('Something went wrong.'));
    }
    const cancelModal = () => {setShowModal(false);}

    const isAlreadyInProject = (option) => {
        let isInProject = false;

        if(option.id === project.user.id)
            isInProject = true;
        else {
            project.users_in_project.map((user_in_project) => {
                if (option.id === user_in_project.user.id)
                    isInProject = true;
            })
        }

        return isInProject;
    }

    if(organizationGroupMembers.isLoading) return <Loading/>
    if(organizationGroupMembers.isError) return <Error errorMessage={organizationGroupMembers.error.message}/>

    return (
        <div className="content">

            <h2>Project informations</h2>

            <p id="infos__project-name">
                <span className="project__color" style={{background: project.color.hex_code}}/>
                {project.name}
                {project.state ? <span className="project-state project-state--archived">Archived</span> : ''}
            </p>
            <div id="infos__data-progression">
                <div className="data-progression__date">
                    <p><strong>Project creation</strong></p>
                    <p className="date__element">{getFormattedDate(project.creation_date)}</p>
                </div>
                <div className="data-progression__date">
                    <p><strong>Last modification</strong></p>
                    <p className="date__element">{getFormattedDate(project.last_modification)}</p>
                </div>
            </div>
            <div className="flex-div">
                <div className="half-width">
                    <strong>Tags</strong><br/>
                    {project.tags.length > 0 ?
                        project.tags.map((tag, i) => <span className="project__tag" key={i}>{tag}</span>)
                        : <em>None</em>
                    }
                </div>
                <div className="half-width" id="infos__members">
                    <strong>Members</strong><br/>
                    <div id="members__list">
                        <Avatar
                            user={project.user}
                            isAdmin={true}
                            size="small"
                        />
                        {project.users_in_project.map((user_in_project) => (
                            <Avatar
                                key={user_in_project.user.id}
                                user={user_in_project.user}
                                isAdmin={false}
                                size="small"
                            />
                        ))}
                    </div>

                    {isUserGranted('ROLE_UPDATE_PROJECT') &&
                        <form id="members__add-form">
                            <Autocomplete
                                id="f-username"
                                disableClearable
                                onChange={(event, value) => handleSelect(value)}
                                forcePopupIcon={false}
                                options={
                                    organizationGroupMembers.data.map((member) => {
                                        return ({username: `${member.firstname} ${member.lastname}`, id: member.id})
                                    }).filter(member => !isAlreadyInProject(member))
                                }
                                getOptionDisabled={(option) => isAlreadyInProject(option)}
                                getOptionLabel={(option) => option.username}
                                renderInput={(params) => (
                                    <TextField {...params} InputProps={{...params.InputProps, disableUnderline: true}}
                                               autoComplete="off" placeholder="Add a member"/>
                                )}
                                noOptionsText="No member to add."
                            />
                        </form>
                    }
                </div>
            </div>
            <div>
                {isUserGranted('ROLE_UPDATE_PROJECT') &&
                <>
                    <EditProjectComponent project={project} successCallback={successCallback} errorCallback={errorCallback}/>

                    <button className="button-link button-icon"
                        onClick={async () => {
                            if (window.confirm('Are you sure you want to archive the project ?')) {
                                project.state = 1;
                                await archiveProject(project)
                                    .then(() => successCallback('The project has been archived.'))
                                    .catch(() => errorCallback('Something went wrong.'));
                            }
                        }}
                    >
                        <i className="fa fa-archive"/> Archive
                    </button>
                </>
                }

                {isUserGranted('ROLE_DELETE_PROJECT') &&
                    <button className="button-link button-icon btn-delete-project" onClick={async () => {setShowModal(true)}}>
                        <i className="fa fa-times"/> Delete
                    </button>
                }

                {showModal &&
                    <Modal
                        title="Delete a project"
                        description={`Are you sure you want to delete the project <strong>${project.name}</strong>?`}
                        buttons={[
                            {label: `Yes, delete <strong>${project.name}</strong>`, callback: () => handleDelete(project.id)}
                        ]}
                        cancelCallback={() => cancelModal()}
                    />
                }
            </div>
        </div>
    )
}
import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {useGetData} from "../../api/useFetch";
import {postUser} from "../../api/apiUser";
import {useForm, Controller} from "react-hook-form";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {CircularProgress} from "@material-ui/core";

export const Register = () => {
    // const {token} = useParams();

    const {handleSubmit, setValue, setError, formState: { errors }, control} = useForm();

    // const users = useGetData('usertoken','new_users',{token: token});
    // const colors = useGetData('colorsData', 'resource_colors');
    const organizations = useGetData('memberData','organizations');

    const [fields, setFields] = useState({
        'email': '',
        'confirm_email': '',
        'firstname': '',
        'lastname': '',
        'phone': null,
        'organization': null,
        'password': '',
        'confirm_password': '',
        'color': 1
    });

    const onSubmit = (dataUser) => {
        if(dataUser.password !== dataUser.confirm_password) {
            setError("confirm_password", {
                    type: "focus",
                    message: "The passwords are different.",
                },
                {shouldFocus: true},
            );
        }
        else {
            console.log(dataUser)
            postUser(dataUser).then((response) => {
                window.location.href = '/profile';
            });
        }
    }

    const handleSelectChange = (field, value) => {
        setValue(field, value);
        setFields({...fields, [field]: value});
    }

    // if(users.isLoading || colors.isLoading || organizations.isLoading) return <Loading/>
    if(organizations.isLoading) return <Loading/>
    // if(users.isError) return <Error errorMessage={users.error}/>
    // if(colors.isError) return <Error errorMessage={colors.error}/>
    if(organizations.isError) return <Error errorMessage={organizations.error}/>

    // if(users.data.length !== 1)
    //     return 'Invalid token.';

    // const user = users.data[0];
    // setValue('email', user.email);

    return (
        <div id="main__welcome">

            <form id="welcome__form" onSubmit={handleSubmit(onSubmit)}>
                {/*<p>You register with the address : {user.email}</p>*/}
                <div>
                    <div>
                        <label htmlFor="f-email">Email</label>
                        <Controller
                            control={control}
                            name="email"
                            rules={{required: true}}
                            value={fields['email']}
                            defaultValue={fields['email']}
                            onChange={e => setFields({...fields, email: e.target.value})}
                            render={({field}) => (
                                <input type="text" id="f-email" name="email" {...field}/>
                            )}
                        />
                    </div>

                    <div>
                        <label htmlFor="f-organization">Organization</label>
                        <Controller
                            control={control}
                            name="organization"
                            rules={{required: true}}
                            render={({field}) => (
                                <select name="confirm_password" {...field} value={fields['organization']}
                                        onChange={e => handleSelectChange('organization', e.target.value)}>
                                    <option value="">Select an organization</option>
                                    {organizations.data.map((organization) => (
                                        <option key={organization.id} value={organization.id}>{organization.name}</option>
                                    ))}
                                </select>
                            )}
                        />
                    </div>
                </div>

                <div>
                    <div>
                        <label htmlFor="f-firstname">First name</label>
                        <Controller
                            control={control}
                            name="firstname"
                            rules={{required: true}}
                            value={fields['firstname']}
                            defaultValue={fields['firstname']}
                            onChange={e => setFields({...fields, firstname: e.target.value})}
                            render={({field}) => (
                                <input type="text" id="f-firstname" name="firstname" {...field}/>
                            )}
                        />
                    </div>

                    <div>
                        <label htmlFor="f-lastname">Last name</label>
                        <Controller
                            control={control}
                            name="lastname"
                            rules={{required: true}}
                            value={fields['lastname']}
                            defaultValue={fields['lastname']}
                            onChange={e => setFields({...fields, lastname: e.target.value})}
                            render={({field}) => (
                                <input type="text" id="f-lastname" name="lastname" {...field}/>
                            )}
                        />
                    </div>
                </div>

                <div>
                    <div>
                        <label htmlFor="f-password">Password</label>
                        <Controller
                            control={control}
                            name="password"
                            rules={{required: true}}
                            value={fields['password']}
                            defaultValue={fields['password']}
                            onChange={e => setFields({...fields, password: e.target.value})}
                            render={({field}) => (
                                <input type="password" id="f-password" name="password" {...field}/>
                            )}
                        />
                    </div>

                    <div>
                        <label htmlFor="f-confirm-password">Confirm password</label>
                        <Controller
                            control={control}
                            name="confirm_password"
                            rules={{required: true}}
                            value={fields['confirm_password']}
                            defaultValue={fields['confirm_password']}
                            onChange={e => setFields({...fields, confirm_password: e.target.value})}
                            render={({field}) => (
                                <input type="password" id="f-confirm-password" name="confirm_password" {...field}/>
                            )}
                        />
                        {errors.confirm_password && <p style={{color: "red"}}>{errors.confirm_password.message}</p>}
                    </div>
                </div>

                <input type="submit" value="Register"/>

            </form>

        </div>
    )
}
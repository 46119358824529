import {OutlinedCard} from "../../common/OutlinedCard";
import {getManualPrinterStatus, getPrinterStatusByPrinterId} from "../../../../services/FarmService";
import {isMinimalApp} from "../../../CommonFunctions";

export const PrinterStatusBlock = (props) => {

    const {printers, smartPrintersStatusData, manualPrintersStatus, farmVersion} = props;

    const getManualPrinterStatusValueDisplay = (printerId) => {
        const printerStatus = getPrinterStatusByPrinterId(printerId, manualPrintersStatus);
        const status = getManualPrinterStatus(printerStatus !== null ? printerStatus.status : null);
        return(
            <span className="status-value" style={{color: (status !== undefined && status !== null) ? status.color : '#000000'}}>
                {(status !== undefined && status !== null) ? status.value : '--'}
            </span>
        );
    }

    const getSmartPrinterStatusValueDisplay = (printerId) => {
        const status = getPrinterStatusByPrinterId(printerId, smartPrintersStatusData);
        return(
            <span className="status-value" style={{color: (status !== undefined && status !== null) ? status.status.color : '#000000'}}>
                {(status !== undefined && status !== null) ? status.status.value : '--'}
            </span>
        );
    }

    if (isMinimalApp() && farmVersion !== 2) return null;

    return(
        <OutlinedCard>
            <div className="farm__dialog_status_blocks">
                <h2>Printers status</h2>
                {printers.map((printer, index) => (
                    <div key={printer.id} className={index < printers.length - 1 ? 'printers_status_printer_block' : ''}>
                        <div className="printers_status_printer_name">{printer.name}</div>
                        {farmVersion === 2 &&
                            <div>
                                <span className="status-label">Smart status detection:</span>
                                {getSmartPrinterStatusValueDisplay(printer.id)}
                            </div>
                        }
                    </div>
                ))}
                {printers.length === 0 &&
                    <div>No printer</div>
                }
            </div>
        </OutlinedCard>
    );
}

import {Popover} from "@material-ui/core";
import {useState} from "react";
import {ReactComponent as IconAction} from "../../../../images/icons/pic/IconAction.svg";
import {getCurrentTimestamp} from "../../../CommonFunctions";
import {getTasksIncoming, smartScheduleTasks} from "../../../../services/gantt/SmartSchedulingService";

export const ResourceMenu = (props) => {
    const {printer, tasksSelected, setTasksSelected, setAlert, setIsWorkloadDialogOpen, setPrinterTaskWorkload,
        firstDisplayedDate, externalPrintTasks, reloadCalendar} = props;
    const [menuAnchorElement, setMenuAnchorElement] = useState(null);

    const isMenuOpen = menuAnchorElement !== null;

    const handleClickIcon = (e) => {
        setMenuAnchorElement(e.currentTarget);
    };

    const handleSelectAllSlots = (e) => {
        let ArraySelectedTasks = [];
        if (tasksSelected.length === 0) {
            let currentDisplayedDateNumber = new Date(firstDisplayedDate).getDate();
            let currentDisplayedMonthNumber = new Date(firstDisplayedDate).getMonth();
            printer.tasks.map(task => {
                if (task.date) {
                    let taskDateNumber = new Date(task.date*1000).getDate();
                    let taskMonthNumber = new Date(task.date*1000).getMonth();
                    if(task.date + task.print_duration >= getCurrentTimestamp() && currentDisplayedDateNumber === taskDateNumber && currentDisplayedMonthNumber === taskMonthNumber){
                        ArraySelectedTasks.push(task.id);
                    }
                }
            })
        }
        setTasksSelected(ArraySelectedTasks);
        handleMenuClose(e);
    }

    const handleSmartScheduling = async (e) => {
        // Find tasks to schedule
        if (printer.tasks.length > 0) {
            const tasksIncoming = getTasksIncoming(printer.tasks, externalPrintTasks,
                printer.printer_maintenances ? printer.printer_maintenances : []);

            // If tasks found, schedule tasks
            if (tasksIncoming.length > 0) {
                smartScheduleTasks(tasksIncoming)
                    .then(tasksUpdated => {
                        if(tasksUpdated.length > 0) {
                            reloadCalendar();
                        } else {
                            setAlert({message: "No upcoming tasks updated", status: "warning", date: new Date()});
                        }
                    })
                    .catch((e) => setAlert({message: e, status: "error", date: new Date()}));
            } else {
                setAlert({message: "No upcoming tasks are planned.", status: "error", date: new Date()});
            }
        } else {
            setAlert({message: "No upcoming tasks are planned.", status: "error", date: new Date()});
        }
        handleMenuClose(e);
    }

    const handleClickWorkload = (e) => {
        setPrinterTaskWorkload(printer.tasks);
        setIsWorkloadDialogOpen(true);
        handleMenuClose(e);
    }

    const handleMenuClose = (e) => {
        setMenuAnchorElement(null);
    };

    return (
        <td className="gc-resource-row__cell gc-resource-row__cell--menu gc-resource-menu">
            <div className="gc-resource-menu__icon gc-resource-menu-icon">
                <IconAction onClick={(e) => handleClickIcon(e)} />
            </div>

            <Popover
                id={printer.id}
                anchorEl={menuAnchorElement}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={isMenuOpen}
                onClose={(e) => handleMenuClose(e)}
            >
                <div className="gc-resource-menu__actions gc-resource-menu-actions">
                    <button className="gc-resource-menu-actions__item" onClick={(e) => handleSmartScheduling(e)}>
                        Smart scheduling
                    </button>
                    <a href="/builder"> 
                        <button className="gc-resource-menu-actions__item">
                            Create a new PIC
                        </button>
                    </a>
                    <button className="gc-resource-menu-actions__item" onClick={(e) => handleSelectAllSlots(e)}>
                        {tasksSelected.length === 0 ? 'Select all slots':'Unselect all slots'}
                    </button>
                    <button className="gc-resource-menu-actions__item" onClick={(e) => handleClickWorkload(e)}>
                        Workload
                    </button>
                </div>
            </Popover>
        </td>
    );
};

import React, {useCallback, useEffect, useState} from "react";
import {downloadAPIFile, fetchData} from "../../api/useFetch";
import {getCurrentUser} from "../CommonFunctions";
import {DropZone} from "../common/DropZone";
import {Loading} from "../common/Loading";

export const ViewPrinterComponent = (props) => {

    const {printerData, setRightPartIsOpen, successCallback, errorCallback} = props;

    const [isAttachedFilesLoading, setIsAttachedFilesLoading] = useState(true);

    useEffect(() => {
        getPrinterAttachedFilesData(printerData.id);
        return(() => {
            setIsAttachedFilesLoading(true);
        });
    }, []);

    /* Get attached files data and set it to printer data */
    const getPrinterAttachedFilesData = (printerId) => {
        let errorMessage = 'An error occurred while retrieving attached files data'
        fetchData('printer_attached_files?printer=' + printerId)
            .then(response => response.json())
            .catch(() => errorCallback(errorMessage))
            .then(data => printerData.attached_files = data)
            .catch(() => errorCallback(errorMessage))
            .finally(() => setIsAttachedFilesLoading(false));
    }

    /* Attached files */
    const onFileUploaded = useCallback(async (response) => {
        successCallback('File(s) added');
        setRightPartIsOpen(false);
    }, []);

    const onFileNotUploaded = useCallback((response) => {
        alert('File not uploaded!');
    }, []);

    const createRequestBody = useCallback((file) => {
        return {
            user: process.env.REACT_APP_API_URI + `users/${getCurrentUser().id}`,
            name: file.name,
            size: file.size,
            printer: process.env.REACT_APP_API_URI + `printers/${printerData.id}`
        };
    }, []);

    return (
        <div id="main__printer-details">
            <div className="block">
                <h2>Information</h2>
                <p><strong>Technology:</strong> {printerData.technology.name}</p>
                <p><strong>Extruders number:</strong> {printerData.extruders_number}</p>
                <p><strong>Printable volume (mm):</strong> {printerData.printable_length}(l) x {printerData.printable_width}(w) x {printerData.printable_height}(h)</p>
                { printerData.controllable_printer_id &&
                        <p><i className="fa fa-check-circle"/> Connected</p>
                }
            </div>

            <div className="block">
                <h2>Attached files</h2>
                <p><em>Instructions, maintenance documents, user guides...</em></p>

                <div id="details__files-list">
                    {isAttachedFilesLoading  ?
                        <Loading/> :
                        printerData.attached_files.map((file) => {
                            if(file.id) {
                                return (
                                    <a href="#" className="files-list__file-item" key={file.id}
                                       onClick={() => downloadAPIFile(
                                           `printer_attached_files/${file.id}/download`,
                                           file.name
                                       )}>{file.name}</a>
                                );
                            }
                        })
                    }
                </div>

                <DropZone
                    uploadEndpoint={'printer_attached_files'}
                    createRequestBody={createRequestBody}
                    onFileUploaded={onFileUploaded}
                    onFileNotUploaded={onFileNotUploaded}
                />
            </div>
        </div>
    )
}


import React from "react";
import {Projects} from "./projects/Projects";
import {Project} from "./projects/Project";
import {QueryClient, QueryClientProvider} from 'react-query';
import {HeaderTabs} from "./common/HeaderTabs";

const queryClient = new QueryClient();

export const ProjectsMain = () => {

    const headerLinks = [
        {label: 'Projects', path: 'projects'}
    ];

    return (
        <>
            <HeaderTabs links={headerLinks}/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">
                    <QueryClientProvider client={queryClient}>
                        <Projects/>
                    </QueryClientProvider>
                </div>
            </div>
        </>
    )
}




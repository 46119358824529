import {
    CELL_TYPES, FARM_EVENT_TYPES,
    findRunningProfileId, getManualPrinterStatus,
    getMaterialCellStatus, getPrinterStatusByPrinterId, isInfluxdbConnectionError, SMART_FARM_COMMANDS,
    SMART_FARM_DATA_FIELDS
} from "../../../services/FarmService";
import {PrinterStatusBlock} from "./blocks/PrinterStatusBlock";
import {MaterialStatusBlock} from "./blocks/MaterialStatusBlock";
import {HistoryEventsBlock} from "./blocks/HistoryEventsBlock";
import {useGetData} from "../../../api/useFetch";
import {Loading} from "../../common/Loading";
import {Error} from "../../common/Error";
import {isUserGranted} from "../../CommonFunctions";
import {EnvironmentManagerBlock} from "./blocks/EnvironmentManagerBlock";
import {EnvironmentProfilesBlock} from "./blocks/EnvironmentProfilesBlock";
import React, {useCallback, useEffect, useState} from "react";
import {postFarmEvent} from "../../../api/apiFarmCommand";
import {sendFarmCommandEvent} from "../../../services/socketEventsService";
import {DigitCode} from "../../common/DigitCode";
import {SystemStatusBlock} from "./blocks/SystemStatusBlock";

export const FarmDialogCell = (props) => {

    const {
        farmCell, smartFarmCellData, smartPrintersStatusData, manualPrintersStatus, farmVersion,
        secondsWithoutData, farmProfiles, getFarmProfiles, setAlert, setCommandsInProcess,
        getChartsDataRangeBlock, getHumidityBlock, getTemperatureBlock, getPollutionBlock, commandsInProcess,
        getMaintenanceBlock, cellMaintenance, structureMaintenance, farmStructureId
    } = props;

    const isPrinterPrinting = () => {
        for(let printer of farmCell.printers){
            const printerStatus = getPrinterStatusByPrinterId(printer.id, manualPrintersStatus);
            const manualStatus = getManualPrinterStatus(printerStatus !== null ? printerStatus.status : null);
            const smartStatus = getPrinterStatusByPrinterId(printer.id, smartPrintersStatusData);

            if((manualStatus !== undefined && manualStatus !== null && manualStatus.value !== 'free') ||
                (smartStatus !== undefined && smartStatus !== null && smartStatus.status.value !== 'free'))
                return true;
        }
        return false;
    };

    const historyEvents = useGetData('farm_events', 'farm_events?farm_cell=' + farmCell.id);

    /* Get temperature command value from smart farm data or commands in process */
    const getTemperatureCommandValue = () => {
        if(commandsInProcess[farmCell.system_code] && commandsInProcess[farmCell.system_code][SMART_FARM_DATA_FIELDS.COMMAND_TEMPERATURE]) {
            return commandsInProcess[farmCell.system_code][SMART_FARM_DATA_FIELDS.COMMAND_TEMPERATURE].value;
        } else if(smartFarmCellData && smartFarmCellData[SMART_FARM_DATA_FIELDS.COMMAND_TEMPERATURE] !== undefined &&
            smartFarmCellData[SMART_FARM_DATA_FIELDS.COMMAND_TEMPERATURE] !== null){
            return parseInt(smartFarmCellData[SMART_FARM_DATA_FIELDS.COMMAND_TEMPERATURE]);
        }
        return 0;
    }

    /* Get air extraction command value from smart farm data or commands in process */
    const getAirExtractionCommandValue = () => {
        if(commandsInProcess[farmCell.system_code] && commandsInProcess[farmCell.system_code][SMART_FARM_DATA_FIELDS.COMMAND_AIR_EXTRACTION]) {
            return commandsInProcess[farmCell.system_code][SMART_FARM_DATA_FIELDS.COMMAND_AIR_EXTRACTION].value;
        } else if(smartFarmCellData && smartFarmCellData[SMART_FARM_DATA_FIELDS.COMMAND_AIR_EXTRACTION] !== undefined &&
            smartFarmCellData[SMART_FARM_DATA_FIELDS.COMMAND_AIR_EXTRACTION] !== null){
            return parseInt(smartFarmCellData[SMART_FARM_DATA_FIELDS.COMMAND_AIR_EXTRACTION]);
        }
        return 0;
    }

    const getEnvironmentCommandData = () => {
        return({
            'temperature': getTemperatureCommandValue(),
            'air_extraction': getAirExtractionCommandValue()
        });
    }

    // Running profile
    const [runningProfileId, setRunningProfileId] = useState(null);

    // Environment data values used by sliders
    const [environmentCommandData, setEnvironmentCommandData] = useState(getEnvironmentCommandData());

    useEffect(() => {
        const newEnvironmentCommandData = getEnvironmentCommandData();
        // If environment data has changed outside the app, update selected profile
        if(newEnvironmentCommandData.temperature !== environmentCommandData.temperature || newEnvironmentCommandData.humidity !== environmentCommandData.humidity) {
            setRunningProfileId(findRunningProfileId(farmProfiles, newEnvironmentCommandData, farmCell.system_type));
        }
        setEnvironmentCommandData(newEnvironmentCommandData);
    }, [smartFarmCellData]);

    useEffect(() => {
        setRunningProfileId(findRunningProfileId(farmProfiles, environmentCommandData, farmCell.system_type));
    }, [environmentCommandData]);

    const saveFarmEvent = (type, value, userId = null, farmProfileId = null) => {
        postFarmEvent(farmCell.id, type, value, userId, false, farmProfileId)
            .then(() => historyEvents.refetch())
            .catch(() => null);
    }

    const [temperatureTmpValue, setTemperatureTmpValue] = useState(null);
    const [extractionTmpValue, setExtractionTmpValue] = useState(null);
    const [isTemperatureDigitCodeVisible, setIsTemperatureDigitCodeVisible] = useState(false);
    const [isExtractionDigitCodeVisible, setIsExtractionDigitCodeVisible] = useState(false);
    const [profileIdTmpValue, setProfileIdTmpValue] = useState(null);

    const handleTemperatureChange = (newTemperature, farmProfileId=null) => {
        if(isPrinterPrinting()){
            setTemperatureTmpValue(newTemperature);
            setIsTemperatureDigitCodeVisible(true);
            setProfileIdTmpValue(farmProfileId);
        }
        else
            handleTemperatureChangeAfterDigitCode(null, newTemperature, farmProfileId);
    };

    const handleTemperatureChangeAfterDigitCode = (user=null, newTemperature=null, newFarmProfileId=null) => {
        const temperature = newTemperature !== null ? newTemperature : temperatureTmpValue;
        const farmProfileId = newFarmProfileId !== null ? newFarmProfileId : profileIdTmpValue;
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.TEMPERATURE, temperature);
        saveFarmEvent(FARM_EVENT_TYPES.TEMPERATURE, temperature, user !== null ? user.id : null, farmProfileId);
        setRunningProfileId(null);
        setCommandsInProcess({type: 'add-command', value: {systemCode: farmCell.system_code, commandType: SMART_FARM_DATA_FIELDS.COMMAND_TEMPERATURE, commandValue: temperature}});
    };

    const handleExtractionChange = (newExtraction) => {
        if(isPrinterPrinting()){
            setExtractionTmpValue(newExtraction);
            setIsExtractionDigitCodeVisible(true);
        }
        else
            handleExtractionChangeAfterDigitCode(null, newExtraction);
    };

    const handleExtractionChangeAfterDigitCode = (user=null, newExtraction=null) => {
        const extraction = newExtraction !== null ? newExtraction : extractionTmpValue;
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.AIR_EXTRACTION, extraction);
        saveFarmEvent(FARM_EVENT_TYPES.AIR_EXTRACTION, extraction, user !== null ? user.id : null);
        setCommandsInProcess({type: 'add-command', value: {systemCode: farmCell.system_code, commandType: SMART_FARM_DATA_FIELDS.COMMAND_AIR_EXTRACTION, commandValue: extraction}});
    };

    /* Get right command temperature value to display (command sent value or influxdb value) */
    const getCommandTemperatureToDisplay = useCallback(() => {
        if(commandsInProcess[farmCell.system_code] && commandsInProcess[farmCell.system_code][SMART_FARM_DATA_FIELDS.COMMAND_TEMPERATURE]) {
            environmentCommandData['temperature'] = commandsInProcess[farmCell.system_code][SMART_FARM_DATA_FIELDS.COMMAND_TEMPERATURE].value;
        }
        return environmentCommandData['temperature'];
    }, [commandsInProcess[farmCell.system_code], environmentCommandData['temperature']]);

    /* Get right command air extraction value to display (command sent value or influxdb value) */
    const getCommandAirExtractionToDisplay = useCallback(() => {
        if(commandsInProcess[farmCell.system_code] && commandsInProcess[farmCell.system_code][SMART_FARM_DATA_FIELDS.COMMAND_AIR_EXTRACTION]) {
            environmentCommandData['air_extraction'] = commandsInProcess[farmCell.system_code][SMART_FARM_DATA_FIELDS.COMMAND_AIR_EXTRACTION].value;
        }
        return environmentCommandData['air_extraction'];
    }, [commandsInProcess[farmCell.system_code], environmentCommandData['air_extraction']]);

    /* Get maintenance data from smart farm data (date and if cell is in maintenance) */
    const getSmartFarmMaintenanceInfo = () => {
        if(smartFarmCellData && smartFarmCellData[SMART_FARM_DATA_FIELDS.MAINTENANCE_ACTIVE] !== undefined &&
            smartFarmCellData[SMART_FARM_DATA_FIELDS.MAINTENANCE_ACTIVE] !== null) {
            return {date: Math.floor(smartFarmCellData.time / 1000), isInMaintenance: smartFarmCellData[SMART_FARM_DATA_FIELDS.MAINTENANCE_ACTIVE] > 0};
        }
        return {date: null, isInMaintenance: null};
    }

    if(historyEvents.isLoading) return <Loading/>;
    if(historyEvents.isError) return <Error/>;

    return(
        <div className="farm-dialog-body">
            <DigitCode
                visible={isTemperatureDigitCodeVisible} setVisible={setIsTemperatureDigitCodeVisible}
                callback={handleTemperatureChangeAfterDigitCode} errorCallback={() => null}
            />
            <DigitCode
                visible={isExtractionDigitCodeVisible} setVisible={setIsExtractionDigitCodeVisible}
                callback={handleExtractionChangeAfterDigitCode} errorCallback={() => null}
            />

            {isUserGranted('ROLE_MANAGE_SMART_FARM') &&
                <EnvironmentManagerBlock
                    environmentData={environmentCommandData} setEnvironmentData={setEnvironmentCommandData} farmCell={farmCell} saveFarmEvent={saveFarmEvent}
                    smartFarmCellData={smartFarmCellData} isReceivingData={!isInfluxdbConnectionError(secondsWithoutData)} setCommandsInProcess={setCommandsInProcess}
                    commandsInProcess={commandsInProcess} isPrinterPrinting={isPrinterPrinting}
                />
            }

            {getMaintenanceBlock(structureMaintenance, cellMaintenance, farmStructureId, farmCell.id, getSmartFarmMaintenanceInfo())}

            {isUserGranted('ROLE_MANAGE_SMART_FARM') &&
                <EnvironmentProfilesBlock
                    farmProfiles={farmProfiles} systemType={farmCell.system_type} setAlert={setAlert}
                    environmentData={environmentCommandData} setEnvironmentData={setEnvironmentCommandData}
                    handleTemperatureChange={handleTemperatureChange} getFarmProfiles={getFarmProfiles}
                    runningProfileId={runningProfileId} setRunningProfileId={setRunningProfileId}
                />
            }

            {getChartsDataRangeBlock()}

            {getTemperatureBlock(
                historyEvents.data,
                (farmCell.system_type === CELL_TYPES.PRINTER || farmCell.system_type === CELL_TYPES.MATERIALS),
                structureMaintenance, cellMaintenance, getCommandTemperatureToDisplay(), handleTemperatureChange
            )}

            {getHumidityBlock()}

            {getPollutionBlock(
                (farmCell.system_type === CELL_TYPES.PRINTER || farmCell.system_type === CELL_TYPES.POST_TREATMENT),
                structureMaintenance, cellMaintenance, getCommandAirExtractionToDisplay(), handleExtractionChange, farmCell.filter_change
            )}

            {farmCell.system_type === CELL_TYPES.PRINTER &&
                <PrinterStatusBlock
                    printers={farmCell.printers} smartPrintersStatusData={smartPrintersStatusData}
                    manualPrintersStatus={manualPrintersStatus} farmVersion={farmVersion}
                />
            }

            {farmCell.system_type === CELL_TYPES.MATERIALS &&
                <MaterialStatusBlock
                    farmCell={farmCell}
                    materialStatus={getMaterialCellStatus((smartFarmCellData && smartFarmCellData.dehumidifier_active !== undefined &&
                        smartFarmCellData.dehumidifier_active !== null) ? smartFarmCellData.dehumidifier_active : null)}
                    systemCode={farmCell.system_code} setCommandsInProcess={setCommandsInProcess} commandsInProcess={commandsInProcess}
                />
            }

            <HistoryEventsBlock historyEvents={historyEvents.data}/>

            <SystemStatusBlock systemCode={farmCell.system_code}/>
        </div>
    );
}
import React from "react";
import {makeStyles} from "@material-ui/core";

var appBarHeight = 64;
var drawerWidth = 304;
var menuDrawerWidht = 56;
const cardWidth = 850;

export const useStyles = makeStyles((theme) => ({
    icon_return_app: {
        width: "32px",
        height: "32px"
    },
    button: {
        "&:hover": {
            backgroundColor: 'transparent'
        },
    },
    page_content: {
        marginTop: "120px",
        width: cardWidth,
        padding: 0,

        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuOpen: {
        marginRight: drawerWidth + menuDrawerWidht + 16,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    f_name: {
        color: "#333333",
        fontFamily: "Cabin",
        fontWeight: "bold",
        fontSize: "40px",
        lineHeight: "24px",
    },
    card_header: {
        display: "flex",
        alignItems: "center",
        padding: "10px 10px 10px 13px",
        height: "88px",

        background: "#F8F8F8",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
        borderRadius: "4px 4px 0px 0px",

        '&:hover': {
            cursor: 'pointer',
        }
    },
    card_title: {
        fontFamily: "Cabin",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "26px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "#555555",
    },
    menu_side_bar: {
        flexShrink: 0,
        width: menuDrawerWidht,

        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menu_side_bar_content: {
        top: appBarHeight,
        width: menuDrawerWidht,
        background: "#F8F8F8",

        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuDrawerShift: {
        marginRight: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menu_side_bar_icone_button: {
        display: "contents",
    },
    side_bar: {
        flexShrink: 0,
        width: drawerWidth,
    },
    side_bar_content: {
        paddingTop: appBarHeight,
        paddingBottom: '30px',
        width: drawerWidth,
        background: "#F8F8F8",
    },
    accordion: {
        background: "#F8F8F8",
        boxShadow: "none",
        '&:before' : {
            background: "none"
        }
    },
    accordionTask: {
        background: "white",
        boxShadow: "none",
        '&:before' : {
            background: "none"
        }
    },
    accordion_summary: {
        alignItems: "center",
    },
    accordion_details_root: {
        padding: 0,
    },
}));
import React from "react";
import {Project} from "./projects/Project";
import {QueryClient, QueryClientProvider} from 'react-query';
import {HeaderTabs} from "./common/HeaderTabs";
import {useParams} from "react-router-dom";

const queryClient = new QueryClient();

export const ProjectMain = () => {

    const headerLinks = [
        {label: 'My projects', path: 'projects'}
    ];

    return (
        <>
            <HeaderTabs links={headerLinks}/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">
                    <QueryClientProvider client={queryClient}>
                        <Project/>
                    </QueryClientProvider>
                </div>
            </div>
        </>
    )
}




import {DndContext} from "@dnd-kit/core";
import React, {useMemo, useRef, useState, useEffect} from "react";
import {patchStartTask} from "../../../../api/apiGantt";
import {dateToTimestampInSec, dateToUtcTimestampInSec, timestampInSecToDate} from "../../../../utils/date";
import {DISPLAY_MODES} from "../Calendar";
import {TaskDraggable} from "./TaskDraggable";
import {getCurrentTimestamp, getCurrentUser} from "../../../CommonFunctions";
import {isTargetEqualsOrDescendantOfSpecificClassNode} from "../../../../services/TablesService";
import {formatMaintenancesInTasksData} from "../../../../services/gantt/GanttService";

export const TasksRow = (props) => {
    const {displayMode, firstDisplayedDate, selectNextDate} = props;
    const { 
        lastDisplayedDate,tasks, allTasks, tasksSelected, setTasksSelected, setRightPartDetailsIsOpen,
        setTaskId, setUpdateRef, setAlert, setDigitCodeVisible, setSelectedTaskIdsForHistory,
        setArrayDraggedElement, selectedTaskIdsForHistory, handleDissociateButtonClick, externalPrintTasks,
        maintenances
    } = props;
    const {dragDeltaInPxToMin, getDraggedTaskDeltaLimits, dragSensors, dragModifiers} = props;
    const [refreshPos, setRefreshPos] = useState();
    const [draggedElements, setDraggedElements] = useState({});
    const [selectNextDateTrigger, setSelectNextDateTrigger] = useState(null);
    const [formattedMaintenances, setFormattedMaintenances] = useState(formatMaintenancesInTasksData(maintenances));
    const selectNextDateOnDragToLimitTimeout = 1000;

    useEffect(() => {
        const interval = setInterval(() => {
            setFormattedMaintenances(formatMaintenancesInTasksData(maintenances));
        }, 300000); // 5 minutes
        return () => clearInterval(interval);
    }, []);

    const refTasksRow = useRef(null);
    const refsTaskDraggable = useRef(() => {
        const refs = {};
        displayedTasks.forEach((task) => {
            refs[task.id] = React.createRef(null);
        });
        return refs;
    });
    const displayedTasks = useMemo(() => {
        return allTasks.filter((task) => {
            const taskDateInSec = task.tempDragDateInSec || task.date;
            const taskDate = timestampInSecToDate(taskDateInSec);
            const taskEndDate = timestampInSecToDate(taskDateInSec + task.print_duration);
            return (taskDate > firstDisplayedDate || taskEndDate > firstDisplayedDate) && taskDate < lastDisplayedDate;
        });
    }, [firstDisplayedDate, lastDisplayedDate, allTasks]);

    const displayedTasksForRow = useMemo(() => {
        return tasks.filter((task) => {
            const taskDateInSec = task.tempDragDateInSec || task.date;
            const taskDate = timestampInSecToDate(taskDateInSec);
            const taskEndDate = timestampInSecToDate(task.end_date !== undefined ? task.end_date : taskDateInSec + task.print_duration);
            return (taskDate > firstDisplayedDate || taskEndDate > firstDisplayedDate) && (taskDate < lastDisplayedDate) &&
                ((task.pic !== undefined && task.pic !== null) || // Has PIC
                 task.is_fast_print); // or is fast print
        });
    }, [firstDisplayedDate, lastDisplayedDate, tasks]);

    // Tasks not in manually added in gantt but detected from printer status
    const externalPrinterTasks = useMemo(() => {
        return externalPrintTasks.filter((task) => {
            const taskStartDate = timestampInSecToDate(task.start_date_detected);
            const taskEndDateTimestamp = task.end_date_detected ? task.end_date_detected : getCurrentTimestamp();
            const taskEndDate = timestampInSecToDate(taskEndDateTimestamp);
            if((taskStartDate > firstDisplayedDate || taskEndDate > firstDisplayedDate) && taskStartDate < lastDisplayedDate &&
                ((task.pic === undefined || task.pic === null) && // Has no PIC
                    (task.start_date_detected !== undefined && task.start_date_detected !== null) // and has start_date_detected
                )){
                task.date = task.start_date_detected;
                if(!task.external_print_duration) {
                    task.print_duration = taskEndDateTimestamp - task.start_date_detected;
                }
                return task;
            }
        });
    }, [firstDisplayedDate, lastDisplayedDate, externalPrintTasks]);

    const checkIfDigitCodeNeeded = async (selectedTasksIds) => {

        let currentUser = getCurrentUser();

        let idsTasksCreatedByOtherUser = [];
        selectedTasksIds.map(id => {
            /** we take the dragged tasks among the displayed tasks*/
            const draggedTask = displayedTasks.find((displayedTask) => {
                return displayedTask.id === id;
            });
            /** detect if you attempt to move a task created by another user than the connected one*/ 
            if (draggedTask.user.id !== currentUser.id)
                idsTasksCreatedByOtherUser.push(draggedTask.id);
        });
        /** we can't use directly the state "selectedTaskIdsForHistory" to determine of there is a difference cause to the delay of update when a new state is set*/
        let copySelectedTaskIdsForHistory = selectedTaskIdsForHistory;

        /** if copySelectedTaskIdsForHistory is empty, then we set a fake id never used by the tasks. 
         If we don't do this, the first task move after a refresh will not show the digitcode even if this is needed */ 
        if(copySelectedTaskIdsForHistory.length === 0)
        copySelectedTaskIdsForHistory = [-2];
        /**  Check if there is a difference between the task id previously mouved and the new one */
        let difference = copySelectedTaskIdsForHistory.filter(id => !selectedTasksIds.includes(id));
        setSelectedTaskIdsForHistory(selectedTasksIds);

        for (const [key, draggedElement] of Object.entries(draggedElements)) {
            const draggedTaskTempDateInSec = draggedElement.task.tempDragDateInSec;

            /** sending of all selected tasks to the Tasks Component*/
            let arrayElem = [];
            selectedTasksIds.map(idTaskSelected => {
                arrayElem.push({
                    id: idTaskSelected, 
                    time: draggedElements[idTaskSelected].task.tempDragDateInSec, 
                    previousPositionInSec: draggedElements[idTaskSelected].task.date
                })
            })
            setArrayDraggedElement(arrayElem);
            /** update visualy the position of the dragged task before digitcode open. needed to evoid a visual flash back of the task */ 
            draggedElement.task.date = draggedTaskTempDateInSec;
            if (idsTasksCreatedByOtherUser.length !== 0 && difference.length !== 0) {
                setDigitCodeVisible(true);
            } else {
                patchStartTask(draggedElement.task.id, draggedTaskTempDateInSec)
                .then((response) => {
                    draggedElement.task.is_fixed_scheduling = true;
                })
                .catch((error) => {
                    const message = `Unable to move the task "${draggedElement.task.name}" to the selected slot.\n(${error.message})`;
                    setAlert({message: message, status: "error", date: new Date()});
                });
            }
        }
    }

    const handleDragStart = (e, tasksSelected) => {
        const {active} = e;

        let newArrayTasks;
        if (tasksSelected.length === 0) {
            newArrayTasks = [active.id];
            setTasksSelected([active.id]);
        }
        else
            newArrayTasks = [...tasksSelected];
            let cleanedArray = newArrayTasks.filter(id => {
                if (displayedTasks.find((task) => { return task.id === id;}) === undefined) {
                    return false;
                } else {
                    return true;
                }
            })
            

        cleanedArray.map(id => {
            const draggedTask = displayedTasks.find((displayedTask) => {
                return displayedTask.id === id;
            });

            /* The size of elements should already be given by the library event, but it is not the case. */
            const tasksRowRect = refTasksRow.current.getBoundingClientRect();
            const draggedTaskRect = refsTaskDraggable.current[draggedTask.id].getBoundingClientRect();
            const draggedTaskDeltaLimits = getDraggedTaskDeltaLimits(tasksRowRect, draggedTaskRect);
            const draggedElement = {
                task: draggedTask,
                dragDeltaLimits: draggedTaskDeltaLimits,
                isDragDisabled: false,
                oldFirstDisplayedDate: new Date(firstDisplayedDate),
            };

            setDraggedElements((previousState) => {
                return {...previousState, [id]: draggedElement};
            });

            setRefreshPos(Date.now());
        })
    };

    const handleDragMove = (e) => {
        const {active, delta} = e;
        let newArrayTasks = [...tasksSelected];
        let cleanedArray = newArrayTasks.filter(id => {
            if (displayedTasks.find((task) => { return task.id === id;}) === undefined) {
                return false;
            } else {
                return true;
            }
        })
        let isTaskDraggedToLeftLimit;
        let isTaskDraggedToRightLimit;
        cleanedArray.map(id => {
            const draggedElement = draggedElements[id];

            clearSelectNextDateTrigger();
            updateDraggedTaskTempDate(draggedElement, delta, firstDisplayedDate);
            isTaskDraggedToLeftLimit = delta.x <= draggedElement.dragDeltaLimits.left;
            isTaskDraggedToRightLimit = delta.x >= draggedElement.dragDeltaLimits.right;
        });
        if (isTaskDraggedToLeftLimit || isTaskDraggedToRightLimit) {
            const interval = setTimeout(() => {
                selectNextDate(isTaskDraggedToLeftLimit, (newFirstDisplayedDate) => {
                    /** visual update off all draggedElements when a switch of day/week/month happens */
                    for (const [key, draggedElement] of Object.entries(draggedElements)) {
                        delta.x = isTaskDraggedToLeftLimit ? draggedElement.dragDeltaLimits.left : draggedElement.dragDeltaLimits.right;
                        updateDraggedTaskTempDate(draggedElement, delta, newFirstDisplayedDate);
                    }
                });
            }, selectNextDateOnDragToLimitTimeout);
            setSelectNextDateTrigger({
                interval: interval,
                left: isTaskDraggedToLeftLimit,
                right: isTaskDraggedToRightLimit,
            });
        }

        setRefreshPos(Date.now());
    };

    const handleDragEnd = async (e) => {
        const {active, delta} = e;
        let newArrayTasks = [...tasksSelected];
        let cleanedArray = newArrayTasks.filter(id => {
            if (displayedTasks.find((task) => { return task.id === id;}) === undefined) {
                return false;
            } else {
                return true;
            }
        })
        cleanedArray.map(async(id) => {
            const draggedElement = draggedElements[id];
            clearSelectNextDateTrigger();
            setDraggedElements((previousState) => {
                const newState = {...previousState};
                newState[id].isDragDisabled = true;
                return newState;
            });

            const draggedTaskTempDateInSec = draggedElement.task.tempDragDateInSec;

            if (draggedTaskTempDateInSec && draggedTaskTempDateInSec !== draggedElement.task.date) {
                const collidingTask = searchDraggedTaskCollidingTask(draggedElement);
                if (collidingTask && tasksSelected.length === 1) {
                    let message = `Unable to move the task "${draggedElement.task.name}" to the selected slot.`;
                    if(collidingTask.isMaintenance) {
                        message += ' A maintenance already occupies it.';
                    } else {
                        message += ` The task "${collidingTask.name}" already occupies it.`
                    }
                    setAlert({message: message, status: "error", date: new Date()});
                }
                else
                    checkIfDigitCodeNeeded(cleanedArray);
            }

            for (const [key, draggedElement] of Object.entries(draggedElements)) {
                delete draggedElement.task.tempDragDateInSec;
            }

            setDraggedElements((previousState) => {
                const newState = {...previousState};
                delete newState[id];
                return newState;
            });
        });

        setRefreshPos(Date.now());
        setTasksSelected([]);
    };

    const clearSelectNextDateTrigger = () => {
        if (selectNextDateTrigger) {
            clearInterval(selectNextDateTrigger.interval);
            setSelectNextDateTrigger(null);
        }
    };

    /* UTC dates are used to properly handle time changes. */
    const updateDraggedTaskTempDate = (draggedElement, delta, currentFirstDisplayedDate) => {
        const draggedTaskTempDate = timestampInSecToDate(draggedElement.task.date);

        /* Get the date offset from the origin of the drag. */
        const dragOffsetInMin = dragDeltaInPxToMin(delta.x);
        if (displayMode === DISPLAY_MODES.DAY) {
            draggedTaskTempDate.setUTCMinutes(draggedTaskTempDate.getUTCMinutes() + dragOffsetInMin);
        } else {
            draggedTaskTempDate.setMinutes(draggedTaskTempDate.getMinutes() + dragOffsetInMin);
        }

        /* Get the date offset from the original first displayed date. */
        const oldFirstDisplayedDate = draggedElement.oldFirstDisplayedDate;
        let currentFirstDisplayedDateInSec;
        let oldFirstDisplayedDateInSec;
        if (displayMode === DISPLAY_MODES.DAY) {
            currentFirstDisplayedDateInSec = dateToTimestampInSec(currentFirstDisplayedDate);
            oldFirstDisplayedDateInSec = dateToTimestampInSec(oldFirstDisplayedDate);
        } else {
            currentFirstDisplayedDateInSec = dateToUtcTimestampInSec(currentFirstDisplayedDate);
            oldFirstDisplayedDateInSec = dateToUtcTimestampInSec(oldFirstDisplayedDate);
        }
        const firstDisplayedDateOffsetInSec = currentFirstDisplayedDateInSec - oldFirstDisplayedDateInSec;
        if (displayMode === DISPLAY_MODES.DAY) {
            draggedTaskTempDate.setUTCSeconds(draggedTaskTempDate.getUTCSeconds() + firstDisplayedDateOffsetInSec);
        } else {
            draggedTaskTempDate.setSeconds(draggedTaskTempDate.getSeconds() + firstDisplayedDateOffsetInSec);
        }

        /* Update the local task object (instead of a state, to force a new render of the "TaskDraggable" child component). */
        draggedElement.task.tempDragDateInSec = dateToTimestampInSec(draggedTaskTempDate);  
    };

    const searchDraggedTaskCollidingTask = (draggedElement) => {
        /* Consider that dragged tasks occupy their old and temporary time slots at the same time,
           to handle any error when updating through the API. */
        const tempDraggedTasks = Object.entries(draggedElements)
            .filter(([elementId, element]) => {
                return element.task.tempDragDateInSec;
            })
            .map(([elementId, element]) => {
                return {...element.task, date: element.task.tempDragDateInSec};
            });
        let otherTasks = tasks.concat(tempDraggedTasks).concat(externalPrintTasks).filter((task) => {
            return task.id !== draggedElement.task.id;
        }).concat(formattedMaintenances);

        /* Search for a colliding task. */
        const draggedTaskTempDateInSec = draggedElement.task.tempDragDateInSec;
        const draggedTaskTempEndDateInSec = draggedTaskTempDateInSec + draggedElement.task.print_duration;
        return otherTasks.find((otherTask) => {
            const otherTaskDateInSec = otherTask.date;
            const otherTaskEndDateInSec = otherTask.date + otherTask.print_duration;
            return draggedTaskTempEndDateInSec > otherTaskDateInSec && otherTaskEndDateInSec > draggedTaskTempDateInSec;
        });
    };

    const cellDragLimitClassNames = useMemo(() => {
        let classNames;
        if (selectNextDateTrigger) {
            classNames = "gc-tasks-cell-drag-limit";
            if (selectNextDateTrigger.left) {
                classNames += " gc-tasks-cell-drag-limit--left";
            } else if (selectNextDateTrigger.right) {
                classNames += " gc-tasks-cell-drag-limit--right";
            }
        }
        return classNames;
    }, [selectNextDateTrigger]);

    const isTaskDragDisabled = (task) => {
        return (draggedElements[task.id] && draggedElements[task.id].isDragDisabled) ||
            (task.start_date_detected !== undefined && task.start_date_detected !== null);
    };

    /**Store in state tasksSelected all the task when a ctrl+clic happens */
    const handleClickTask = (e, taskId) => {
        if(!isTargetEqualsOrDescendantOfSpecificClassNode(e.target, 'gc-task-draggable-content__dissociate', 3)) {
            if((!e.metaKey && !e.ctrlKey) || (!e.ctrlKey && !e.metaKey) ){
                setTaskId(taskId);
                setRightPartDetailsIsOpen(true);
            }
            if (e.metaKey || e.ctrlKey ) {
                let newArrayTasks = [...tasksSelected];
                if (newArrayTasks.includes(taskId)){
                    let filteredSelectedTasks = newArrayTasks.filter(value => {
                        return value !== taskId;
                    })
                    setTasksSelected(filteredSelectedTasks);
                } else {
                    let isIncluded = false;
                    newArrayTasks.map(addedTask => {
                        tasks.map(task => {
                            if (task.id === addedTask) {
                                isIncluded = true;
                            }
                        })
                    })
                    if (!isIncluded) {
                        newArrayTasks.length = 0;
                    }
                    newArrayTasks.push(taskId);
                    setTasksSelected(newArrayTasks);
                }
            }
        }
    };

    const handleMouseUpTask = (e, taskId) => {
        if ((!e.metaKey && !e.ctrlKey) || (!e.ctrlKey && !e.metaKey) ) {
            if (tasksSelected.length === 1) {
                let newArrayTasks = [...tasksSelected];
                if (newArrayTasks.includes(taskId)){
                    let filteredSelectedTasks = newArrayTasks.filter(value => {
                        return value !== taskId;
                    })
                    setTasksSelected(filteredSelectedTasks);
                }
            } else {
                setTasksSelected([]);
            }
        }
    };

    useEffect(() => {
        setUpdateRef({...refsTaskDraggable});
    }, []);

    return (
        <>
            <tr ref={refTasksRow} className="gc-tasks-table__row gc-tasks-row">
                <td className="gc-tasks-row__cell gc-tasks-cell">
                    {selectNextDateTrigger && <div className={cellDragLimitClassNames}/>}

                    <DndContext
                        sensors={dragSensors}
                        modifiers={dragModifiers}
                        onDragStart={(e) => handleDragStart(e, tasksSelected)}
                        onDragMove={(e) => handleDragMove(e)}
                        onDragEnd={(e) => handleDragEnd(e)}
                    >
                        {formattedMaintenances && formattedMaintenances.map(maintenance =>
                            <TaskDraggable
                                key={'maintenance-' + maintenance.id}
                                ref={(element) => {
                                    refsTaskDraggable.current[maintenance.id] = element;
                                }}
                                displayMode={displayMode}
                                firstDisplayedDate={firstDisplayedDate}
                                lastDisplayedDate={lastDisplayedDate}
                                draggableId={maintenance.id}
                                isDragDisabled={true}
                                task={maintenance}
                                handleClickTask={null}
                                handleMouseUpTask={null}
                                tasksSelected={tasksSelected}
                                refreshPos={refreshPos}
                                isMaintenance={true}
                                isExternalPrinterTask={false}
                            />
                        )}
                        {externalPrinterTasks.map(task =>
                            <TaskDraggable
                                key={task.id}
                                ref={(element) => {
                                    refsTaskDraggable.current[task.id] = element;
                                }}
                                displayMode={displayMode}
                                firstDisplayedDate={firstDisplayedDate}
                                lastDisplayedDate={lastDisplayedDate}
                                draggableId={task.id}
                                isDragDisabled={true}
                                task={task}
                                handleClickTask={handleClickTask}
                                handleMouseUpTask={handleMouseUpTask}
                                tasksSelected={tasksSelected}
                                refreshPos={refreshPos}
                                isExternalPrinterTask={true}
                                isMaintenance={false}
                            />
                        )}
                        {displayedTasksForRow.map((task) => {
                            return (
                                <TaskDraggable
                                    key={task.id}
                                    ref={(element) => {
                                        refsTaskDraggable.current[task.id] = element;
                                    }}
                                    displayMode={displayMode}
                                    firstDisplayedDate={firstDisplayedDate}
                                    lastDisplayedDate={lastDisplayedDate}
                                    draggableId={task.id}
                                    isDragDisabled={isTaskDragDisabled(task)}
                                    task={task}
                                    handleClickTask={handleClickTask}
                                    handleMouseUpTask={handleMouseUpTask}
                                    tasksSelected={tasksSelected}
                                    refreshPos={refreshPos}
                                    isExternalPrinterTask={false}
                                    isMaintenance={false}
                                    handleDissociateButtonClick={handleDissociateButtonClick}
                                />
                            );
                        })}
                    </DndContext>
                </td>
            </tr>
        </>
    );
};

import {useState} from "react";
import {Table} from 'rsuite';
import {isUserGranted} from "../CommonFunctions";
import Modal from "../common/Modal";
import { Grid } from "@material-ui/core";
import {TableWrapper} from "../common/TableWrapper";

export const IncomingTasksTable = (props) => {
    const {tasks, handleClickOpenDialog, setTaskInWaiting, setAlert, reloadCalendar} = props;
    const { Column, HeaderCell, Cell } = Table;

    const [showModal, setShowModal] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState({});
    const [sortColumnTable, setSortColumnTable] = useState();
    const [sortTypeTable, setSortTypeTable] = useState();

    const cancelModal = () => setShowModal(false);

    const successCallback = (message) => {
        setAlert({message: message, status: "success", date: new Date()});
    }

    const errorCallback = (message) => {
        setAlert({message: message, status: "error", date: new Date()})
    };

    const handleDeleteWaitingTask = async (taskId) => {
        await fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${taskId}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(() => {
                reloadCalendar();
                setShowModal(false);
                successCallback('The job has been removed.');
            })
            .catch(() => {
                errorCallback('Something went wrong.');
            })
    }

    const futureTasks = tasks.filter((task) => {
        return task.waiting_number;
    });

    const ProjectCell = ({rowData, dataKey, ...props}) => { 
        return (
            <Cell {...props} style={{ padding: 0 }}>
                {rowData.hex_code ? <span className="project__name" style={{backgroundColor: rowData.hex_code}}>{rowData.project.name}</span> : '-'}
            </Cell>
        );
    };
    
    const ActionCell = ({rowData, dataKey, ...props}) => {
        let taskObject = futureTasks.filter(task => task.id === rowData.id)[0];

        return(
            <Cell {...props} style={{ padding: 0 }}>
                {isUserGranted('ROLE_CREATE_TASK') &&
                    <>
                        <button onClick={() => {
                            if (typeof taskObject.pic !== 'object') {
                                let picSplit = taskObject.pic.split('/');
                                taskObject.pic = {
                                    uri: taskObject.pic,
                                    id: Number(picSplit[picSplit.length-1])
                                };
                            }
                            handleClickOpenDialog();
                            setTaskInWaiting(taskObject);
                        }}>
                            <i className="fa fa-calendar" style={{margin: 0}}/>
                        </button>
                        &nbsp;
                    </>
                }
                {isUserGranted('ROLE_DELETE_TASK') &&
                    <button onClick={() => (setShowModal(true), setTaskToDelete(taskObject))}>
                        <i className="fa fa-times" style={{margin: 0}}/>
                    </button>
                }

                
            </Cell>
        );
    };

        /**
         * Data sorting depending on column and type (asc / desc)
         */
         const getSortedData = (data) => {
            if (sortColumnTable && sortTypeTable) {
                return data.sort((a, b) => {
                    // Values to sort
                    let x = a[sortColumnTable];
                    let y = b[sortColumnTable];
                    // Case : undefined values
                    if(x === undefined) {
                        return 1;
                    }
                    if(y === undefined) {
                        return -1;
                    }
    
                    // Get values to compare
                    let valueX = 0;
                    let valueY = 0;
                    if (typeof x === 'string' && typeof y === 'string') {
                        let i = 0;
                        do {
                            valueX = x.charCodeAt(i);
                            valueY = y.charCodeAt(i);
                            i++;
                        } while (valueX === valueY && i < x.length && i < y.length);
                    } else if (typeof x === 'number' && typeof y === 'number') {
                        valueX = x;
                        valueY = y;
                    }
    
                    if (sortTypeTable === 'asc') {
                        return valueX - valueY;
                    } else {
                        return valueY - valueX;
                    }
                });
            }
            return data;
        };

        const handleSortColumnTable = async (sortColumnDataHistory, sortTypeDataHistory) => {
            setSortColumnTable(sortColumnDataHistory);
            setSortTypeTable(sortTypeDataHistory);
        }

        const formatedDataForTable = () => {
            const dataFormated = [];
            futureTasks.forEach((data) => {
                // Format resources data
                let formattedResources = data.material1.name + ', ' + data.brand1.name;
                if(data.color1 && data.color1.color_name) {
                    formattedResources += ', ' + data.color1.color_name;
                }
                dataFormated.push({
                    id: data.id,
                    name: data.name,
                    number: data.waiting_number,
                    project: data.project ? data.project.name : '-',
                    printer: data.printer.name,
                    resources: formattedResources,
                    hex_code: data.project ? data.project.color.hex_code : null
                })
            })
            return dataFormated;
        }


    const content = () => {
            if (futureTasks.length === 0) {
                return <span>No waiting task</span>;
            }

        return (
            <TableWrapper classNames="main__schedule_pic_table">
                <Table
                    height={300}
                    data={getSortedData(formatedDataForTable())}
                    sortColumn={sortColumnTable}
                    sortType={sortTypeTable}
                    onSortColumn={handleSortColumnTable}
                >
                    <Column flexGrow={2} verticalAlign="middle" align="center" sortable>
                        <HeaderCell><b>Name</b></HeaderCell>
                        <Cell dataKey="name" />
                    </Column>
                    <Column flexGrow={2} verticalAlign="middle" align="center" sortable>
                        <HeaderCell><b>Quantity</b></HeaderCell>
                        <Cell dataKey="number" />
                    </Column>
                    <Column flexGrow={2} verticalAlign="middle" align="center" sortable>
                        <HeaderCell><b>Project</b></HeaderCell>
                        <ProjectCell dataKey="project" />
                    </Column>
                    <Column flexGrow={2} verticalAlign="middle" align="center" sortable>
                        <HeaderCell><b>Printer</b></HeaderCell>
                        <Cell dataKey="printer" />
                    </Column>
                    <Column flexGrow={2} verticalAlign="middle" align="center" sortable>
                        <HeaderCell><b>Resources</b></HeaderCell>
                        <Cell dataKey="resources" />
                    </Column>
                    <Column flexGrow={2} verticalAlign="middle" align="center" sortable>
                        <HeaderCell><b>Actions</b></HeaderCell>
                        <ActionCell dataKey="status" />
                    </Column>
                </Table>
            </TableWrapper>
        );
    };

    return (
        <div className="block">
            <Grid
                container
                direction="row"
                alignItems="center"
                style={{justifyContent: 'space-between'}}
            > 
                <h2>Ready for manufacturing</h2>
            </Grid>
            {content()}

            {showModal &&
                <Modal
                    title="Delete a job"
                    description={`Are you sure you want to delete the job <strong>${taskToDelete.name}</strong>?`}
                    buttons={[
                        {label: `Yes, delete <strong>${taskToDelete.name}</strong>`, callback: () => handleDeleteWaitingTask(taskToDelete.id)}
                    ]}
                    cancelCallback={cancelModal}
                />
            }
        </div>
    );
};

import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import {getFormattedDate, getCurrentUser, getFormattedRoundedDateWithoutYear} from "../CommonFunctions";
import { DateTimePickerComponent } from "../common/DateTimePickerComponent";
import { Typography, Button, Grid, Table, TableBody, TableCell, TableContainer,TableHead, TableRow, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


export const Workload = (props) => {
    const {label, ymin, ymax, color, data, resources} = props;
    const [isStartDateTimePickerOpen, setIsStartDateTimePickerOpen] = useState(false);
    const [isEndDateTimePickerOpen, setIsEndDateTimePickerOpen] = useState(false);
    const [selectedStartTime, setSelectedStartTime] = useState("00:00");
    const [selectedEndTime, setSelectedEndTime] = useState("23:59");
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [dataForGraphWorkload, setDataForGraphWorkload] = useState([]);
    const [percentageWorkload, setPercentageWorkload] = useState();
    const [convertedSelectedTime, setConvertedSelectedTime] = useState();

    const useStyles = makeStyles({
        container:{
            width:'90%',
            margin:'auto',
        },
        table: {
          minWidth: 650,
          background:'#f3f3f3'
        },
      });

      const classes = useStyles();

    const handleDateTimePickerClose = () => {
        setIsStartDateTimePickerOpen(false);
        setIsEndDateTimePickerOpen(false);
    }

    const totalRessourcesNeeded = () => {
        let arrayOfMaterialName = [];
        let arrayOfRessources = [];
        let splittedHour = selectedEndTime.split(':');
        let endDate = selectedEndDate.setHours(splittedHour[0], splittedHour[1], '00');
        data.map(task => {
            if (endDate < Date.now()) {
                return(
                    <Grid
                        container
                        direction="column"
                        justify="space-evenly"
                        alignItems="center"
                    >
                        <Typography gutterBottom>
                            All tasks are already done for this period.
                        </Typography>
                    </Grid>
                )
            } else if (task.date*1000 > Date.now() && task.date*1000 > selectedStartDate) {
            if (!task.material2) {
                task.material2 = {name: null}; 
            }
            if (!task.color2) {
                task.color2 = {color_name: null}; 
            }
            if ((!arrayOfMaterialName.includes(task.material1.name) && !arrayOfMaterialName.includes(task.color1.color_name)) 
                && 
                (!arrayOfMaterialName.includes(task.material2.name) && !arrayOfMaterialName.includes(task.color2.color_name)))
            {
                arrayOfMaterialName.push([task.material1.name, task.color1.color_name]);
            } 
            }
        })
        arrayOfMaterialName.map(materialName => {
            arrayOfRessources.push({name: materialName[0], quantityNeeded: 0, remaining_quantity:null, color: materialName[1]})
        })
        arrayOfRessources.map(ressourceObj => {
            data.map(task => {
                if (task.date*1000 > Date.now()) {
                    if ((task.material1.name === ressourceObj.name) && (task.color1.color_name ===ressourceObj.color)) {
                        ressourceObj.quantityNeeded +=task.quantity1;
                    }
                    if(task.material2.name === ressourceObj.name){
                        ressourceObj.quantityNeeded += task.quantity2;
                    }
                }
            })
            resources.map(ressource => {
                if (ressource.material.name === ressourceObj.name && ressource.color.color_name === ressourceObj.color) {
                    ressourceObj.remaining_quantity += ressource.remaining_quantity
                }
            })
        })
       

        if(arrayOfRessources.length === 0){
            return(
                <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="center"
                >
                    <Typography gutterBottom>
                        All tasks are already done for this period.
                    </Typography>
                </Grid>
            )
        } else {
            return (
                <TableContainer component={Paper} className={classes.container}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell size="small"><b>Name</b></TableCell>
                                <TableCell size="small" align="right"><b>Color</b></TableCell>
                                <TableCell size="small" align="right"><b>Quantity needed&nbsp;(g)</b></TableCell>
                                <TableCell size="small" align="right"><b>Quantity in stock&nbsp;(g)</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                        arrayOfRessources.map((ressource, index) => (
                            <TableRow key={index}>
                                <TableCell component="td" scope="row">{ressource.name}</TableCell>
                                <TableCell component="td" scope="row" align="right">{ressource.color}</TableCell>
                                <TableCell component="td" scope="row" align="right">{ressource.quantityNeeded}</TableCell>
                                <TableCell component="td" scope="row" align="right">{ressource.remaining_quantity}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        
    }

    //get the selectedStartDate, tranformed in timestamp then transformed again with getFormattedDate function then splited
    let DateStartFormatted = getFormattedDate(Math.floor(selectedStartDate.getTime()/1000));
    let DateStartFormattedSplit = DateStartFormatted.split("/");
    // Same for the DateEndFormatted
    let DateEndFormatted = getFormattedDate(Math.floor(selectedEndDate.getTime()/1000));
    let DateEndFormattedSplit = DateEndFormatted.split("/");
    //we just splitting the time to use it after
    let timeStart = selectedStartTime.split(":");
    let timeEnd = selectedEndTime.split(":");
    //Getting the new date well formatted with the state values
    let startGraph = new Date(DateStartFormattedSplit[2], DateStartFormattedSplit[1]-1, DateStartFormattedSplit[0], timeStart[0],timeStart[1],0,0);
    var startGraphTimestamp = startGraph.getTime()/1000;
    //Same as above
    let endGraph = new Date(DateEndFormattedSplit[2], DateEndFormattedSplit[1]-1, DateEndFormattedSplit[0], timeEnd[0],timeEnd[1],59,0);
    var endGraphTimestamp = endGraph.getTime()/1000;

    /**
     * Building of an array, every 3sec we check with the tasks if we have a task in production for this timestamp.
     * if we have one,we change balance to 100 and push balance in "ArrayBalanceToPush"
     * then we check if this array have 100 as value, if finded, we push in arrayOfDatas the data for this 3sec loop.
     * We also create the percentage workload
     */
    const refreshGraph = () => {
        let watchTime = 60*60;
        let arrayOfDatas = [];
        let arrayBalance100ForPercentage = [];
        for (let observedTime = startGraphTimestamp; observedTime < endGraphTimestamp; observedTime+=watchTime) {
            let ArrayBalanceToPush = [];
            data.map(task => {
                let balance = 0;
                if (task.date < observedTime && task.date+task.print_duration > observedTime) {
                        balance = 100;
                }
                ArrayBalanceToPush.push(balance);

            })
           
            if (ArrayBalanceToPush.includes(100)) {
                arrayOfDatas.push([observedTime*1000, 100]);
                arrayBalance100ForPercentage.push(100);
            } else {
                arrayOfDatas.push([observedTime*1000, 0]);
            }
            
        }

        setPercentageWorkload((arrayBalance100ForPercentage.length*100/arrayOfDatas.length))
        setDataForGraphWorkload(arrayOfDatas);
    }
    //param data needed for the graph
    const chartData = {
        series: [{
            name: label,
            data: dataForGraphWorkload
        }],
        options: {
            chart: {
            type: 'area',
            height: 500,
            zoom: {
                autoScaleYaxis: true
              }
            },
            dataLabels: {enabled: false},
            stroke: {
                curve: 'smooth',
                width: 1.2,
            },
            xaxis: {
                type: 'datetime',
                tickAmount: 12,
                min: startGraph,
                max: endGraph,
                 labels:{
                    rotate: -55,
                    rotateAlways: true,
                    formatter: function(val, timestamp) {
                        let date = new Date(timestamp);
                        let day = date.getDate();
                        let month=date.getMonth();
                        return `${getFormattedRoundedDateWithoutYear(timestamp / 1000)}`
                    }
                }
            },
            yaxis: {
                min: ymin,
                max: ymax
            },
            tooltip: {
                x: {format: 'H:m'}
            },
            colors: [color],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 0.7,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100]
                }
            },
        }
    };

    //To know which date picker open
    const handleClickButtonDatePicker = (e, type) => {
        if (type === "Start") {
            setIsStartDateTimePickerOpen(true);
            
        } else {
            setIsEndDateTimePickerOpen(true);
        }
    }

    //Needed to build the graph when the component is mounted
    useEffect(() => {
        refreshGraph();
        totalRessourcesNeeded();
    }, [selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime]);

    return (
        <div className="workload">
            <DateTimePickerComponent
                isDateTimePickerOpen={isStartDateTimePickerOpen}
                handleClose={handleDateTimePickerClose}
                selectedTime={selectedStartTime}
                selectedDate={selectedStartDate}
                setSelectedDate={setSelectedStartDate}
                setSelectedTime={setSelectedStartTime}
                setConvertedSelectedTime={setConvertedSelectedTime}
                messageTimePicker={'Start time'}
                validateIcon={true}
            />

            <DateTimePickerComponent
                isDateTimePickerOpen={isEndDateTimePickerOpen}
                handleClose={handleDateTimePickerClose}
                selectedTime={selectedEndTime}
                selectedDate={selectedEndDate}
                setSelectedDate={setSelectedEndDate}
                setSelectedTime={setSelectedEndTime}
                setConvertedSelectedTime={setConvertedSelectedTime}
                messageTimePicker={'End time'}
                validateIcon={true}
            />

            <Typography align="center" variant="h3">Workload</Typography>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                <Button variant="contained" onClick={(e) => handleClickButtonDatePicker(e, 'Start')}>Change start date: <br/> {DateStartFormatted} {selectedStartTime}</Button>
                <Button variant="contained" onClick={(e) => handleClickButtonDatePicker(e, 'End')}>Change end date: <br/> {DateEndFormatted} {selectedEndTime}</Button>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{marginTop:10}}
            >
                <Typography variant="h6">{Math.round((percentageWorkload+Number.EPSILON)*100)/100}% workload for selected dates</Typography>
            </Grid>
            <Chart options={chartData.options} series={chartData.series} type="area" height={400} width={1000} />
            <Grid>
                <Typography align="center" variant="h5" gutterBottom>Resources needed for incoming tasks in the selected period</Typography>

                {totalRessourcesNeeded()}

            </Grid>
        </div>
    )
}

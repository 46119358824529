import React, {useState} from 'react';
import {useGetData} from "../../../api/useFetch";
import {postModel, deleteModel, patchModel} from "../../../api/apiBackoffice";
import {Drawer, makeStyles} from "@material-ui/core";
import {Loading} from "../../common/Loading";

export const Models = () => {

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [alert, setAlert] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [actualModel, setActualModel] = useState();

    const models = useGetData('models', 'models');

    const successCallback = (message) => {
        models.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        models.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    const handleCreate = async (name) => {
        await postModel(name)
            .then(() => {
                setRightPartIsOpen(!rightPartIsOpen);
                successCallback('This Model has been created.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    };

    const handleDelete = async (model_Id) => {
        if(window.confirm('Are you sure you want to delete this Model ?'))
            await deleteModel(model_Id)
                .then(() => successCallback('This Model has been deleted.'))
                .catch(() => errorCallback('Something went wrong.'));
    };

    const handleUpdate = async (name, id) => {
        await patchModel(name, id)
            .then(() => {
                setRightPartIsOpen(!rightPartIsOpen);
                successCallback('This Model has been updated.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    };

    return (
        <div className="backoffice_block" style={{display: "block", border: "2px solid lightgrey", padding: "15px"}}>
            <div className="models_header">
                <h2>Models</h2>
                <button className="button-link" onClick={() => {
                    setIsUploading(false);
                    setRightPartIsOpen(!rightPartIsOpen);
                }}>
                    <i className="fa fa-plus"/> Add a model
                </button>
            </div>
            {models.data ? (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {models.data.map((model) => (
                            <tr key={model.id}>
                                <td>{model.name}</td>
                                <td>
                                    <button className="button-link" onClick={()=> {
                                        setIsUploading(true);
                                        setActualModel(model);
                                        setRightPartIsOpen(!rightPartIsOpen);
                                    }}>
                                        <i className="fas fa-edit" />
                                    </button>
                                    &nbsp;
                                    <button className="button-link" onClick={()=> handleDelete(model.id)}><i className="fas fa-trash-alt" /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <Loading />
            )}
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)}>

                <div className="backoffice_drawer_content" style={{width:"500px", "marginTop":"30px"}}>
                    {!isUploading ? (
                        <>
                            <h2 style={{"paddingLeft":"10px"}}>Add Model</h2>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleCreate(e.target.name.value)
                            }}>
                                <label>Model name :
                                    <input type="text" name="name" />
                                </label>
                                <br/>
                                <input type="submit" value="Envoyer" style={{display: "block", margin: "auto"}} />
                            </form>
                        </>
                    ) : (
                        <>
                            <h2 style={{"paddingLeft":"10px"}}>Update Model</h2>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleUpdate(actualModel.name, actualModel.id);
                            }}>
                                <label>Model name :
                                    <input type="text" name="name" value={actualModel.name} onChange={(e) => setActualModel({...actualModel, ["name"]: e.currentTarget.value})}/>
                                </label>
                                <br/>
                                <input type="submit" value="Envoyer" style={{display: "block", margin: "auto"}} />
                            </form>
                        </>
                    )}
                </div>
            </Drawer>
        </div>
    )
}

import Modal from "../../common/Modal";
import React from "react";
import {deletePic} from "../../../api/apiPic";

export const PicDeleteModal = (props) => {

    const {selectedData, setShowDeletePicModal, successCallback, errorCallback, isScheduled,
        selectedDataType, setSelectedDataType} = props;

    const defineDataType = () => {
        switch (selectedDataType) {
            case 'template': return 'Template';
            case 'project': return 'Project';
            default: return 'PIC';
        }
    }

    /* Delete pic */
    const handleDelete = async (pic) => {
        setShowDeletePicModal(false);
        setSelectedDataType(null);

        await deletePic(pic.original_pic ? pic.original_pic.id : pic.id)
            .then(() => successCallback('The pic has been deleted.'))
            .catch(() => errorCallback('Something went wrong.'));
    };

    return(
        <Modal
            title={"Delete a " + defineDataType()}
            description={
                (isScheduled(selectedData.id) ?
                    `Your " ${defineDataType()}" "${selectedData.name}" is currently schedule on your production planning.\n` : '')
                + `Are you sure you want to delete "${selectedData.name}"?`}
            cancelCallback={() => {
                setSelectedDataType(null);
                setShowDeletePicModal(false);
            }}
            buttons={[
                {label: `Yes, delete ${selectedData.name}`, callback: () => handleDelete(selectedData)}
            ]}
        />
    );
}

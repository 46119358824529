import React from "react";
import {Resources} from "./resources/Resources";
import {HeaderTabs} from "./common/HeaderTabs";
import {QueryClient, QueryClientProvider} from 'react-query';

const queryClient = new QueryClient();

export const ResourcesMain = () => {

    const headerLinks = [
        { label: "Organization printers", path: "printers" },
        { label: "Materials stock", path: "resources" }
    ];

    return (
        <>
            <HeaderTabs links={headerLinks} defaultPath='resources'/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">
                    <QueryClientProvider client={queryClient}>
                        <Resources/>
                    </QueryClientProvider>
                </div>
            </div>
        </>
    )
}




import React, {useState} from "react";
import Chart from "react-apexcharts";
import {getCurrentUser, getFormattedPrinterName, isUserGranted} from "../CommonFunctions";
import {useAPIRequest, useGetData} from "../../api/useFetch";
import {AddResourceComponent} from "../resources/right-panel/AddResource";
import {AddProjectComponent} from "../projects/AddProject";
import {AddPieceComponent} from "../library/AddPiece";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {Gantt} from "../gantt/Gantt";

export const Dashboard = (props) => {

    const currentUser = getCurrentUser();

    /* User data */
    const userNextTaskUrl = process.env.REACT_APP_API_ADDRESS + 'users/' +
        currentUser.id + '?groups[]=read:User:NextTask';

    const {data: userData, isLoading: userIsLoading, isError: userIsError} = useAPIRequest(userNextTaskUrl);

    /* Tasks data */
    const organizationGroupTasksUrl = process.env.REACT_APP_API_ADDRESS + 'organization_groups/' +
        currentUser.organization.organization_group.id + '?groups[]=read:OrganizationGroup:Tasks'

    const {data: tasksData, isLoading: tasksIsLoading, isError: tasksIsError} = useAPIRequest(organizationGroupTasksUrl);
    let tasksNames, tasksDurations, tasksQuantities, tasksDurationsData, tasksQuantitiesData;

    const colors = useGetData('colors', 'resource_colors');
    const brands = useGetData('brands', 'resource_brands');
    const models = useGetData('models', 'models');
    const materials = useGetData('materials', 'materials');
    const technologies = useGetData('technologies', 'technologies');
    const printers = useGetData('printerData',
        'printers/essentials',
        {organization_group: currentUser.organization.organization_group.id}
    );
    const {data: resourcesData, isLoading: resourcesIsLoading, isError: resourcesIsError, error: resourcesError, refetch: resourcesRefetch} = useGetData(
        'resources',
        'resource_items/essentials',
        {
            organization_group: currentUser.organization.organization_group.id,
            archived: false
        }
    );

    const [shouldRefetch, setShouldRefetch] = useState(0);

    const structures = useGetData(
        'farmStructure',
        'farm_structures',
        {
            organization_group: currentUser.organization.organization_group.id
        }
    );

    if(tasksData !== undefined){
        tasksNames = [];
        tasksDurations = [];
        tasksQuantities = [];

        for(const organization of tasksData.organizations){
            tasksNames.push(organization.name);
            tasksDurations.push(Math.round(organization.total_print_duration / 3600));
            tasksQuantities.push(organization.total_print_quantity);
        }

        tasksDurationsData = {
            options: {
                labels: tasksNames,
                title: {
                    text: 'Usage of Printers (hours)'
                }
            },
            series: tasksDurations
        };

        tasksQuantitiesData = {
            options: {
                labels: tasksNames,
                title: {
                    text: 'Usage of Materials (grams)'
                }
            },
            series: tasksQuantities
        };
    }


    /* Printers and technologies data */
    const organizationGroupPrintersUrl = process.env.REACT_APP_API_ADDRESS + 'organization_groups/' +
        currentUser.organization.organization_group.id + '?groups[]=read:OrganizationGroup:Printers'

    const {data: printersData, isLoading: printersIsLoading, isError: printersIsError} = useAPIRequest(organizationGroupPrintersUrl);
    let printersNames, printersDurations, technologiesNames, technologiesQuantities,
        printersDurationsData, technologiesQuantitiesData, leastFilledResources;

    if(printersData !== undefined){
        printersNames = [];
        printersDurations = [];
        technologiesNames = [];
        technologiesQuantities = [];

        for(const organization of printersData.organizations){
            for(const printer of organization.printers){
                printersNames.push(printer.name);
                printersDurations.push(Math.round(printer.total_used_duration / 3600));
            }
        }

        for(const technologyName in printersData.most_used_technologies){
            technologiesNames.push(technologyName);
            technologiesQuantities.push(printersData.most_used_technologies[technologyName]);
        }

        printersDurationsData = {
            options: {
                labels: printersNames,
                title: {
                    text: 'Most used Printers (hours)'
                }
            },
            series: printersDurations
        };

        technologiesQuantitiesData = {
            options: {
                labels: technologiesNames,
                title: {
                    text: 'Used materials rate by technologies (grams)'
                }
            },
            series: technologiesQuantities
        };

    }


    if(resourcesData !== undefined){
        let resourcesNames = [];
        let resourcesQuantities = [];

        // Sort resources from lowest remaining quantity to highest
        resourcesData.sort((a, b) => {
            return a.remaining_quantity - b.remaining_quantity;
        })

        let i = 0;
        resourcesData.map((resource) => {
            if(resource.remaining_quantity !== 0 && i < 5){
                resourcesNames.push(resource.serial_number);
                resourcesQuantities.push(resource.remaining_quantity);

                i++;
            }
        })

        leastFilledResources = {
            options: {
                title: {
                    text: 'Materials stock (grams)'
                },

                xaxis: {
                    categories: resourcesNames,
                    labels: {
                        rotate: 0
                    },
                }
            },
            series: [{
                name: 'Remaining quantity',
                data: resourcesQuantities
            }]
        };
    }

    if(tasksIsLoading || printersIsLoading || userIsLoading || resourcesIsLoading || structures.isLoading) return <Loading/>
    if(tasksIsError || printersIsError || userIsError || resourcesIsError || structures.isError) return <Error errorMessage={resourcesError}/>

    return (
        tasksIsLoading || printersIsLoading || userIsLoading || resourcesIsLoading ? ('Loading dashboard...') :
        tasksIsError || printersIsError || userIsError || resourcesIsError ? ('Error while loading dashboard.') : (
        <div id="main__dashboard">

            <div id="dashboard__grid">
                <div id="grid__buttons">
                    {isUserGranted('ROLE_ADD_RESOURCE') &&
                        <div>
                            <AddResourceComponent
                                colors={colors} brands={brands} models={models} materials={materials} technologies={technologies}
                                callback={() => setShouldRefetch(shouldRefetch + 1)}
                            />
                        </div>
                    }
                    {isUserGranted('ROLE_ADD_PIECE') &&
                        <div>
                            <AddPieceComponent/>
                        </div>
                    }
                    {isUserGranted('ROLE_CREATE_PROJECT') &&
                        <div>
                            <AddProjectComponent/>
                        </div>
                    }
                    <div><a href="mailto:contact@handddle.com">Talk with Handddle</a></div>
                </div>

                {/*<div id="grid__resources" className="dashboard-block">*/}
                {/*    <div id="least-filled-resources"/>*/}
                {/*    <Chart*/}
                {/*        options={leastFilledResources.options}*/}
                {/*        height={180}*/}
                {/*        series={leastFilledResources.series}*/}
                {/*        type="bar"*/}
                {/*    />*/}
                {/*</div>*/}

                <div id="grid__next-print" className="dashboard-block">
                    <h2>Next Print</h2>
                    {
                        userData.next_user_task ?
                            <>
                                <p>
                                    <strong>Piece(s) :</strong> {userData.next_user_task.pic.platform ?
                                        userData.next_user_task.pic.platform[0].piece.name : '-'
                                    }
                                    {userData.next_user_task.pic.platform && userData.next_user_task.pic.platform.length > 1 &&
                                        ' (+' + (userData.next_user_task.pic.platform.length - 1) + ' others(s))'
                                    }
                                </p>
                                <p><strong>Printer :</strong> {getFormattedPrinterName(userData.next_user_task.printer)}</p>
                            </>
                        : <em>No print planned</em>
                    }
                </div>

                <div id="grid__main-block" className="dashboard-block">
                    <div>
                        <Chart
                            options={tasksDurationsData.options}
                            series={tasksDurationsData.series}
                            type="donut"
                        />
                    </div>
                    <div>
                        <Chart
                            options={tasksQuantitiesData.options}
                            series={tasksQuantitiesData.series}
                            type="donut"
                        />
                    </div>
                </div>

                <div id="grid__stats" className="dashboard-block">
                    <div id="most-used-printers">
                        <Chart
                            options={printersDurationsData.options}
                            series={printersDurationsData.series}
                            type="donut"
                            width="400px"
                        />
                    </div>
                    <div id="resources-used-by-technologies">
                        <Chart
                            options={technologiesQuantitiesData.options}
                            series={technologiesQuantitiesData.series}
                            type="donut"
                            width="400px"
                        />
                    </div>
                </div>

                <div id="grid__printers" className="dashboard-block">
                    <h2>Printer stock</h2>
                    {
                        printers.isLoading ? <Loading/> :
                            printers.isError ? <Error errorMessage={printers.error}/> :
                                <div>
                                    {printers.data.map(printer => (
                                        <div key={printer.id}>
                                            {
                                                printer.status === 'free' ? <span className="printer-state printer-state--free"/> :
                                                printer.status === 'working' ? <span className="printer-state printer-state--working"/> :
                                                    <span className="printer-state printer-state--upcoming"/>
                                            }
                                            {printer.name}
                                        </div>
                                    ))}
                                </div>

                    }
                    <div>

                    </div>
                </div>

                <div id="grid__gantt" className="dashboard-block">
                    <Gantt/>
                </div>
            </div>
        </div>
    ));
}

import React from "react";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";

export const ArchivedProjectsComponent = (props) => {

    const {ownedProjects, userInProjects, getMemberClass, getMemberColor} = props;

    if(ownedProjects.isLoading || userInProjects.isLoading) return <Loading/>
    if(ownedProjects.isError || userInProjects.isError) return <Error errorMessage={ownedProjects.error.message}/>

    const projects = ownedProjects.data.concat(userInProjects.data).filter(project => project.state === 1);

    if(projects.length === 0) return <p>No archived project</p>;

    return (
        projects.map((project) => (
            <a href={'projects/'+project.id} className="projects-list__archived-project-item dyn-nav" key={project.id}>
                <div className="project-item__zone">
                    <div className="project-item__title">
                        <h2>
                            {project.name}
                        </h2>
                        <span className="project-state project-state--archived">Archived</span>
                    </div>
                </div>
                <div className="project-item__zone">
                    <p className="project-item__description">
                        {project.notes || <em>No description</em>}
                    </p>
                    <div className="project-item__misc-data">
                        <div>
                            <p className="project-item__sub-title">Members</p>
                            <p className="no-margin">
                                <span className={getMemberClass(project.user, project)} style={{background: getMemberColor(project.user)}}>{project.user.initials}</span>
                                {project.users_in_project.map((user_in_project) => (
                                    <span className={getMemberClass(user_in_project.user, project)} style={{background: getMemberColor(user_in_project.user)}}>{user_in_project.user.initials}</span>
                                ))}
                            </p>
                        </div>
                    </div>
                </div>
            </a>
        ))
    )
}
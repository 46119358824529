import React from "react";
import {getFormattedDate, escapeHtml} from "../CommonFunctions";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {CircularProgressWithLabel} from "./CircularProgressWithLabel";
import {Avatar} from "../common/Avatar";
import {NavLink} from 'react-router-dom';

export const CurrentProjectsComponent = (props) => {
    const {ownedProjects, userInProjects, getMemberClass, getMemberColor} = props;

    if(ownedProjects.isLoading || userInProjects.isLoading) return <Loading/>
    if(ownedProjects.isError || userInProjects.isError) return <Error errorMessage={ownedProjects.error.message}/>

    const projects = ownedProjects.data.concat(userInProjects.data).filter(project => project.state === 0);

    return (
        projects.map((project) => (
            <NavLink to={'projects/'+project.id} className="projects-list__project-item" key={project.id}>
                <div className="project-item__zone">
                    <div className="project-item__progress circle-progress">
                        <CircularProgressWithLabel value={project.total_pieces_number > 0 ? Math.floor(project.done_pieces_number/project.total_pieces_number * 100) : 0}/>
                    </div>

                    <div className="project-item__title">
                        <h2>
                            {project.name}
                        </h2>

                        <div>
                            {project.tags.map((tag) => (
                                <span className="project-item__tag" key={tag.id}>{escapeHtml(tag.tag)}</span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="project-item__zone">
                    <div className="project-item__data-progression">
                        <div className="data-progression__date">
                            <p className="project-item__sub-title">Created at</p>
                            <p className="date__element date__element--start-date">
                                {getFormattedDate(project.creation_date)}
                            </p>
                        </div>
                    </div>
                    <p className="project-item__description">
                        {project.notes || <em>No description</em>}
                    </p>
                    <div className="project-item__misc-data">
                        <div>
                            <p className="project-item__sub-title">Printed pieces</p>
                            <p className="project-item__data">{project.done_pieces_number} / {project.total_pieces_number }</p>{/* @TODO Calcul */}
                        </div>
                        <div>
                            <p className="project-item__sub-title">Members</p>
                            <p className="no-margin">
                                <Avatar
                                    user={project.user}
                                    isAdmin={true}
                                    size="normal-small"
                                />
                                {project.users_in_project.map((user_in_project) => (
                                    <Avatar
                                        key={user_in_project.user.id}
                                        user={user_in_project.user}
                                        isAdmin={false}
                                        size="normal-small"
                                    />
                                ))}
                            </p>
                        </div>
                    </div>
                </div>
            </NavLink>
        ))
    )
}
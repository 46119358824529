import {Controller, useForm} from "react-hook-form";
import React from "react";
import {patchMaterialData, postMaterialData,} from "../../../api/apiBackoffice";

export const MaterialDataForm = (props) => {

    const {successCallback, errorCallback, actualMaterialData, setActualMaterialData,
            setRightPartIsOpen} = props;

    const {handleSubmit, control, setValue} = useForm();

    const handleSubmitForm = async (data) => {
        if(actualMaterialData === null) {
            // Create new Material Data
            await postMaterialData(data)
                .then(() => {
                    setValue('name', '');
                    setRightPartIsOpen(false);
                    successCallback('This Resource Brand has been updated.');
                })
                .catch(() => errorCallback('Something went wrong.'));
        } else {
            // Update actual Material Data
            data.id = actualMaterialData.id;
            await patchMaterialData(data)
                .then(() => {
                    setValue('name', '');
                    setActualMaterialData(null);
                    setRightPartIsOpen(false);
                    successCallback('This Resource Brand has been updated.');
                })
                .catch(() => errorCallback('Something went wrong.'));
        }
    };

    return(
        <>
            <h2 style={{"paddingLeft": "10px"}}>
                {actualMaterialData !== null ? 'Update material data' : 'Add material data'}
            </h2>
            <form onSubmit={handleSubmit(handleSubmitForm)} id="update-form">
                <label>Material name :</label>
                <div style={{"display": "flex", "justifyContent": "center", "marginBottom": "20px"}}>
                    <div>
                        <Controller
                            control={control}
                            name="name"
                            rules={{required: true}}
                            defaultValue={actualMaterialData !== null ? actualMaterialData.name : ''}
                            render={({field}) => (
                                <input type="text" {...field}/>
                            )}
                        />
                    </div>
                </div>
                <br/>
                <input type="submit" value="Save" style={{display: "block", margin: "auto"}}/>
            </form>
        </>
    );
}

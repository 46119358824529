import {getCurrentUser} from "../components/CommonFunctions";
import {patchData, postData} from "./tools/apiTools";

const token = localStorage.getItem('token');
const currentUser = getCurrentUser();

const url = 'controllable_printer_api_connections';

export const postControllablePrinterApiConnection = (data) => {
    return postData(url, prepareApiConnectionData(data));
}

export const patchControllablePrinterApiConnection = (data) => {
    return patchData(url + '/' + data.id, prepareApiConnectionData(data));
}

export const deleteControllablePrinterApiConnection = async (id) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + url + '/' + id, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok) {
            throw Error;
        }
    })
}

/* Create request body */
const prepareApiConnectionData = (data) => {
    return {
        organization: process.env.REACT_APP_API_URI + `organizations/${currentUser.organization.id}`,
        brand: process.env.REACT_APP_API_URI + `printer_brands/${data.brand}`,
        username: data.username !== null ? data.username : null,
        password: data.password !== null ? data.password : null,
        bearerToken: data.bearerToken !== null ? data.bearerToken : null,
        expiration_date: data.expiration_date !== null ? data.expiration_date : null
    };
}

import React, {useEffect, useState} from "react";
import {getFormattedDate, getFormattedTimePicker} from "../../CommonFunctions";
import {
    getFormattedHumidityValue,
    getFormattedTemperatureValue
} from "../../../api/apiInfluxdb";
import {getFarmVersion} from "../../../services/FarmService";
import {Tooltip} from "@material-ui/core";
import {MaterialIcon} from "../../common/icons/MaterialIcon";

export const MicroFactoryBlock = (props) => {

    const {selectedOrganization, farmVersion, externalSystemCode, smartFarmData} = props;

    const isEnvironmentDataAvailable = (farmVersion !== null && getFarmVersion(farmVersion) === 2);

    // Realtime hour
    const [today, setToday] = useState(Math.floor((new Date()).getTime() / 1000));

    // Environment data
    const externalData = smartFarmData !== null && externalSystemCode in smartFarmData ? smartFarmData[externalSystemCode] : {};

    useEffect(() => {
        // Get realtime hour
        const interval = setInterval(() => {
            setToday(Math.floor((new Date()).getTime() / 1000));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);


    return(
        <div className="farm-header-outlined-block micro-factory-block">
            {/* realtime hour */}
            <div className="realtime-hour-block">
                <div className="realtime-date">{getFormattedDate(today)}</div>
                <div className="realtime-hour">{getFormattedTimePicker(today)}</div>
            </div>

            {/* External environment */}
            <div className="external-environment-block">
                <h2 className="external-environment-title">Micro factory</h2>
                {isEnvironmentDataAvailable &&
                    <div className="external-environment-subtitle">External environment</div>
                }
                {isEnvironmentDataAvailable &&
                    <div className="external-environment-values">
                        <Tooltip title="Temperature" arrow placement="bottom">
                        <span className="environment-data">
                            <MaterialIcon label="thermostat"/>
                            <span className="environment-data-value">
                                {externalData.temperature_ext !== undefined ? getFormattedTemperatureValue(externalData.temperature_ext) + '°C' : '--'}
                            </span>
                        </span>
                        </Tooltip>
                        <Tooltip title="Humidity" arrow placement="bottom">
                        <span className="environment-data">
                            <MaterialIcon label="water_drop"/>
                            <span className="environment-data-value">
                                {externalData.humidity_ext !== undefined ? getFormattedHumidityValue(externalData.humidity_ext) + '%' : '--'}
                            </span>
                        </span>
                        </Tooltip>
                        <Tooltip title="VOC Index" arrow placement="bottom">
                        <span className="environment-data">
                            <MaterialIcon label="grain"/>
                            <span className="environment-data-value">
                                {externalData.voc_index_ext !== undefined ? Math.round(externalData.voc_index_ext) + ' (index)' : '--'}
                            </span>
                        </span>
                        </Tooltip>
                    </div>
                }
            </div>

            {/* farm connection */}
            <div className="connection-block">
                <div className="header-data-block">
                    <div className="header-data-label-block">
                        <div className="header-data-label">Location</div>
                        <div className="header-data-label">Version</div>
                    </div>
                    <div>
                        <div className="header-data-value">
                            {(selectedOrganization !== undefined && selectedOrganization !== null && selectedOrganization.location) ? selectedOrganization.location : '--'}
                        </div>
                        <div className="header-data-value">{farmVersion !== null ? farmVersion : '--'}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

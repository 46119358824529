import {Controller, useForm} from "react-hook-form";
import React from "react";
import {patchResourceBrandData, postResourceBrandData} from "../../../api/apiBackoffice";

export const BrandDataForm = (props) => {

    const {successCallback, errorCallback, actualBrandData, setActualBrandData,
            setRightPartIsOpen} = props;

    const {handleSubmit, control, setValue} = useForm();

    const handleSubmitForm = async (data) => {
        if(actualBrandData === null) {
            // Create new Brand Data
            await postResourceBrandData(data)
                .then(() => {
                    setValue('name', '');
                    setRightPartIsOpen(false);
                    successCallback('This Resource Brand has been updated.');
                })
                .catch(() => errorCallback('Something went wrong.'));
        } else {
            // Update actual Brand Data
            data.id = actualBrandData.id;
            await patchResourceBrandData(data)
                .then(() => {
                    setValue('name', '');
                    setActualBrandData(null);
                    setRightPartIsOpen(false);
                    successCallback('This Resource Brand has been updated.');
                })
                .catch(() => errorCallback('Something went wrong.'));
        }
    };

    return(
        <>
            <h2 style={{"paddingLeft": "10px"}}>
                {actualBrandData !== null ? 'Update resource brand data' : 'Add resource brand data'}
            </h2>
            <form onSubmit={handleSubmit(handleSubmitForm)} id="update-form">
                <label>Resource Brand name :</label>
                <div style={{"display": "flex", "justifyContent": "center", "marginBottom": "20px"}}>
                    <div>
                        <Controller
                            control={control}
                            name="name"
                            rules={{required: true}}
                            defaultValue={actualBrandData !== null ? actualBrandData.name : ''}
                            render={({field}) => (
                                <input type="text" {...field}/>
                            )}
                        />
                    </div>
                </div>
                <br/>
                <input type="submit" value="Save" style={{display: "block", margin: "auto"}}/>
            </form>
        </>
    );
}

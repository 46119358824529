import React, {useState, useEffect} from "react";
import {Snackbar, Slide} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

export const AlertMessage = (props) => {

    const {message, status} = props;
    const [isOpen, setIsOpen] = useState(true);

    useEffect(
        () => {
            setIsOpen(true);
        },
        [status]
    );

    const handleClose = () => {
        setIsOpen(false);
    }

    return(
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isOpen}
            autoHideDuration={5000}
            onClose={handleClose}
            // TransitionComponent={<Slide direction="left"/>}
        >
            <Alert onClose={handleClose} severity={status} elevation={6}>
                {message}
            </Alert>
        </Snackbar>
    )
}
import React from "react";
import {Library} from "./library/Library";
import {HeaderTabs} from "./common/HeaderTabs";
import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient();

export const LibraryMain = () => {

    const headerLinks = [
        {label: 'Parts', path: 'library'}
    ];

    return (
        <>
            <HeaderTabs links={headerLinks}/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">
                    <QueryClientProvider client={queryClient}>
                        <Library/>
                    </QueryClientProvider>
                </div>
            </div>
        </>
    )
}



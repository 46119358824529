import {useMemo, useState} from "react";
import {DISPLAY_MODES} from "../Calendar";
import {TasksCursor} from "./TasksCursor";
import {TasksGrid} from "./TasksGrid";
import {TasksTable} from "./TasksTable";
import {TasksTimeBar} from "./TasksTimeBar";
import {TasksMovableTimeBar} from "./TasksMovableTimeBar";
import {TasksToolbarActions} from "./TasksToolbarActions";
import {DigitCode} from "../../../common/DigitCode";
import {patchStartTask} from "../../../../api/apiGantt";
import Modal from "../../../common/Modal";

export const Tasks = (props) => {
    const {
        displayMode, firstDisplayedDate, selectNextDate, tasksSelected, setTasksSelected, reloadCalendar,
        setRightPartDetailsIsOpen, setTaskId, setAlert, isOffPeakHoursVisible, setIsOffPeakHoursVisible,
        setIsFirstDisplayedDateUpdated, printers, schedulePic, arrayFilteredPrinter, setSelectedPrinterSchedule, taskResult,
        organization, dissociateTask, externalPrintTasks, isDissociateModalOpen, setIsDissociateModalOpen
    } = props;

    const [cursorTime, setCursorTime] = useState();
    const [isMovableTimeBarRender, setIsMovableTimeBarRender] = useState(false);

    const [dissociateTaskData, setDissociateTaskData] = useState(null);

    /** State for digit code handling */ 
    const [digitCodeVisible, setDigitCodeVisible] = useState(false);
    const [oldSelectedTaskIdsForHistory, setOldSelectedTaskIdsForHistory] = useState();
    const [selectedTaskIdsForHistory, setSelectedTaskIdsForHistory] = useState([]);
    const [arrayDraggedElement, setArrayDraggedElement] = useState([]);

    /**
     * The grid must always have the same number of cells in the selected display mode, to properly drag tasks to edges.
     * Therefore, some time slots may not be displayed when a time change occurs (where there is not 24 hours in the day).
     *
     * UTC dates are used to properly handle time changes.
     */
    const [displayedDates, lastDisplayedDate] = useMemo(() => {
        const dates = [];
        const currentDate = new Date(firstDisplayedDate);
        switch (displayMode) {
            case DISPLAY_MODES.DAY:
                let openTime = !isOffPeakHoursVisible && organization.open_time ? organization.open_time/3600 : 0 ;
                let closeTime = !isOffPeakHoursVisible && organization.close_time ? organization.close_time/3600 : 24 ;
                for (let hour = openTime; hour < closeTime; hour++) {
                    if(hour >= 0 && hour < 24){
                        dates.push(new Date(currentDate));
                    }
                    currentDate.setUTCHours(currentDate.getUTCHours() + 1);
                }
                break;
            case DISPLAY_MODES.WEEK:
                for (let day = 0; day < 7 * 2; day++) {
                    dates.push(new Date(currentDate));
                    currentDate.setHours(currentDate.getHours() + 12);
                }
                break;
            case DISPLAY_MODES.MONTH:
                for (let day = 0; day < 31; day++) {
                    dates.push(new Date(currentDate));
                    currentDate.setDate(currentDate.getDate() + 1);
                }
                break;
            default:
                dates.push(new Date(currentDate));
        }

        const lastDate = new Date(currentDate);
        if (isOffPeakHoursVisible) {
            lastDate.setSeconds(lastDate.getSeconds() - 1);
            lastDate.setHours(23, 59, 59, 59);
        } else {
            lastDate.setSeconds(lastDate.getSeconds() - 1);
            if(lastDate.getHours() === 23){
                lastDate.setHours(organization.close_time/3600, 59, 59, 59);
            } else {
                lastDate.setHours(organization.close_time/3600, 0, 0, 0);
            }
        }

        return [dates, lastDate];
    }, [displayMode, firstDisplayedDate, isOffPeakHoursVisible]);

    const handleDigitCodeResponse = async (user) => {
        let tasksForHistory = [];
        taskResult.filter(task => {
            selectedTaskIdsForHistory.map(selectedId => {
                if (selectedId === task.id) {
                    tasksForHistory.push(task);
                } else {
                    return false
                }
            })
        })
        arrayDraggedElement.map(async(elem) => {
            await patchStartTask(elem.id, elem.time);
        })
        setOldSelectedTaskIdsForHistory(selectedTaskIdsForHistory);
        setArrayDraggedElement([]);
    }

    const handleDigitCodeError = async (errorType) => {
        if (errorType === 'clickOut') {
            reloadCalendar();
        }
    }

    const handleDissociateButtonClick = (task) => {
        setDissociateTaskData(task);
        setIsDissociateModalOpen(true);
    }

    return (
        <>
            {(isDissociateModalOpen && dissociateTaskData) &&
                <Modal
                    title="Dissociate task from current print"
                    description={'Do you want to dissociate the task: "' + dissociateTaskData.name + '" from the current print?'}
                    buttons={[
                        {callback: () => dissociateTask(dissociateTaskData, false), label: 'Only dissociate'},
                        {callback: () => dissociateTask(dissociateTaskData, true), label: 'Dissociate and reschedule'}
                    ]}
                    cancelCallback={() => setIsDissociateModalOpen(false)}
                />
            }
            <DigitCode
                visible={digitCodeVisible}
                setVisible={setDigitCodeVisible}
                callback={handleDigitCodeResponse}
                errorCallback={handleDigitCodeError}
            />
            <div className="gc-main-container__tasks gc-tasks">
                <TasksToolbarActions 
                    isOffPeakHoursVisible={isOffPeakHoursVisible} 
                    setIsOffPeakHoursVisible={setIsOffPeakHoursVisible} 
                    setIsFirstDisplayedDateUpdated={setIsFirstDisplayedDateUpdated}
                    displayMode={displayMode}
                />
                <div className="gc-tasks__container gc-tasks-container">
                    <TasksTimeBar
                        displayMode={displayMode}
                        firstDisplayedDate={firstDisplayedDate}
                        lastDisplayedDate={lastDisplayedDate}
                    />
                    {isMovableTimeBarRender &&
                        <TasksMovableTimeBar
                            displayMode={displayMode}
                            firstDisplayedDate={firstDisplayedDate}
                            lastDisplayedDate={lastDisplayedDate}
                            cursorTime={cursorTime}
                            setCursorTime={setCursorTime}
                        />
                    }
                    <TasksCursor
                        firstDisplayedDate={firstDisplayedDate}
                        lastDisplayedDate={lastDisplayedDate}
                        setCursorTime={setCursorTime}
                        setIsMovableTimeBarRender={setIsMovableTimeBarRender}
                    />
                    <TasksGrid
                        displayMode={displayMode}
                        displayedDates={displayedDates}
                        printers={printers}
                        schedulePic={schedulePic}
                        setAlert={setAlert}
                        arrayFilteredPrinter={arrayFilteredPrinter}
                        setSelectedPrinterSchedule={setSelectedPrinterSchedule}
                        organization={organization}
                    />
                    <TasksTable
                        displayMode={displayMode}
                        firstDisplayedDate={firstDisplayedDate}
                        selectNextDate={selectNextDate}
                        lastDisplayedDate={lastDisplayedDate}
                        printers={printers}
                        tasksSelected={tasksSelected}
                        setTasksSelected={setTasksSelected}
                        setRightPartDetailsIsOpen={setRightPartDetailsIsOpen}
                        setTaskId={setTaskId}
                        setAlert={setAlert}
                        arrayFilteredPrinter={arrayFilteredPrinter}
                        setDigitCodeVisible={setDigitCodeVisible}
                        setSelectedTaskIdsForHistory={setSelectedTaskIdsForHistory}
                        selectedTaskIdsForHistory={selectedTaskIdsForHistory}
                        setArrayDraggedElement={setArrayDraggedElement}
                        oldSelectedTaskIdsForHistory={oldSelectedTaskIdsForHistory}
                        handleDissociateButtonClick={handleDissociateButtonClick}
                        externalPrintTasks={externalPrintTasks}
                    />
                </div>
            </div>
        </>
    );
};

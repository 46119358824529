import {Controller, useForm} from "react-hook-form";
import React from "react";
import {patchResourceEmptyWeightData, postResourceEmptyWeightData,} from "../../../api/apiBackoffice";

export const ResourceEmptyWeightDataForm = (props) => {

    const {successCallback, errorCallback, actualResourceEmptyWeightData, setActualResourceEmptyWeightData,
            setRightPartIsOpen, brandsData, materialsData} = props;

    const {handleSubmit, control, setValue} = useForm();

    const handleSubmitForm = async (data) => {
        if(actualResourceEmptyWeightData === null) {
            // Create new Material Data
            await postResourceEmptyWeightData(data)
                .then(() => {
                    resetForm();
                    setRightPartIsOpen(false);
                    successCallback('This Resource Empty Weight Data has been updated.');
                })
                .catch(() => errorCallback('Something went wrong.'));
        } else {
            // Update actual Material Data
            data.id = actualResourceEmptyWeightData.id;
            await patchResourceEmptyWeightData(data)
                .then(() => {
                    setActualResourceEmptyWeightData(null);
                    setRightPartIsOpen(false);
                    successCallback('This Resource Empty Weight Data has been updated.');
                })
                .catch(() => errorCallback('Something went wrong.'));
        }
    }

    const resetForm = () => {
        setValue('brand', 0);
        setValue('material', 0);
        setValue('initial_quantity', '');
        setValue('empty_weigh', '');
    }

    return(
        <>
            <h2 style={{"paddingLeft": "10px"}}>
                {actualResourceEmptyWeightData !== null ? 'Update resource empty weight data' : 'Add resource empty weight data'}
            </h2>
            <form onSubmit={handleSubmit(handleSubmitForm)} id="update-form">
                <div style={{"display": "flex", "justifyContent": "center", "marginBottom": "20px"}}>
                    <div>
                        <label>Brand data:</label>
                        <Controller
                            control={control}
                            name="brand"
                            rules={{required: true, validate: (value) => (value !== 0)}}
                            defaultValue={actualResourceEmptyWeightData !== null ? actualResourceEmptyWeightData.brand.id : 0}
                            render={({field}) => (
                                <select id="brand" {...field}>
                                    <option disabled value={0}>--Select a brand--</option>
                                    {brandsData.data.map(brand => (
                                        <option key={brand.id} value={brand.id}>{brand.name}</option>
                                    ))}
                                </select>
                            )}
                        />
                    </div>
                </div>
                <div style={{"display": "flex", "justifyContent": "center", "marginBottom": "20px"}}>
                    <div>
                        <label>Material data:</label>
                        <Controller
                            control={control}
                            name="material"
                            defaultValue={(actualResourceEmptyWeightData !== null && actualResourceEmptyWeightData.material) ?
                                actualResourceEmptyWeightData.material.id : 0}
                            render={({field}) => (
                                <select id="material" {...field}>
                                    <option value={0}>--No material selected--</option>
                                    {materialsData.data.map(brand => (
                                        <option key={brand.id} value={brand.id}>{brand.name}</option>
                                    ))}
                                </select>
                            )}
                        />
                    </div>
                </div>
                <div style={{"display": "flex", "justifyContent": "center", "marginBottom": "20px"}}>
                    <div>
                        <label>Resource initial quantity (without empty weight):</label>
                        <Controller
                            control={control}
                            name="initial_quantity"
                            defaultValue={(actualResourceEmptyWeightData !== null && actualResourceEmptyWeightData.initial_quantity) ?
                                actualResourceEmptyWeightData.initial_quantity : ''}
                            render={({field}) => (
                                <input type="number" {...field}/>
                            )}
                        />
                    </div>
                </div>
                <div style={{"display": "flex", "justifyContent": "center", "marginBottom": "20px"}}>
                    <div>
                        <label>Empty weight:</label>
                        <Controller
                            control={control}
                            name="empty_weight"
                            rules={{required: true, validate: (value) => value >= 0}}
                            defaultValue={actualResourceEmptyWeightData !== null ? actualResourceEmptyWeightData.empty_weight : ''}
                            render={({field}) => (
                                <input type="number" {...field}/>
                            )}
                        />
                    </div>
                </div>
                <br/>
                <input type="submit" value="Save" style={{display: "block", margin: "auto"}}/>
            </form>
        </>
    );
}

import React from "react";
import {useGetData} from "../../api/useFetch";
import {unlinkProject} from "../../api/apiLibrary";
import {Drawer, makeStyles} from '@material-ui/core';
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";

const useStyles = makeStyles({
    paper: {
        left: "50%"
    }
});

export const UnlinkProjectComponent = (props) => {
    const classes = useStyles();

    const {checkedPieces, isChecked, callback, setIsChecked, setCheckedPieces, projects, successCallback, errorCallback} = props;

    const [rightPartIsOpen, setRightPartIsOpen] = React.useState(false);

    const handleClick = async (project) => {
        await unlinkProject(checkedPieces, project)
            .then(() => {
                setRightPartIsOpen(false);
                setIsChecked(false);
                setCheckedPieces([]);
                projects.refetch().then(() => {
                    successCallback('Pieces have been deleted from the project.')
                })
            })
            .catch(() => {
                projects.refetch().then(() => errorCallback('Something went wrong.'))
            });
    }


    return (
        <>
            <button className="button-link" id="btn-unlink-project" onClick={() => setRightPartIsOpen(!rightPartIsOpen)} disabled={!isChecked}><i className="fa fa-unlink"/> Delete pieces of a project</button>
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)} classes={{paper: classes.paper}}>

                <div id="main__library-link-project">

                    <div id="library__projects">

                        <div id="library__projects-list-container" className="block">
                            <h2>Associate parts to a project</h2>

                            <div id="library__projects-list">
                                <div id="projects-list__background"></div>
                                <p>Select a project from the list to add parts to this project.</p>

                                {
                                    projects.isLoading ? <Loading/> :
                                        projects.isError ? <Error errorMessage={projects.error.message}/> :
                                    projects.data.length ? (
                                        projects.data.map((project) => (
                                            <div key={project.id} className="projects-list__project-item" onClick={() => handleClick(project)}>
                                                <span className="project-item__color" style={{background: project.color.hex_code}}/>
                                                {project.name}
                                            </div>
                                        ))
                                    ) : 'No Project available.'
                                }

                            </div>
                        </div>

                    </div>

                </div>
            </Drawer>
        </>
    )
}


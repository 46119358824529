import React from "react";
import {CircularProgress} from "@material-ui/core";

export const Loading = (props) => {
    const {inline} = props;

    return (
        <>
           <div className={`loading-container ${inline ? 'inline' : ''}`}>
               <CircularProgress/>
           </div>
        </>
    );

}
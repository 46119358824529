import React, {useState} from "react";
import icone_more_grey from "../../../images/icons/pic/IconeMoreGrey.png";
import {getConvertedDuration, getFormattedDuration} from "../../CommonFunctions";
import {TextField} from "@material-ui/core";
import InputMask from "react-input-mask";

export const CardMachineJob = (props) => {

    const {picCard, setIsActive, globalCardData, refreshGlobalState, handleContinueCard} = props;

    const card = picCard.card;

    const [, refreshState] = useState();

    const hasPreparationSlots = () => card.machine_job_time_slots && card.machine_job_time_slots.filter(slot => slot.period === 'preparation').length > 0;
    const hasExtraSlots = () => card.machine_job_time_slots && card.machine_job_time_slots.filter(slot => slot.period === 'extra').length > 0;

    function getTotalDuration(){
        let totalDuration = globalCardData['print_duration'] || 0;

        card.machine_job_time_slots.map((time_slot) => {
            totalDuration += time_slot.duration;
        })

        return totalDuration;
    }
    function getPrevDuration() {
        let prevDuration = 0;

        card.machine_job_time_slots.map((time_slot) => {
            prevDuration += (time_slot.period === 'preparation' ? time_slot.duration : 0);
        })

        return prevDuration;
    }
    function getExtraDuration() {
        let extraDuration = 0;

        card.machine_job_time_slots.map((time_slot) => {
            extraDuration += (time_slot.period === 'extra' ? time_slot.duration : 0);
        })

        return extraDuration;
    }

    let previousDuration = getPrevDuration() || 0;
    let slicerDuration = globalCardData['print_duration'] || 0;
    let extraDuration = getExtraDuration() || 0;

    let previousPercentW = Math.floor((previousDuration / getTotalDuration() * 100) * 100) / 100;
    let slicerPercentW = Math.floor((slicerDuration / getTotalDuration() * 100) * 100) / 100;
    let extraPercentW = Math.floor((extraDuration / getTotalDuration() * 100) * 100) / 100;

    const handleClickAddTime = (period) => {
        card.machine_job_time_slots.push({title: '', duration: 0, period: period});
        refreshState({});
    };

    const handleRemoveSlot = (index) => {
        console.log(index)
        card.machine_job_time_slots.splice(index, 1);
        refreshState({});
    };

    const handleClickContinue = () => {
        handleContinueCard(picCard);
        setIsActive(false);
    };

    return (
        <div className="main__card-machine-job">
            <h2>Job slot creation</h2>
            <div>
                <div className="controls">
                    <div className="plus" onClick={() => handleClickAddTime('preparation')}>
                        <img src={icone_more_grey} alt="add_preparation"/>
                    </div>
                    <div className="total-duration">
                        <div className="flex-duration">
                                                
                            {(hasPreparationSlots() && getPrevDuration() !== 0)?
                                <span className="previous-duration" style={{
                                    width: `${previousPercentW}%` 
                                }}>
                                    
                                    {previousDuration >= 3600 ?
                                        getFormattedDuration(previousDuration)
                                        :
                                        Math.floor((previousDuration) / 60) + "min"
                                    }
                                </span>
                            :
                            <></>
                            }
                            <span className="print-duration" style={{width: (previousDuration === 0 && extraDuration === 0 ? "100%" : `${slicerPercentW}%`)}}>
                                {getFormattedDuration(slicerDuration)}
                            </span>
                            {(hasExtraSlots() && getExtraDuration() !== 0) ?
                                <span className="extra-duration" style={{
                                    width: `${extraPercentW}%` 
                                }}>
                                    {extraDuration >= 3600 ?
                                        getFormattedDuration(extraDuration)
                                        :
                                        Math.floor((extraDuration) / 60) + "min"
                                    }
                                </span>
                            :
                            <></>
                            }
                        </div>
                    </div>
                    <div className="plus" onClick={() => handleClickAddTime('extra')}>
                        <img src={icone_more_grey} alt="add_extra"/>
                    </div>
                </div>
                <div>
                    {
                        hasPreparationSlots() > 0 &&
                        <div className="block">
                            <h3>Preparation time slots</h3>
                            {card.machine_job_time_slots.map((preparationTime, index) => (
                                preparationTime.period === 'preparation' &&
                                    <div className="slot-container" key={`p-${index}`}>
                                        <TextField
                                            label="Description"
                                            defaultValue={preparationTime.title}
                                            variant="outlined"
                                            size="small"
                                            onBlur={(e) => {
                                                preparationTime.title = e.target.value;
                                            }}
                                        />

                                        &nbsp;

                                        <InputMask
                                            mask="aa:ba"
                                            onChange={e => {
                                                preparationTime.duration = getConvertedDuration(e.target.value);
                                                refreshGlobalState({});
                                            }}
                                            formatChars={{
                                                'a': '[0-9]',
                                                'b': '[0-5]',
                                            }}
                                            defaultValue={getFormattedDuration(preparationTime.duration, ':')}
                                            alwaysShowMask={true}
                                            maskChar="0"
                                        >
                                            {(inputProps) =>
                                                <TextField
                                                    label="Duration (hh:mm)"
                                                    variant="outlined"
                                                    size="small"
                                                    {...inputProps}
                                                />}
                                        </InputMask>

                                        &nbsp;

                                        <button onClick={() => handleRemoveSlot(index)}>Delete</button>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    {
                        hasExtraSlots() > 0 &&
                        <div className="block">
                            <h3>Extra time slots</h3>
                            {card.machine_job_time_slots.map((extraTime, index) => (
                                extraTime.period === 'extra' &&
                                    <div className="slot-container" key={`e-${index}`}>
                                        <TextField
                                            label="Description"
                                            defaultValue={extraTime.title}
                                            variant="outlined"
                                            size="small"
                                            onBlur={(e) => {
                                                extraTime.title = e.target.value
                                            }}
                                        />

                                        &nbsp;

                                        <InputMask
                                            mask="aa:ba"
                                            onChange={e => {
                                                extraTime.duration = getConvertedDuration(e.target.value);
                                                refreshGlobalState({});
                                            }}
                                            formatChars={{
                                                'a': '[0-9]',
                                                'b': '[0-5]',
                                            }}
                                            defaultValue={getFormattedDuration(extraTime.duration, ':')}
                                            alwaysShowMask={true}
                                            maskChar="0"
                                        >
                                            {(inputProps) =>
                                                <TextField
                                                    label="Duration (hh:mm)"
                                                    variant="outlined"
                                                    size="small"
                                                    {...inputProps}
                                                />}
                                        </InputMask>

                                        &nbsp;

                                        <button onClick={() => handleRemoveSlot(index)}>Delete</button>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>

                <p>Your total machine build time will be <strong>{getFormattedDuration(getTotalDuration())}</strong></p>
            </div>
            <div className="button-zone">
                <button onClick={() => handleClickContinue()}><h2>Continue</h2></button>
            </div>
        </div>
    )
}
import {ResourceRow} from "./ResourceRow";
import {getTasksByPrinterId} from "../../../../services/gantt/GanttService";

export const ResourcesTable = (props) => {
    const {printers, tasksSelected, setTasksSelected, setAlert, setIsWorkloadDialogOpen, setPrinterTaskWorkload,
        arrayFilteredPrinter, firstDisplayedDate, externalPrintTasks, reloadCalendar} = props;

    const printerList = arrayFilteredPrinter.length === 0 ? printers : arrayFilteredPrinter;

    return (
        <table className="gc-resources__table gc-resources-table">
            <tbody>
                {printerList.map((printer) => {
                    return (
                    <ResourceRow 
                        key={printer.id}
                        printer={printer}
                        tasksSelected={tasksSelected}
                        setTasksSelected={setTasksSelected}
                        setAlert={setAlert}
                        setIsWorkloadDialogOpen={setIsWorkloadDialogOpen}
                        setPrinterTaskWorkload={setPrinterTaskWorkload}
                        firstDisplayedDate={firstDisplayedDate}
                        externalPrintTasks={getTasksByPrinterId(externalPrintTasks, printer.id)}
                        reloadCalendar={reloadCalendar}
                    />
                    );
                })}
            </tbody>
        </table>
    );
};

import React, {useEffect, useState} from 'react';
import {useGetData} from "../../../api/useFetch";
import {deleteMaterial} from "../../../api/apiBackoffice";
import {Drawer} from "@material-ui/core";
import {Loading} from "../../common/Loading";
import {MaterialAdd} from "./MaterialAdd";
import {MaterialEdit} from "./MaterialEdit";

export const Materials = (props) => {

    const {materialsData} = props;

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [alert, setAlert] = useState();
    const [isUpdating, setIsUpdating] = useState(false);
    const [actualMaterial, setActualMaterial] = useState();
    const [isCustomMaterialValue, setIsCustomMaterialValue] = useState(false);

    const materials = useGetData('materials', 'materials');
    const technologies = useGetData('technologies', 'technologies');

    useEffect(() => {
        if(actualMaterial !== undefined && actualMaterial !== null) {
            if(actualMaterial.material_data === undefined || actualMaterial.material_data === null || !actualMaterial.material_data.id) {
                setIsCustomMaterialValue(true);
            }
        }
    }, [actualMaterial]);

    
    const successCallback = (message) => {
        materials.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        materials.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    }

    const handleDelete = async (material_Id) => {
        if(window.confirm('Are you sure you want to delete this Material ?'))
            await deleteMaterial(material_Id)
                .then(() => successCallback('This Material has been deleted.'))
                .catch(() => errorCallback('Something went wrong.'));
    }

    const getTechnoName = (techno_Id) => {
        let name = "";
        technologies.data &&
        technologies.data.map((techno) => (
            techno_Id === techno.id ? name = techno.name: ""
        ))
        return name;
    }

    const handleClose = () => {
        setIsCustomMaterialValue(false);
        setActualMaterial(null);
        setRightPartIsOpen(!rightPartIsOpen);
    }

    return (
        <div className="backoffice_block" style={{display: "block", border: "2px solid lightgrey", padding: "15px"}}>
            <div className="materials_header">
                <h2>Materials</h2>
                <button className="button-link" onClick={() => {
                    setIsUpdating(false);
                    setRightPartIsOpen(!rightPartIsOpen);
                }}>
                    <i className="fa fa-plus"/> Add a material
                </button>
            </div>
            {materials.data ? (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Technology</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {materials.data &&
                        materials.data.map((material) => (
                            <tr key={material.id}>
                                <td>{material.name}</td>
                                <td>{getTechnoName(material.technology.id)}</td>
                                <td>
                                    <button className="button-link" onClick={()=> {
                                        setIsUpdating(true);
                                        setActualMaterial(material);
                                        setRightPartIsOpen(!rightPartIsOpen);
                                    }}>
                                        <i className="fas fa-edit" />
                                    </button>
                                    &nbsp;
                                    <button className="button-link" onClick={()=> handleDelete(material.id)}><i className="fas fa-trash-alt" /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <Loading />
            )}
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => handleClose()}>
                <div className="backoffice_drawer_content" style={{width:"500px", "marginTop":"30px"}}>
                    {!isUpdating &&
                        <MaterialAdd
                            materialsData={materialsData} materials={materials} technologies={technologies}
                            setRightPartIsOpen={setRightPartIsOpen} rightPartIsOpen={rightPartIsOpen}
                            successCallback={successCallback} errorCallback={errorCallback}
                            isCustomMaterialValue={isCustomMaterialValue}
                            setIsCustomMaterialValue={setIsCustomMaterialValue}
                        />
                    }
                    {(actualMaterial && isUpdating) &&
                        <MaterialEdit
                            actualMaterial={actualMaterial} setActualMaterial={setActualMaterial}
                            materialsData={materialsData} materials={materials} technologies={technologies}
                            setRightPartIsOpen={setRightPartIsOpen} rightPartIsOpen={rightPartIsOpen}
                            successCallback={successCallback} errorCallback={errorCallback}
                            isCustomMaterialValue={isCustomMaterialValue} setIsCustomMaterialValue={setIsCustomMaterialValue}
                        />
                    }
                </div>
            </Drawer>
        </div>
    )
}

import {getCurrentUser} from "../components/CommonFunctions";

const token = localStorage.getItem('token');

/*POST function to add new project*/
export const postProject = async (dataProject) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + 'projects', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            user: process.env.REACT_APP_API_URI + `users/${getCurrentUser().id}`,
            name: dataProject.name,
            code: "123",
            creation_date: Math.floor(Date.now() / 1000),
            last_modification: Math.floor(Date.now() / 1000),
            color: process.env.REACT_APP_API_URI + `resource_colors/${dataProject.color}`,
            notes: dataProject.notes
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchProject = async (dataProject, callback) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `projects/${dataProject.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: dataProject.name,
            last_modification: Math.floor(Date.now() / 1000),
            color: process.env.REACT_APP_API_URI + `resource_colors/${dataProject.color}`,
            notes: dataProject.notes
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const archiveProject = async (dataProject) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `projects/${dataProject.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            last_modification: Math.floor(Date.now() / 1000),
            state: dataProject.state,
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const addProjectMember = async (memberId, projectId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + 'user_in_projects', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            user: process.env.REACT_APP_API_URI + `users/${memberId}`,
            project: process.env.REACT_APP_API_URI + `projects/${projectId}`,
            join_date: Math.floor(Date.now() / 1000),
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const deleteProject = async (projectId) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `projects/${projectId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}
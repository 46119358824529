import {Controller, useForm} from "react-hook-form";
import React from "react";
import {patchResourceBrand} from "../../../api/apiBackoffice";

export const BrandEdit = (props) => {

    const {brandsData, brands, successCallback, errorCallback, actualBrand, setActualBrand,
        isCustomBrandValue, setIsCustomBrandValue, setRightPartIsOpen, rightPartIsOpen} = props;

    const {reset, handleSubmit, control, setValue} = useForm();

    const handleUpdate = async (data) => {
        let brand = addNewDataToBrand({id: actualBrand.id}, data);
        await patchResourceBrand(brand)
            .then(() => {
                reset();
                setIsCustomBrandValue(false);
                setRightPartIsOpen(!rightPartIsOpen);
                successCallback('This Resource Brand has been updated.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    };

    const handleBrandSelectChange = (e) => {
        if(e.target.value === 'custom') {
            setIsCustomBrandValue(true);
        } else {
            setValue('name', e.target.value);
            return e.target.value;
        }
    }

    const addNewDataToBrand = (brand, data) => {
        if(isCustomBrandValue) {
            brand.name = data.name_custom;
            brand.brand_data = null;
        } else {
            const brandData = JSON.parse(data.name);
            brand.name = brandData.name;
            brand.brand_data = brandData.id;
        }
        return brand;
    }

    // Check if brandData is already used by another brand
    const checkIfBrandDataAlreadyUsed = (brandData) => {
        for(let i = 0; i < brands.data.length; i++) {
            if(brands.data[i].brand_data && brandData.id === brands.data[i].brand_data.id) {
                return true;
            }
        }
        return false;
    }

    // Check if brand can be selected
    const checkIfBrandIsAvailable = (brandData) => {
        if(!actualBrand.brand_data) {
            return checkIfBrandDataAlreadyUsed(brandData);
        } else {
            return checkIfBrandDataAlreadyUsed(brandData) && actualBrand.brand_data && brandData.id !== actualBrand.brand_data.id;
        }
    }

    const defineDefaultNameValue = () => {
        for(let i = 0; i < brandsData.data.length; i++) {
            if(actualBrand.brand_data && actualBrand.brand_data.id === brandsData.data[i].id) {
                return JSON.stringify(brandsData.data[i]);
            }
        }
        return 0;
    }

    const handleChooseBrandInListButtonClick = () => {
        setIsCustomBrandValue(false);
    }

    return(
        <>
            <h2 style={{"paddingLeft": "10px"}}>Update Resource Brand</h2>
            <form onSubmit={handleSubmit(handleUpdate)} id="update-form">
                <label>Resource Brand name :</label>
                <div style={{"display": "flex", "justifyContent": "center", "marginBottom": "20px"}}>
                    {!isCustomBrandValue &&
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                required: !isCustomBrandValue,
                                validate: (value) => (value !== 0 || isCustomBrandValue)
                            }}
                            defaultValue={defineDefaultNameValue()}
                            render={({field}) => (
                                <select id="brand-name" {...field}
                                        onChange={(e) => handleBrandSelectChange(e)}>
                                    <option disabled value={0}>--Select a brand--</option>
                                    {brandsData.data.map(brand => (
                                        <option key={brand.id} value={JSON.stringify(brand)}
                                                disabled={checkIfBrandDataAlreadyUsed(brand)}>{brand.name}</option>
                                    ))}
                                    <option value="custom">Add a custom value</option>
                                </select>
                            )}
                        />
                    }
                    {isCustomBrandValue &&
                        <div>
                            <Controller
                                control={control}
                                name="name_custom"
                                rules={{required: isCustomBrandValue}}
                                defaultValue={actualBrand.name}
                                render={({field}) => (
                                    <input type="text" {...field}/>
                                )}
                            />
                            <input type="button" value="Choose a brand in list" style={{display: "block", margin: "auto"}}
                                   onClick={() => handleChooseBrandInListButtonClick()}/>
                        </div>
                    }
                </div>
                <br/>
                <input type="submit" value="Save" style={{display: "block", margin: "auto"}}/>
            </form>
        </>
    );
}

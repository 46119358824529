import {dateToHourMinuteString, dateToShortDayMonthString} from "../../../../utils/date";
import {DISPLAY_MODES} from "../Calendar";
import {isUserGranted} from "../../../CommonFunctions";

export const TasksGrid = (props) => {
    const {displayMode, displayedDates, printers, schedulePic, setAlert, arrayFilteredPrinter, setSelectedPrinterSchedule, organization} = props;

    const handleClickCell = (e, printer, date) => {
        if (isUserGranted('ROLE_CREATE_TASK')) {
            schedulePic(date);
            setSelectedPrinterSchedule(printer.name);
        }
        else
            setAlert({message: 'You do not have the required privileges for this action.', status: 'error', date: new Date()});
    };

    const getHeaderCellClassNames = (date) => {
        let classNames = "gc-tasks-grid-header-cell__text";
        if (isDateInNewMonth(date) || isDateInNewDay(date) || !isTimeOpen(date)) {
            classNames += " gc-tasks-grid-header-cell__text--grayed-out";
        }
        return classNames;
    };

    const getHeaderCellText = (date, index) => {
        switch (displayMode) {
            case DISPLAY_MODES.DAY:
                return dateToHourMinuteString(date);
            case DISPLAY_MODES.WEEK:
                if (index % 2 === 0) {
                    return dateToShortDayMonthString(date);
                }
                return "";
            case DISPLAY_MODES.MONTH:
            default:
                return dateToShortDayMonthString(date);
        }
    };

    const getCellClassNames = (date) => {
        let classNames = "gc-tasks-grid-row__cell";
        if (isDateInNewMonth(date) || isDateInNewDay(date) || !isTimeOpen(date)) {
            classNames += " gc-tasks-grid-row__cell--grayed-out";
        }
        return classNames;
    };

    const isDateInNewMonth = (date) => {
        return displayMode === DISPLAY_MODES.MONTH && date.getMonth() !== displayedDates[0].getMonth();
    };

    const isDateInNewDay = (date) => {
        return displayMode === DISPLAY_MODES.DAY && date.getDate() !== displayedDates[0].getDate();
    };

    /**
     * Function allowing to know if the current hour is within working hours or not
     * @param {Date} date 
     * @returns boolean
     */
    const isTimeOpen = (date) => {
        /* We just get the hours open and close because each cell on the grid represents one hour,
        even if the open_time is for exemple 09:30 we can't show it precisly for now */
        let openTimeHourInNumber = organization.open_time / 3600;
        let closeTimeHourInNumber = organization.close_time / 3600;
        return (
            //condition if we are in display mode day
            displayMode === DISPLAY_MODES.DAY &&
            date.getHours() >= openTimeHourInNumber &&
            date.getHours() < closeTimeHourInNumber &&
            //Handling grayed out cell for week end
            date.getDay() !== 0 && date.getDay() !== 6
        ) || (
            // condition if we are NOT in display mode day
            displayMode !== DISPLAY_MODES.DAY &&
            //Handling grayed out cell for week end
            date.getDay() !== 0 && date.getDay() !== 6
        );
    };
    const printerList = arrayFilteredPrinter.length === 0 ? printers : arrayFilteredPrinter;

    return (
        <table className="gc-tasks-container__tasks-grid gc-tasks-grid">
            <thead>
                <tr className="gc-tasks-grid__header gc-tasks-grid-header">
                    {displayedDates.map((displayedDate, index) => {
                        return (
                            <th key={displayedDate} className="gc-tasks-grid-header__cell gc-tasks-grid-header-cell">
                                <div className={getHeaderCellClassNames(displayedDate)}>
                                    {getHeaderCellText(displayedDate, index)}
                                </div>
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {printerList.map((printer) => {
                    return (
                        <tr key={printer.id} className="gc-tasks-grid__row gc-tasks-grid-row">
                            {displayedDates.map((displayedDate) => {
                                return (
                                    <td
                                        key={displayedDate}
                                        className={getCellClassNames(displayedDate)}
                                        onClick={(e) => handleClickCell(e, printer, displayedDate)}
                                    />
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export const OutlinedCard = (props) => {

    const {children} = props;

    return (
        <div className="outlined-card">
            {children}
        </div>
    );
}

import React from "react";
import {BrowserRouter as Router, Route, Switch, useRouteMatch} from "react-router-dom";
import {PicBuilderComponent} from "./pic/PicBuilderComponent";
import {EditPicMain} from "./pic/EditPicMain";
import {QueryClient, QueryClientProvider} from 'react-query';

const queryClient = new QueryClient();

export const PicBuilderMain = () => {
    let {path} = useRouteMatch();

    return (
        <Router>
            <Switch>
                <Route exact path={path}>
                    <QueryClientProvider client={queryClient}>
                        <PicBuilderComponent/>
                    </QueryClientProvider>
                </Route>
                <Route exact path={path + '/template'}>
                    <QueryClientProvider client={queryClient}>
                        <PicBuilderComponent/>
                    </QueryClientProvider>
                </Route>
                <Route exact path={path + '/:id'}>
                    <QueryClientProvider client={queryClient}>
                        <EditPicMain/>
                    </QueryClientProvider>
                </Route>
                <Route exact path={path + '/template/:id'}>
                    <QueryClientProvider client={queryClient}>
                        <EditPicMain/>
                    </QueryClientProvider>
                </Route>
            </Switch>
        </Router>

    )
}




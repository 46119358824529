import {FarmPrinter} from "./FarmPrinter";
import {getLedHexadecimalColor, getPrinterStatusByPrinterId, LED_COLORS} from "../../../services/FarmService";

export const FarmCellPrinter = (props) => {

    const {printers, cellSize, smartFarmCellData, smartPrintersStatusData, manualPrintersStatus, cellContentClasses} = props;

    const color = (smartFarmCellData && smartFarmCellData.led_color) ? getLedHexadecimalColor(smartFarmCellData.led_color) : LED_COLORS.OFF.color;
    const linearGradient = 'linear-gradient(' + color + '55, ' + color + '00)';

    return(
        <div className={cellContentClasses}>
            <div className="cell-led-light">
                <div className="cell-led-light-effect" style={{backgroundImage: color !== LED_COLORS.OFF.color ? linearGradient : 'unset'}}></div>
            </div>
            <div className="cell-header">
                <div className="cell-led-line" style={{backgroundColor: color !== LED_COLORS.OFF.color ? color : 'unset'}}></div>
                <div className="cell-type">Printers</div>
            </div>
            <div className="cell-body-printers cell-body">
                  <div className="cell-printers">
                      {printers && printers.map(printer =>
                          <FarmPrinter
                              printer={printer} key={printer.id} cellSize={cellSize}
                              smartPrinterStatus={getPrinterStatusByPrinterId(printer.id, smartPrintersStatusData)}
                              manualPrinterStatus={getPrinterStatusByPrinterId(printer.id, manualPrintersStatus)}
                          />
                      )}
                  </div>
            </div>
        </div>
    );
}

import React, {useEffect, useRef, useState} from "react";
import {fetchData, useGetData} from "../../api/useFetch";
import {
    ALERT_STATUS,
    getAlertContent,
    getCurrentTimestamp,
    getFormattedDuration,
    getFormattedRoundedDate
} from "../CommonFunctions";
import calendar_icone from "../../images/icons/pic/CalendarPickerIcone.png";
import left_arrow from "../../images/icons/pic/chevron_left_black_24dp.svg";
import right_arrow from "../../images/icons/pic/chevron_right_black_24dp.svg";
import warning_icone from "../../images/icons/pic/WarningFlag.png";
import {ReactComponent as ProductionIcone} from "../../images/icons/pic/Production-NAV.svg";
import {schedulePic, scheduleWaitingList, updateWaitingTask} from "../../api/apiPic";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import LinearProgress from '@material-ui/core/LinearProgress';
import {deleteTask, patchTaskSmartScheduling} from "../../api/apiGantt";
import {DateTimePickerComponent} from "../common/DateTimePickerComponent";
import {useHistory} from "react-router-dom";
import {requestAutoScheduling} from "../../api/apiAutoSchedule";
import {PicScheduleTable} from "./PicScheduleTable";
import {Radio, RadioGroup} from "rsuite";
import {formatPicsList} from "../../services/PicsService";


export const PicSchedule = (props) => {

    let history = useHistory();

    const {picId, callback, taskInWaiting, setTaskInWaiting, setAlert, picScheduleSelectedDate, selectedPrinterSchedule} = props;
    const [formattedPics, setFormattedPics] = useState([]);
    const [selectedPic, setSelectedPic] = useState();
    const [isBackFromTask, setIsBackFromTask] = useState(false);

    const scheduledPicQuantity = useRef(0);

    const pics = useGetData(
        'picData',
        'pics'
    );

    const SCHEDULING_MODE_SMART = 0;
    const SCHEDULING_MODE_MANUAL = 1;
    const SCHEDULING_MODE_WAITING = 2;

    useEffect(() => {
        if (!pics.isLoading && (picId || (taskInWaiting && taskInWaiting.pic && taskInWaiting.pic.id)) && !selectedPic) {
            window.history.pushState({}, "gantt", "/gantt");
            if(!isBackFromTask) {
                formattedPics.map((pic) => {
                    if((taskInWaiting && taskInWaiting.pic && parseInt(taskInWaiting.pic.id) === pic.id) || parseInt(picId) === pic.id)
                        setSelectedPic(pic);
                });
            }
        }
    }, [formattedPics]);

    const [warningMessage, setWarningMessage] = useState(null);
    const [isDateTimePickerOpen, setIsDateTimePickerOpen] = useState(false);

    const [schedulingMode, setSchedulingMode] = useState(null);
    const [selectedDate, setSelectedDate] = useState(picScheduleSelectedDate);
    const [selectedTime, setSelectedTime] = useState('00:00');
    const [convertedSelectedTime, setConvertedSelectedTime] = useState(0);
    const [picQuantity, setPicQuantity] = useState(1);
    const [selectedPrinter, setSelectedPrinter] = useState(null);
    const [isPicScheduling, setIsPicScheduling] = useState(false);
    const [smartScheduledTasks, setSmartScheduledTasks] = useState([]);
    const [smartScheduleTimes, setSmartScheduleTimes] = useState({start: 0, end: 0});

    useEffect(() => {
        if(pics.data !== undefined) {
            setFormattedPics(formatPicsList(pics.data));
        }
    }, [pics.isLoading]);

    useEffect(() => {
        if(taskInWaiting){
            setPicQuantity(taskInWaiting.waiting_number);
        }
    }, [taskInWaiting])

    useEffect(() => {
        if(selectedPrinterSchedule !== ''){
            let secondsOfDay = picScheduleSelectedDate.getHours() + picScheduleSelectedDate.getMinutes();
            setConvertedSelectedTime(secondsOfDay);
            setSchedulingMode(SCHEDULING_MODE_MANUAL);
        }
    }, [picScheduleSelectedDate]);

    /* Define default selectedPrinter for selectedPic */
    useEffect(() => {
        if(selectedPic) {
            if(taskInWaiting && taskInWaiting.printer) {
                selectedPic.slicer.printers.map(printer => {
                    if(printer.id === taskInWaiting.printer.id) {
                        setSelectedPrinter(printer);
                    }
                });
            } else if(selectedPic.slicer.main_printer) {
                selectedPic.slicer.printers.map(printer => {
                    if(printer.id === selectedPic.slicer.main_printer.id) {
                        setSelectedPrinter(printer);
                    }
                });
            }
        }
    }, [selectedPic]);

    const resetSchedulingData = () => {
        if(schedulingMode === SCHEDULING_MODE_SMART){
            setSelectedDate(new Date().setHours(0,0,0,0));
            setSelectedTime('00:00');
            setConvertedSelectedTime(0);
            setSchedulingMode(null);
            setSmartScheduleTimes({start: 0, end: 0});
        }
    };

    const handleClickDateTimePicker = () => {
        setIsDateTimePickerOpen(selectedPic && schedulingMode === SCHEDULING_MODE_MANUAL);
    }

    const handleClickNextPeriod = () => {
        /* @TODO */
    }

    // (Smart) scheduling
    const handleSchedulingModeChange = async (mode) => {
        if(!selectedPic.slicer || !selectedPrinter){
            alert('The selected PIC has no printer associated.');
            mode = SCHEDULING_MODE_MANUAL;
        }

        setSchedulingMode(mode);

        // Smart scheduling
        if(mode === SCHEDULING_MODE_SMART){

            let tasks = await fetchData('tasks', `printer.id=${selectedPrinter.id}&date[gt]=${getCurrentTimestamp() - 5 * 86400}`);
            tasks = await tasks.json()

            const scheduleResponse = await requestAutoScheduling(tasks, selectedPic.use_printer_duration, picQuantity);

            if(scheduleResponse.success){
                // Find the new task with ID -1
                let firstTaskIndex = 0;
                let minStart = Number.MAX_VALUE, maxEnd = Number.MIN_VALUE;
                for(let i=0; i<scheduleResponse.scheduled_tasks.length; i++){
                    let task = scheduleResponse.scheduled_tasks[i];
                    task.date = task.start;

                    if(task.id < 0 && task.start < minStart)
                        minStart = task.start;

                    if(task.id < 0 && task.end > maxEnd)
                        maxEnd = task.end;

                    if(task.id === -1)
                        firstTaskIndex = i;
                }

                setSmartScheduleTimes({start: minStart, end: maxEnd});

                let firstDate = new Date(minStart * 1000);
                let secondsOfDay = firstDate.getHours() * 3600 + firstDate.getMinutes() * 60;
                setSelectedDate((scheduleResponse.scheduled_tasks[firstTaskIndex].start - secondsOfDay) * 1000);
                setSelectedTime(firstDate.getHours() + ':' + firstDate.getMinutes());
                setConvertedSelectedTime(secondsOfDay);

                if(scheduleResponse.scheduled_tasks.length)
                    setSmartScheduledTasks(scheduleResponse.scheduled_tasks);
            }
            else{
                alert(scheduleResponse.error_message);
            }
        }
    }

    const scheduleCallback = () => {
        scheduledPicQuantity.current++;

        if(scheduledPicQuantity.current === picQuantity)
            callback();
    };

    // Add task to Waiting list
    const handleWaitingList = async () => {
        if (selectedPic && !taskInWaiting) {
            setIsPicScheduling(true);
            await scheduleWaitingList(selectedPic, selectedPrinter, callback, picQuantity);
        } else {
            alert('The job is already in waiting list.');
            callback();
        }
    }

    /* Interact with controllable printers APIs  - Actually disabled because we directly send build to print */
    /*const handleControllablePrinterSchedule = async () => {
        if(selectedPrinter.controllable_printer !== undefined && selectedPic.slicer.build_id !== undefined && selectedPic.slicer.build_id !== null) {
            // Add build to controllable printer queue
            await postAddBuildToQueue(selectedPrinter.controllable_printer, selectedPic.slicer.build_id, picQuantity)
                .then(response => {
                    // Show alert if response code different than 201 (success) or 501 (no operation for this API)
                    if (response.printer_data_response_code !== 201 && response.printer_data_response_code !== 501) {
                        setAlert({message: 'Error while sending build to printer queue', status: "error", date: new Date()});
                    }
                })
                .catch(e => {
                    setAlert({message: 'Error while sending build to printer queue', status: "error", date: new Date()});
                }
            );
        }
    }*/

    // Submit PIC
    const handleClickSchedule = async () => {
        history.push('/gantt');

        if(!selectedPic?.slicer || !selectedDate || !convertedSelectedTime || !selectedPrinter)
            return;

        setIsPicScheduling(true);

        // Send build to printer queue and show alert if error
        // Actually disabled because we directly send build to print
        //await handleControllablePrinterSchedule();

        let promises = [];

        if(schedulingMode === SCHEDULING_MODE_SMART){
            for(let task of smartScheduledTasks){
                // When updating an existing task
                if(task.id >= 0)
                    promises.push(patchTaskSmartScheduling(task));

                // When creating a new task, i.e. task.id < 0
                else
                    promises.push(schedulePic(selectedPic, task.start, selectedPrinter, scheduleCallback));
            }
        }

        // Manual mode: schedule PIC *without* running Smart Scheduling
        else if(schedulingMode === SCHEDULING_MODE_MANUAL){
            let lastScheduleDateTime = selectedDate / 1000 + convertedSelectedTime;

            for(let i=0; i<picQuantity; i++){
                promises.push(schedulePic(selectedPic, lastScheduleDateTime, selectedPrinter, scheduleCallback));

                lastScheduleDateTime += selectedPic.use_printer_duration;
            }
        }

        // When scheduling tasks that were in the waiting list
        if(taskInWaiting?.waiting_number){
            // All waiting task have been scheduled, remove the waiting line in the database
            if(picQuantity >= taskInWaiting.waiting_number){
                promises.push(deleteTask(taskInWaiting.id));
            }
            else
                promises.push(updateWaitingTask(taskInWaiting.id, taskInWaiting.waiting_number - picQuantity, scheduleCallback));
        }
        setTaskInWaiting(null);
        Promise.all(promises)
            .then(() => callback())
            .catch(() => setAlert(getAlertContent('an error occurred while scheduling pic', ALERT_STATUS.ERROR)));
    };

    const handleBackButton = () => {
        setSelectedPic(null);
        if (taskInWaiting) {
            setIsBackFromTask(true);
        }
    }

    const handlePrinterChange = (printer) => {
        setSelectedPrinter(printer);
        setSchedulingMode(null);
    }

    if(pics.isLoading) return <Loading/>
    if(pics.isError) return <Error errorMessage={pics.error}/>

    return (
        <div className="main__shedule_pic">
            <LinearProgress color={"secondary"} hidden={!isPicScheduling}/>
            {
                !selectedPic &&
                    <div className="pic-selection">
                        <PicScheduleTable formattedPics={formattedPics} setSelectedPic={setSelectedPic} selectedPrinterSchedule={selectedPrinterSchedule} />
                    </div>
            }
            {
                selectedPic &&
                <>
                    <button className="button--with-icon" onClick={() => handleBackButton()}>
                        <i className="fa fa-chevron-left"/> Back to list
                    </button>
                    <div className="pic-details">
                        <h1>{selectedPic.name}</h1>
                        <div className="pic-details-table">
                            <table className="table--compact">
                                <tbody>
                                    <tr>
                                        <td className="header"><h2>Quantity</h2></td>
                                        <td>
                                            <div className="pic-quantity">
                                                <img src={left_arrow} alt="left_arrow"
                                                     onClick={() => {
                                                         resetSchedulingData();
                                                         setPicQuantity(picQuantity > 1 ? picQuantity - 1 : picQuantity);
                                                     }}/>

                                                <input type="number" min="1" value={picQuantity}
                                                       onChange={(e) => {
                                                            resetSchedulingData();
                                                           setPicQuantity(parseInt(e.target.value));
                                                       }}
                                                />

                                                <img src={right_arrow} alt="right_arrow"
                                                     onClick={() => {
                                                         resetSchedulingData();
                                                         setPicQuantity(picQuantity + 1);
                                                     }}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="header"><h2>PIC duration</h2></td>
                                        <td>
                                            <h3>{getFormattedDuration(selectedPic.duration, ':')}</h3>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="header"><h2>Machine</h2></td>
                                        <td>
                                            {
                                                selectedPic.slicer ?
                                                    <>
                                                        {
                                                            selectedPic.slicer.printers.length > 0 && selectedPic.slicer.main_printer ?
                                                                <RadioGroup inline={false} name="printersList" value={selectedPrinter} onChange={value => handlePrinterChange(value)}>
                                                                    {selectedPic.slicer.printers.map(printer => (
                                                                        <Radio className="machine-choice-item" key={printer.id + printer.name} value={printer}>
                                                                            <div className="radio-label-printers">
                                                                                <h3>{printer.name}</h3>
                                                                                <p>
                                                                                    {printer.brand.name + ' - ' +
                                                                                    printer.technology.name}
                                                                                </p>
                                                                                {(printer.id === selectedPic.slicer.main_printer.id) &&
                                                                                    <p className="radio-label-printers-main-printer">(main printer)</p>
                                                                                }
                                                                            </div>
                                                                        </Radio>
                                                                    ))}
                                                                </RadioGroup>
                                                            :
                                                                <h3>-</h3>
                                                        }
                                                    </>
                                                    :
                                                    <p>No selected printer</p>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr/>

                    {
                        warningMessage !== null &&
                            <div className="message-zone">
                                <img src={warning_icone} alt="warning"/>
                                <h3>{warningMessage}</h3>
                            </div>
                    }
                    <div className="schedule-mode-zone">
                        <h3>Add to schedule</h3>
                        <div className="schedule-mode-selection">
                            <input type="checkbox" id="smart-scheduling"
                                   checked={schedulingMode === SCHEDULING_MODE_SMART}
                                   onChange={() => handleSchedulingModeChange(SCHEDULING_MODE_SMART)}/>
                            <label htmlFor="smart-scheduling">
                                <span/>
                                <div>
                                    <h3>Smart scheduling</h3>
                                    <p>Your PIC will be automatically added at the end of the machine queue.</p>
                                </div>

                            </label>
                            <input type="checkbox" id="manual-scheduling"
                                   checked={schedulingMode === SCHEDULING_MODE_MANUAL}
                                   onChange={() => handleSchedulingModeChange(SCHEDULING_MODE_MANUAL)}/>
                            <label htmlFor="manual-scheduling">
                                <span/>
                                <div>
                                    <h3>Manual scheduling</h3>
                                    <p>Pick a starting date for your PIC.</p>
                                </div>
                            </label>
                            {!taskInWaiting &&
                                <>
                                    <input
                                        type="checkbox"
                                        id="waiting-list"
                                        checked={schedulingMode === SCHEDULING_MODE_WAITING}
                                        onChange={() => handleSchedulingModeChange(SCHEDULING_MODE_WAITING)}
                                    />
                                    <label htmlFor="waiting-list">
                                        <span />
                                        <div>
                                            <h3>Add to waiting list</h3>
                                            <p>You can schedule the PIC later.</p>
                                        </div>
                                    </label>
                                </>
                            }
                        </div>
                    </div>
                    {
                        schedulingMode === SCHEDULING_MODE_MANUAL &&
                            <img src={calendar_icone} className="data-time-picker" onClick={handleClickDateTimePicker} alt="calendar"/>
                    }
                    <DateTimePickerComponent
                        isDateTimePickerOpen={isDateTimePickerOpen}
                        handleClose={() => setIsDateTimePickerOpen(false)}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        setSelectedTime={setSelectedTime}
                        setConvertedSelectedTime={setConvertedSelectedTime}
                    />
                    <hr/>
                    <div className="schedule-period">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="header"><h2>Start</h2></td>
                                    <td>
                                        <h3>
                                            {
                                                smartScheduleTimes.start > 0 ?
                                                    getFormattedRoundedDate(smartScheduleTimes.start) :

                                                selectedDate ?
                                                    getFormattedRoundedDate(selectedDate / 1000 + convertedSelectedTime) : ''
                                            }
                                        </h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="header"><h2>End</h2></td>
                                    <td>
                                        <h3>
                                            {
                                                smartScheduleTimes.end > 0 ?
                                                    getFormattedRoundedDate(smartScheduleTimes.end) :

                                                selectedDate ?
                                                    getFormattedRoundedDate(selectedDate / 1000 + selectedPic.use_printer_duration * picQuantity + convertedSelectedTime) : ''
                                            }
                                        </h3>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {
                            schedulingMode === SCHEDULING_MODE_SMART &&
                                <img src={right_arrow} onClick={() => handleClickNextPeriod()} alt="right_arrow"/>
                        }
                        
                        {schedulingMode === SCHEDULING_MODE_WAITING && 
                            <button onClick={handleWaitingList}>
                                Add to waiting list
                            </button>
                        }
                        {schedulingMode !== SCHEDULING_MODE_WAITING &&
                        <button onClick={handleClickSchedule}
                                disabled={!selectedPic || schedulingMode === null || !selectedDate || !convertedSelectedTime}
                                className={ schedulingMode === SCHEDULING_MODE_MANUAL && selectedPic && selectedPic.slicer && selectedDate && convertedSelectedTime ? '' : 'disabled'}
                        >
                            <ProductionIcone className="production-icone" /> Schedule
                        </button>
                        }
                    </div>
                </>
            }
        </div>
    )
}

import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {CircularProgress} from "@material-ui/core";

export const SimpleDropZoneOneFile = (props) => {

    const {setAlert, uploadedFile, setUploadedFile, isUploading, setIsUploading} = props;

    const onDrop = useCallback(acceptedFiles => {
        if(acceptedFiles.length !== 1){
            setAlert({message: 'Please upload only one file.', status: "error", date: new Date()});
            return;
        }

        const file = acceptedFiles[0];
        setUploadedFile(file);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});


    return (
        !isUploading ? (
            <div {...getRootProps()} className="dropzone dropzone-small">
                <input {...getInputProps({})} />
                {
                    isDragActive ?
                        <p>Release to send...</p>
                    : uploadedFile !== null && uploadedFile.name !== undefined ?
                        <p>{uploadedFile.name}</p>
                    :
                        <p>Drag and Drop or add your files</p>
                }
            </div>
        ) : (
            <>
                <div className="progresszone">
                    <CircularProgress />
                    <p>Ongoing treatment...</p>
                </div>
            </>
        )
    )
}
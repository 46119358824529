import {ResourcesListTable} from "./ResourcesListTable";
import React from "react";
import {Loading} from "../common/Loading";

export const ResourceMaterialGroup = (props) => {

    const {colors, brands, technologies, materials, resources, successCallback, errorCallback, materialList, models,
        dataChange, setRightPartAddIsOpen, rightPartAddIsOpen, refreshState, setResourceDataToAdd, resourceDataToAdd,
        printers, resourceEmptyWeightsData} = props;

    const isFolded = JSON.parse(localStorage.getItem('folded-resources'));

    const handleHeaderClick = (materialId, technologyId) => {
        let isFolded = JSON.parse(localStorage.getItem('folded-resources'));

        const resourceRef = 'material_' + materialId + '_' + technologyId;
        isFolded[resourceRef] = JSON.parse(isFolded[resourceRef]) !== true;
        localStorage.setItem('folded-resources', JSON.stringify(isFolded));

        refreshState({});
    };

    if(JSON.parse(localStorage.getItem('folded-resources')) === null) return <Loading/>

    return (
        <div key={materialList.material.id}>
            <div className="resources__material-title">
                <span onClick={() => handleHeaderClick(materialList.material.id, materialList.material.technology.id)}>
                    Material: {materialList.material.name} - {materialList.material.technology.name}
                </span>
            </div>
            {(isFolded !== null &&!isFolded['material_' + materialList.material.id + '_' + materialList.material.technology.id]) &&
            <ResourcesListTable
                colors={colors} brands={brands} models={models} materials={materials}
                technologies={technologies} printers={printers}
                resources={resources} successCallback={successCallback} errorCallback={errorCallback}
                materialList={materialList} dataChange={dataChange}
                setResourceDataToAdd={setResourceDataToAdd} resourceDataToAdd={resourceDataToAdd}
                setRightPartAddIsOpen={setRightPartAddIsOpen} rightPartAddIsOpen={rightPartAddIsOpen}
                resourceEmptyWeightsData={resourceEmptyWeightsData}
            />
            }
        </div>
    );
}

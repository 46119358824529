import React, {useCallback, useState} from 'react'
import axios from "axios";
import {useDropzone} from 'react-dropzone'
import {LinearProgress, CircularProgress} from "@material-ui/core";

let globalUploadProgress = {};
let globalUploadTotal = 0;

export const DropZone = (props) => {

    const {accept, uploadEndpoint, createRequestBody, onFileUploaded, onFileNotUploaded, containerData} = props;

    let acceptedFiles = accept;
    if(acceptedFiles === undefined)
        acceptedFiles = [];

    const [selectedNumber, setSelectedNumber] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [, refreshState] = useState();

    const uploadFile = (file, onUploadProgress) => {
        // Create request body
        let data = new FormData();

        data.append('file', file);

        const requestBody = createRequestBody(file, containerData);
        Object.entries(requestBody).forEach(([key, value]) => {
            data.append(key, value);
        });

        // Send the request
        axios.post(process.env.REACT_APP_API_ADDRESS + uploadEndpoint, data, {
            headers: {
                'Content-Type': "multipart/form-data",
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            onUploadProgress
        })
            .then(response => {
                setIsUploading(false);

                // When upload finished without error
                if(response.status === 201 && onFileUploaded !== undefined){
                    onFileUploaded(response);
                }

                // In case of error
                else if(onFileNotUploaded !== undefined)
                    onFileNotUploaded(response);
            })
            .catch(error => {
                setIsUploading(false);

                if(onFileNotUploaded !== undefined)
                    onFileNotUploaded(error);
            });
    };

    const uploadFiles = (files) => {
        for(const file of files){
            globalUploadTotal += file.size;

            uploadFile(file, (e) => {
                globalUploadProgress[file.name] = e.loaded; // Obliged to use a global variable because of concurrency
                refreshState({}); // Hack to re-render
            });
        }
    };

    const onDrop = useCallback(acceptedFiles => {
        globalUploadProgress = {};
        globalUploadTotal = 0;
        refreshState({}); // Hack to re-render

        setSelectedNumber(acceptedFiles.length);
        setIsUploading(true);

        uploadFiles(acceptedFiles);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});


    // Compute progress
    let progress = 0;
    if(globalUploadTotal > 0 && Object.keys(globalUploadProgress).length){
        let sumUploadProgress = 0;

        for(const p of Object.values(globalUploadProgress))
            sumUploadProgress += p;

        progress = Math.min(100, sumUploadProgress / globalUploadTotal * 100);
    }

    return (
        !isUploading ? (
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps({
                    accept: acceptedFiles
                })} />
                {
                    isDragActive ?
                        <p>Release to send...</p> :
                        <p>Drag and Drop or add your files</p>
                }
            </div>
        ) : progress < 100 ? (
            <>
                <div className="progresszone">
                    <p>
                        {selectedNumber} selected files(s)
                    </p>

                    <LinearProgress variant="determinate" value={progress} />

                </div>
            </>
        ) : (
            <>
                <div className="progresszone">
                    <CircularProgress />
                    <p>Ongoing treatment...</p>
                </div>
            </>
        )
    )
}
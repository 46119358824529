import {useMemo} from "react";

export const ResourceMaterialBar = (props) => {
    const {material} = props;

    const barFillStyle = useMemo(() => {
        let style = {};
        if (material.color) {
            style.backgroundColor = material.color.hex_code;
        }
        style.width = `${(material.remaining_quantity / material.initial_quantity) * 100}%`;
        return style;
    }, [material.color, material.initial_quantity, material.remaining_quantity]);

    return (
        <div className="gc-materials__resource-material-bar gc-resource-material-bar">
            <div className="gc-resource-material-bar__fill" style={barFillStyle}></div>
        </div>
    );
};

import React, {useState} from "react";
import {getMemberColor} from "../projects/Projects";
import {Popover} from "@material-ui/core";
import {logout} from "../CommonFunctions";

export const Avatar = (props) => {

    const {user, isAdmin, size, isMenu} = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const classNames = require('classnames');

    const avatarClasses = classNames({
        'avatar': true,
        'avatar-mini': size === 'mini',
        'avatar-small': size === 'small',
        'avatar-normal-small': size === 'normal-small',
        'avatar-huge': size === 'huge',
        'avatar-with-menu': isMenu === true
    });

    const handleClickUser = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <span className={avatarClasses} onClick={handleClickUser} style={{background: getMemberColor(user)}}>
                {user.initials}
                {isAdmin && '★'}
            </span>
            {isMenu &&
                <Popover
                    id="user-actions"
                    open={open && props.isHeader}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div id="user__panel">
                        <a href="/profile" className="action-link"><i className="fa fa-user"/> Profile</a>
                        <a href="/organization" className="action-link"><i className="fa fa-users"/> Organization</a>
                        <a href="mailto:contact@handddle.com" className="action-link"><i className="fa fa-question-circle"/> Help</a>
                        <a href="/" onClick={logout} className="action-link"><i className="fa fa-sign-out-alt"/> Logout</a>
                    </div>
                </Popover>
            }
        </>
    )
}

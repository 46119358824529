import {PicActionsMenu} from "./PicActionsMenu";
import {Whisper} from "rsuite";
import React from "react";

export const PicActionsMenuButton = (props) => {

    const {elementId, type, handleDeletePic, handleDuplicatePic, handleManagePicProjects,
        handleCreatePicFromTemplate, handleDuplicateTemplate} = props;

    return (
        <Whisper placement="bottomEnd" trigger="click"
                 speaker={(props, ref) => PicActionsMenu({
                     elementId: elementId,
                     type: type,
                     handleDeletePic: handleDeletePic,
                     handleDuplicatePic: handleDuplicatePic,
                     handleManagePicProjects: handleManagePicProjects,
                     handleCreatePicFromTemplate: handleCreatePicFromTemplate,
                     handleDuplicateTemplate: handleDuplicateTemplate,
                     ...props},
                     ref)
                 }>
                    <span className="action-buttons">
                        <i className="fa fa-ellipsis-v"/>
                    </span>
        </Whisper>
    );
}

import {fetchData} from "./useFetch";
import {AUTOMATION_TOKEN_KEY} from "../services/automation/AutomationService";

export const getSmartFarmAutomationServerTokenByUser = (userData) => {
    fetchData('organizations/' + userData.organization.id + '/automation-token')
        .then(response => {
            response.json().then(data => {
                if(data && data.smart_farm_automation_server_token) {
                    // Start socket server connection and connect to events
                    localStorage.setItem(AUTOMATION_TOKEN_KEY, data.smart_farm_automation_server_token);
                }
            });
        })
        .catch(() => localStorage.removeItem(AUTOMATION_TOKEN_KEY));
}
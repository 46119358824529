const token = localStorage.getItem('token');

/**
 * Generic function to patch data
 * @param path
 * @param body
 * @returns {Promise<any>}
 */
export const patchData = (path, body) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + path, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => {
        if(response.ok) {
            return response.json();
        }
        throw Error(response.status.toString());
    });
}

/**
 * Generic function to post data
 * @param path
 * @param body
 * @return {Promise<any>}
 */
export const postData = (path, body) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + path, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => {
        if(response.ok) {
            return response.json();
        }
        throw Error(response.status.toString());
    });
}
import React from "react";
import {HeaderTabs} from './common/HeaderTabs'
import {BrowserRouter as Router, Route, Switch, useRouteMatch} from "react-router-dom";
import {Dashboard} from "./dashboard/Dashboard";
import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient();

export const DashboardMain = () => {
    let {path} = useRouteMatch();

    const headerLinks = [
        {label: 'Dashboard', path: 'dashboard'}
    ];

    return (
        <Router>
            <HeaderTabs links={headerLinks}/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">
                    <Switch>
                        <Route exact path={path}>
                            <QueryClientProvider client={queryClient}>
                                <Dashboard/>
                            </QueryClientProvider>
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    )
}
import {useEffect, useState} from "react";
import {getSortedData} from "../../services/TablesService";
import {getFormattedDate} from "../CommonFunctions";

export const ResourceDataBlock = (props) => {

    const {resources} = props;

    const [referencesQuantity, setReferencesQuantity] = useState(0);
    const [resourcesQuantity, setResourcesQuantity] = useState(0);
    const [lastUsedResource, setLastUsedResource] = useState(null);

    /* Get a formatted reference name - Result syntax : Material Technology (Brand - Color) */
    const getCompleteReferenceFormattedName = (reference) => {
        let name = '';
        if(reference.children && reference.children[0]) {
            name = reference.children[0].material.name + ' ' + reference.children[0].material.technology.name +
                ' (' + reference.children[0].brand.name;
            if(reference.children[0].color) {
                name += ' - ' + reference.children[0].color.color_name;
            }
            name += ')';
        }
        return name;
    }

    /* Get a formatted material name - Result syntax : Material Technology */
    const getFormattedMaterialName = (resource) => {
        return resource.material.name + ' ' + resource.material.technology.name;
    }

    /* Get a formatted reference name - Result syntax : Brand - Color */
    const getFormattedReferenceName = (resource) => {
        let referenceName = resource.brand.name;
        if(resource.color) {
            referenceName += ' - ' + resource.color.color_name;
        }
        return referenceName;
    }

    useEffect(() => {
        // Material types (= reference) quantity and resources quantity
        let refQuantity = 0;
        let resourcesQuantity = 0;
        // Last used resource
        let lastResource = null;

        // Get data
        resources.map(material => {
            refQuantity += material.materialTypes.length;
            material.materialTypes.map(materialType => {
                resourcesQuantity += materialType.children.length;
                // Find last used resource
                materialType.children.map(resource => {
                    if(resource.task_resources.length && resource.task_resources.length > 0) {
                        resource.task_resources.map(taskResource => {
                            if(lastResource === null ||
                                (lastResource.date < taskResource.task.date && taskResource.validated)) {
                                    lastResource = {
                                        date: taskResource.task.date,
                                        batchNumber: resource.serial_number,
                                        material: getFormattedMaterialName(resource),
                                        reference: getFormattedReferenceName(resource)
                                    }
                            }
                        });
                    }
                });
            });
        });

        // Set data
        setReferencesQuantity(refQuantity);
        setResourcesQuantity(resourcesQuantity);
        setLastUsedResource(lastResource);

    }, [resources]);

    return (
        <div id="main__resource_datablock">

                <div className="datablock-display">
                    <div className="datablock">
                        <h2 className="datablock-title">Resources quantity</h2>
                        {resourcesQuantity > 0 &&
                            <div className="datablock-content">
                                <p><span className="datablock-number">{resources.length}</span> <span>materials</span></p>
                                <p><span className="datablock-number">{referencesQuantity}</span> <span>references</span></p>
                                <p><span className="datablock-number">{resourcesQuantity}</span> <span>resources</span></p>
                            </div>
                        }
                        {resourcesQuantity === 0 &&
                            <p className="datablock-small-text">No stock</p>
                        }
                    </div>
                    <div className="datablock">
                        <h2 className="datablock-title">Last used resource</h2>
                        <div className="datablock-content">
                            {lastUsedResource !== null &&
                                <div>
                                    <p className="datablock-batchnumber">{lastUsedResource.batchNumber}</p>
                                    <p className="datablock-date">{getFormattedDate(lastUsedResource.date)}</p>
                                    <div className="datablock-resource-name">
                                        <p className="datablock-resource-material">{lastUsedResource.material}</p>
                                        <p>({lastUsedResource.reference})</p>
                                    </div>
                                </div>
                            }
                            {lastUsedResource === null &&
                                <p className="datablock-small-text">No used resource</p>
                            }
                        </div>
                    </div>
                </div>

        </div>
    );
}

import React, {useState, useCallback} from "react";
import {ReactComponent as DimensionalViewButton} from "../../../images/icons/pic/visibility_black_24dp.svg";
import {PlatformDropZone} from "../PlatformDropZone";
import {getCurrentUser, humanFileSize} from "../../CommonFunctions";
import {PieceViewer} from "../../viewer/PieceViewer";
import {Loading} from "../../common/Loading";
import {Drawer, makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    paper: {
        left: "50%",
        background: "#f5f5f5"
    },
});

export const CardPlatform = (props) => {
    const classes = useStyles();

    const {picCard, setIsActive, refreshGlobalState, handleContinueCard, updateUntitledPicNameFromStl} = props;

    const card = picCard.card;

    const [, refreshState] = useState();

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [pieceData, setPieceData] = useState(null);

    const onPieceUploaded = useCallback((response) => {
        card.card_platform_pieces = [
            ...card.card_platform_pieces,
            {copies: 1, piece: response.data}
        ]
        if(response.data && response.data.name) {
            const name = response.data.name.split(".")[0];
            updateUntitledPicNameFromStl(name);
        }
        refreshState({}); // Hack to re-render
    }, [card.card_platform_attachments]);

    const onPieceNotUploaded = useCallback((response) => {
        alert('Piece not uploaded!');
    }, []);

    const createRequestBody = useCallback((file) => {
        return {
            user: process.env.REACT_APP_API_URI + `users/${getCurrentUser().id}`,
            sent: 1,
            name: file.name,
            size: file.size
        };
    }, []);

    const handleClickContinue = () => {
        handleContinueCard(picCard);
        setIsActive(false);
    }

    const onAttachmentUploaded = useCallback((response) => {
        card.card_platform_attachments = [
            ...card.card_platform_attachments,
            {attachment: response.data}
        ]
        refreshState({}); // Hack to re-render
    }, [card.card_platform_attachments]);

    const onAttachmentNotUploaded = useCallback((response) => {
        alert("Attachment not uploaded!");
    }, []);

    let totalPieceFile = 0;
    card.card_platform_pieces.forEach((piece) => {
        totalPieceFile += piece.copies;
    })

    return (
        <>
            <div className="main__card-platform">
                <PlatformDropZone
                    uploadEndpoint={'pieces'}
                    createRequestBody={createRequestBody}
                    onFileUploaded={onPieceUploaded}
                    onFileNotUploaded={onPieceNotUploaded}
                    uploadedPieces={card.card_platform_pieces}
                />
                {card.card_platform_pieces.length > 0 &&
                    <>
                        <div className="list-files">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{width: '40%'}}><h2>Name</h2></th>
                                        <th style={{width: '15%'}}><h2>Type</h2></th>
                                        <th style={{width: '15%'}}><h2>Quantity</h2></th>
                                        <th style={{width: '15%'}}><h2>Size</h2></th>
                                        <th style={{width: '15%'}}><h2>3D</h2></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {card.card_platform_pieces.map((pPiece, index) => (
                                        <tr key={index}>
                                            <td title={pPiece.name ? pPiece.name : pPiece.piece.name ? pPiece.piece.name : ''}>
                                                {pPiece.name ? pPiece.name : pPiece.piece.name ? pPiece.piece.name : ''}
                                            </td>
                                            <td>STL</td>
                                            <td><input type="number" min="1" defaultValue={pPiece.copies ? pPiece.copies : 1} onChange={(e) => {
                                                pPiece.copies = parseInt(e.target.value)
                                                refreshState({});
                                            }}/>
                                            </td>
                                            <td>{humanFileSize(pPiece.piece.size, true)}</td>
                                            <td>
                                                <DimensionalViewButton
                                                    onClick={(e) => {
                                                        setPieceData(pPiece.piece);
                                                        setRightPartIsOpen(!rightPartIsOpen);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <p>Your build platform contains {totalPieceFile} part(s).</p>

                        {card.card_platform_attachments && card.card_platform_attachments.length ?
                            <>
                                <div className="list-files">
                                    <table style={{width: "50%"}}>
                                        <thead>
                                            <tr>
                                                <th width="70%">
                                                    <h2>Attachments</h2>
                                                </th>
                                                <th>
                                                    <h2>Size</h2>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {card.card_platform_attachments.map((card_platform_attachment, index ) => (
                                                <tr key={index}>
                                                    <td title={card_platform_attachment.attachment.name}>{card_platform_attachment.attachment.name}</td>
                                                    <td>{humanFileSize(card_platform_attachment.attachment.size, true)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        
                                    </table>
                                </div>
                                <p>
                                    Your build platform contains {card.card_platform_attachments.length} attachment(s).
                                </p>
                            </>
                        
                        : ""
                        }
                        <div className="button-zone">
                            {/* Button upload attachments */}
                            <PlatformDropZone
                                uploadEndpoint={"attachments"}
                                createRequestBody={createRequestBody}
                                onFileUploaded={onAttachmentUploaded}
                                onFileNotUploaded={onAttachmentNotUploaded}
                                uploadedPieces={card.card_platform_attachments}
                            />
                            <button onClick={handleClickContinue}><h2>Continue</h2></button>
                        </div>
                    </>
                }
            </div>

            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)} classes={{paper: classes.paper}}>
                {
                    pieceData != null
                        ? <PieceViewer piece={pieceData}/>
                        : <Loading/>
                }
            </Drawer>
        </>
    )
}

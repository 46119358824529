import React from "react";
import {QueryClient, QueryClientProvider} from 'react-query';
import {HeaderTabs} from "./common/HeaderTabs";
import {PicManager} from "./pic/pic-manager/PicManager";

const queryClient = new QueryClient();

export const PicManagerMain = () => {

    const headerLinks = [
        {label: 'PIC Manager', path: 'manager'}
    ];

    return (
        <>
            <HeaderTabs links={headerLinks}/>

            <div id="main-grid__item--main-content" className="main__pic-manager">
                <div id="main-content">
                    <QueryClientProvider client={queryClient}>
                        <PicManager/>
                    </QueryClientProvider>
                </div>
            </div>
        </>
    )
}

import {COMMAND_TIMEOUT_LIMIT} from "../FarmService";
import {getCurrentTimestamp} from "../../components/CommonFunctions";

export const commandsInProcessReducer = (state, action) => {
    if(action.type === 'add-command') {
        return addCommandToCommandsInProcess(state, action.value);
    }
    if(action.type === 'remove-command') {
        return deleteCommandInCommandsInProcess(state, action.value);
    }
    if(action.type === 'update-time') {
        return updateCommandsInProcessTime(state, action.value);
    }
    return state;
}

/**
 * Add command by system code in commands in process object
 * @param state
 * @param newCommand
 * @returns {*}
 */
export const addCommandToCommandsInProcess = (state, newCommand) => {
    if(state[newCommand.systemCode]) {
        state[newCommand.systemCode][newCommand.commandType] = {value: newCommand.commandValue, time: getCurrentTimestamp()};
    } else {
        state[newCommand.systemCode] = {};
        state[newCommand.systemCode][newCommand.commandType] = {value: newCommand.commandValue, time: getCurrentTimestamp()};
    }
    return state;
}

/**
 * Delete system code command in commands in process object
 * @param state
 * @param commandToDelete
 * @returns {*}
 */
export const deleteCommandInCommandsInProcess = (state, commandToDelete) => {
    if(state[commandToDelete.systemCode] && state[commandToDelete.systemCode][commandToDelete.commandType]) {
        delete state[commandToDelete.systemCode][commandToDelete.commandType];
    }
    return state;
}

/**
 * Update all commands stored in commands in process depending on their success / failure
 * @param state
 * @param smartFarmData
 * @returns {*}
 */
export const updateCommandsInProcessTime = (state, smartFarmData) => {
    // For all system codes with commands running
    for(let [systemCode, cellCommands] of Object.entries(state)) {
        // For all commands corresponding to a system code
        for(let [commandType, commandContent] of Object.entries(cellCommands)) {
            // If influxdb sent data corresponding to that command
            if(smartFarmData && smartFarmData[systemCode] && smartFarmData[systemCode][commandType]) {
                // Delete command in state if command has been processed or command has been timeout (30 seconds since command has been sent)
                if(smartFarmData[systemCode][commandType] === commandContent.value || ((getCurrentTimestamp() - commandContent.time) > COMMAND_TIMEOUT_LIMIT)) {
                    if(Object.keys(state[systemCode]).length === 1) {
                        delete state[systemCode];
                    } else {
                        delete state[systemCode][commandType];
                    }
                }
            }
        }
    }
    return state;
}
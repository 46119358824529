import {getCurrentTimestamp, getCurrentUser, setCurrentUserInLocalStorage} from "../components/CommonFunctions";

const token = localStorage.getItem('token');
const currentUser = getCurrentUser();

export const getUserByDigitCode = async (digitCode) => {
    return await fetch(process.env.REACT_APP_API_ADDRESS + 'users?' + new URLSearchParams({
        digitCode: digitCode
    }), {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCurrentUser()}`
        }
    }).then(r => r.json());
};

export const postUser = async (dataUser) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + "users", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            color: process.env.REACT_APP_API_URI + 'resource_colors/1',
            email: dataUser.email,
            firstname: dataUser.firstname,
            lastname: dataUser.lastname,
            roles: ['ROLE_USER'],
            organization: process.env.REACT_APP_API_URI + `organizations/${dataUser.organization}`,
            password: dataUser.password,
            digit_code: '----',
            phone: null,
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchUser = async (dataUser, callback) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `users/${dataUser.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            firstname: dataUser.firstname,
            lastname: dataUser.lastname,
            email: dataUser.email,
            phone: dataUser.phone,
            // user_group: process.env.REACT_APP_API_URI + dataUser.organization.user_group,
            color: process.env.REACT_APP_API_URI + `resource_colors/${dataUser.color}`,
            digit_code: dataUser.digit_code
        })
    }).then(response =>
        response.json()
    ).then((response) => {
        if(response.id){
            setCurrentUserInLocalStorage(response);
        }
        else
            throw Error;
    })
}

export const confirmUser = async (code, user) => {

    fetch(process.env.REACT_APP_API_ADDRESS + `new_users?token=${user.token}&confirmationCode=${code}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.json())
        .then((data) => {
            if(!data.ok)
                throw Error;
            if(data.length)
                window.location = `/register/${data[0].token}`
        })
}

export const changePassword = async (data, userId=null) => {

    let userIdToChange = currentUser.id;
    if(userId !== null)
        userIdToChange = userId;

    return fetch(process.env.REACT_APP_API_ADDRESS + `users/${userIdToChange}/password`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
    })
}

export const shareLibrary = async (dataUser) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `users/${dataUser.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            share_library: !dataUser.share_library,
        })
    }).then(response =>
        response.json()
    ).then((response) => {
        console.log(response)
        if(response.id){
            localStorage.setItem('user', JSON.stringify(response));
        }
        else
            throw Error;
    })
}

export const patchOrganizationGroupStockAlert = async (orgaId, stock_alert) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `organization_groups/${orgaId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            stock_alert: stock_alert
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchWorkingHours = async (orgaId, openTimeInSec, closeTimeInSec) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `organizations/${orgaId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            openTime: openTimeInSec,
            closeTime: closeTimeInSec
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const readNotification = async (notification, redirect=true) => {
    // If notification not read, read it
    if(!notification.read){
        await fetch(process.env.REACT_APP_API_ADDRESS + `notifications/${notification.id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/merge-patch+json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                read_date: getCurrentTimestamp()
            })
        });
    }

    if(redirect)
        window.location = notification.link;
};

export const readAllNotifications = (notifications) => {
    let promises = [];

    for(const notification of notifications.filter(notification => !notification.read)){
        promises.push(fetch(process.env.REACT_APP_API_ADDRESS + `notifications/${notification.id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/merge-patch+json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                read_date: getCurrentTimestamp()
            })
        }));
    }

    if(promises.length > 0){
        Promise.all(promises).then(reponses => {
            window.location.reload();
        }).catch(error => window.location.reload());
    }
}

import {getCurrentTimestamp} from "../../components/CommonFunctions";
import {requestAutoScheduling} from "../../api/apiAutoSchedule";
import {patchTaskSmartScheduling} from "../../api/apiGantt";

/**
 * Smart schedule tasks
 * @param tasksIncoming
 * @returns {Promise<*>}
 */
export const smartScheduleTasks = (tasksIncoming) => {
    // Request smart scheduling
    if (tasksIncoming.length > 0) {
        return requestAutoScheduling(tasksIncoming, 0, 0)
            .then(response => {
                if (response.success) {
                    // Patch all rescheduled tasks
                    return patchRescheduledTasks(response.scheduled_tasks)
                        .then((responses) => Promise.all(responses.map(r => r.json())))
                        .then(data => data)
                        .catch(() => Promise.reject('Tasks could not be updated'));
                } else {
                    return Promise.reject('Tasks could not be scheduled');
                }
            })
            .catch(() => Promise.reject('Tasks could not be scheduled'))
    }
    return Promise.resolve([]);
}

export const getTasksIncoming = (ganttTasks, externalPrintTasks, maintenances) => {
    ganttTasks = removeExternalTasksInGanttTasks(ganttTasks, externalPrintTasks);

    let tasksIncoming = [];
    // Check if there are tasks to schedule
    if (ganttTasks.length > 0) {
        let currentTimestamp = getCurrentTimestamp();
        let firstTaskDate = currentTimestamp;
        let lastExternalTaskEnd = 0;

        // Get gantt tasks to schedule
        tasksIncoming = ganttTasks.filter(task => {
            const endDate = task.date + task.print_duration;
            // Check if task is a gantt task and ends after now
            const isGanttTaskToSchedule = task.pic && currentTimestamp < endDate;
            if (isGanttTaskToSchedule && task.date < firstTaskDate) {
                firstTaskDate = task.date;
            }
            // If linked task, fix scheduling
            if (isGanttTaskToSchedule && task.start_date_detected) {
                task.is_fixed_scheduling = true;
                if ((task.end_date_detected && (task.end_date_detected > lastExternalTaskEnd)) || endDate > lastExternalTaskEnd) {
                    lastExternalTaskEnd = task.end_date_detected ? task.end_date_detected : endDate;
                }
            }
            return isGanttTaskToSchedule;
        });

        // Add external print tasks which ends after first gantt task starts
        externalPrintTasks && externalPrintTasks.map(task => {
            if (((task.pic === undefined || task.pic === null) && task.start_date_detected) &&
                ((task.end_date_detected > firstTaskDate) || (firstTaskDate < currentTimestamp))) {
                tasksIncoming.push(formatTaskForSchedule(task.id, task.date, true, task.print_duration, true));
                // Get last external print task end date
                if ((task.end_date_detected && task.end_date_detected > lastExternalTaskEnd) || currentTimestamp > lastExternalTaskEnd) {
                    lastExternalTaskEnd = task.end_date_detected ? task.end_date_detected : currentTimestamp;
                }
            }
        });

        maintenances && maintenances.map(maintenance => {
           if(maintenance.start_date > firstTaskDate || (firstTaskDate < currentTimestamp)) {
               const endDate = maintenance.end_date ? maintenance.end_date : getCurrentTimestamp();
               tasksIncoming.push(formatTaskForSchedule(maintenance.id, maintenance.start_date, true,
                   endDate - maintenance.start_date, true));
               if ((endDate > lastExternalTaskEnd) || currentTimestamp > lastExternalTaskEnd) {
                   lastExternalTaskEnd = endDate;
               }
           }
        });

        // Remove is_fixed_scheduling on tasks starting before external or linked task ends
        tasksIncoming.map(task => {
            if (!task.start_date_detected && task.pic && task.date < lastExternalTaskEnd) {
                task.is_fixed_scheduling = false;
            }
        });
    }

    return tasksIncoming;
}

const removeExternalTasksInGanttTasks = (ganttTasks, externalTasks) => {
    return ganttTasks.filter(ganttTask => {
       for(let externalTask of externalTasks) {
           if(externalTask.id === ganttTask.id) {
               return false;
           }
       }
       return true;
    });
}

/**
 * Patch rescheduled tasks start date
 * @param tasks
 * @returns {Promise<*[]>}
 */
const patchRescheduledTasks = (tasks) => {
    let promises = [];
    for (let i = 0; i < tasks.length; i++) {
        let task = tasks[i];
        task.date = task.start;
        if (task.id >= 0) {
            promises.push(patchTaskSmartScheduling(task));
        }
    }
    return Promise.all(promises);
}

/**
 * Format task for scheduling
 * @param id
 * @param date
 * @param priority
 * @param printDuration
 * @param isFixedScheduling
 * @returns {{date, print_duration, id, priority, is_fixed_scheduling}}
 */
const formatTaskForSchedule = (id, date, priority, printDuration, isFixedScheduling) => {
    return {
        id: id,
        date: date,
        priority: priority,
        print_duration: printDuration,
        is_fixed_scheduling: isFixedScheduling
    };
}
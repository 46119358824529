import React from "react";
import {TaskActionsPrinting} from "./TaskActionsPrinting";

export const TaskActions = (props) => {
    const {task, successCallback, errorCallback, setIsTaskLoading, controllablePrinter, setAlert,
        getControllablePrinter} = props;

    return(
        <div>
            <div className="block">
                <h2>Slot management</h2>
                {
                    !task.data.end_validated ?
                        <TaskActionsPrinting task={task} successCallback={successCallback} errorCallback={errorCallback}
                                             setIsTaskLoading={setIsTaskLoading} controllablePrinter={controllablePrinter}
                                             setAlert={setAlert} getControllablePrinter={getControllablePrinter}/>
                    :
                        <h3>Done</h3>
                }
            </div>
        </div>
    )
}

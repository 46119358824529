import React from "react";
import {getCurrentDateAtMidnight} from "../../../utils/date";
import {Resources} from "./resources/Resources";
import {Tasks} from "./tasks/Tasks";
import {Toolbar} from "./toolbar/Toolbar";

export const DISPLAY_MODES = {
    DAY: "Day",
    WEEK: "Week",
    MONTH: "Month",
};

/* The component is declared as class, because the callback function of "setState()" is needed by the "TasksRow" child component. */
export class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.printers = props.printers;
        this.schedulePic = props.schedulePic;
        this.setAlert = props.setAlert;
        this.setRightPartDetailsIsOpen = props.setRightPartDetailsIsOpen;
        this.setTaskId = props.setTaskId;
        this.setIsWorkloadDialogOpen = props.setIsWorkloadDialogOpen;
        this.setPrinterTaskWorkload = props.setPrinterTaskWorkload;
        this.setSelectedPrinterSchedule = props.setSelectedPrinterSchedule;
        this.taskResult = props.taskResult;
        this.organization = props.organization;
        this.dissociateTask = props.dissociateTask;
        this.externalPrintTasks = props.externalPrintTasks;
        this.isDissociateModalOpen = props.isDissociateModalOpen;
        this.setIsDissociateModalOpen = props.setIsDissociateModalOpen;
        this.reloadCalendar = props.reloadCalendar;
        this.handleOpenFastPrintDialog = props.handleOpenFastPrintDialog;

        this.state = {
            displayMode: localStorage.displayMode ? localStorage.displayMode : DISPLAY_MODES.DAY,
            firstDisplayedDate: localStorage.firstDisplayedDate ? new Date(localStorage.firstDisplayedDate) : getCurrentDateAtMidnight(),
            isFirstDisplayedDateUpdated: false,
            previousFirstDisplayedDate: null,
            tasksSelected: [],
            // Peak-off State
            isOffPeakHoursVisible: localStorage.isOffPeakHoursVisible ? JSON.parse(localStorage.isOffPeakHoursVisible) : true,
            // State for filterByPrinter
            selectedBrand: '',
            arrayFilteredPrinter: [],
        };

        this.setDisplayMode = this.setDisplayMode.bind(this);
        this.setFirstDisplayedDate = this.setFirstDisplayedDate.bind(this);
        this.setPreviousFirstDisplayedDate = this.setPreviousFirstDisplayedDate.bind(this);
        this.setIsFirstDisplayedDateUpdated = this.setIsFirstDisplayedDateUpdated.bind(this);
        this.selectNextDate = this.selectNextDate.bind(this);
        this.setTasksSelected = this.setTasksSelected.bind(this);
        this.setIsOffPeakHoursVisible = this.setIsOffPeakHoursVisible.bind(this);
        this.setSelectedBrand = this.setSelectedBrand.bind(this);
        this.setArrayFilteredPrinter = this.setArrayFilteredPrinter.bind(this);
        this.onBrandChangeHandler = this.onBrandChangeHandler.bind(this);
    }

    /* Properly set the first displayed date and the previous one, according to the initial display mode. */
    componentDidMount() {
        this.updateFirstDisplayDate(this.isOffPeakHoursVisible);
    }
    componentDidUpdate(previousProps, previousState){
        if(this.state.isOffPeakHoursVisible !== previousState.isOffPeakHoursVisible)
        this.updateFirstDisplayDate();
        this.printers = this.props.printers;
    }

    updateFirstDisplayDate(){
        const date = localStorage.firstDisplayedDate ? new Date(localStorage.firstDisplayedDate) : getCurrentDateAtMidnight();
        let openTime = this.organization.open_time/3600;
        date.setHours(openTime, 0, 0, 0);

        if(this.state.isOffPeakHoursVisible){
            this.setFirstDisplayedDate(new Date(this.state.firstDisplayedDate.setHours(0,0,0,0)));
            this.setIsFirstDisplayedDateUpdated(true);
        } else {
            this.setFirstDisplayedDate(date);
            this.setIsFirstDisplayedDateUpdated(true);
        }
    }

    setIsFirstDisplayedDateUpdated(newState){
        this.setState({isFirstDisplayedDateUpdated: newState})
    }

    setIsOffPeakHoursVisible(newIsOffPeakHoursVisible){
        this.setState({isOffPeakHoursVisible: newIsOffPeakHoursVisible})
        this.setFirstDisplayedDate(this.state.firstDisplayedDate);
        this.onBrandChangeHandler();
    }

    setTasksSelected(newArrayTasksSelected){
        this.setState({tasksSelected: newArrayTasksSelected})
    }
    setSelectedBrand(newSelectedBrand){
        this.setState({selectedBrand: newSelectedBrand})
    }

    setArrayFilteredPrinter(newArrayFilteredPrinter){
        this.setState({arrayFilteredPrinter: newArrayFilteredPrinter})
    }

    setDisplayMode(mode) {
        this.setState({displayMode: mode}, () => {
            let newFirstDisplayedDate;
            switch (this.state.displayMode) {
                case DISPLAY_MODES.MONTH:
                    newFirstDisplayedDate = this.state.firstDisplayedDate;
                    break;
                case DISPLAY_MODES.DAY:
                case DISPLAY_MODES.WEEK:
                default:
                    newFirstDisplayedDate = this.state.previousFirstDisplayedDate;
            }
            this.setFirstDisplayedDate(newFirstDisplayedDate);
        });
    }

    /** Fires when a new printer brand is selected from the select input */
    onBrandChangeHandler = () => {
        let ArrayOfPrinterFilteredByBrand = []
        this.printers.map(printer => {
            if (printer.brand.name === this.state.selectedBrand) {
                ArrayOfPrinterFilteredByBrand.push(printer);
            }
        })
        this.setArrayFilteredPrinter(ArrayOfPrinterFilteredByBrand);
    }

    setFirstDisplayedDate(date, callback) {
        this.setPreviousFirstDisplayedDate(new Date(date));
        if (this.state.displayMode === DISPLAY_MODES.MONTH) {
            date.setDate(1);
        }
        const newState = {firstDisplayedDate: new Date(date)};
        if (callback) {
            this.setState(newState, callback(date));
        } else {
            this.setState(newState);
        }
    }

    setPreviousFirstDisplayedDate(date) {
        this.setState({previousFirstDisplayedDate: date});
    }

    selectNextDate(reverse, callback) {
        const offset = reverse ? -1 : +1;
        const nextDate = new Date(this.state.firstDisplayedDate);
        switch (this.state.displayMode) {
            case DISPLAY_MODES.DAY:
                nextDate.setDate(nextDate.getDate() + offset);
                break;
            case DISPLAY_MODES.WEEK:
                nextDate.setDate(nextDate.getDate() + offset * 7);
                break;
            case DISPLAY_MODES.MONTH:
                nextDate.setMonth(nextDate.getMonth() + offset);
                this.setPreviousFirstDisplayedDate(nextDate);
                break;
            default:
        }
        this.setFirstDisplayedDate(nextDate, callback);
    }

    render() {
        return (
            <div className="gantt-calendar">
                {this.printers.length > 0 ? (
                    <>
                        <Toolbar
                            displayMode={this.state.displayMode}
                            setDisplayMode={this.setDisplayMode}
                            firstDisplayedDate={this.state.firstDisplayedDate}
                            setFirstDisplayedDate={this.setFirstDisplayedDate}
                            selectNextDate={this.selectNextDate}
                            schedulePic={this.schedulePic}
                            setSelectedPrinterSchedule={this.setSelectedPrinterSchedule}
                            handleOpenFastPrintDialog={this.handleOpenFastPrintDialog}
                        />
                        <div className="gantt-calendar__main-container gc-main-container">
                            <Resources
                                printers={this.printers}
                                tasksSelected={this.state.tasksSelected}
                                setTasksSelected={this.setTasksSelected}
                                setAlert={this.setAlert}
                                setIsWorkloadDialogOpen={this.setIsWorkloadDialogOpen}
                                setPrinterTaskWorkload={this.setPrinterTaskWorkload}
                                selectedBrand={this.state.selectedBrand}
                                setSelectedBrand={this.setSelectedBrand}
                                arrayFilteredPrinter={this.state.arrayFilteredPrinter}
                                onBrandChangeHandler={this.onBrandChangeHandler}
                                firstDisplayedDate={this.state.firstDisplayedDate}
                                externalPrintTasks={this.props.externalPrintTasks}
                                reloadCalendar={this.reloadCalendar}
                            />
                            <Tasks
                                displayMode={this.state.displayMode}
                                firstDisplayedDate={this.state.firstDisplayedDate}
                                selectNextDate={this.selectNextDate}
                                printers={this.props.printers}
                                schedulePic={this.schedulePic}
                                tasksSelected={this.state.tasksSelected}
                                setTasksSelected={this.setTasksSelected}
                                setRightPartDetailsIsOpen={this.setRightPartDetailsIsOpen}
                                setTaskId={this.setTaskId}
                                setAlert={this.setAlert}
                                setIsOffPeakHoursVisible={this.setIsOffPeakHoursVisible}
                                isOffPeakHoursVisible={this.state.isOffPeakHoursVisible}
                                setIsFirstDisplayedDateUpdated={this.setIsFirstDisplayedDateUpdated}
                                selectedBrand={this.state.selectedBrand}
                                arrayFilteredPrinter={this.state.arrayFilteredPrinter}
                                setSelectedPrinterSchedule={this.setSelectedPrinterSchedule}
                                taskResult={this.taskResult}
                                organization={this.props.organization}
                                dissociateTask={this.props.dissociateTask}
                                externalPrintTasks={this.props.externalPrintTasks}
                                isDissociateModalOpen={this.props.isDissociateModalOpen}
                                setIsDissociateModalOpen={this.props.setIsDissociateModalOpen}
                                reloadCalendar={this.reloadCalendar}
                            />
                        </div>
                    </>
                ) : (
                    <h3 className="gantt-calendar__text">Your organization group does not have any printer.</h3>
                )}
            </div>
        );
    }
}

import React, {useState} from 'react';
import {deleteMaterialData} from "../../../api/apiBackoffice";
import {Drawer} from "@material-ui/core";
import {Loading} from "../../common/Loading";
import {MaterialDataForm} from "./MaterialDataForm";

export const MaterialsData = (props) => {

    const {materialsData, resourceEmptyWeightsData} = props;

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [alert, setAlert] = useState();
    const [actualMaterialData, setActualMaterialData] = useState(null);

    const successCallback = (message) => {
        materialsData.refetch().then(() => {
            resourceEmptyWeightsData.refetch();
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        materialsData.refetch().then(() => {
            resourceEmptyWeightsData.refetch();
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    const handleDelete = async (materialId) => {
        if(window.confirm('Are you sure you want to delete this Material Data ?'))
            await deleteMaterialData(materialId)
                .then(() => successCallback('This Material Data has been deleted.'))
                .catch(() => errorCallback('Something went wrong.'));
    };

    const handleClose = () => {
        setActualMaterialData(null);
        setRightPartIsOpen(!rightPartIsOpen);
    }

    if(materialsData.isLoading) return <Loading/>

    return (
        <div className="backoffice_block" style={{display: "block", border: "2px solid lightgrey", padding: "15px"}}>
            <div className="resource_header">
                <h2>Materials Data</h2>
                <button className="button-link" onClick={() => {
                    setRightPartIsOpen(!rightPartIsOpen);
                }}>
                    <i className="fa fa-plus"/> Add a material data
                </button>
            </div>
            {materialsData.data ? (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {materialsData.data.map((material) => (
                            <tr key={material.id}>
                                <td>{material.name}</td>
                                <td>
                                    <button className="button-link" onClick={()=> {
                                        setActualMaterialData(material);
                                        setRightPartIsOpen(!rightPartIsOpen);
                                    }}>
                                        <i className="fas fa-edit" />
                                    </button>
                                    &nbsp;
                                    <button className="button-link" onClick={()=> handleDelete(material.id)}><i className="fas fa-trash-alt" /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <Loading />
            )}
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => handleClose()}>
                <div className="backoffice_drawer_content" style={{width:"500px", "marginTop":"30px"}}>
                    <MaterialDataForm
                        actualMaterialData={actualMaterialData} setActualMaterialData={setActualMaterialData}
                        setRightPartIsOpen={setRightPartIsOpen}
                        successCallback={successCallback} errorCallback={errorCallback}
                    />
                </div>
            </Drawer>
        </div>
    )
}

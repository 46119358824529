import React, {useState} from "react";
import {AddProjectComponent} from "./AddProject";
import {useGetData} from "../../api/useFetch";
import {CurrentProjectsComponent} from "./CurrentProjects";
import {ArchivedProjectsComponent} from "./ArchivedProjects";
import {getCurrentUser, isUserGranted} from "../CommonFunctions";
import {AlertMessage} from "../common/AlertMessage";

/* TODO This function should be used in the Avatar component */
export const getMemberClass = (member, project) => {
    let className = "project__member";

    if(member.id === project.user.id)
        className += " member--admin";
    if(member.state)
        className += " member--unsubscribed";

    return className;
}

/* TODO This function should be defined in the backend */
export const getMemberColor = (member) => {
    let color;

    if(!member.state && member.color !== undefined)
        color = member.color.hex_code;
    else
        color = '#FFFFFF';

    return color;
}

export const Projects = () => {

    let currentUser = getCurrentUser();
    const ownedProjects = useGetData('ownedProjectsData', `projects?user.id=${currentUser.id}`);
    const userInProjects = useGetData('userInProjectsData', `projects?usersInProject.user.id=${currentUser.id}`);

    const [alert, setAlert] = useState();

    const successCallback = (message) => {
        ownedProjects.refetch().then(() => {
            userInProjects.refetch().then(() => {
                setAlert({message: message, status: "success", date: new Date()});
            })
        });
    }

    const errorCallback = (message) => {
        ownedProjects.refetch().then(() => {
            userInProjects.refetch().then(() => {
                setAlert({message: message, status: "error", date: new Date()});
            })
        });
    };
    
    return (
        <div id="main__projects">
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}
            <div id="projects__projects-list">
                {isUserGranted('ROLE_CREATE_PROJECT') &&
                    <AddProjectComponent successCallback={successCallback} errorCallback={errorCallback}/>
                }
                <CurrentProjectsComponent
                    ownedProjects={ownedProjects}
                    userInProjects={userInProjects}
                    getMemberClass={getMemberClass}
                    getMemberColor={getMemberColor}
                />
            </div>
            <>
                <h2>Archived projects</h2>
                <div id="projects__archived-projects-list">
                    <ArchivedProjectsComponent
                        ownedProjects={ownedProjects}
                        userInProjects={userInProjects}
                        getMemberClass={getMemberClass}
                        getMemberColor={getMemberColor}
                    />
                </div>
            </>
        </div>
    )
}


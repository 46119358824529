import React, {useState} from "react";
import {patchResourceRemainingQuantity} from "../../api/apiResource";
import {Controller, useForm} from "react-hook-form";

export const EditRemainingQuantity = (props) => {

    const {resource, successCallback, errorCallback} = props;

    const [quantity, setQuantity] = useState(resource.remaining_quantity);
    const {control} = useForm();

    const handleEditRemainingQuantity = async (e) => {
            setQuantity(e.target.value);
            resource.remaining_quantity = parseInt(e.target.value);
        if(parseInt(e.target.value) !== quantity){

            await patchResourceRemainingQuantity(resource.id, parseInt(e.target.value))
                .then(() => {
                    successCallback('The remaining quantity has been updated.');
                })
                .catch(() => {
                    errorCallback('Something went wrong.');
                })
        }
    }

    return (
        <>
            <Controller
                control={control}
                name="quantity"
                defaultValue={quantity}
                render={({field}) => (
                    <input type="text" {...field} onBlur={handleEditRemainingQuantity} onClick={e => e.stopPropagation()}/>
                )}
            /> g
        </>
    );
};
import React from "react";
import {Gantt} from "./gantt/Gantt";
import {QueryClient, QueryClientProvider} from 'react-query';
import {HeaderTabs} from "./common/HeaderTabs";

const queryClient = new QueryClient();

export const GanttMain = () => {
    const headerLinks = [
        {label: 'Gantt view', path: 'gantt'},
        {label: 'Print queue', path: 'history'}
    ];

    return (
        <>
            <HeaderTabs links={headerLinks}/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">
                    <QueryClientProvider client={queryClient}>
                        <Gantt/>
                    </QueryClientProvider>
                </div>
            </div>
        </>

    )
}
import React from "react";


export const ProjectDescription = (props) => {

    const {project} = props;

    return (
        <>
            <h2>Project description</h2>
            <p className="project-item__description">{project.notes || <em>No description</em>}</p>
        </>
    )

}
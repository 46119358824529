import {Controller, useForm} from "react-hook-form";
import React, {useRef, useState} from "react";
import {useGetData} from "../../api/useFetch";
import {getUserByDigitCode, patchUser} from "../../api/apiUser";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {DigitCode} from "../common/DigitCode";

export const ProfileData = (props) => {

    const {user, successCallback, errorCallback} = props;

    const colors = useGetData('colorsData', 'resource_colors');

    const {handleSubmit, control} = useForm();
    const [digitCodeVisible, setDigitCodeVisible] = useState(false);
    const dataUserRef = useRef(null);

    const onSubmitEditProfile = async (dataUser) => {
        dataUserRef.current = dataUser;

        // If the digit code has been changed, ask the user his previous digit code
        if(dataUser.digit_code !== user.digit_code && user.digit_code !== '----'){
            setDigitCodeVisible(true);
        }
        else
            await updateProfile();
    };

    const checkUserAndUpdateProfile = async (digitUser) => {
        if(digitUser.id === user.id)
            await updateProfile();
        else
            errorCallback('You must be identified to change your PIN code.')
    };

    const updateProfile = async () => {
        // Check if the PIN code is not already used by another user
        const users = await getUserByDigitCode(dataUserRef.current.digit_code);
        let canUse = true; // Is the digit code free or not

        for(const _user of users){
            if(_user.id !== user.id){
                canUse = false;
                break;
            }
        }

        if(canUse){
            await patchUser(dataUserRef.current)
                .then(() => {
                    window.location = '/profile';
                    successCallback('Profile updated.');
                })
                .catch(() => errorCallback('Something went wrong.'));
        }
        else{
            errorCallback('This PIN is not available in your organization.')
        }
    };

    if(colors.isLoading) return <Loading/>
    if(colors.isError) return <Error errorMessage={colors.error.message}/>

    return (
        <>
            <DigitCode visible={digitCodeVisible} setVisible={setDigitCodeVisible}
                       callback={checkUserAndUpdateProfile} errorCallback={() => null}/>

            <h2>Personal information</h2>

            <form id="profile__form" onSubmit={handleSubmit(onSubmitEditProfile)}>
                <div>
                    <div>
                        <Controller
                            control={control}
                            name="id"
                            defaultValue={user.id}
                            render={({field, value}) =>
                                <input type="hidden"
                                       {...field} />}
                        />

                        <label htmlFor="f-firstname">First Name</label>
                        <Controller
                            control={control}
                            name="firstname"
                            rules={{required: true}}
                            defaultValue={user.firstname}
                            render={({field}) => (
                                <input type="text" id="f-firstname" name="firstname" {...field}/>
                            )}
                        />
                    </div>

                    <div>
                        <label htmlFor="f-lastname">Name</label>
                        <Controller
                            control={control}
                            name="lastname"
                            rules={{required: true}}
                            defaultValue={user.lastname}
                            render={({field}) => (
                                <input type="text" id="f-lastname" {...field}/>
                            )}
                        />
                    </div>
                </div>

                <div>
                    <div>
                        <label htmlFor="f-mail">Email</label>
                        <Controller
                            control={control}
                            name="email"
                            rules={{required: true}}
                            defaultValue={user.email}
                            render={({field}) => (
                                <input type="text" id="f-mail" {...field}/>
                            )}
                        />
                    </div>

                    <div>
                        <label htmlFor="f-phone">Phone</label>
                        <Controller
                            control={control}
                            name="phone"
                            defaultValue={user.phone}
                            render={({field}) => (
                                <input type="text" id="f-phone" {...field}/>
                            )}
                        />
                    </div>
                </div>
                <div className="form-3">
                    <div>
                        <label htmlFor="f-user-group">Group</label>
                        <Controller
                            control={control}
                            name="user_group"
                            render={({field}) => (
                                <select id="f-user-group" {...field}>
                                    <option value="-1">None</option>
                                    {user.organization ? (
                                        user.organization.user_groups.map((user_group) => {
                                            if (user.user_group.id != user_group.id) {
                                                return <option key={user_group.id}
                                                               value={user_group.id}>{user_group.name}</option>
                                            } else {
                                                return <option key={user_group.id}
                                                               value={user_group.id}>{user_group.name}</option>
                                            }
                                        })
                                    ) : ''}
                                </select>
                            )}
                        />
                    </div>

                    <div>
                        <label htmlFor="f-color-id">Color</label>
                        <Controller
                            control={control}
                            name="color"
                            rules={{required: true}}
                            defaultValue={user.color !== undefined ? user.color.id : null}
                            render={({field}) => (
                                <select id="f-color-id" {...field}>
                                    {colors.data.map((color) => (
                                        <option key={color.id} value={color.id}>{color.color_name}</option>
                                    ))}
                                </select>
                            )}
                        />
                    </div>

                    <div>
                        <label htmlFor="f-digit-code">PIN code</label>
                        <Controller
                            control={control}
                            name="digit_code"
                            defaultValue={user.digit_code}
                            render={({field}) => (
                                <input type="password" id="f-digit-code" minLength={4} maxLength={4} {...field}/>
                            )}
                        />
                    </div>
                </div>

                <button><i className="fa fa-check"/> Update the profile</button>
            </form>
        </>
    )
}

import React, {useState} from "react";
import {patchMember} from "../../api/apiMember";
import {Drawer, makeStyles} from '@material-ui/core';
import {Table, Checkbox, Popover, Whisper} from "rsuite";

const useStyles = makeStyles({
    paper: {
        left: "50%"
    }
});

export const ManageRightsComponent = (props) => {
    const classes = useStyles();

    const {member, successCallback, errorCallback} = props;

    // Data format: [GET, POST, UPDATE, DELETE]
    const rightsData = [
        {name: 'PIC', view: true, create: 'ROLE_CREATE_PIC', edit: 'ROLE_UPDATE_PIC', remove: 'ROLE_DELETE_PIC'},
        {name: 'Jobs', view: true, create: 'ROLE_CREATE_TASK', edit: 'ROLE_UPDATE_TASK', remove: 'ROLE_DELETE_TASK'},
        {name: 'Printers', view: true, create: 'ROLE_ADD_PRINTER', edit: 'ROLE_UPDATE_PRINTER', remove: 'ROLE_DELETE_PRINTER'},
        {name: 'Resources', view: true, create: 'ROLE_ADD_RESOURCE', edit: 'ROLE_UPDATE_RESOURCE', remove: 'ROLE_DELETE_RESOURCE'},
        {name: 'Pieces', view: true, create: 'ROLE_ADD_PIECE', edit: 'ROLE_UPDATE_PIECE', remove: 'ROLE_DELETE_PIECE'},
        // {name: 'Attachments', view: true, create: 'ROLE_ADD_ATTACHMENT', edit: 'ROLE_UPDATE_ATTACHMENT', remove: 'ROLE_DELETE_ATTACHMENT'},
        {name: 'Projects', view: true, create: 'ROLE_CREATE_PROJECT', edit: 'ROLE_UPDATE_PROJECT', remove: 'ROLE_DELETE_PROJECT'},
        // {name: 'Organization members', view: true, create: 'ROLE_ADD_MEMBER_ORGANIZATION', edit: null, remove: 'ROLE_DELETE_MEMBER_ORGANIZATION'},
        {name: 'Smart Farm Manager', view: true, create: null, edit: 'ROLE_MANAGE_SMART_FARM', remove: null}
    ];

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [checkedKeys, setCheckedKeys] = useState(member.roles);

    const handleCheck = async (role, checked) => {
        const keys = checked ? [...checkedKeys, role] : checkedKeys.filter(item => item !== role);
        setCheckedKeys(keys);

        if(!member.roles.includes(role))
            member.roles.push(role)
        else
            member.roles = member.roles.filter(r => r !== role)

        await patchMember(member).catch(() => errorCallback('Something went wrong.'));
    };

    const handleAllCreateCheck = async (_, checked, updateDb=true) => {
        for(const right of rightsData){
            if(checked && !member.roles.includes(right.create) && right.create !== null)
                member.roles.push(right.create)
            else if(!checked && member.roles.includes(right.create))
                member.roles = member.roles.filter(r => r !== right.create)
        }
        setCheckedKeys([...member.roles]);
        if(updateDb) await patchMember(member).catch(() => errorCallback('Something went wrong.'));
    };

    const handleAllEditCheck = async (_, checked, updateDb=true) => {
        for(const right of rightsData){
            if(checked && !member.roles.includes(right.edit) && right.edit !== null)
                member.roles.push(right.edit)
            else if(!checked && member.roles.includes(right.edit))
                member.roles = member.roles.filter(r => r !== right.edit)
        }
        setCheckedKeys([...member.roles]);
        if(updateDb) await patchMember(member).catch(() => errorCallback('Something went wrong.'));
    };

    const handleAllRemoveCheck = async (_, checked, updateDb=true) => {
        for(const right of rightsData){
            if(checked && !member.roles.includes(right.remove) && right.remove !== null)
                member.roles.push(right.remove)
            else if(!checked && member.roles.includes(right.remove))
                member.roles = member.roles.filter(r => r !== right.remove)
        }
        setCheckedKeys([...member.roles]);
        if(updateDb) await patchMember(member).catch(() => errorCallback('Something went wrong.'));
    };

    const handleAllAllCheck = async (_, checked) => {
        await handleAllCreateCheck(null, checked, false);
        await handleAllEditCheck(null, checked, false);
        await handleAllRemoveCheck(null, checked, false);
        await patchMember(member).catch(() => errorCallback('Something went wrong.'));
    };

    const CheckCell = ({rowData, dataKey}) => (
        <div style={{ lineHeight: '46px' }}>
            {
                rowData[dataKey] === null ?
                    <Checkbox checked={false} disabled={true}/>
                : rowData[dataKey] === true ?
                    <Checkbox checked={true} disabled={true}/>
                :
                    <Checkbox checked={checkedKeys.some(item => item === rowData[dataKey])}
                              onChange={handleCheck} value={rowData[dataKey]}/>
            }
        </div>
    );

    const allCreate = (() => {
        for(const right of rightsData)
            if(right.create !== null && !(checkedKeys.includes(right.create)))
                return false;
        return true;
    })();

    const allEdit = (() => {
        for(const right of rightsData)
            if(right.edit !== null && !(checkedKeys.includes(right.edit)))
                return false;
        return true;
    })();

    const allRemove = (() => {
        for(const right of rightsData)
            if(right.remove !== null && !(checkedKeys.includes(right.remove)))
                return false;
        return true;
    })();

    return (
        <>
            <Whisper placement="top" trigger="hover" speaker={<Popover><span>Manage user rights</span></Popover>}>
                <button className="button-icon" id="member__manage-rights" onClick={() => setRightPartIsOpen(!rightPartIsOpen)}><i className="fa fa-wrench"/></button>
            </Whisper>
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)} classes={{paper: classes.paper}}>
                <Table
                    height={300}
                    hover={true}
                    autoHeight={true}
                    data={rightsData}
                    bordered={true}
                    cellBordered={true}
                >
                    <Table.Column flexGrow={2}>
                        <Table.HeaderCell children=''/>
                        <Table.Cell dataKey="name"/>
                    </Table.Column>
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell><Checkbox checked={true} disabled={true}>View</Checkbox></Table.HeaderCell>
                        <Table.Cell style={{padding: 0}}>{rowData => <CheckCell rowData={rowData} dataKey="view"/>}</Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell><Checkbox checked={allCreate} onChange={handleAllCreateCheck}>Create</Checkbox></Table.HeaderCell>
                        <Table.Cell style={{padding: 0}}>{rowData => <CheckCell rowData={rowData} dataKey="create"/>}</Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell><Checkbox checked={allEdit} onChange={handleAllEditCheck}>Edit</Checkbox></Table.HeaderCell>
                        <Table.Cell style={{padding: 0}}>{rowData => <CheckCell rowData={rowData} dataKey="edit"/>}</Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell><Checkbox checked={allRemove} onChange={handleAllRemoveCheck}>Remove</Checkbox></Table.HeaderCell>
                        <Table.Cell style={{padding: 0}}>{rowData => <CheckCell rowData={rowData} dataKey="remove"/>}</Table.Cell>
                    </Table.Column>
                </Table>

                <div>
                    <Checkbox checked={allCreate && allEdit && allRemove} onChange={handleAllAllCheck}>
                        Give all privileges to this user
                    </Checkbox>
                </div>

                <p className="members-message-warning">
                    <i className='fa fa-exclamation-triangle'/> To apply changes on the member's account, ask this member to reload the application in his browser
                </p>
            </Drawer>
        </>

    )
}

import React from "react";
import {Profile} from "./profile/Profile";
import {QueryClient, QueryClientProvider} from 'react-query';
import {HeaderTabs} from "./common/HeaderTabs";
import {getCurrentUser} from "./CommonFunctions";

const queryClient = new QueryClient();

export const ProfileMain = () => {

    const headerLinks = [
        {label: 'Profile', path: 'profile'},
        {label: 'Organization', path: 'organization'},
        {label: 'Members', path: 'members', visible: getCurrentUser().admin},
        {label: 'Connectivity', path: 'connectivity'},
        {label: 'Help', path: 'help'}
    ];

    return (
        <>
            <HeaderTabs links={headerLinks}/>

            <div id="main-grid__item--main-content" className="main-grid__item">
                <div id="main-content">
                    <QueryClientProvider client={queryClient}>
                        <Profile/>
                    </QueryClientProvider>
                </div>
            </div>
        </>
    )
}



import React, {useEffect, useState} from 'react';
import {useGetData} from "../../../api/useFetch";
import {deleteResourceBrand} from "../../../api/apiBackoffice";
import {Drawer} from "@material-ui/core";
import {Loading} from "../../common/Loading";
import {BrandAdd} from "./BrandAdd";
import {BrandEdit} from "./BrandEdit";

export const Brands = (props) => {

    const {brandsData} = props;

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [alert, setAlert] = useState();
    const [isUpdating, setIsUpdating] = useState(false);
    const [actualBrand, setActualBrand] = useState();
    const [isCustomBrandValue, setIsCustomBrandValue] = useState(false);

    const brands = useGetData('brands', 'resource_brands');

    useEffect(() => {
        if(actualBrand !== undefined && actualBrand !== null) {
            if(actualBrand.brand_data === undefined || actualBrand.brand_data === null || !actualBrand.brand_data.id) {
                setIsCustomBrandValue(true);
            }
        }
    }, [actualBrand]);

    const successCallback = (message) => {
        brands.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        brands.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    const handleDelete = async (res_brand_Id) => {
        if(window.confirm('Are you sure you want to delete this Resource Brand ?'))
            await deleteResourceBrand(res_brand_Id)
                .then(() => successCallback('This Resource Brand has been deleted.'))
                .catch(() => errorCallback('Something went wrong.'));
    };

    const handleClose = () => {
        setIsCustomBrandValue(false);
        setActualBrand(null);
        setRightPartIsOpen(!rightPartIsOpen);
    }

    if(brandsData.isLoading || brands.isLoading) return <Loading/>

    return (
        <div className="backoffice_block" style={{display: "block", border: "2px solid lightgrey", padding: "15px"}}>
            <div className="resource_header">
                <h2>Resources Brand</h2>
                <button className="button-link" onClick={() => {
                    setIsUpdating(false);
                    setRightPartIsOpen(!rightPartIsOpen);
                }}>
                    <i className="fa fa-plus"/> Add a brand
                </button>
            </div>
            {brands.data ? (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {brands.data.map((brand) => (
                            <tr key={brand.id}>
                                <td>{brand.name}</td>
                                <td>
                                    <button className="button-link" onClick={()=> {
                                        setIsUpdating(true);
                                        setActualBrand(brand);
                                        setRightPartIsOpen(!rightPartIsOpen);
                                    }}>
                                        <i className="fas fa-edit" />
                                    </button>
                                    &nbsp;
                                    <button className="button-link" onClick={()=> handleDelete(brand.id)}><i className="fas fa-trash-alt" /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <Loading />
            )}
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => handleClose()}>
                <div className="backoffice_drawer_content" style={{width:"500px", "marginTop":"30px"}}>
                    {!isUpdating &&
                        <BrandAdd
                            brandsData={brandsData} brands={brands}
                            setRightPartIsOpen={setRightPartIsOpen} rightPartIsOpen={rightPartIsOpen}
                            successCallback={successCallback} errorCallback={errorCallback}
                            isCustomBrandValue={isCustomBrandValue} setIsCustomBrandValue={setIsCustomBrandValue}
                        />
                    }
                    {(actualBrand && isUpdating) &&
                        <BrandEdit
                            brandsData={brandsData} brands={brands} actualBrand={actualBrand} setActualBrand={setActualBrand}
                            setRightPartIsOpen={setRightPartIsOpen} rightPartIsOpen={rightPartIsOpen}
                            successCallback={successCallback} errorCallback={errorCallback}
                            isCustomBrandValue={isCustomBrandValue} setIsCustomBrandValue={setIsCustomBrandValue}
                        />
                    }
                </div>
            </Drawer>
        </div>
    )
}

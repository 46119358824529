import React from "react";
import {postResourceBrand} from "../../../api/apiBackoffice";
import {Controller, useForm} from "react-hook-form";

export const BrandAdd = (props) => {

    const {brandsData, brands, successCallback, errorCallback,
        isCustomBrandValue, setIsCustomBrandValue, setRightPartIsOpen, rightPartIsOpen} = props;

    const {reset, handleSubmit, control, setValue} = useForm();

    const handleCreate = async (data) => {
        const brand = addNewDataToBrand({}, data);
        await postResourceBrand(brand)
            .then(() => {
                setIsCustomBrandValue(false);
                reset();
                setRightPartIsOpen(!rightPartIsOpen);
                successCallback('This Resource Brand has been created.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    };

    const handleBrandSelectChange = (e) => {
        if(e.target.value === 'custom') {
            setIsCustomBrandValue(true);
        } else {
            setValue('name', e.target.value);
            return e.target.value;
        }
    }

    const addNewDataToBrand = (brand, data) => {
        if(isCustomBrandValue) {
            brand.name = data.name_custom;
            brand.brand_data = null;
        } else {
            const brandData = JSON.parse(data.name);
            brand.name = brandData.name;
            brand.brand_data = brandData.id;
        }
        return brand;
    }

    // Check if brandData is already used by another brand
    const checkIfBrandDataAlreadyUsed = (brandData) => {
        for(let i = 0; i < brands.data.length; i++) {
            if(brands.data[i].brand_data && brandData.id === brands.data[i].brand_data.id) {
                return true;
            }
        }
        return false;
    }

    return(
        <>
            <h2 style={{"paddingLeft": "10px"}}>Add Resource Brand</h2>
            <form onSubmit={handleSubmit(handleCreate)} id="add-form">
                <label>Resource Brand name :</label>
                <div style={{"display": "flex", "justifyContent": "center", "marginBottom": "20px"}}>
                    {!isCustomBrandValue &&
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                required: !isCustomBrandValue,
                                validate: (value) => (value !== 0 || isCustomBrandValue)
                            }}
                            defaultValue={0}
                            render={({field}) => (
                                <select id="brand-name" {...field}
                                        onChange={(e) => handleBrandSelectChange(e)}>
                                    <option disabled value={0}>--Select a brand--</option>
                                    {brandsData.data.map(brand => (
                                        <option key={brand.id} value={JSON.stringify(brand)}
                                                disabled={checkIfBrandDataAlreadyUsed(brand)}>{brand.name}</option>
                                    ))}
                                    <option value="custom">Add a custom value</option>
                                </select>
                            )}
                        />
                    }
                    {isCustomBrandValue &&
                        <Controller
                            control={control}
                            name="name_custom"
                            rules={{required: isCustomBrandValue}}
                            defaultValue={''}
                            render={({field}) => (
                                <input type="text" {...field}/>
                            )}
                        />
                    }
                </div>
                <br/>
                <input type="submit" value="Save" style={{display: "block", margin: "auto"}}/>
            </form>
        </>
    );
}

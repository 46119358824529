import {fetchData} from "../../api/useFetch";
import {activateFarmProfile, changeFarmCellLedColor, LED_COLORS} from "../FarmService";
import {getCurrentTimestamp} from "../../components/CommonFunctions";

export const EXTERNAL_TASK_DATA = {
    name: 'Detected job'
}

export const TASK_STATUS = {
    STATUS_UPCOMING: 'upcoming',
    STATUS_IN_PROGRESS: 'progress',
    STATUS_DONE: 'done'
}

// Interval between 2 requests on external print tasks (in seconds)
export const EXTERNAL_PRINT_TASK_REQUEST_INTERVAL = 60;

/**
 * Check if a task is an external task (detected by automation)
 * @param task
 * @returns {boolean}
 */
export const isExternalPrintTask = (task) => {
    return (task.pic === undefined || task.pic === null) &&
        task.start_date_detected !== undefined && task.start_date_detected !== null;
}

/**
 * Get tasks corresponding to a specific printer by printer id
 * @param tasks
 * @param printerId
 * @returns {*}
 */
export const getTasksByPrinterId = (tasks, printerId) => {
    if(tasks && tasks.length > 0) {
        return tasks.filter(task => {
            return (task.printer && task.printer.id && task.printer.id === printerId);
        });
    }
    return [];
}

/**
 * Get printer tasks from printers list object
 * @param printers
 * @param printerId
 * @returns {*|*[]}
 */
export const getPrinterInPrintersList = (printers, printerId) => {
    for(let printer of printers) {
        if(printer.id === printerId) {
            return printer
        }
    }
    return null;
}

/**
 * Get controllable printer corresponding to printer
 * @param printer
 * @param controllablePrinters
 * @returns {*|null}
 */
export const getControllablePrinter = (printer, controllablePrinters) => {
    if(printer.controllable_printer) {
        for(let controllablePrinter of controllablePrinters) {
            if(controllablePrinter.id === printer.controllable_printer.id) {
                return controllablePrinter;
            }
        }
    }
    return null;
}

/**
 * Format maintenance data as task data
 * @param maintenance
 * @returns {{date: (*|number), print_duration: number, name: string, isMaintenance: boolean, id}}
 */
export const formatMaintenanceInTaskFormat = (maintenance) => {
    return {
        id: maintenance.id,
        name: 'maintenance',
        date: maintenance.start_date,
        print_duration: maintenance.end_date ? maintenance.end_date - maintenance.start_date : getCurrentTimestamp() - maintenance.start_date,
        isMaintenance: true
    };
}

/**
 * Format a maintenances list as task data
 * @param maintenances
 * @returns {*[]}
 */
export const formatMaintenancesInTasksData = (maintenances) => {
    let formattedMaintenances = [];
    if(maintenances) {
        maintenances.map(maintenance => {
            formattedMaintenances.push(formatMaintenanceInTaskFormat(maintenance));
        });
    }
    return formattedMaintenances;
}

/**
 * Activate task farm profile
 * @param task
 * @returns {Promise<void>}
 */
export const activateTaskFarmProfileOnStart = (task) => {
    if(task.printer && task.printer.farm_cell && task.printer.farm_cell.id && task.printer.farm_cell.system_code) {
        // Change led color to orange
        changeFarmCellLedColor(task.printer.farm_cell.system_code, task.printer.farm_cell.id, LED_COLORS.ORANGE.index, true)
            .then(() => {
                // Check if there is a profile to execute
                if (task.pic && task.pic.slicer && task.pic.slicer.farm_profile && task.pic.slicer.farm_profile.id) {
                    // Get farm profile to execute
                    fetchData('farm_profiles/' + task.pic.slicer.farm_profile.id)
                        .then(response => response.json())
                        // Execute profile
                        .then(profile => activateFarmProfile(profile, task.printer.farm_cell.system_code, task.printer.farm_cell.id, true))
                }
            });
    }
}

/**
 * Return the profile associated to the task
 * @param task
 * @returns {null|*}
 */
export const getTaskProfile = (task) => {
    if(task.pic !== undefined && task.pic.pic_cards !== undefined){
        for(const picCard of task.pic.pic_cards){
            if(picCard.card.farm_profile !== undefined)
                return picCard.card.farm_profile;
        }
    }
    return null;
};
import Modal from "../../../common/Modal";
import React from "react";
import {sendFarmCommandEvent} from "../../../../services/socketEventsService";
import {FARM_EVENT_TYPES, SMART_FARM_COMMANDS} from "../../../../services/FarmService";

export const StopCurrentModal = (props) => {

    const {farmCell, cancelModal, saveFarmEvent} = props;

    const handleStopCurrent = () => {
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.RELAY_OFF, 0);
        setTimeout(() => {
            sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.RELAY_OFF, 0);
            saveFarmEvent(FARM_EVENT_TYPES.RELAY_OFF, 1);
        }, 5000);
        cancelModal();
    };

    return (
        <Modal
            title="Stop printer current"
            description={`Are you sure you want to stop printer current? The printer will shut down.`}
            buttons={[
                {label: 'Yes, stop current', callback: handleStopCurrent}
            ]}
            cancelCallback={() => cancelModal()}
        />
    );
}

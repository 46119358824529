import React from "react";

export const PicManagerBlock = (props) => {

    const {children, title, actionAdd} = props;

    return (
        <div className="block">
            <div className="block-header">
                <h2>{title}</h2>
                {actionAdd &&
                    <button className="rounded-block-button button-link" onClick={() => actionAdd()}>
                        <i className="fa fa-plus"/>
                    </button>
                }
            </div>
            {children}
        </div>
    );
}

import React, {useState} from 'react';
import {deleteResourceBrandData} from "../../../api/apiBackoffice";
import {Drawer} from "@material-ui/core";
import {Loading} from "../../common/Loading";
import {BrandDataForm} from "./BrandDataForm";

export const BrandsData = (props) => {

    const {brandsData, resourceEmptyWeightsData} = props;

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [alert, setAlert] = useState();
    const [actualBrandData, setActualBrandData] = useState(null);

    const successCallback = (message) => {
        brandsData.refetch().then(() => {
            resourceEmptyWeightsData.refetch();
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        brandsData.refetch().then(() => {
            resourceEmptyWeightsData.refetch();
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    const handleDelete = async (brandId) => {
        if(window.confirm('Are you sure you want to delete this Resource Brand Data ?'))
            await deleteResourceBrandData(brandId)
                .then(() => successCallback('This Resource Brand Data has been deleted.'))
                .catch(() => errorCallback('Something went wrong.'));
    };

    const handleClose = () => {
        setActualBrandData(null);
        setRightPartIsOpen(!rightPartIsOpen);
    }

    if(brandsData.isLoading) return <Loading/>

    return (
        <div className="backoffice_block" style={{display: "block", border: "2px solid lightgrey", padding: "15px"}}>
            <div className="resource_header">
                <h2>Resources Brands Data</h2>
                <button className="button-link" onClick={() => {
                    setRightPartIsOpen(!rightPartIsOpen);
                }}>
                    <i className="fa fa-plus"/> Add a resource brand data
                </button>
            </div>
            {brandsData.data ? (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {brandsData.data.map((brand) => (
                            <tr key={brand.id}>
                                <td>{brand.name}</td>
                                <td>
                                    <button className="button-link" onClick={()=> {
                                        setActualBrandData(brand);
                                        setRightPartIsOpen(!rightPartIsOpen);
                                    }}>
                                        <i className="fas fa-edit" />
                                    </button>
                                    &nbsp;
                                    <button className="button-link" onClick={()=> handleDelete(brand.id)}><i className="fas fa-trash-alt" /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <Loading />
            )}
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => handleClose()}>
                <div className="backoffice_drawer_content" style={{width:"500px", "marginTop":"30px"}}>
                    <BrandDataForm
                        actualBrandData={actualBrandData} setActualBrandData={setActualBrandData}
                        setRightPartIsOpen={setRightPartIsOpen}
                        successCallback={successCallback} errorCallback={errorCallback}
                    />
                </div>
            </Drawer>
        </div>
    )
}

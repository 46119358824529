import {SMART_PRINTER_STATUS} from "../FarmService";

export const getStartPrintErrorModalDataByErrorCode = (errorCode, goToPicBuilderButton, switchToManualModeButton, validateStartPrintingButton) => {
    switch(errorCode) {
        case 402: return {title: 'Printer unavailable', description: 'Print cancelled: unable to connect to the printer', buttons: [switchToManualModeButton]};
        case 403: return {title: 'Printer error', description: 'Print cancelled: unable to validate if printer is ready to print', buttons: [switchToManualModeButton]};
        case 404: return {title: 'Printer unavailable', description: 'Print cancelled: the printer is already printing', buttons: [switchToManualModeButton]};
        case 405: return {title: 'Printer unavailable', description: 'Print cancelled: the printer is offline', buttons: [switchToManualModeButton]};
        case 406: return {title: 'Build error', description: 'The build defined in your PIC could not be found, please select a new build in the PIC builder', buttons: [goToPicBuilderButton, switchToManualModeButton]};
        case 407: return {title: 'Build error', description: 'The build defined in your PIC could not be found, please select a new build in the PIC builder', buttons: [goToPicBuilderButton, switchToManualModeButton]};
        case 408: return {title: 'Printing start communication error', description: 'The printer didn\'t start printing. Please check the printer\'s status manually, and validate or cancel printing start.', buttons: [validateStartPrintingButton]};
        case 409: return {title: 'Printing start communication error', description: 'The printer didn\'t start printing. Please check the printer\'s status manually, and validate or cancel printing start.', buttons: [validateStartPrintingButton]};
        case 410: return {title: 'Printing start error', description: 'Unable to check if the connected printer has started printing. Please check the printer\'s status manually, and validate or cancel printing start.', buttons: [validateStartPrintingButton]};
        default: return {title: 'Print start error', description: 'An error occurred', buttons: [switchToManualModeButton]};
    }
}

export const isPrinterWorking = (statusId) => {
    return statusId === SMART_PRINTER_STATUS.WORKING.id || statusId === SMART_PRINTER_STATUS.WARMING_UP.id || statusId === SMART_PRINTER_STATUS.PRINTING.id;
}

export const isPrinterStandBy = (status) => {
    return status === SMART_PRINTER_STATUS.STAND_BY.id || status === SMART_PRINTER_STATUS.OFF.id || status === SMART_PRINTER_STATUS.ON.id;
}
import {OutlinedCard} from "../../common/OutlinedCard";
import {Button, ButtonGroup, ButtonToolbar, DateRangePicker} from "rsuite";
import {afterToday} from "rsuite/esm/DateRangePicker/disabledDateUtils";
import {CHARTS_DATA_RANGE} from "../../../../services/FarmService";
import {MaterialIcon} from "../../../common/icons/MaterialIcon";

export const ChartsDataRangeBlock = (props) => {

    const {selectedDataRange, setSelectedDataRange, setCustomDataRange, handleDownloadEnvironmentData} = props;

    const buttonGroupAppearance = 'ghost';

    const handleCustomDataRangeChange = (datesArray) => {
        setSelectedDataRange(CHARTS_DATA_RANGE.CUSTOM);
        setCustomDataRange({startDate: datesArray[0], endDate: datesArray[1]});
    }

    return(
      <OutlinedCard>
          <div className="farm__dialog_graphics_data_range">
              <div className="data-range-header">
                  <h2>Graphics data range</h2>
                  {/* Download environment data button */}
                  <button className="button-outlined-main data-range-export-button" onClick={() => handleDownloadEnvironmentData()}>
                      <MaterialIcon label="download" classes='data-range-export-button-icon'/>
                      <span>Download environment data</span>
                  </button>
              </div>

              <div className="data-range-select-block">
                  <ButtonToolbar>
                      <ButtonGroup>
                          <Button appearance={buttonGroupAppearance} active={selectedDataRange === CHARTS_DATA_RANGE.DAY}
                                  onClick={() => setSelectedDataRange(CHARTS_DATA_RANGE.DAY)}>Day</Button>
                          <Button appearance={buttonGroupAppearance} active={selectedDataRange === CHARTS_DATA_RANGE.WEEK}
                                  onClick={() => setSelectedDataRange(CHARTS_DATA_RANGE.WEEK)}>Week</Button>
                          <Button appearance={buttonGroupAppearance} active={selectedDataRange === CHARTS_DATA_RANGE.MONTH}
                                  onClick={() => setSelectedDataRange(CHARTS_DATA_RANGE.MONTH)}>Month</Button>
                      </ButtonGroup>
                  </ButtonToolbar>
                  <div className="data-range-custom-select-block">
                      <span className="data-range-custom-select-label">Custom:</span>
                      <DateRangePicker
                          placeholder="Select a data range" placement="bottomEnd" onChange={datesArray => handleCustomDataRangeChange(datesArray)}
                          disabledDate={afterToday()} showOneCalendar={true}
                      />
                  </div>
              </div>
          </div>
      </OutlinedCard>
    );
}

// Find element in data list by id
export const findElementById = (list, id) => {
    if(list) {
        for(let i = 0; i < list.length; i++) {
            if(list[i].id === id) {
                return list[i];
            }
        }
    }
    return null;
}

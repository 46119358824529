import React from "react";
import {Table} from "rsuite";
import {Avatar} from "../common/Avatar";
import {TableWrapper} from "../common/TableWrapper";
import {MaterialIcon} from "../common/icons/MaterialIcon";

export const ProjectPics = (props) => {

    const {pics, isLoading} = props;

    const { Column, HeaderCell, Cell } = Table;

    const handleEditButtonClick = (picId) => {
        if(picId) {
            window.location = '/builder/' + picId;
        }
    }

    return (
        <div className="project__pic_table">
            <h2 className="pic-table-title">Pics in the project</h2>
            {(pics && pics.length > 0) ?
                <TableWrapper classNames="">
                    <Table data={pics}
                           autoHeight={false}
                           rowKey="key"
                           loading={isLoading}
                           loadAnimation={true}
                    >
                        <Column flexGrow={3.5} verticalAlign="middle">
                            <HeaderCell>Name</HeaderCell>
                            <Cell dataKey="name">
                                {rowData => <span>{rowData.pic && rowData.pic.name}</span>}
                            </Cell>
                        </Column>
                        <Column flexGrow={1.2} verticalAlign="middle">
                            <HeaderCell>User</HeaderCell>
                            <Cell dataKey="user">
                                {rowData => (rowData.pic && rowData.pic.user) &&
                                    <div className="table-user-avatar">
                                        <Avatar user={rowData.pic.user} size="small"/>
                                    </div>
                                }
                            </Cell>
                        </Column>
                        <Column flexGrow={2} verticalAlign="middle">
                            <HeaderCell>Status</HeaderCell>
                            <Cell dataKey="complete">
                                {rowData => {
                                    return(rowData.pic &&
                                        (rowData.pic.complete ?
                                            <span className="table-status table-status-active">active</span> :
                                            <div className="table-status table-status-draft">draft</div>
                                        )
                                    );
                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={2} verticalAlign="middle">
                            <HeaderCell>Actions</HeaderCell>
                            <Cell dataKey="edit-link">
                                {rowData => rowData.pic &&
                                    <button className="pic-table-edit-button" onClick={() => handleEditButtonClick(rowData.pic.id)}>
                                        <MaterialIcon label="edit" classes="pic-table-edit-button-icon"/>
                                        <span className="pic-table-edit-button-label">edit pic</span>
                                    </button>
                                }
                            </Cell>
                        </Column>
                    </Table>
                </TableWrapper> :
                <div>No pic linked to this project</div>
            }
        </div>
    );
}

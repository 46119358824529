import {
    CELL_SIZES,
    getClassesDependingOnCellSize, getManualPrinterStatus
} from "../../../services/FarmService";
import {isMinimalApp} from "../../CommonFunctions";
import {MaterialIcon} from "../../common/icons/MaterialIcon";


export const FarmPrinter = (props) => {

    const {printer, cellSize, smartPrinterStatus, manualPrinterStatus} = props;

    const isPrinterInManualMode = () => {
        return smartPrinterStatus === null;
    }

    const isManualMode = isPrinterInManualMode();
    const printerStatus = isManualMode ? getManualPrinterStatus(manualPrinterStatus ? manualPrinterStatus.status : printer.status) : smartPrinterStatus.status;

    const isMinimalAppVersion = isMinimalApp();

    return(
        <div className="cell-printer">
          <div className="printer-header">
            <div className={getClassesDependingOnCellSize(cellSize, 'printer-header-content')}>{printer.controllable_printer_id ? 'connected' : ''}</div>
          </div>
          <div className={getClassesDependingOnCellSize(cellSize, 'printer-body')}>
              <div className="printer-data">
                  <div className={getClassesDependingOnCellSize(cellSize, 'printer-name')}>{printer.name}</div>
                  {(cellSize === CELL_SIZES.BIG || cellSize === CELL_SIZES.SMALL) &&
                    <div className="printer-brand">{printer.brand && printer.brand.name}</div>
                  }
              </div>
              <div className="printer-status-block">
                  {/* Display big and small printers */}
                  {(cellSize === CELL_SIZES.BIG || cellSize === CELL_SIZES.SMALL) &&
                      <div className={'printer-status ' + (cellSize === CELL_SIZES.BIG ? 'printer-status-big' : 'printer-status-small')}>
                          <div className="printer-status-data">
                              {!(isMinimalAppVersion && isManualMode) &&
                                  <>
                                      <div className="printer-status-icon">
                                          <MaterialIcon label={printerStatus.icon} style={{color: printerStatus.color}}/>
                                      </div>
                                      <div className="printer-status-value" style={{color: printerStatus.color}}>{printerStatus.value}</div>
                                      {isManualMode &&
                                          <div className="printer-status-manual-mode">(manual)</div>
                                      }
                                  </>
                              }
                              {(isMinimalAppVersion && isManualMode) &&
                                  <div>--</div>
                              }
                          </div>
                      </div>
                  }
                  {/* Display xs printers */}
                  {cellSize === CELL_SIZES.XS &&
                      <div className="printer-status printer-status-xs">
                          <div className="printer-status-data">
                              {!(isMinimalAppVersion && isManualMode) &&
                                  <>
                                      <div className="printer-status-value" style={{color: printerStatus.color}}>{printerStatus.value}</div>
                                      {isManualMode &&
                                          <div className="printer-status-manual-mode">(manual)</div>
                                      }
                                  </>
                              }
                              {(isMinimalAppVersion && isManualMode) &&
                                  <div>--</div>
                              }
                          </div>
                      </div>
                  }
              </div>
          </div>
        </div>
    );
}

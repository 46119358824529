import {useCallback} from "react";

/* Wrapper for RSuite Tables - fix scroll bug on touch devices */
export const TableWrapper = (props) => {

    const {children, classNames} = props;

    const mainGrid = document.getElementById('main-grid');

    const stopPageScrollCallback = useCallback(e => {
        e.preventDefault();
    }, []);

    return(
        <div className={classNames}
             onTouchStart={() => mainGrid.addEventListener('touchmove', stopPageScrollCallback, true)}
             onTouchEnd={() => mainGrid.removeEventListener('touchmove', stopPageScrollCallback, true)}
        >
            {children}
        </div>
    );
}

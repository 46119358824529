import React from "react";
import {Tabs, Tab} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    tabStyle: {
        height: '70px',
        width: '120px',
        fontSize: '0.8em'
    },
}));

export const HeaderTabs = (props) => {

    let {links, defaultPath} = props;
    defaultPath = (defaultPath === undefined) ? links[0].path : defaultPath;

    let history = useHistory();

    const classes = useStyles();

    const [value, setValue] = React.useState(defaultPath);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push('/' + newValue);
    };

    return (
        <div id="main-grid__item--tabs" className="main-grid__item">
            <div id="tabs">
                <Tabs value={value} onChange={handleChange}>
                    {links.map((link) => (link.visible === undefined || link.visible) && (
                        <Tab
                            label={link.label}
                            value={link.path}
                            onChange={handleChange}
                            className={classes.tabStyle}
                            key={link.path}
                        />
                    ))}
                </Tabs>
            </div>
        </div>
    )
}

import React from "react";
import {EditPrinterComponent} from "./EditPrinter";
import {DeletePrinterComponent} from "./DeletePrinter";
import {ViewPrinterComponent} from "./ViewPrinter";
import {useState} from 'react';
import {isUserGranted} from "../CommonFunctions";
import {Drawer, makeStyles} from '@material-ui/core';
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";

const useStyles = makeStyles({
    paper: {
        left: "50%"
    }
});

export const TableComponent = (props) => {
    const classes = useStyles();

    const {brands, models, technologies, successCallback, errorCallback, printers, controllablePrinters, selectedOrganization} = props;

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [printerData, setPrinterData] = useState(null);

    if(printers.isLoading || controllablePrinters.isLoading || brands.isLoading) return <Loading/>
    if(printers.isError) return <Error errorMessage={printers.error.message}/>

    let hasFarm = false;

    if(printers.data !== undefined) {
        printers.data.map((printer) => {
            if (printer.farm_cell)
                hasFarm = true;
        })
    }

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th style={{width: '15%'}}>ID</th>
                        <th style={{width: '30%'}}>Printer</th>
                        <th style={{width: '7%'}}>Connected</th>
                        {hasFarm && <th style={{width: '10%'}}>Smart Farm Volume</th>}
                        <th style={{width: '20%'}}>Printable volume (mm)</th>
                        <th style={{width: '8%'}}>Attached file(s)</th>
                        <th style={{width: '20%'}}/>
                    </tr>
                </thead>
                <tbody>
                {printers.data.filter(printer => printer.organization.id === selectedOrganization).map((printer) => (
                    <tr
                        key={printer.id}
                        onClick={(e) => {
                            setPrinterData(printer);
                            setRightPartIsOpen(!rightPartIsOpen);
                        }}
                    >
                        <td>{printer.serial_number}</td>
                        <td>{printer.name} ({printer.model.name} - {printer.technology.name} - {printer.brand.name})</td>
                        <td>{printer.controllable_printer_id && <span><i className='fa fa-check-circle'/></span>}</td>
                        {hasFarm && <td>{printer.farm_cell && <span><i className='fa fa-check-circle'/></span>}</td>}
                        <td>{printer.printable_length}(l) x {printer.printable_width}(w) x {printer.printable_height}(h)</td>
                        <td>{printer.attached_files.length}</td>
                        <td onClick={(e) => { e.stopPropagation() }}>
                            {isUserGranted('ROLE_UPDATE_PRINTER') &&
                                <>
                                    <EditPrinterComponent
                                        printer={printer} brands={brands.data} models={models.data} technologies={technologies.data}
                                        controllablePrinters={controllablePrinters} printers={printers}
                                        successCallback={successCallback}
                                        errorCallback={errorCallback}
                                    />
                                    &nbsp;
                                </>
                            }
                            {(isUserGranted('ROLE_DELETE_PRINTER') && printer.printer_used === false) &&
                                <DeletePrinterComponent
                                    printer={printer}
                                    successCallback={successCallback} errorCallback={errorCallback}
                                />
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)} classes={{paper: classes.paper}}>

            {printerData != null
                    ? <ViewPrinterComponent
                            printerData={printerData}
                            refetch={printers.refetch}
                            setRightPartIsOpen={setRightPartIsOpen}
                            successCallback={successCallback}
                            errorCallback={errorCallback}
                        />
                    : <Loading/>
                }
            </Drawer>

        </>
    )
}

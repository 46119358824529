import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    circle: {
        strokeLinecap: "round",
        color: '#1a90ff !important',
        animationDuration: '1000ms',
    },
}));

export const CircularProgressWithLabel = (props) => {
    const classes = useStyles();

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} size={100} thickness={3} className={classes.circle}/>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
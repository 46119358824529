import React, {useEffect, useMemo, useState} from "react";
import {fetchData, useGetData} from "../../../api/useFetch";
import {
    getCurrentTimestamp,
    getFormattedDuration,
    getTaskProgress, isUserGranted, ALERT_STATUS
} from "../../CommonFunctions";
import {fixUnfixTaskScheduling, patchTaskSmartScheduling} from "../../../api/apiGantt";
import {Loading} from "../../common/Loading";
import {Error} from "../../common/Error";
import {Checkbox} from "@material-ui/core";
import pic_details_icone from "../../../images/icons/planning/box-arrow-up-right 1.png";
import details_icone from "../../../images/icons/planning/1st.png";
import actions_icone from "../../../images/icons/planning/2nd.png";
import {TaskInformation} from "./TaskInformation";
import {TaskActions} from "./TaskActions";
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import {AlertMessage} from "../../common/AlertMessage";
import {withStyles} from '@material-ui/core/styles';
import Modal from "../../common/Modal";
import {requestAutoScheduling} from "../../../api/apiAutoSchedule";
import {sendFarmCommandEvent} from "../../../services/socketEventsService";
import {FARM_EVENT_TYPES, SMART_FARM_COMMANDS} from "../../../services/FarmService";
import {isExternalPrintTask} from "../../../services/gantt/GanttService";
import {fetchAutomationData} from "../../../api/apiConnectivity";
import {DigitCode} from "../../common/DigitCode";
import {postFarmEvent} from "../../../api/apiFarmCommand";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 5,
        borderRadius: 10,
    },
    colorPrimary: {
        backgroundColor: 'white',
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#00CAC0',
        width: '100%'
    },
}))(LinearProgress);

export const TaskDetailsMain = (props) => {

    const {taskId, setRightPartDetailsIsOpen, setIsTaskUpdated, reloadPageData} = props;

    const task = useGetData(`taskData-${taskId}`, `tasks/${taskId}`);

    const [controllablePrinter, setControllablePrinter] = useState(null);

    const [farmEvents, setFarmEvents] = useState({data: null, isLoading: true, isError: false});
    const [printFails, setPrintFails] = useState({data: null, isLoading: true, isError: false});

    const isTaskExternalTask = useMemo(() => {
        if(task && task.data) {
            return isExternalPrintTask(task.data);
        }
        return false;
    }, [task.isLoading]);

    // Get FarmEvents after task data have been loaded
    useEffect(() => {
        if(task && !task.isLoading && farmEvents.data === null && task.data.printer.farm_cell !== undefined){
            fetch(process.env.REACT_APP_API_ADDRESS +
                `farm_events?farmCell=${task.data.printer.farm_cell.id}&date[gte]=${task.data.date}&date[lte]=${task.data.end_date}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(response => response.json())
            .then(response => {
                setFarmEvents({...farmEvents, data: response, isLoading: false});
            })
            .catch(error => {
                setFarmEvents({...farmEvents, isLoading: false, isError: true});
            });
        }

    }, [task.isLoading]);

    // Get PrintFails after task data have been loaded
    useEffect(() => {
        if(task && !task.isLoading && farmEvents.data === null && task.data.printer.farm_cell !== undefined){
            const start_date = task.data.date;
            const end_date = task.data.end_date !== start_date ? task.data.end_date : getCurrentTimestamp();
            fetch(process.env.REACT_APP_API_ADDRESS +
                `print_fails?farmCell=${task.data.printer.farm_cell.id}&date[gte]=${start_date}&date[lte]=${end_date}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(response => response.json())
                .then(response => {
                    setPrintFails({...printFails, data: response, isLoading: false});
                })
                .catch(error => {
                    setPrintFails({...printFails, isLoading: false, isError: true});
                });
        }

    }, [task.isLoading]);

    const refetchTask = () => {
        return task.refetch();
    }

    const getControllablePrinter = async () => {
        const brandCode = task.data.printer.brand.printer_brand_data ? task.data.printer.brand.printer_brand_data.code : null;
        return fetchAutomationData('controllable_printers/' + task.data.printer.controllable_printer_id, {brand_code: brandCode})
            .then(response => {
                return response.status === 200 ? response.json() : null;
            }).then((data) => {
                if(data !== null && data.status_code !== 200) {
                    data = null;
                }
                setControllablePrinter(data ? data.data : null);
                return data;
            })
            .catch(() => {
                setControllablePrinter(null);
                return null;
            });
    }

    useEffect(() => {
        if(task.data !== undefined) {
            setIsFixedScheduling(task.data.is_fixed_scheduling);
        }
        if(task.data !== undefined && task.data.printer.controllable_printer_id) {
            getControllablePrinter();
        }
    }, [task.isLoading]);

    /* Get controllable printer print information each minute */
    useEffect(() => {
        const interval = setInterval(() => {
            if(task.data !== undefined && task.data.printer.controllable_printer_id) {
                getControllablePrinter();
            }
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    const [menuState, setMenuState] = useState('details');
    const [menuAlreadyChecked, setMenuAlreadyChecked] = useState(false);
    const [isTaskLoading, setIsTaskLoading] = useState(false);
    const [alert, setAlert] = useState();
    const [isFixedScheduling, setIsFixedScheduling] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showActiveSSModal, setShowActiveSSModal] = useState(false);
    const [isOpenDoorDigitCodeVisible, setIsOpenDoorDigitCodeVisible] = useState(false);

    const handleClickScheduling = async () => {
        const newFixedScheduling = !isFixedScheduling;
        setIsFixedScheduling(newFixedScheduling);

        if(!newFixedScheduling)
            setShowActiveSSModal(true);

        await fixUnfixTaskScheduling(newFixedScheduling, task.data.id)
            .then(() => {
                if(newFixedScheduling)
                    successCallback('This job will no longer be scheduled automatically.');
                else
                    successCallback('This job will be scheduled automatically.');
            })
            .catch(() => {
                errorCallback('Something went wrong.');
            })
        setIsTaskUpdated(true);
    }

    const handleRunSmartScheduling = async () => {
        let tasks = await fetchData('tasks', `printer.id=${task.data.printer.id}`);
        tasks = await tasks.json()
        // Add external task from controllable printer
        if(controllablePrinter !== null) {
            if (controllablePrinter.active_task !== undefined &&
                (controllablePrinter.active_task.ended_at === undefined &&
                    controllablePrinter.state.toLowerCase() === 'printing')) {
                tasks.push({
                    id: controllablePrinter.active_task.id,
                        date: getCurrentTimestamp(),
                    priority: true,
                    print_duration: controllablePrinter.active_task.estimated_seconds_remaining,
                    is_fixed_scheduling: true
                });
            }
        }

        const scheduleResponse = await requestAutoScheduling(tasks, 0, 0);

        if(scheduleResponse.success){
            for(let task of scheduleResponse.scheduled_tasks){
                if(task.id >= 0){
                    task.date = task.start;
                    patchTaskSmartScheduling(task);
                }
            }
        }

        setShowActiveSSModal(false);
    };

    const successCallback = (message) => {
        setIsTaskLoading(true);
        task.refetch().then(() => {
            setIsTaskLoading(false);
            setAlert({message: message, status: "success", date: new Date()});
        });
        setIsTaskUpdated(true);
    }

    const errorCallback = (message) => {
        if(message === 'clickOut')
            return;
        
        setIsTaskLoading(true);
        task.refetch().then(() => {
            setIsTaskLoading(false);
            setAlert({message: message, status: "error", date: new Date()})
        });
        setIsTaskUpdated(true);
    };

    const handleOpenDoor = (user) => {
        const userId = user.id ? user.id : null;
        const farmCell = task.data.printer.farm_cell;

        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.DOOR_CLOSED, 0);

        postFarmEvent(farmCell.id, FARM_EVENT_TYPES.DOOR_CLOSED, 0, userId)
            .then(() => null)
            .catch(() => null);

        setAlert({message: 'The command has been sent.', status: 'success', date: new Date()});
    };

    const handleDeleteTask = () => {

        fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${task.data.id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(() => {
                setAlert({message: "The job has been removed.", status: ALERT_STATUS.SUCCESS, date: new Date()});
                setShowModal(false);
                setRightPartDetailsIsOpen(false);
                if(reloadPageData) {
                    reloadPageData();
                }
            })
            .catch(() => {
                errorCallback('Something went wrong.');
            })
    }

    const downloadJobSummary = async () => {
        let pdfGenerationUrl = process.env.REACT_APP_BASE_PROTOCOL + '://' + process.env.REACT_APP_BASE_URL;

        if(process.env.REACT_APP_PDF_GENERATION_PORT)
            pdfGenerationUrl += ':' + process.env.REACT_APP_PDF_GENERATION_PORT;

        pdfGenerationUrl += process.env.REACT_APP_PDF_GENERATION_URI;

        window.open(pdfGenerationUrl + task.data.id);
    };

    /* Open second tab if print is running */
    useEffect(() => {
        if(!menuAlreadyChecked && !task.isLoading){
            if(task.data.status === 'progress')
                setMenuState('action');

            setMenuAlreadyChecked(true);
        }
    }, [task.isLoading]);

    if(task.isLoading) return <Loading/>
    if(task.isError) return <Error errorMessage={task.error}/>

    return(
        <>
            <div id="main__task-details" key={taskId}>
                <DigitCode
                    visible={isOpenDoorDigitCodeVisible} setVisible={setIsOpenDoorDigitCodeVisible}
                    callback={handleOpenDoor} errorCallback={() => null}
                />

                {alert && <AlertMessage key={alert.date} message={alert.message} status={alert.status}/>}
                <LinearProgress color={"secondary"} hidden={!isTaskLoading}/>
                <div className="details__header">
                    <div className="header__title">
                        <h1>
                            {task.data.name}
                            {(task.data.pic && task.data.pic.id) &&
                                <a href={`/builder/${task.data.pic.id}`}>
                                    <img src={pic_details_icone} alt="pic-details"/>
                                </a>
                            }
                        </h1>

                        <div>
                            {
                                isUserGranted('ROLE_MANAGE_SMART_FARM') &&
                                task.data.printer && task.data.printer.farm_cell &&
                                    <button onClick={() => setIsOpenDoorDigitCodeVisible(true)}>Open door</button>
                            }

                            <button onClick={downloadJobSummary}>Job summary</button>

                            {isUserGranted('ROLE_DELETE_TASK') &&
                                <button className="button-error" onClick={() => setShowModal(true)}>Delete job</button>
                            }

                            {
                                task.data.status === 'upcoming' ? <span className="state task-state-upcoming">Incoming</span> :
                                    task.data.status === 'progress' && (task.data.start_validated || isTaskExternalTask) ? <span className="state task-state-progress">In progress</span> :
                                        task.data.status === 'progress' ? <span className="state task-state-upcoming">Waiting user action</span> :
                                            <span className="state task-state-done">Done</span>
                            }
                        </div>
                    </div>
                    {!isTaskExternalTask &&
                        <div className="header-details">
                            {isUserGranted('ROLE_UPDATE_TASK') &&
                                <>
                                    <div onClick={handleClickScheduling}>
                                        <Checkbox checked={!isFixedScheduling}/>
                                        <p>Smart scheduling</p>
                                    </div>
                                </>
                            }
                        </div>
                    }

                    {!isTaskExternalTask &&
                        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                            <Box>
                                <h3 style={{marginRight: 16, marginLeft: 0, fontSize: 12}}>Progress</h3>
                            </Box>
                            <Box width="50%">
                                <BorderLinearProgress variant="determinate" value={getTaskProgress(task.data, controllablePrinter)}/>
                            </Box>
                            <Box>
                                <h3 style={{marginRight: 0, marginLeft: 16, fontSize: 12}}>{getTaskProgress(task.data, controllablePrinter)}%</h3>
                            </Box>
                        </Box>
                    }

                    {(!isTaskExternalTask && task.data.status === 'progress' && task.data.start_validated && controllablePrinter !== null &&
                            controllablePrinter.active_task !== undefined && controllablePrinter.active_task !== null) &&
                        <div className="task-progression-infos">
                            {controllablePrinter.active_task.estimated_seconds_remaining !== undefined &&
                                <p>Estimated time remaining: <span>{getFormattedDuration(controllablePrinter.active_task.estimated_seconds_remaining)}</span></p>
                            }
                            {controllablePrinter.active_task.current_layer !== undefined &&
                                <p>Current layer: <span>{controllablePrinter.active_task.current_layer} / {controllablePrinter.active_task.layer_count}</span></p>
                            }
                        </div>
                    }
                </div>
                <div className="task-details__body">
                    <div className="task-details__menu">
                        <img src={details_icone} className={menuState === 'details' ? 'active' : ''} onClick={() => setMenuState('details')} alt="details"/>
                        <img src={actions_icone} className={menuState === 'action' ? 'active' : ''} onClick={() => setMenuState('action')} alt="actions"/>
                    </div>
                    <div  className="details-content">
                        <div>
                            {
                                menuState === 'details' ?
                                    <TaskInformation
                                        task={task.data}
                                        farmEvents={farmEvents}
                                        printFails={printFails}
                                        refetchTask={refetchTask}
                                        successCallback={successCallback}
                                        errorCallback={errorCallback}
                                        setIsTaskLoading={setIsTaskLoading}
                                        isTaskExternalTask={isTaskExternalTask}
                                        setAlert={setAlert}
                                        setIsTaskUpdated={setIsTaskUpdated}
                                    />
                                : isUserGranted('ROLE_UPDATE_TASK') ?
                                    <TaskActions
                                        task={task}
                                        successCallback={successCallback}
                                        errorCallback={errorCallback}
                                        setIsTaskLoading={setIsTaskLoading}
                                        setRightPartDetailsIsOpen={setRightPartDetailsIsOpen}
                                        controllablePrinter={controllablePrinter}
                                        setAlert={setAlert}
                                        getControllablePrinter={getControllablePrinter}
                                    />
                                : <p>No action available.</p>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <Modal
                    title="Delete a job"
                    description={`Are you sure you want to delete the job <strong>${task.data.name}</strong>?`}
                    buttons={[
                        {label: `Yes, delete <strong>${task.data.name}</strong>`, callback: () => handleDeleteTask(task.data.id)}
                    ]}
                    cancelCallback={() => setShowModal(false)}
                />
            )}

            {showActiveSSModal && (
                <Modal
                    title="Smart scheduling execution"
                    description={'Do you want to execute the Smart scheduling algorithm?'}
                    buttons={[
                        {label: 'Yes', callback: handleRunSmartScheduling}
                    ]}
                    cancelCallback={() => setShowActiveSSModal(false)}
                />
            )}
        </>
    )
}

import React from "react";
import {Alert} from "@material-ui/lab";

export const Error = (props) => {

    const {errorMessage} = props;

    return (
        <>
            <Alert severity="error">
                <div>
                    Sorry, something went wrong.
                </div>
                {errorMessage !== undefined &&
                    <div>
                        {errorMessage}
                    </div>
                }
            </Alert>
        </>
    );

}
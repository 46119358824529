import {GoogleMap} from "./GoogleMap";
import {Wrapper, Status} from "@googlemaps/react-wrapper";
import * as React from "react";
import {useState} from "react";
import {GoogleMarker} from "./GoogleMarker";
import BlackMarker from "../../../images/icons/map/marker_black.svg";
import BlueMarker from "../../../images/icons/map/marker_blue.svg";
import RedMarker from "../../../images/icons/map/marker_red.svg";

// Main component to use in the app
export const GoogleMapMain = (props) => {

    // Markers format: array of {key, color, latitude, longitude}
    const {markers, handleMarkerClick} = props;
    const markersIcons = [BlackMarker, BlueMarker, RedMarker];

    const [clicks, setClicks] = useState([]);
    const [zoom, setZoom] = useState(3); // initial zoom
    const [center, setCenter] = useState({lat: 44.833328,lng: -0.56667}); // Initial map center (Bordeaux)

    const onMapClick = (e) => {
        setClicks([...clicks, e.latLng]);
    };

    const onMapIdle = (m) => {
        setZoom(m.getZoom());
        setCenter(m.getCenter().toJSON());
    };

    const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return status;
            case Status.FAILURE:
                return status;
            case Status.SUCCESS:
                return <GoogleMap
                    center={center}
                    onClick={onMapClick}
                    onIdle={onMapIdle}
                    zoom={zoom}
                    style={{ flexGrow: "1", height: "100%" }}
                >
                    {markers && markers.map((marker) => (
                        <GoogleMarker
                            key={marker.key} icon={markersIcons[marker.color]}
                            position={new window.google.maps.LatLng(marker.latitude, marker.longitude)}
                            onClickAction={() => handleMarkerClick(marker.key)}
                        />
                    ))}
                </GoogleMap>
        }
    };

    return(
        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={render}/>
    );
}

import {useGetData} from "../../../api/useFetch";
import React, {useEffect, useState} from "react";
import {getCurrentUser} from "../../CommonFunctions";
import {SelectPicker} from "rsuite";

export const OrganizationBlock = (props) => {

    const {selectedOrganization, setSelectedOrganization} = props;

    const currentUser = getCurrentUser();

    /* Get all accessible organizations */
    const organizationGroup = useGetData('organizationGroup',
        'organization_groups/' + currentUser.organization.organization_group.id +
        '?groups[]=read:OrganizationGroup:Organizations'
    );

    const [organizations, setOrganizations] = useState([]);

    useEffect(() => {
        if(organizationGroup.data && organizationGroup.data.organizations && organizationGroup.data.organizations.length > 0) {
            // Initialize selected organization data
            let currentOrganizationId = getSelectedOrganizationIdDependingOnLocalStorage();
            setSelectedOrganization(getSelectedOrganizationData(currentOrganizationId));

            // Set organizations list
            setOrganizations(organizationGroup.data.organizations.map(organization => (
                {label: organization.name, value: organization.id}
            )));
        }
    }, [organizationGroup.data]);

    /* Get organization id in local storage */
    const getSelectedOrganizationIdDependingOnLocalStorage = () => {
        /* Get selected organization in local storage */
        let currentOrganizationId = localStorage.getItem('selected-organization');
        if(currentOrganizationId === undefined || currentOrganizationId === null || currentOrganizationId === 'null') {
            return currentUser.organization.id;
        } else {
            return parseInt(currentOrganizationId);
        }
    }

    /* Get organization data depending on organization id */
    const getSelectedOrganizationData = (organizationId) => {
        if(organizationGroup.data && organizationGroup.data.organizations && organizationGroup.data.organizations.length > 0 && organizationId) {
            let organizationData = organizationGroup.data.organizations.filter(organization => organization.id === organizationId);
            if(organizationData.length > 0) {
                return organizationData[0];
            }
        }
        return null;
    }

    /* Update selected organization */
    const handleSelectOrganization = (selectedOrganizationId) => {
        if(selectedOrganizationId) {
            localStorage.setItem('selected-organization', JSON.stringify(selectedOrganizationId));
            setSelectedOrganization(getSelectedOrganizationData(selectedOrganizationId));
        }
    };

    return(
        <div className="farm-header-solid-block farm-header-organization-block">
            <div className="organization-block-content">
                <div className="organization-block-label">Organization</div>
                <div className="organization-block-value">
                    {(selectedOrganization && selectedOrganization.name) ? selectedOrganization.name : 'No selected organization'}
                </div>
                <div className="organization-block-select">
                    <SelectPicker
                        block cleanable={false} searchable={false} size="sm"
                        placeholder="Select an organization" data={organizations}
                        value={selectedOrganization ? selectedOrganization.id : null}
                        onChange={handleSelectOrganization}
                        renderValue={() => 'Change organization'}
                    />
                </div>
            </div>
        </div>
    );
}

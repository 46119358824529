import React, {useCallback} from "react";
import {downloadAPIFile} from "../../api/useFetch";
import {DropZone} from "../common/DropZone";
import {getCurrentUser} from "../CommonFunctions";


export const ProjectFiles = (props) => {

    const {project, successCallback, errorCallback} = props;

    const onFileUploaded = useCallback((response) => {
        successCallback('Files has been added to the project.');
    }, []);

    const onFileNotUploaded = useCallback((response) => {
        errorCallback('Something went wrong.');
    }, []);

    const createRequestBody = useCallback((file, containerData) => {
        return {
            user: process.env.REACT_APP_API_URI + `users/${getCurrentUser().id}`,
            name: file.name,
            size: file.size,
            project: process.env.REACT_APP_API_URI + `projects/${containerData.id}`
        };
    }, []);

    return (
        <>
            <div id="project__pieces" className="block">
                <h2>Pieces in the project</h2>

                <div id="pieces__listing">
                    {
                        project.pieces_in_project.length > 0 ?
                            project.pieces_in_project.map(piece_in_project => (
                                <div className="listing__piece-item" key={piece_in_project.id}>
                                    <a href="#">
                                        <div className="piece__name">{piece_in_project.piece.name}</div>
                                        <p className="piece__quantity">x{piece_in_project.copies}</p>
                                    </a>
                                </div>
                            ))
                            : <em>No piece in the project</em>
                    }
                </div>
            </div>
            <div id="project__files" className="block">
                <h2>Files assiociated to the project ({project.attached_files.length} file(s))</h2>

                <div id="files__list">
                    {project.attached_files.map((file) => (
                        <a href="#" className="files-list__file-item" key={file.id}
                           onClick={() => downloadAPIFile(
                               `project_attached_files/${file.id}/download`,
                               file.name
                           )}>{file.name}</a>
                    ))}
                </div>

                <DropZone
                    uploadEndpoint={'project_attached_files'}
                    createRequestBody={createRequestBody}
                    onFileUploaded={onFileUploaded}
                    onFileNotUploaded={onFileNotUploaded}
                    containerData={project}
                />
            </div>
        </>
    )

}
import {emitNotificationSocket} from "./socketEventsService";
import {getCurrentTimestamp} from "../components/CommonFunctions";

export const NOTIFICATION_TYPES = [
    {name: 'Print fail detection', type: 'PFD', app: 'PFD_APP', email: 'PFD_EMAIL'}
];

export const emitNotification = (title, content, link, type, user_id) => {
    emitNotificationSocket({
        title: title,
        content: content,
        link: link,
        type: type,
        creation_date: getCurrentTimestamp(),
        user_id: user_id
    });
};
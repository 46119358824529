export const BUTTON_COLORS = {
    MAIN: 'simple-button-main',
    ERROR: 'simple-button-error',
    WARN: 'simple-button-warn',
    DEFAULT: 'simple-button-default'
}

export const SimpleButton = (props) => {

    const {label, action, icon, isOutlined, color, isBold, padding} = props;

    const getButtonClassnames = () => {
        let classNames = ['simple-button'];
        classNames.push(isOutlined ? 'simple-button-outlined' : 'simple-button-fill');
        classNames.push((color ? color : BUTTON_COLORS.DEFAULT) + '-' + (isOutlined ? 'outlined' : 'fill'));
        (isBold === undefined || isBold) && classNames.push('simple-button-bold');
        return classNames.join(' ');
    }

    const handleButtonClick = () => {
        if(action) {
            return action();
        }
    }

    return(
        <button className={getButtonClassnames()} onClick={handleButtonClick} style={{padding: (padding ? padding : 10) + 'px'}}>
            <div>
                {icon &&
                    <span className="material-icons simple-button-icon">{icon}</span>
                }
                <span>{label}</span>
            </div>
        </button>
    );
}
import React, {useEffect, useState} from "react";
import {Card, CardContent, Input, InputAdornment, Popover} from "@material-ui/core";
import {ReactComponent as CardNameIcon} from "../../images/icons/pic/Action-card.svg";
import {ReactComponent as SearchIcon} from "../../images/icons/pic/search_black_24dp.svg";
import {ReactComponent as IconeAction} from '../../images/icons/pic/IconAction.svg';
import SetupDisableIcon from "../../images/icons/pic/1-PreparationFichiers-disable-8.png";
import FabricationDisableIcon from "../../images/icons/pic/2-fabrication-disable-8.png";
import PostProcessingDisableIcon from "../../images/icons/pic/3-Post-fabrication-disable-8.png";
import {useStyles} from "../../css/pic/pic";
import {CardPlatform} from "./cards/CardPlatform";
import {CardSlicer} from "./cards/CardSlicer";
import {CardMachineJob} from "./cards/CardMachineJob";
import {CardPostTreatment} from "./cards/CardPostTreatment";
import {PicCard} from "./Pic.class";
import {CardUndefined} from "./cards/CardUndefined";

/* Render a PIC card object */
export const CardMain = (props) => {
    const classes = useStyles();

    // data controls the data of the current card in CardMain
    const {picCard, index, slicerDuration, refreshGlobalState, handleClickDeleteCard, globalCardData, setGlobalCardData,
        isScheduled, canDelete, handleContinueCard, setPrinterData, updateUntitledPicNameFromStl} = props;

    const [isActive, setIsActive] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [moreId, setMoreId] = useState(null);

    const [, refreshCardState] = useState();

    const handleClickMore = (event, index) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setMoreId(index);
    }

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
        setMoreId(null);
    };

    const open = Boolean(anchorEl);

    // const generateCardBody = (type, title, data) => {
    //     cardData.type = type;
    //     cardData.title = title;
    //     cardData.data = data;
    //
    //     refreshCardState({});
    // };

    const handleClickCardItem = (displayData) => {
        picCard.type = displayData.type;
        picCard.card = PicCard.CARD_FIELDS[picCard.type];

        refreshCardState({});
    }

    // useEffect(() => {
    //     if(cardData.data)
    //         generateCardBody(cardData.type, cardData.title, cardData.data);
    // }, []);

    return (
        <div>
            <Card className="card">
                <CardContent className="card-content">
                    <div className={classes.card_header} onClick={() => setIsActive(!isActive)}
                    id={`${
                        picCard.type === PicCard.TYPE_BUILD_PLATFORM ? 'build-platform' :
                        picCard.type === PicCard.TYPE_SLICER_CONNECTION ? 'slicer-connection' :
                        picCard.type === PicCard.TYPE_MACHINE_JOB ? 'machine-job' :
                        picCard.type === PicCard.TYPE_POST_TREATMENT ? 'post-treatment' : 'undefined'
                    }${index+1}`}>
                        <CardNameIcon className="card-icone" style={{width: "60px"}}/>
                        <h1 className="card-title" data-order={index+1}>. {PicCard.DISPLAY_DATA[picCard.type].name}</h1>
                        <IconeAction className="icone-action" style={{display: canDelete ? 'none' : ''}} onClick={(e) => handleClickMore(e, index)}/>
                        <Popover
                            id={index}
                            open={open && moreId === index}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div className="actions-list">
                                <button onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickDeleteCard(index);
                                    handleClose(e);
                                }}
                                >
                                    Delete
                                </button>
                            </div>
                        </Popover>
                    </div>
                    <div className={'card-body'+ (!isActive ? ' disable' : '')}>
                        {
                            {
                                [PicCard.TYPE_BUILD_PLATFORM]:
                                    <CardPlatform refreshGlobalState={refreshGlobalState} picCard={picCard} setIsActive={setIsActive}
                                                  globalCardData={globalCardData} handleContinueCard={handleContinueCard}
                                                  updateUntitledPicNameFromStl={updateUntitledPicNameFromStl}/>,

                                [PicCard.TYPE_SLICER_CONNECTION]:
                                    <CardSlicer refreshGlobalState={refreshGlobalState} picCard={picCard} setIsActive={setIsActive} setPrinterData={setPrinterData}
                                                globalCardData={globalCardData} isScheduled={isScheduled} handleContinueCard={handleContinueCard}/>,

                                [PicCard.TYPE_MACHINE_JOB]:
                                    <CardMachineJob refreshGlobalState={refreshGlobalState} picCard={picCard} setIsActive={setIsActive}
                                                    globalCardData={globalCardData} handleContinueCard={handleContinueCard}/>,

                                [PicCard.TYPE_POST_TREATMENT]:
                                    <CardPostTreatment refreshGlobalState={refreshGlobalState} picCard={picCard} setIsActive={setIsActive}
                                                       globalCardData={globalCardData} handleContinueCard={handleContinueCard}/>,

                                [PicCard.TYPE_UNDEFINED]:
                                    <CardUndefined handleClickCardItem={handleClickCardItem}/>
                            }[picCard.type]
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}


import React, {useEffect} from "react";
import ReactHtmlParser from "html-react-parser";

export default function Modal(props) {
    const {title, description, buttons, cancelCallback} = props;

    // Escape Listener Hook
    useEffect(() => {
        const escape = (e) => {
            if (e.key === 'Escape')
                cancelCallback();
        };
        document.addEventListener('keydown', escape, false);
        document.body.style.overflow = 'hidden';

        return () => {
            document.removeEventListener('keydown', escape, false);
            document.body.style.overflow = 'unset';
        };
    }, []);

    // Modal Box
    return (
        <div
            className="modal__overlay"
            onClick={(e) => {
                if (e.target === e.currentTarget)
                    cancelCallback();
            }}
        >
            <div className="modal__dialog">
                <div className="dialog__content">
                    <h2 className="content__title">{title}</h2>
                    {
                        description && <p>{ReactHtmlParser(description)}</p>
                    }
                </div>
                <div className="dialog__footer">
                    <button className="button-error" onClick={cancelCallback}>Cancel</button>
                    {
                        buttons && buttons.map((button, index) =>
                            <button key={index} onClick={button.callback} className="btn-white">{ReactHtmlParser(button.label)}</button>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

// Pour call ce Composant, veuillez suivre le parametrage tel quel :
// ----------------------------------------------------------------

// ----- Configurer un Boolean State pour montrer/cacher la modale -----
// const [showModal, setShowModal] = useState(false);

// ----- Ne pas oublier de reset le state à false dans les fonctions calls, par ex (Func cancel): -----
// const cancel = () => {setShowJobModal(false);}

// ----- Sur le bouton concerné, rajouter un onClick de type :
// onClick= {() => {setShowJobModal(true)}}

// ----- Configuration de la modale -----
// {showJobModal &&

//  <Modal
//     title="Delete a Task"                                                                                ----- Title : String
//     description={`Are you sure you want to delete the Task ${task.data.name}`}                           ----- Description : String
//     buttons={[                                                                                           ----- Buttons : Objects array with 2 values: 
//         {label: `Yes, delete ${task.data.name}`, callback: () => handleDeleteTask(task.data.id)}                     -> label for the name of the button
//     ]}                                                                                                               -> callback for the associated func 
//     cancelCallback={cancelModal}                                                                         -----Cancel button : Func
//  />

import React from "react";
import {useParams} from "react-router-dom";
import {useGetData} from "../../api/useFetch";
import {useForm, Controller} from "react-hook-form";
import {confirmUser} from "../../api/apiUser";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";

export const Confirm = () => {

    // THIS PART OF THE PROCESS IS NO LONGER USED

    const {token} = useParams();

    const {handleSubmit, setValue, control} = useForm();

    const {data: users, isLoading, isError, error} = useGetData('usertoken', 'new_users', {token: token});

    const numOfFields = 6;

    const handleChange = (e) => {
        const { maxLength, value, name } = e.target;
        setValue(name, value)
        const [fieldName, fieldIndex] = name.split('_');

        // Check if max character length
        if (value.length >= maxLength) {
            // Check if it's not the last input field
            if (parseInt(fieldIndex) < numOfFields) {
                // Get the next input field
                const nextSibling = document.querySelector(
                    `input[name=number_${parseInt(fieldIndex, 10) + 1}]`
                );

                // If found, focus the next field
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
    }

    const onSubmit = (data) => {
        let code = data.number_1 + data.number_2 + data.number_3 + data.number_4 + data.number_5 + data.number_6;
        confirmUser(code, users[0]);
    }


    if(isLoading) return <Loading/>
    if(isError) return <Error errorMessage={error}/>

    if(users.length !== 1)
        return 'Invalid token.';

    const user = users[0];

    return (
        <div id="main__confirm">
            <div>
                <h1>Verify your email address</h1>

                <p>We just sent you a mail with a verification code. Please enter the code below :</p>

                <form id="confirm__form" onSubmit={handleSubmit(onSubmit)}>

                    <div id="confirm__numbers">
                        <div>
                            <Controller
                                control={control}
                                name="number_1"
                                rules={{required: true}}
                                render={({field}) => (
                                    <input type="text" name="number_1" className="numbers__number accept-numbers" maxLength="1" id="f-number1" {...field} onChange={handleChange}/>
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name="number_2"
                                rules={{required: true}}
                                render={({field}) => (
                                    <input type="text" name="number_2" className="numbers__number accept-numbers" maxLength="1" id="f-number1" {...field} onChange={handleChange}/>
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name="number_3"
                                rules={{required: true}}
                                render={({field}) => (
                                    <input type="text" name="number_3" className="numbers__number accept-numbers" maxLength="1" id="f-number1" {...field} onChange={handleChange}/>
                                )}
                            />
                        </div>

                        &nbsp;&nbsp;&nbsp;
                        <div>
                            <Controller
                                control={control}
                                name="number_4"
                                rules={{required: true}}
                                render={({field}) => (
                                    <input type="text" name="number_4" className="numbers__number accept-numbers" maxLength="1" id="f-number1" {...field} onChange={handleChange}/>
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name="number_5"
                                rules={{required: true}}
                                render={({field}) => (
                                    <input type="text" name="number_5" className="numbers__number accept-numbers" maxLength="1" id="f-number1" {...field} onChange={handleChange}/>
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name="number_6"
                                rules={{required: true}}
                                render={({field}) => (
                                    <input type="text" name="number_6" className="numbers__number accept-numbers" maxLength="1" id="f-number1" {...field} onChange={handleChange}/>
                                )}
                            />
                        </div>
                    </div>

                    <input type="submit" value="Valider" id="confirm__button"/>
                </form>

                <div id="confirm__footer">
                    <p>
                        Handddle 2021<br/>
                        V.0.0.0
                    </p>
                </div>
            </div>
        </div>
    )
}


import React, {useEffect, useState} from "react";
import {Loading} from "../../common/Loading";
import {Input, InputGroup, Pagination, Table} from "rsuite";
import {getFormattedDate, getFormattedDuration} from "../../CommonFunctions";
import {getSortedData} from "../../../services/TablesService";
import {fetchAutomationData, useGetAutomationData} from "../../../api/apiConnectivity";

export const CardSlicerBuildTable = (props) => {

    const {setIsBuildTableOpen, apiConnection, handleBuildChange, selectedPrinters, tableHeight} = props;

    const brandCode = apiConnection.brand.printer_brand_data ? apiConnection.brand.printer_brand_data.code : null;

    /* Get builds data path to make builds requests */
    const getBuildRequestParams = (pageNumber = 1) => {
        let params = {};
        params.brand_code = brandCode
        params.page = pageNumber
        return params;
    }

    const builds = useGetAutomationData('builds', 'controllable_printers/builds', getBuildRequestParams());
    const controllablePrinters = useGetAutomationData('controllablePrinters', 'controllable_printers');

    const elementsPerPage = 100;

    const { Column, HeaderCell, Cell } = Table;
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [page, setPage] = useState(1);
    // Last build data page requested from external API
    const [lastPageRequest, setLastPageRequest] = useState(1);
    const [hasMoreBuilds, setHasMoreBuilds] = useState(false);
    const [buildsData, setBuildsData] = useState([]);
    const [buildsDataFilter, setBuildsDataFilter] = useState([]);
    const [deviceSeries, setDeviceSeries] = useState([]);
    const [filteredValue, setFilteredValue] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(builds.data && builds.data.status_code === 200) {
            setBuildsData(getFilteredBuildsOnDeviceSeries(builds.data.data ? builds.data.data : []));
            // Check if other builds are available
            setHasMoreBuilds(builds.data.has_more_items);
        } else {
            setBuildsData([]);
            setHasMoreBuilds(false);
        }
    }, [builds.data, deviceSeries]);

    useEffect(() => {
        setLoading(true);
        if(lastPageRequest > 1 && filteredValue && filteredValue.length > 0) {
            setBuildsDataFilter(getFilteredData(filteredValue, buildsData));
        } else {
            setBuildsDataFilter(buildsData);
        }
        setLoading(false);
    }, [buildsData]);

    useEffect(() => {
        if(controllablePrinters.data !== undefined) {
            let devices = [];
            // Find all device series corresponding to selected controllable printers
            selectedPrinters.map(printer => {
                if(printer.controllable_printer_id) {
                    for(let [key, brandControllablePrinters] of Object.entries(controllablePrinters.data)) {
                        if(key === apiConnection.brand.name.toLowerCase() && brandControllablePrinters.status_code === 200) {
                            let controllablePrinter = brandControllablePrinters.data.filter(item =>
                                item.id_manufacturer === printer.controllable_printer_id
                            )[0];
                            if(controllablePrinter && controllablePrinter.printer_series) {
                                devices.push(controllablePrinter.printer_series);
                            }
                        }
                    }
                }
            });
            // Get only distinct values
            devices = devices.filter(filterUniqueValues);
            setDeviceSeries(devices);
        }
    }, [selectedPrinters, controllablePrinters.data]);

    /* Filter unique values in an array */
    const filterUniqueValues = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    /* Request more builds */
    const loadMoreBuilds = () => {
        fetchAutomationData('controllable_printers/builds', getBuildRequestParams(lastPageRequest + 1))
            .then(response => {
                response.json().then(data => {
                    if(data.status_code === 200 && data.data) {
                        // Update builds data
                        setBuildsData([...buildsData, ...getFilteredBuildsOnDeviceSeries(data.data)]);
                        // Check if other builds are available
                        setHasMoreBuilds(data.has_more_items);
                    }
            }).catch(() => null);
        });
        setLastPageRequest(lastPageRequest + 1);
    }

    /* Sorting */
    const handleSortColumn = async (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    /* Filtering */
    const handleSearchBarChange = (value) => {
        setBuildsDataFilter(getFilteredData(value, buildsData));
    }

    const getFilteredData = (value, buildsList) => {
        value = value.toLowerCase();
        setFilteredValue(value);
        return buildsList.filter(build => {
            return (
                (build.name && build.name.toLowerCase().includes(value))
                || (build.created_at && getFormattedDate(build.created_at).includes(value))
                || (build.updated_at && getFormattedDate(build.updated_at).includes(value))
                || (build.estimated_print_duration && getFormattedDuration(build.estimated_print_duration).includes(value))
                || (build.material_1 && build.material_1.toLowerCase().includes(value))
                || (build.material_2 && build.material_2.toLowerCase().includes(value))
            );
        })
    }

    /* Filter builds list to get only builds corresponding to printers device series */
    const getFilteredBuildsOnDeviceSeries = (buildsList) => {
        return buildsList.filter(build => {
            for (const element of deviceSeries) {
                if (build.printer_series && element === build.printer_series) {
                    return true;
                }
            }
            return false;
        });
    }

    /* Pagination */
    const getPaginatedData = (data) => {
        if(buildsDataFilter.length <= 100) {
            return data;
        } else {
            let buildsList = [...buildsDataFilter];
            buildsList = buildsList.filter((value, index) => {
                const start = elementsPerPage * (page - 1);
                const end = start + elementsPerPage;
                return index >= start && index < end;
            });
            return buildsList;
        }
    }

    /* Pic selection */
    const handleRowClick = (data) => {
        handleBuildChange(data);
        setIsBuildTableOpen(false);
    }

    if(builds.isLoading) return <Loading/>

    return(
        <div className="main__card_slicer_build_table main__table">
            <div className="flex">
                <h2 className="title">{'Choose a build (' + apiConnection.brand.name + ')'}</h2>
                <div className="search-bar">
                    <InputGroup inside className="search-bar-input">
                        <Input placeholder="Search" onChange={(value) => handleSearchBarChange(value)}/>
                        <InputGroup.Addon className="search-bar-addon-icon">
                            <i className="fa fa-search"/>
                        </InputGroup.Addon>
                    </InputGroup>
                </div>
            </div>

            <Table data={getPaginatedData(getSortedData(buildsDataFilter, sortColumn, sortType))}
                   onRowClick={(data) => handleRowClick(data)}
                   sortColumn={sortColumn}
                   sortType={sortType}
                   onSortColumn={handleSortColumn}
                   loading={loading}
                   autoHeight={false} height={tableHeight ? tableHeight : 460}
            >
                <Column flexGrow={3} verticalAlign="middle" sortable>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name"/>
                </Column>
                <Column flexGrow={2} verticalAlign="middle" sortable>
                    <HeaderCell>Created at</HeaderCell>
                    <Cell dataKey="created_at">
                        {rowData => getFormattedDate(rowData.created_at)}
                    </Cell>
                </Column>
                <Column flexGrow={2} verticalAlign="middle" sortable>
                    <HeaderCell>Last update</HeaderCell>
                    <Cell dataKey="updated_at">
                        {rowData => getFormattedDate(rowData.updated_at)}
                    </Cell>
                </Column>
                <Column flexGrow={3} verticalAlign="middle" sortable>
                    <HeaderCell>Print duration</HeaderCell>
                    <Cell dataKey="created_at">
                        {rowData => getFormattedDuration(rowData.estimated_print_duration)}
                    </Cell>
                </Column>
                <Column flexGrow={3} verticalAlign="middle" sortable>
                    <HeaderCell>Material 1</HeaderCell>
                    <Cell dataKey="material_1"/>
                </Column>
                <Column flexGrow={3} verticalAlign="middle" sortable>
                    <HeaderCell>Material 2</HeaderCell>
                    <Cell dataKey="material_2"/>
                </Column>
            </Table>
            <div>
                <Pagination
                    prev next first last ellipsis boundaryLinks maxButtons={5}
                    size="xs" layout={['total', '-', '|', 'pager', 'skip']}
                    total={buildsDataFilter.length} limit={elementsPerPage}
                    activePage={page} onChangePage={setPage}
                />
            </div>
            {hasMoreBuilds &&
                <button type="button" className="button-load-builds" onClick={() => loadMoreBuilds()}>
                    Load more builds...
                </button>
            }
        </div>
    );
}

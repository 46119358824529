import {useState, useEffect, useMemo, useRef} from "react";
import {
    dateToLongMonthYearString,
    dateToShortDayMonthYearString,
    getCurrentDateAtMidnight,
} from "../../../../utils/date";
import {DISPLAY_MODES} from "../Calendar";
import {DateTimePickerComponent} from "../../../common/DateTimePickerComponent";

export const ToolbarDateSelection = (props) => {
    const {displayMode, firstDisplayedDate, setFirstDisplayedDate} = props;
    const {selectNextDate} = props;
    
    const [isDateTimePickerOpen, setIsDateTimePickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(firstDisplayedDate);

    const refPreviousFirstDisplayedDate = useRef(null);
    useEffect(() => {
        refPreviousFirstDisplayedDate.current = firstDisplayedDate;
        localStorage.setItem('firstDisplayedDate', firstDisplayedDate);
    }, [firstDisplayedDate]);

    const handleClickToday = (e) => {
        setFirstDisplayedDate(getCurrentDateAtMidnight());
    };

    const handleClickSelectDate = (e) => {
        setSelectedDate(firstDisplayedDate);
        setIsDateTimePickerOpen(true);
    };

    const handleDateTimePickerClose = (e) => {
        setIsDateTimePickerOpen(false);
        setFirstDisplayedDate(selectedDate);
    }

    const handleClickNextDate = (e, reverse) => {
        selectNextDate(reverse);
    };

    const currentDateTextClassNames = useMemo(() => {
        let classNames = "gc-toolbar-date-selection__text";
        if (refPreviousFirstDisplayedDate !== null) {
            if (refPreviousFirstDisplayedDate.current > firstDisplayedDate) {
                classNames += " gc-toolbar-date-selection__text--animate-from-left";
            } else if (refPreviousFirstDisplayedDate.current < firstDisplayedDate) {
                classNames += " gc-toolbar-date-selection__text--animate-from-right";
            }
        }
        return classNames;
    }, [firstDisplayedDate]);

    const currentDateText = useMemo(() => {
        switch (displayMode) {
            case DISPLAY_MODES.MONTH:
                return dateToLongMonthYearString(firstDisplayedDate);
            case DISPLAY_MODES.DAY:
            case DISPLAY_MODES.WEEK:
            default:
                return dateToShortDayMonthYearString(firstDisplayedDate);
        }
    }, [displayMode, firstDisplayedDate]);

    return (
        <div className="gc-toolbar__date-selection gc-toolbar-date-selection">
            <button
                className="gc-toolbar-date-selection__button gc-toolbar-date-selection__button--primary"
                onClick={(e) => handleClickToday(e)}
            >
                Today
            </button>

            <button className="gc-toolbar-date-selection__button" onClick={(e) => handleClickNextDate(e, true)}>
                &lt;
            </button>

            <div key={firstDisplayedDate.getTime()} className={currentDateTextClassNames}>
                {currentDateText}
            </div>

            <button className="gc-toolbar-date-selection__button" onClick={(e) => handleClickNextDate(e, false)}>
                &gt;
            </button>
            <button
                className="gc-toolbar-date-selection__button gc-toolbar-date-selection__button--primary"
                onClick={(e) => handleClickSelectDate(e)}
            >
                Select date
            </button>
            <DateTimePickerComponent
                isDateTimePickerOpen={isDateTimePickerOpen}
                handleClose={handleDateTimePickerClose}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                validateIcon={true}
                showTime={false}
            />
        </div>
    );
};

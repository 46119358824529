import React, {useEffect, useMemo, useState} from "react";
import {OutlinedCard} from "../../common/OutlinedCard";
import {Popover, Whisper} from "rsuite";
import {getFormattedDate, getFormattedDuration} from "../../../CommonFunctions";
import {useGetData} from "../../../../api/useFetch";

Date.prototype.getWeek = function() {
    const d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    const week = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    if(week > 10 && this.getMonth() === 0)
        return 0;
    return week;
}

export const SystemStatusBlock = (props) => {

    const {systemCode} = props;

    const currentYear = (new Date()).getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    const endDate = new Date(currentYear, 11, 31);

    const [events, setEvents] = useState([]);

    const statuses = useGetData(
        'server_statuses_' + systemCode,
        'server_statuses',
        {
            systemCode: systemCode
        }
    );

    const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const daysLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    const allDays = useMemo(() => {
        const days = [];
        for (let dt = new Date(startDate); dt <= new Date(endDate); dt.setDate(dt.getDate() + 1))
            days.push(new Date(dt));
        return days;
    }, []);

    useEffect(() => {
        if(!statuses.isLoading){
            const newEvents = [];

            for(let status of statuses.data){
                let date = new Date(status.start_date * 1000);
                date.setHours(0, 0, 0, 0);

                if(!(date in newEvents))
                    newEvents[date] = 0;

                newEvents[date] += status.end_date - status.start_date;
            }

            setEvents(newEvents);
        }
    }, [statuses.isLoading]);

    return (
        <OutlinedCard>
            <h2>System status</h2>

            <div className="table-heatmap">
                <div className="months-labels">
                    {
                        monthLabels.map(month => <div key={month}>{month}</div>)
                    }
                </div>

                <div className="table-heatmap-grid">
                    {
                        daysLabels.map((day, index) =>
                            <div className="day-label" style={{top: index * 10, left: 0}} key={index}>
                                {day}
                            </div>
                        )
                    }
                    {
                        allDays.map((day, index) => {
                            const posTop = (day.getDay() + 6) % 7 * 11;
                            const postLeft = day.getWeek() * 11 + 30;

                            if(day <= new Date()){
                                let stateClass = events[day] > 4 * 3600 ? 'crit' : events[day] > 0 ? 'warn' : '';

                                return <Whisper placement="bottom" trigger="hover" key={index} speaker={
                                    <Popover><span>
                                        {getFormattedDate(day.getTime() / 1000)}
                                        {
                                            events[day] !== undefined ? ' - Missing data during ' + getFormattedDuration(events[day])
                                                : ' - Nothing to declare'
                                        }
                                    </span></Popover>
                                }>
                                    <div className={`block-day ${stateClass}`} key={index} style={{top: posTop, left: postLeft}}/>
                                </Whisper>
                            }
                            else{
                                return <div className="block-day future" key={index} style={{top: posTop, left: postLeft}}/>
                            }
                        })
                    }
                </div>
            </div>
        </OutlinedCard>
    );

};
import React, {useEffect, useState} from "react";
import {fetchData, useGetData} from "../../api/useFetch";
import {ViewerComponent} from "./ViewerComponent";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import reportWebVitals from "../../reportWebVitals";
import {STLLoader} from "../../lib/PieceLoader";


export const PieceViewer = (props) => {

    const {piece} = props;

    const [fileBytes, setFileBytes] = useState();
    const [error, setError] = useState();
    const [oldPiece, setOldPiece] = useState();

    useEffect(() => {
        if(!fileBytes){
            getFileBytes()
        }
        else if(!oldPiece || oldPiece.id !== piece.id){
            setFileBytes();
            getFileBytes()
        }
        
        setOldPiece(piece);

    }, [piece]);


    const getFileBytes = async () => {
        const path = `pieces/${piece.id}/download/bytes`;
        let response = await fetchData(path);

        if(response.ok){
            response = await response.blob();
            response = await response.arrayBuffer();
            response = new Uint8Array(response);
            setFileBytes(response);
        }
        else
            setError('Piece not found on the server.');
    }


    if(error) return <Error errorMessage={error}/>
    if(!fileBytes) return <Loading/>


    /* Read piece content */
    let loader = new STLLoader(fileBytes);

    return (
        <>
            <ViewerComponent loader={loader}/>
        </>
    )

}
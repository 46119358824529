import {getCurrentDateAtMidnight} from "../../../../utils/date";
import {isUserGranted} from "../../../CommonFunctions";

export const ToolbarActions = (props) => {
    const {schedulePic, setSelectedPrinterSchedule, handleOpenFastPrintDialog} = props;

    const handleClickLaunchPrint = (e) => {
        handleOpenFastPrintDialog();
    };

    const handleClickSchedulePic = (e) => {
        setSelectedPrinterSchedule('');
        schedulePic(getCurrentDateAtMidnight());
    };

    return (
        <div className="gc-toolbar__actions gc-toolbar-actions">
            {
                isUserGranted('ROLE_CREATE_TASK') &&
                    <button className="gc-toolbar-actions__button" onClick={(e) => handleClickLaunchPrint(e)}>
                        Fast print
                    </button>
            }
            &nbsp;
            {
                isUserGranted('ROLE_CREATE_TASK') &&
                    <button className="gc-toolbar-actions__button" onClick={(e) => handleClickSchedulePic(e)}>
                        Schedule a PIC
                    </button>
            }
        </div>
    );
};

import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {changePassword} from "../../api/apiUser";

export const ChangePasswordComponent = ({successCallback, errorCallback}) => {

    const {handleSubmit, control, setValue, setError, formState: { errors }} = useForm();

    const [fields, setFields] = useState({
        'old_password': '',
        'new_password': '',
        'confirm_password': ''
    });

    const onSubmit = async (data) => {

        if(data.new_password !== data.confirm_password) {
            setError("confirm_password", {
                    type: 'focus',
                    message: 'The passwords are different.'
                },
                {shouldFocus: true},
            );
        }
        else {
            await changePassword(data)
                .then((response) => {
                    if(response.ok)
                        successCallback('The password has been updated.')
                    else if(response.status === 401)
                        errorCallback('The old password is incorrect.')
                    else
                        errorCallback('Something went wrong.')
                })
                .catch(() => errorCallback('Something went wrong.'));
        }
    }

    return (
        <>
            <h2>Change password</h2>

            <form id="profile__form-password" onSubmit={handleSubmit(onSubmit)}>

                <div>
                    <div>
                        <label htmlFor="f-old-password">Old password</label>
                        <Controller
                            control={control}
                            name="old_password"
                            rules={{required: true}}
                            value={fields['old_password']}
                            defaultValue={fields['old_password']}
                            onChange={e => setFields({...fields, old_password: e.target.value})}
                            render={({field}) => (
                                <input type="password" id="f-old-password" {...field}/>
                            )}
                        />
                    </div>

                    <div>
                        <label htmlFor="f-password">New password</label>
                        <Controller
                            control={control}
                            name="new_password"
                            rules={{required: true}}
                            value={fields['new_password']}
                            defaultValue={fields['new_password']}
                            onChange={e => setFields({...fields, new_password: e.target.value})}
                            render={({field}) => (
                                <input type="password" id="f-password" {...field}/>
                            )}
                        />
                    </div>
                </div>

                <div>
                    <div>
                        <label htmlFor="f-confirm-password">Confirm password</label>
                        <Controller
                            control={control}
                            name="confirm_password"
                            rules={{required: true}}
                            value={fields['confirm_password']}
                            defaultValue={fields['confirm_password']}
                            onChange={e => setFields({...fields, confirm_password: e.target.value})}
                            render={({field}) => (
                                <input type="password" id="f-confirm-password" {...field}/>
                            )}
                        />
                    </div>

                </div>
                {errors.confirm_password && <p style={{color: "red"}}>{errors.confirm_password.message}</p>}

                <button><i className="fa fa-check"/> Update the password</button>

            </form>
        </>
    )
}
import React, {useEffect} from "react";
import {NavLink} from 'react-router-dom';
import {Popover, Whisper} from "rsuite";
import {isMinimalApp} from "./CommonFunctions";

const MenuPopover = React.forwardRef(({ content, ...props }, ref) => {
    return (
        <Popover ref={ref} {...props}>
            <span>{content}</span>
        </Popover>
    );
});

export const MainMenu = (props) => {

    const {setIsPageOut} = props;

    useEffect(() => {
        setIsPageOut(window.location.pathname === "/pic/builder" || window.location.pathname === "/pic/builder/:id")
    });

    const isActive = (path, possiblePaths) => possiblePaths.includes(path.pathname);

    return (
            <nav id="main-menu">
                <div>
                    {!isMinimalApp() &&
                        <Whisper placement="right" trigger="hover" speaker={<MenuPopover content={'Create a PIC'}/>}>
                            <a href="/builder" id="main-menu__menu-item--pic" className="main-menu__menu-item"/>
                        </Whisper>
                    }

                    <Whisper placement="right" trigger="hover" speaker={<MenuPopover content={'Production environment'}/>}>
                        <NavLink to="/monitoring" id="main-menu__menu-item--farm"
                                 isActive={(match, location) => isActive(location, ['/', '/monitoring'])}
                                 className="main-menu__menu-item"
                                 activeClassName="main-menu__menu-item--active"/>
                    </Whisper>

                    {!isMinimalApp() &&
                        <Whisper placement="right" trigger="hover" speaker={<MenuPopover content={'Production'}/>}>
                            <NavLink to="/gantt" id="main-menu__menu-item--gantt"
                                     isActive={(match, location) => isActive(location, ['/gantt', '/history'])}
                                     className="main-menu__menu-item"
                                     activeClassName="main-menu__menu-item--active"/>
                        </Whisper>
                    }
                    {!isMinimalApp() &&
                        <Whisper placement="right" trigger="hover" speaker={<MenuPopover content={'PIC Manager'}/>}>
                            <NavLink to="/manager" id="main-menu__menu-item--manager"
                                     isActive={(match, location) => isActive(location, ['/manager'])}
                                     className="main-menu__menu-item"
                                     activeClassName="main-menu__menu-item--active"/>
                        </Whisper>
                    }

                    <Whisper placement="right" trigger="hover" speaker={<MenuPopover content={'Resources'}/>}>
                        <NavLink to="/printers" id="main-menu__menu-item--resources"
                                 isActive={(match, location) => isActive(location, ['/printers', '/resources'])}
                                 className="main-menu__menu-item"
                                 activeClassName="main-menu__menu-item--active"/>
                    </Whisper>

                    {!isMinimalApp() &&
                        <Whisper placement="right" trigger="hover" speaker={<MenuPopover content={'Projects'}/>}>
                            <NavLink to="/projects" id="main-menu__menu-item--projects"
                                     isActive={(match, location) => isActive(location, ['/projects'])}
                                     className="main-menu__menu-item"
                                     activeClassName="main-menu__menu-item--active"/>
                        </Whisper>
                    }

                    {!isMinimalApp() &&
                        <Whisper placement="right" trigger="hover" speaker={<MenuPopover content={'Library'}/>}>
                            <NavLink to="/library" id="main-menu__menu-item--library"
                                     isActive={(match, location) => isActive(location, ['/library'])}
                                     className="main-menu__menu-item"
                                     activeClassName="main-menu__menu-item--active"/>
                        </Whisper>
                    }

                    {!isMinimalApp() &&
                        <Whisper placement="right" trigger="hover" speaker={<MenuPopover content={'Dashboard'}/>}>
                            <NavLink to="/dashboard" id="main-menu__menu-item--dashboard"
                                     isActive={(match, location) => isActive(location, ['/dashboard'])}
                                     className="main-menu__menu-item"
                                     activeClassName="main-menu__menu-item--active"/>
                        </Whisper>
                    }

                </div>
                <div>
                    <Whisper placement="right" trigger="hover" speaker={<MenuPopover content={'Account'}/>}>
                        <NavLink to="/profile" id="main-menu__menu-item--settings"
                             isActive={(match, location) => isActive(location, ['/profile', '/organization', '/members', '/connectivity'])}
                             className="main-menu__menu-item"
                             activeClassName="main-menu__menu-item--active"/>
                    </Whisper>

                    <p>
                        © Handddle<br/>
                        V.{process.env.REACT_APP_VERSION}
                    </p>
                </div>
            </nav>
    )
}

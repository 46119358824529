import React from "react";
import {useGetData} from "../../api/useFetch";
import {getCurrentUser} from "../CommonFunctions";
import {HistoryTable} from "./HistoryTable";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";

export const History = () => {

    const currentUser = getCurrentUser();
    const tasks = useGetData('tasks', 'tasks/history', {'printer.organization_group.id': currentUser.organization.organization_group.id});

    if(tasks.isLoading) return <Loading/>
    if(tasks.isError) return <Error errorMessage={tasks.error}/>

    const validatedTasks = tasks.data.filter(task => task.done);
    const notValidatedTasks = tasks.data.filter(task => !task.done);

    const refetchTasks = () => {
        return tasks.refetch();
    }

    return (
        <div id="main__history">

            <HistoryTable tasks={notValidatedTasks} title="Not validated jobs" refetchTasks={refetchTasks}/>

            <HistoryTable tasks={validatedTasks} title="Validated jobs" refetchTasks={refetchTasks}/>

        </div>
    )
}

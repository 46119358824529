import {MouseSensor, TouchSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToHorizontalAxis} from "@dnd-kit/modifiers";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {DISPLAY_MODES} from "../Calendar";
import {TasksRow} from "./TasksRow";
import {dateToTimestampInSec, dateToUtcTimestampInSec} from "../../../../utils/date";
import {
    formatMaintenanceInTaskFormat,
    formatMaintenancesInTasksData,
    getTasksByPrinterId
} from "../../../../services/gantt/GanttService";


export const TasksTable = (props) => {
    const {displayMode, firstDisplayedDate, selectNextDate} = props;
    const {lastDisplayedDate, printers, tasksSelected, setTasksSelected, setRightPartDetailsIsOpen, setTaskId, setAlert, arrayFilteredPrinter,
        setDigitCodeVisible, setSelectedTaskIdsForHistory, setArrayDraggedElement, oldSelectedTaskIdsForHistory, selectedTaskIdsForHistory,
        handleDissociateButtonClick, externalPrintTasks} = props;

    const refTableBody = useRef(null);
    const [tableBodyWidthInPx, setTableBodyWidthInPx] = useState(null);
    const [updateRef, setUpdateRef] = useState([]);

    useEffect(() => {
        const updateTableBodyWidth = () => {
            if (refTableBody.current !== null) {
                setTableBodyWidthInPx(refTableBody.current.getBoundingClientRect().width);
            } else {
                setTableBodyWidthInPx(0);
            }
        };
        updateTableBodyWidth();
        window.addEventListener("resize", updateTableBodyWidth);
        return () => {
            window.removeEventListener("resize", updateTableBodyWidth);
        };
    }, [refTableBody]);

    let allTasks=[];
    printers.map(printer => {
        if (printer.tasks.length > 0) {
            printer.tasks.map(task => allTasks.push(task));
        }
    })

    const dragGridSnapInMin = useMemo(() => {
        switch (displayMode) {
            case DISPLAY_MODES.DAY:
                return 15;
            case DISPLAY_MODES.WEEK:
                return 3 * 60;
            case DISPLAY_MODES.MONTH:
            default:
                return 12 * 60;
        }
    }, [displayMode]);

    /**
     * The snap size must always remain the same while dragging a task, even with a time change.
     * UTC dates are used to properly handle time changes.
     */
    const dragGridSnapInPx = useMemo(() => {
        let firstDateInSec;
        let lastDateInSec;
        if (displayMode === DISPLAY_MODES.DAY) {
            firstDateInSec = dateToTimestampInSec(firstDisplayedDate);
            lastDateInSec = dateToTimestampInSec(lastDisplayedDate);
        } else {
            firstDateInSec = dateToUtcTimestampInSec(firstDisplayedDate);
            lastDateInSec = dateToUtcTimestampInSec(lastDisplayedDate);
        }
        const dragSnapCount = Math.floor((lastDateInSec + 1 - firstDateInSec) / (dragGridSnapInMin * 60));
        return tableBodyWidthInPx / dragSnapCount;
    }, [displayMode, dragGridSnapInMin, firstDisplayedDate, lastDisplayedDate, tableBodyWidthInPx]);

    const dragDeltaInPxToMin = (px) => {
        return Math.round(px / dragGridSnapInPx) * dragGridSnapInMin;
    };

    const dragModifierRestrictToTasksRow = ({transform, activeNodeRect, containerNodeRect}) => {
        if (!activeNodeRect || !containerNodeRect) {
            return transform;
        }
        const newDelta = {...transform};
        const minDeltaX = getDraggedTaskMinDeltaX(containerNodeRect, activeNodeRect);
        const maxDeltaX = getDraggedTaskMaxDeltaX(containerNodeRect, activeNodeRect);
        if (newDelta.x <= minDeltaX) {
            newDelta.x = minDeltaX;
        } else if (newDelta.x >= maxDeltaX) {
            newDelta.x = maxDeltaX;
        }
        return newDelta;
    };

    const dragModifierSnapToGrid = ({transform}) => {
        const newDelta = {...transform};
        if (newDelta.x < 0) {
            newDelta.x = Math.ceil(newDelta.x / dragGridSnapInPx) * dragGridSnapInPx;
        } else {
            newDelta.x = Math.floor(newDelta.x / dragGridSnapInPx) * dragGridSnapInPx;
        }
        return newDelta;
    };

    const getDraggedTaskMinDeltaX = (tasksRowRect, draggedTaskRect) => {
        /* As the transform value is given by the library, a safety pixel is added to handle rounding up or down cases. */
        const leftMargin = Math.min(draggedTaskRect.width, dragGridSnapInPx) / 2;
        return Math.ceil(tasksRowRect.left - leftMargin + 1) - Math.floor(draggedTaskRect.left);
    };

    const getDraggedTaskMaxDeltaX = (tasksRowRect, draggedTaskRect) => {
        /* As the transform value is given by the library, a safety pixel is added to handle rounding up or down cases. */
        const rightRestriction = dragGridSnapInPx / 2;
        return Math.floor(tasksRowRect.right - rightRestriction - 1) - Math.ceil(draggedTaskRect.left);
    };

    const getDraggedTaskDeltaLimits = (tasksRowRect, draggedTaskRect) => {
        const minTransformX = getDraggedTaskMinDeltaX(tasksRowRect, draggedTaskRect);
        const maxTransformX = getDraggedTaskMaxDeltaX(tasksRowRect, draggedTaskRect);
        return {
            left: dragModifierSnapToGrid({transform: {x: minTransformX}}).x,
            right: dragModifierSnapToGrid({transform: {x: maxTransformX}}).x,
        };
    };

    const dragSensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        }),
        useSensor(PointerSensor, {
            activationConstraint: {
              distance: 24,
            },
        })
    );

    const dragModifiers = [
        /* Modifiers must be applied in this precise order. */
        restrictToHorizontalAxis,
        dragModifierRestrictToTasksRow,
        dragModifierSnapToGrid,
    ];

    const printerList = arrayFilteredPrinter.length === 0 ? printers : arrayFilteredPrinter;

    /* Format external task data to get necessary attributes corresponding to app task */
    const formatExternalTaskData = (externalTask) => {
        externalTask.date = externalTask.started_at;
        externalTask.print_duration = Math.floor((new Date()).getTime() / 1000) - externalTask.started_at + externalTask.estimated_seconds_remaining;
        externalTask.name = 'External printing';
        return externalTask;
    }

    const getPrinterGanttTasks = (tasks) => {
        return tasks.filter(task => {
            return (task.pic !== undefined && task.pic !== null) ||
                (task.date !== null && task.end_date !== null);
        });
    }

    return (
        <table className="gc-tasks-container__tasks-table gc-tasks-table">
            <thead/>
            <tbody ref={refTableBody}>
                {printerList.map((printer) => {
                    return (
                        <TasksRow
                            key={printer.id}
                            displayMode={displayMode}
                            firstDisplayedDate={firstDisplayedDate}
                            selectNextDate={selectNextDate}
                            lastDisplayedDate={lastDisplayedDate}
                            allTasks={allTasks}
                            tasks={getPrinterGanttTasks(printer.tasks)}
                            maintenances={printer.printer_maintenances ? printer.printer_maintenances : []}
                            externalPrintTasks={getTasksByPrinterId(externalPrintTasks, printer.id)}
                            dragDeltaInPxToMin={dragDeltaInPxToMin}
                            getDraggedTaskDeltaLimits={getDraggedTaskDeltaLimits}
                            dragSensors={dragSensors}
                            dragModifiers={dragModifiers}
                            tasksSelected={tasksSelected}
                            setTasksSelected={setTasksSelected}
                            setRightPartDetailsIsOpen={setRightPartDetailsIsOpen}
                            setTaskId={setTaskId}
                            setUpdateRef={setUpdateRef}
                            setAlert={setAlert}
                            setDigitCodeVisible={setDigitCodeVisible}
                            setSelectedTaskIdsForHistory={setSelectedTaskIdsForHistory}
                            setArrayDraggedElement={setArrayDraggedElement}
                            oldSelectedTaskIdsForHistory={oldSelectedTaskIdsForHistory}
                            selectedTaskIdsForHistory={selectedTaskIdsForHistory}
                            handleDissociateButtonClick={handleDissociateButtonClick}
                        />
                    );
                })}
            </tbody>
        </table>
    );
};

import {useGetData} from "../../../api/useFetch";
import {Controller} from "react-hook-form";
import {CircularProgress} from "@material-ui/core";
import {useEffect, useState} from "react";

export const FarmProfileFormPrinters = (props) => {

    const {selectedProfile, control, setValue} = props;

    const printerBrands = useGetData('brands', 'printer_brands');
    const printerModels = useGetData('models', 'models');
    const materials = useGetData('materials', 'materials');

    const [selectedPrinterBrandId, setSelectedPrinterBrandId] = useState(
        (selectedProfile && selectedProfile.printer_brand && selectedProfile.printer_brand.id) ? selectedProfile.printer_brand.id : -1);
    const [selectedPrinterModelId, setSelectedPrinterModelId] = useState(
        (selectedProfile && selectedProfile.printer_model && selectedProfile.printer_model.id) ? selectedProfile.printer_model.id : -1);
    const [selectedMaterialId, setSelectedMaterialId] = useState(
        (selectedProfile && selectedProfile.material && selectedProfile.material.id) ? selectedProfile.material.id : -1);

    useEffect(() => {
        if(selectedProfile) {
            setSelectedPrinterBrandId((selectedProfile.printer_brand && selectedProfile.printer_brand.id) ? selectedProfile.printer_brand.id : -1);
            setSelectedMaterialId((selectedProfile.material && selectedProfile.material.id) ? selectedProfile.material.id : -1);
            setSelectedPrinterModelId((selectedProfile.printer_model && selectedProfile.printer_model.id) ? selectedProfile.printer_model.id : -1);
        }
    }, [selectedProfile]);

    const handlePrinterBrandChange = (printerBrandId) => {
        setValue('printer_brand', {id: parseInt(printerBrandId)});
        setSelectedPrinterBrandId(parseInt(printerBrandId));
        // If no printer brand selected, reset printer model
        if(printerBrandId < 1) {
            handlePrinterModelChange(-1);
        }
    }

    const handlePrinterModelChange = (printerModelId) => {
        setValue('printer_model', {id: parseInt(printerModelId)});
        setSelectedPrinterModelId(parseInt(printerModelId));
    }

    const handleMaterialChange = (materialId) => {
        setValue('material', {id: parseInt(materialId)});
        setSelectedMaterialId(parseInt(materialId));
    }

    if(printerBrands.isLoading || printerModels.isLoading || materials.isLoading) return (
        <div className="profile-data-loading">
            <CircularProgress size={20}/>
        </div>
    );

    return(
        <div>
            {/* Printer data */}
            <div className="farm-profile-form-section-info">Link this profile to a printer brand and model (optional)</div>
            <div  className="profile-data-inputs">
                {/* Printer brand */}
                <div>
                    <label htmlFor="printer_brand" className="farm-profile-label">Printer brand</label>
                    <Controller
                        control={control} name="printer_brand" rules={{required: false}}
                        render={() => (
                            <select
                                id="select-printer-brand"
                                onChange={(e) => handlePrinterBrandChange(e.target.value)}
                                value={selectedPrinterBrandId}
                            >
                                <option value={-1} key="no-printer-brand">-- No brand selected --</option>
                                {printerBrands.data && printerBrands.data.map(printerBrand =>
                                    <option value={printerBrand.id} key={printerBrand.id}>{printerBrand.name}</option>
                                )}
                            </select>
                        )}
                    />
                </div>
                {/* Printer model */}
                <div>
                    <label htmlFor="printer_model" className="farm-profile-label">Printer model</label>
                    <Controller
                        control={control} name="printer_model" rules={{required: false}}
                        value={selectedPrinterModelId}
                        render={() => (
                            <select
                                disabled={!selectedPrinterBrandId || selectedPrinterBrandId < 0}
                                id="select-printer-model"
                                value={selectedPrinterModelId}
                                onChange={(e) => handlePrinterModelChange(e.target.value)}
                            >
                                <option value={-1} key="no-printer-model">-- No model selected --</option>
                                {printerModels.data && printerModels.data.map(printerModel =>
                                    <option value={printerModel.id} key={printerModel.id}>{printerModel.name}</option>
                                )}
                            </select>
                        )}
                    />
                </div>
            </div>
            {/* Material data */}
            <div className="farm-profile-form-section-info">Link this profile to a material (optional)</div>
            <div  className="profile-data-inputs">
                {/* Material */}
                <div>
                    <label htmlFor="material" className="farm-profile-label">Material</label>
                    <Controller
                        control={control} name="material" rules={{required: false}}
                        render={() => (
                            <select
                                id="select-material"
                                onChange={(e) => handleMaterialChange(e.target.value)}
                                value={selectedMaterialId}
                            >
                                <option value={-1} key="no-printer-brand">-- No material selected --</option>
                                {materials.data && materials.data.map(material =>
                                    <option value={material.id} key={material.id}>{material.name}</option>
                                )}
                            </select>
                        )}
                    />
                </div>
            </div>
        </div>
    );
}
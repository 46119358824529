import {React, useState} from "react";
import {deletePrinters} from "../../api/apiPrinter";
import Modal from "../common/Modal";

export const DeletePrinterComponent = (props) => {
    const { printer, successCallback, errorCallback } = props;
    const [showModal, setShowModal] = useState(false);

    const handleDelete = async () => {
        await deletePrinters(printer.id)
            .then(() => successCallback("Printer has been deleted."))
            .catch(() => errorCallback("Something went wrong."));
    };
    const cancelModal = () => {setShowModal(false);}

    return (
        <>
            <button onClick={() => setShowModal(true)}>
                <i className="fa fa-times" />
            </button>
            {showModal && 
                <Modal
                    title="Delete a printer"
                    description={`Are you sure you want to delete the printer <strong>${printer.name}</strong>?`}
                    buttons={[
                        {label: `Yes, delete <strong>${printer.name}</strong>`, callback: () => handleDelete()}
                    ]}
                    cancelCallback={() => cancelModal()}
                />
            }
        </>
    );
};
import {ResourceChoiceTask} from "./ResourceChoiceTask";
import {useGetData} from "../../../api/useFetch";
import {Loading} from "../../common/Loading";
import {getCurrentTimestamp, getCurrentUser} from "../../CommonFunctions";
import {useEffect, useState} from "react";
import {getTasksForResourceChoice} from "../../../api/apiGantt";
import {MaterialIcon} from "../../common/icons/MaterialIcon";

export const ResourceChoice = (props) => {

    const {setAlert} = props;

    // Used to know if block has been closed by the user
    const [isBlockVisible, setIsBlockVisible] = useState(true);
    // Used to define if block is mounted
    const [isMounted, setIsMounted] = useState(true);
    const [tasks, setTasks] = useState([]);

    const resources = useGetData(
        'resources',
        'resource_items',
        {
            organization_group: getCurrentUser().organization.organization_group.id,
            archived: false
        }
    );

    const refetchTasks = (date) => {
        getTasksForResourceChoice(date)
            .then((data) => {
                if(isMounted && isBlockVisible) {
                    setTasks(data);
                }
            })
            .catch(() => {
                if(isMounted && isBlockVisible) {
                    setTasks([]);
                }
            });
    }

    useEffect(() => {
        refetchTasks(getCurrentTimestamp());
        const interval = setInterval(() => {
            refetchTasks(getCurrentTimestamp());
        }, 300000); // 5 minutes
        return(() => {
            setIsMounted(false);
            clearInterval(interval);
        });
    }, []);

    useEffect(() => {
        setIsMounted(tasks && tasks.length > 0);
    }, [tasks.length]);

    const handleCloseModalClick = () => {
        setIsBlockVisible(false);
        setIsMounted(false);
    }

    if(resources.isLoading) return <Loading/>;
    if(resources.isError || !isMounted || !isBlockVisible) return null;

    return(
      <div className="main__resource-choice">
          <div className="resource-choice-title">
              <span>Choose resources used in started tasks</span>
              <button className="resource-choice-close-button" onClick={() => handleCloseModalClick()}>
                  <MaterialIcon label="close"/>
              </button>
          </div>
          <div className="resource-choice-content">
              {tasks && tasks.map(task =>
                  <ResourceChoiceTask
                      task={task} key={task.id} resources={resources.data ? resources.data : []}
                      refetchTasks={refetchTasks} setAlert={setAlert}
                  />
              )}
          </div>
      </div>
    );
}
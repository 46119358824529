import * as React from "react";
import {useEffect, useState} from "react";

export const GoogleMarker = (props) => {

    const {onClickAction} = props;

    const [marker, setMarker] = useState();

    useEffect(() => {
        if (!marker) {
            let newMarker = new window.google.maps.Marker();
            newMarker.addListener('click', () => onClickAction());
            setMarker(newMarker);
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(props);
        }
    }, [marker, props]);

    return null;
}

import {ToolbarActions} from "./ToolbarActions";
import {ToolbarDateSelection} from "./ToolbarDateSelection";
import {ToolbarDisplayMode} from "./ToolbarDisplayMode";

export const Toolbar = (props) => {
    const {displayMode, setDisplayMode, firstDisplayedDate, setFirstDisplayedDate} = props;
    const {selectNextDate, schedulePic, setSelectedPrinterSchedule, handleOpenFastPrintDialog} = props;

    return (
        <div className="gantt-calendar__toolbar gc-toolbar">
            <ToolbarDisplayMode displayMode={displayMode} setDisplayMode={setDisplayMode} />
            <ToolbarDateSelection
                displayMode={displayMode}
                firstDisplayedDate={firstDisplayedDate}
                setFirstDisplayedDate={setFirstDisplayedDate}
                selectNextDate={selectNextDate}
            />
            <ToolbarActions schedulePic={schedulePic} setSelectedPrinterSchedule={setSelectedPrinterSchedule}
                            handleOpenFastPrintDialog={handleOpenFastPrintDialog}/>
        </div>
    );
};

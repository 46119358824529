import {Redirect, Route} from "react-router-dom";
import {isMinimalApp} from "../CommonFunctions";

export const RestrictedRoute = (props) => {

    const {children, ...rest} = props;

    return(
        <Route
            {...rest}
            render={({ location }) =>
                !isMinimalApp() ?
                    children :
                    <Redirect to={{pathname: '/monitoring', state: { from: location }}}/>
            }
        />
    );
}

const token = localStorage.getItem('token');


export const patchMember = async (dataMember) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `users/${dataMember.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            roles: dataMember.roles,
            notifications_settings: dataMember.notifications_settings
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const deleteMember = async (dataMember) => {

    dataMember.organization = null;

    await fetch(process.env.REACT_APP_API_ADDRESS + `users/${dataMember.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            organization: dataMember.organization
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const addMember = async (data) => {

    return fetch(process.env.REACT_APP_API_ADDRESS + 'register', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

}

import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {useGetData} from "../../../api/useFetch";
import {Loading} from "../../common/Loading";
import {Error} from "../../common/Error";
import {postProject} from "../../../api/apiProject";

export const PicCreateProjectModal = (props) => {

    const {cancelCallback, successCallback, errorCallback, setShowCreateProjectModal} = props;

    const colors = useGetData('colors', 'resource_colors');

    const {handleSubmit, control} = useForm();

    // Escape Listener Hook
    useEffect(() => {
        const escape = (e) => {
            if (e.key === 'Escape')
                cancelCallback();
        };
        document.addEventListener('keydown', escape, false);
        document.body.style.overflow = 'hidden';

        return () => {
            document.removeEventListener('keydown', escape, false);
            document.body.style.overflow = 'unset';
        };
    }, []);

    const onSubmit = async (data, event) => {
        event.preventDefault();
        await postProject(data)
            .then(() => {
                successCallback('Project has been added.');
                setShowCreateProjectModal(false);
            })
            .catch(() => errorCallback('Something went wrong.'));
    }

    if(colors.isLoading) return <Loading/>
    if(colors.isError) return <Error errorMessage={colors.error}/>

    return (
        <div
            className="modal__overlay main__pic-create-project-modal"
            onClick={(e) => {
                if (e.target === e.currentTarget)
                    cancelCallback();
            }}
        >
            <div className="modal__dialog">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="dialog__content">
                        <h2 className="content__title">Create a new Project</h2>
                        <div className="create-project-form">
                            <div className="form-input-group">
                                <label htmlFor="f-name">Name</label>
                                <Controller
                                    control={control}
                                    name="name"
                                    rules={{required: true}}
                                    defaultValue={''}
                                    render={({field}) => (
                                        <input type="text" id="f-name" placeholder="Project name" {...field}/>
                                    )}
                                />
                            </div>
                            <div className="form-input-group">
                                <label htmlFor="f-color-id">Color</label>
                                <Controller
                                    control={control}
                                    name="color"
                                    rules={{required: true, validate: (value) => value !== 0}}
                                    defaultValue={0}
                                    render={({field}) => (
                                        <select id="f-color-id" {...field}>
                                            <option disabled value={0}>Select a color</option>
                                            {colors.data.map((color) => (
                                                <option key={color.id} value={color.id}>{color.color_name}</option>
                                            ))}
                                        </select>
                                    )}
                                />
                            </div>
                            <div className="form-input-group">
                                <label htmlFor="f-notes">Description</label>
                                <Controller
                                    control={control}
                                    name="notes"
                                    render={({field}) => (
                                        <textarea id="f-notes" placeholder="Describe the project..." {...field}/>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="dialog__footer">
                        <button type="button" className="button-error" onClick={cancelCallback}>Cancel</button>
                        <button type="submit" className="btn-white">Save</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

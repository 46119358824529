import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {
    deleteControllablePrinterApiConnection,
    patchControllablePrinterApiConnection,
    postControllablePrinterApiConnection
} from "../../api/apiControllablePrinterApiConnection";

export const ConnectivityEiger = (props) => {
    const {
        currentUser,
        eigerConnectionId, setEigerConnectionId,
        eigerConnectionBrandId,
        successCallback, errorCallback
    } = props;

    const [isEigerConnectionUpdating, setIsEigerConnectionUpdating] = useState(false);

    const {handleSubmit, reset, register} = useForm();

    /* Form submission */
    const onSubmitEigerConnection = (data) => {
        data.brand = eigerConnectionBrandId;

        if(eigerConnectionId === null) {
            // If no already existing credentials : create new api connection
            postControllablePrinterApiConnection(data)
                .then((apiConnectionData) => {
                    successCallback('Connection created');
                    setEigerConnectionId(apiConnectionData.id);
                    reset();
                })
                .catch(() => {errorCallback('An error occurred while creating connection')})
        } else {
            // Update existing credentials
            data.id = eigerConnectionId;
            patchControllablePrinterApiConnection(data)
                .then(() => {
                    successCallback('Eiger connection updated');
                    setIsEigerConnectionUpdating(false);
                    reset();
                })
                .catch(() => {errorCallback('An error occurred while updating connection')})
        }
    }

    /* Delete credentials */
    const handleDeleteCredentials = () => {
        deleteControllablePrinterApiConnection(eigerConnectionId)
            .then(() => {
                successCallback('Connection deleted');
                setEigerConnectionId(null);
            })
            .catch(() => errorCallback('An error occured while deleting connection'))
    }

    const handleCancelUpdate = () => {
        setIsEigerConnectionUpdating(false);
        reset();
    }

    return(
        <div className="block">
            <h2>Connect to Eiger API</h2>
            {
                /* Case no existing eiger connection and user is admin */
                ((eigerConnectionId === null || isEigerConnectionUpdating) && currentUser.admin) &&
                <form id="eiger-api__form" onSubmit={handleSubmit(onSubmitEigerConnection)}>
                    <div className="api-form">
                        <div>
                            <label htmlFor="f-eiger-access-key">API Access Key</label>
                            <input type="text" id="f-eiger-access-key" name="username" required={true} {...register("username")}/>
                        </div>
                        <div>
                            <label htmlFor="f-eiger-secret-key">API Secret Key</label>
                            <input type="text" id="f-eiger-secret-key" name="password" required={true} {...register("password")}/>
                        </div>
                        <button type="submit" className="api-form-button"><i className="fa fa-check"/> Save credentials</button>
                        {eigerConnectionId !== null &&
                        <button type="button" onClick={() => handleCancelUpdate()} className="api-warning-button">Cancel update</button>
                        }
                    </div>
                </form>
            }
            {
                /* Case no existing eiger connection and user is not admin */
                (eigerConnectionId === null && !currentUser.admin) &&
                <p>No credentials saved</p>
            }
            {
                /* Case already existing eiger connection */
                (eigerConnectionId !== null && !isEigerConnectionUpdating) &&
                <div>
                    <p><i className="fa fa-check"/> Credentials saved</p>
                    {
                        currentUser.admin &&
                        <div>
                            <button className="api-button api-update-button" onClick={() => setIsEigerConnectionUpdating(true)}>Update credentials</button>
                            <button className="api-button api-warning-button" onClick={() => handleDeleteCredentials()}>Delete credentials</button>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export const MaterialIcon = (props) => {

    const {label, classes, style} = props;

    const getClasses = () => {
        let iconClasses = 'material-icons';
        if(classes) {
            iconClasses += ' ' + classes;
        }
        return iconClasses;
    }

    return(
        <span className={getClasses()} translate="no" style={style ? style : {}}>{label}</span>
    );
}
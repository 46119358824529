
export const getFormattedDate = (date) => {
    date = new Date(date * 1000);
    let day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
    let month = date.getMonth() >= 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);

    return day + '/' + month + '/' + date.getFullYear();
}

export const getFormattedDateWithoutYear = (date) => {
    date = new Date(date * 1000);
    let day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
    let month = date.getMonth() >= 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);

    return day + '/' + month;
}

export const getFormattedDatePicker = (date) => {
    date = new Date(date * 1000);
    let day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
    let month = date.getMonth() >= 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);

    return month + '/' + day + '/' + date.getFullYear();
}

export const getFormattedTimePicker = (date, separator = '-') => {
    date = new Date(date * 1000);
    let hour = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours();
    let minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();

    return hour + ':' + minutes;
}

export const getFormattedRoundedDate = (date, separator = '-') => {
    date = new Date(date * 1000);
    let day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
    let month = date.getMonth() >= 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    let hour = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours();
    let minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();

    return day + '/' + month + '/' + date.getFullYear() + ' ' + separator + ' ' + hour + ':' + minutes;
}

export const getFormattedRoundedDateWithoutYear = (date, separator = '-') => {
    date = new Date(date * 1000);
    let day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
    let month = date.getMonth() >= 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    let hour = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours();
    let minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();

    return day + '/' + month + ' ' + separator + ' ' + hour + ':' + minutes;
}

export const getFormattedFullFrDate = (date) => {
    date = new Date(date * 1000);
    let day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
    let month = date.getMonth() >= 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    let hour = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours();
    let minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();

    return day + '/' + month + '/' + date.getFullYear().toString().substring(2,4) + ' - ' + hour + ':' + minutes;
}

export const getFormattedFullFrDateWithSeconds = (date) => {
    date = new Date(date * 1000);
    let day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
    let month = date.getMonth() >= 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    let hour = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours();
    let minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();
    let seconds = date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds();

    return day + '-' + month + '-' + date.getFullYear().toString().substring(2,4) + ' ' + hour + ':' + minutes + ':' + seconds;
}

export const getFormattedDateNotification = (timestamp) => {
    const now = getCurrentTimestamp();

    if(now - timestamp < 60)
        return 'A few seconds ago';
    else if(now - timestamp < 3600)
        return Math.floor((now - timestamp) / 60) + ' minutes ago';
    else if(now - timestamp < 24 * 3600)
        return Math.floor((now - timestamp) / 3600) + ' hours ago';

    else
        return getFormattedFullFrDate(timestamp);
}

export const escapeHtml = (text) => {
    return text
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export const getUserName = (token) => {
    let tokenParts = token.split(".");
    let tokenPayload = atob(tokenParts[1]);
    let jwtPayload = JSON.parse(tokenPayload);
    return jwtPayload.username;
}

/* Get user from local storage and decode it */
export const getCurrentUser = () => {
    let user = localStorage.getItem('user');
    if(user) {
        try {
            user = JSON.parse(atob(user));
            if(user === undefined || user === null) {
                disconnectUser();
            }
        } catch {
            // logout user and redirect to login page
            disconnectUser();
        }
    }
    return user;
}

/* Remove user from local storage and redirect to login page */
export const disconnectUser = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location = '/login';
}

/* Encode user data and set it in local storage */
export const setCurrentUserInLocalStorage = (user) => {
    user = btoa(JSON.stringify(user));
    localStorage.setItem('user', user);
}

export const getFormattedPrinterName = (printer) => {
    return printer.name + ' (' + printer.model.name + ' - ' + printer.technology.name + ' - ' + printer.brand.name + ')';
}

const hasTaskResource = (task, slot) => {
    let hasTaskResources = false;

    if(task.task_resources){
        task.task_resources.map((task_resource) => {
            hasTaskResources = task_resource.slot === slot ? true : hasTaskResources;
        })
    }

    return hasTaskResources;
}

export const getFormattedMaterialsName = (task, includeQuantity = false, splitLines = false) => {
    let resources_html = '';
    let separator = splitLines ? '<br/>' : ' | ';
    let separator_in = ', ';

    if(hasTaskResource(task, 0) && hasTaskResource(task, 1)){
        // Resource 1
        let resources1_str = [];
        let task_resources_1 = task.task_resources.filter(task_resource => task_resource.slot == 0);

        task_resources_1.map(task_resource => {
            resources1_str.push(getFormattedResourceName(task_resource.resource) + (includeQuantity ? ' (' + task.quantity1 + 'g)' : ''));
        })

        resources_html += resources1_str.join(separator_in);

        resources_html += separator;

        // Resource 2
        let resources2_str = [];
        let task_resources_2 = task.task_resources.filter(task_resource => task_resource.slot == 1);

        task_resources_2.map(task_resource => {
            resources2_str.push(getFormattedResourceName(task_resource.resource) + (includeQuantity ? ' (' + task.quantity2 + 'g)' : ''));
        })

        resources_html += resources2_str.join(separator_in);
    }
    else if(hasTaskResource(task, 0)){
        // Resource 1
        let resources1_str = [];
        let task_resources_1 = task.task_resources.filter(task_resource => task_resource.slot == 0);

        task_resources_1.map(task_resource => {
            resources1_str.push(getFormattedResourceName(task_resource.resource) + (includeQuantity ? ' (' + task.quantity1 + 'g)' : ''));
        })

        resources_html += resources1_str.join(separator_in);
    }
    else if(hasTaskResource(task, 1)){
        // Resource 2
        let resources2_str = [];
        let task_resources_2 = task.task_resources.filter(task_resource => task_resource.slot == 1);

        task_resources_2.map(task_resource => {
            resources2_str.push(getFormattedResourceName(task_resource.resource) + (includeQuantity ? ' (' + task.quantity2 + 'g)' : ''));
        })

        resources_html += resources2_str.join(separator_in);
    }
    else if(task.material1 && task.material2){
        // Resource 1
        resources_html = task.material1.name;

        if(task.brand1)
            resources_html += separator_in + task.brand1.name;
        if(task.color1)
            resources_html += separator_in + task.color1.color_name;
        if(includeQuantity)
            resources_html += ' (' + task.quantity1 + 'g)';

        // Resource 2
        resources_html += separator + task.material2.name;

        if(task.brand2)
            resources_html += separator_in + task.brand2.name;
        if(task.color2)
            resources_html += separator_in + task.color2.color_name;
        if(includeQuantity)
            resources_html += ' (' + task.quantity2 + 'g)';
    }
    else if(task.material1){
        // Resource 1
        resources_html = task.material1.name;

        if(task.brand1)
            resources_html += separator_in + task.brand1.name;
        if(task.color1)
            resources_html += separator_in + task.color1.color_name;
        if(includeQuantity)
            resources_html += ' (' + task.quantity1 + 'g)';
    }
    else if(task.material2){
        // Resource 2
        resources_html = task.material2.name;

        if(task.brand2)
            resources_html += separator_in + task.brand2.name;
        if(task.color2)
            resources_html += separator_in + task.color2.color_name;
        if(includeQuantity)
            resources_html += ' (' + task.quantity2 + 'g)';
    }
    else
        resources_html = '-';

    // Fiber info
    if(task.printer && task.printer.is_fiber && task.pic && task.pic.slicer &&
        task.pic.slicer.is_fiber_used !== null && task.pic.slicer.is_fiber_used !== undefined) {
        resources_html += task.pic.slicer.is_fiber_used ? ' (fiber used)' : ' (fiber not used)';
    }

    return resources_html;
}

export const getFormattedResourceName = (resource) => {
    let string;
    if(resource.material.technology.has_color && resource.color)
        string = resource.material.name + ' - ' + resource.brand.name + ' (' + resource.color.color_name + ')';
    else
        string = resource.material.name + ' - ' + resource.brand.name;

    if(resource.serial_number)
        string = '[' + resource.serial_number + '] ' + string;

    return string;
}

export const getSortedResources = (resources) => {
    let sorted_resources = {};

    resources.map((resource) => {
        let material_id = resource.material.id
        let brand_id = resource.brand.id
        let color_id = resource.color ? resource.color.id : null;

        if(!(material_id in sorted_resources))
            sorted_resources[material_id] = {};

        if(color_id){
            if(!(brand_id in sorted_resources[material_id]))
                sorted_resources[material_id][brand_id] = {};

            if(!(color_id in sorted_resources[material_id][brand_id]))
                sorted_resources[material_id][brand_id][color_id] = [];

            sorted_resources[material_id][brand_id][color_id].push(resource);
        }
        else{
            if(!(brand_id in sorted_resources[material_id]))
                sorted_resources[material_id][brand_id] = [];

            sorted_resources[material_id][brand_id].push(resource);
        }
    });

    return sorted_resources;
}

export const hasResources1 = (task, resources) => {
    const sorted_resources = getSortedResources(resources);
    let has_resources_1 = false;

    if (task.material1 && task.brand1) {
        const material_id_1 = task.material1.id;
        const brand_id_1 = task.brand1.id;

        has_resources_1 = material_id_1 in sorted_resources;
        has_resources_1 = has_resources_1 && brand_id_1 in sorted_resources[material_id_1];

        if(task.printer.technology.has_color)
            has_resources_1 = has_resources_1 && task.color1.id in sorted_resources[material_id_1][brand_id_1];
    }

    return has_resources_1;
}

export const hasResources2 = (task, resources) => {
    const sorted_resources = getSortedResources(resources);
    let has_resources_2 = false;

    if (task.material2 && task.brand2 && task.color2) {
        const material_id_2 = task.material2.id;
        const brand_id_2 = task.brand2.id;
        const color_id_2 = task.color2 ? task.color2.id : null;

        has_resources_2 = material_id_2 in sorted_resources;
        has_resources_2 = has_resources_2 && brand_id_2 in sorted_resources[material_id_2];
        has_resources_2 = has_resources_2 && color_id_2 in sorted_resources[material_id_2][brand_id_2];
    }

    return has_resources_2;
}

export const getTaskStatusHtml = (task) => {
    const progress = getTaskProgress(task);
    if (progress > 0 && progress < 100) {
        return <span className="task-state task-state--working">Working - {progress}%</span>;
    }
    if (progress >= 100) {
        return <span className="task-state task-state--finished">Finished</span>;
    }
    return <span className="task-state task-state--upcoming">Upcoming</span>;
};

export const getTaskProgress = (task, controllablePrinter) => {
    if(controllablePrinter && controllablePrinter.active_task && controllablePrinter.active_task.progress !== undefined &&
        controllablePrinter.active_task.progress !== null) {
        return controllablePrinter.active_task.progress;
    }

    let progress = Math.min(Math.floor((new Date().getTime()/1000 - task.date) * 100 / task.duration), 100);
    progress = progress < 0 ? 0 : progress;

    return progress;
}

export const isTaskIncoming = (task) => {
    return getTaskProgress(task) === 0;
}

export const isTaskWorking = (task) => {
    const progress = getTaskProgress(task);
    return (progress > 0 && progress < 100);
}

export const isTaskFinished = (task) => {
    return getTaskProgress(task) >= 100;
}

export const getTaskStatus = (task) => {
    if(task.status === 'upcoming')
        return <span className="task-state task-state--incoming">Incoming</span>;
    else if(task.status === 'progress')
        return <span className="task-state task-state--working">In progress</span>;
    else
        return <span className="task-state task-state--complete">Done</span>;
}

export const getConvertedDuration = (duration, separator = ':') => {
    let parts = duration.replaceAll('_', '').split(separator);

    let hour = parseInt(parts[0]) || 0;
    let minutes = parseInt(parts[1]) || 0;

    return hour * 3600 + minutes * 60;
}

export const getFormattedDuration = (duration) => {
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration - hours * 3600) / 60);

    return hours.toString().padStart(2, '0') + 'h ' + minutes.toString().padStart(2, '0') + 'min';
}

export const getFormattedLongDuration = (duration) => {
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration - hours * 3600) / 60);

    return hours.toString().padStart(3, '0') + 'h ' + minutes.toString().padStart(2, '0') + 'min';
}

export const getDurationInHours = (duration) => {
    let hours = Math.floor(duration / 3600);
    return hours.toString() + (hours === 0 ? ' hour' : ' hours');
}

export const getCurrentTimestamp = () => {
    return Math.floor(Date.now() / 1000);
}

/**
 * Get string date with custom separator beginning by year (ex: yyyy-mm-dd-hh-mm-ss)
 * @param timestamp
 * @param separator
 * @returns {string}
 */
export const getCustomFormattedDateBeginningByYear = (timestamp, separator = '-') => {
    timestamp = new Date(timestamp * 1000);
    let day = timestamp.getDate() <= 9 ? '0' + timestamp.getDate() : timestamp.getDate();
    let month = timestamp.getMonth() >= 9 ? timestamp.getMonth() + 1 : '0' + (timestamp.getMonth() + 1);
    let hour = timestamp.getHours() <= 9 ? '0' + timestamp.getHours() : timestamp.getHours();
    let minutes = timestamp.getMinutes() <= 9 ? '0' + timestamp.getMinutes() : timestamp.getMinutes();
    let seconds = timestamp.getSeconds() <= 9 ? '0' + timestamp.getSeconds() : timestamp.getSeconds();

    return [timestamp.getFullYear(), month, day, hour, minutes, seconds].join(separator);
}

export const getTimestampFromStrDate = (stringDate, regexFormat, yGroup, mGroup, dGroup, hGroup, minGroup, sGroup) => {
    const match = stringDate.match(regexFormat);
    if(match === null)
        return Number.MAX_SAFE_INTEGER;
    let timestamp = new Date(match[mGroup] + '/' + match[dGroup] + '/' + match[yGroup]).getTime() / 1000;
    if(hGroup !== undefined) timestamp += 3600 * parseInt(match[hGroup]);
    if(minGroup !== undefined) timestamp += 60 * parseInt(match[minGroup]);
    if(sGroup !== undefined) timestamp += parseInt(match[sGroup]);
    return timestamp;
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si=false, dp=1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
};

export const isUserGranted = (role) => {
    const user = getCurrentUser();
    return user.roles.includes(role) || user.admin;
};

export const isSmartFarmUser = (user) => {
    if(user === undefined || user === null) return false;
    if(user.roles === undefined || user.roles === null) return false;
    return user.roles.includes('ROLE_SF_DATA');
};

export const getSelectedOrganizationId = () => {
    let selectedOrganizationId = localStorage.getItem('selected-organization');

    if(selectedOrganizationId === undefined || selectedOrganizationId === null || selectedOrganizationId === 'null')
        selectedOrganizationId = getCurrentUser().organization.id;
    else
        selectedOrganizationId = parseInt(selectedOrganizationId);

    return selectedOrganizationId;
};

/* Check if app is in minimal version (not full access) */
export const isMinimalApp = () => {
    return process.env.REACT_APP_MINIMAL_APP === 'true';
}

/* Check if scanner mode is enabled in app client */
export const isScannerModeEnabled = () => {
    return localStorage.getItem('scanner_mode') === 'true';
}

export const ALERT_STATUS = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
};

export const getAlertContent = (message, status, date = new Date()) => {
    return {message: message, status: status, date: date};
}
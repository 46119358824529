import {
    formatTaskMaterialValue, getBrandsList, getColorsList, getMaterialsList,
    getTaskMaterialResources,
    isMaterialFullSelected
} from "../../../services/gantt/ResourceChoiceService";
import {SelectPicker, TagPicker} from "rsuite";

export const ResourceChoiceTaskResource = (props) => {

    const {task, resources, resourceIndex, chosenResources, setChosenResourcesIds, printerTechnology,
        selectedMaterialId, setSelectedMaterialId, selectedBrandId, setSelectedBrandId, selectedColorId, setSelectedColorId} = props;

    const handleMaterialChange = (materialId) => {
        setSelectedMaterialId(materialId);
        if(materialId !== null) {
            const newBrandsList = getBrandsList(resources, materialId);
            if(newBrandsList.length === 1) {
                handleBrandChange(newBrandsList[0].value);
            } else {
                handleBrandChange(null);
            }
        } else {
            handleBrandChange(null);
        }
    }

    const handleBrandChange = (brandId) => {
        setSelectedBrandId(brandId);
        if(brandId !== null && printerTechnology.has_color) {
            const newColorsList = getColorsList(resourceIndex, resources, selectedMaterialId, brandId);
            if(newColorsList.length === 1) {
                handleColorChange(newColorsList[0].value);
            } else {
                handleColorChange(null);
            }
        } else {
            handleColorChange(null);
        }
    }

    const handleColorChange = (colorId) => {
        setSelectedColorId(colorId);
    }

    const handleResourcesSelectedChange = (selectedIds) => {
        if(selectedIds === undefined || selectedIds === null) {
            selectedIds = [];
        }
        setChosenResourcesIds(selectedIds)
    }

    const isFullMaterialSelected = () => {
        if(task.pic) {
            return resourceIndex === 1 ?
                isMaterialFullSelected(task.material1, task.brand1, printerTechnology.has_color, task.color1) :
                isMaterialFullSelected(task.material2, task.brand2, printerTechnology.has_color, task.color2)
        }
        return !task.pic && isMaterialFullSelected(selectedMaterialId, selectedBrandId, printerTechnology.has_color, selectedColorId);
    }

    const getMaterialResources = () => {
        if(task.pic) {
            return resourceIndex === 1 ?
                getTaskMaterialResources(task.brand1.id, task.material1.id, task.color1 ? task.color1.id : null, resources) :
                getTaskMaterialResources(task.brand2.id, task.material2.id, task.color2 ? task.color2.id : null, resources)
        }
        return getTaskMaterialResources(selectedBrandId, selectedMaterialId, selectedColorId, resources);
    }

    return(
        <div>
            {/* Display material for gantt tasks */}
            {(task.pic && isFullMaterialSelected()) &&
                <div className="resource-choice-task-material-info">{formatTaskMaterialValue(resourceIndex, task)}</div>
            }
            {/* Material choice for external tasks */}
            {!task.pic &&
                <div>
                    <div className="resource-choice-task-material-label">{'Material ' + resourceIndex}</div>
                    {/* Material */}
                    <div className="resource-choice-task-select">
                        <SelectPicker
                            size="sm"
                            placeholder="Select a material"
                            data={getMaterialsList(resources, printerTechnology.id)}
                            value={selectedMaterialId}
                            onChange={e => handleMaterialChange(e)}
                            searchable={false}
                            preventOverflow={true}
                        />
                    </div>
                    {/* Brand */}
                    {selectedMaterialId &&
                        <div className="resource-choice-task-select">
                            <SelectPicker
                                size="sm"
                                placeholder="Select a manufacturer"
                                data={getBrandsList(resources, selectedMaterialId)}
                                value={selectedBrandId}
                                onChange={e => handleBrandChange(e)}
                                searchable={false}
                                preventOverflow={true}
                            />
                        </div>
                    }
                    {/* Color */}
                    {(printerTechnology.has_color && selectedBrandId) &&
                        <div className="resource-choice-task-select">
                            <SelectPicker
                                size="sm"
                                placeholder="Select a color"
                                data={getColorsList(resourceIndex, resources, selectedMaterialId, selectedBrandId)}
                                value={selectedColorId}
                                onChange={e => handleColorChange(e)}
                                searchable={false}
                                preventOverflow={true}
                            />
                        </div>
                    }
                </div>
            }
            {/* Resource choice */}
            {isFullMaterialSelected() &&
                <div className="resource-choice-task-select">
                    <TagPicker
                        size="sm"
                        placeholder="Select used resources"
                        data={getMaterialResources()}
                        value={chosenResources}
                        onChange={e => handleResourcesSelectedChange(e)}
                        searchable={false}
                        preventOverflow={true}
                    />
                </div>
            }
        </div>
    );
}
import {Dropdown, Popover} from "rsuite";
import {isUserGranted} from "../../CommonFunctions";

export const PicActionsMenu = (props, ref) => {

    const {className, left, top, onClose, elementId, type, handleDeletePic, handleDuplicatePic,
        handleManagePicProjects, handleCreatePicFromTemplate, handleDuplicateTemplate} = props;
    const {Menu, Item} = Dropdown;

    // COMMON actions

    /* Handle delete element action */
    const handleDeleteElementButton = (e) => {
        e.preventDefault();
        onClose();
        handleDeletePic(elementId, type);
    }

    // PIC Actions

    /* Handle duplicate pic action */
    const handleDuplicatePicButton = (e) => {
        e.preventDefault();
        onClose();
        handleDuplicatePic(elementId);
    }

    /* Handle edit pic action */
    const handleEditPicButton = (e) => {
        e.preventDefault();
        window.location = '/builder/' + elementId;
    }

    /* Handle edit pic projects action */
    const handleManagePicProjectsButton = (e) => {
        e.preventDefault();
        onClose();
        handleManagePicProjects(elementId);
    }

    // TEMPLATE actions

    /* Handle create new PIC from template action */
    const handleCreatePicFromTemplateButton = (e) => {
        e.preventDefault();
        onClose();
        handleCreatePicFromTemplate(elementId);
    }

    /* Handle duplicate pic action */
    const handleDuplicateTemplateButton = (e) => {
        e.preventDefault();
        onClose();
        handleDuplicateTemplate(elementId);
    }

    /* Handle edit pic action */
    const handleEditTemplateButton = (e) => {
        e.preventDefault();
        window.location = '/builder/template/' + elementId;
    }

    /* Handle go to project details action */
    const handleProjectDetailsButton = (e) => {
        e.preventDefault();
        window.location = '/projects/' + elementId;
    }

    return (
        <Popover ref={ref} className={className} style={{left, top}} full>
            {type === 'pic' &&
                <Menu className="main__pic_actions_menu">
                    {isUserGranted('ROLE_UPDATE_PIC') &&
                        <Item onClick={(e) => handleEditPicButton(e)}>
                            <span className="actions-menu-icon"><i className="fa fa-edit fa-lg"/></span>Edit
                        </Item>
                    }
                    {isUserGranted('ROLE_DELETE_PIC') &&
                        <Item onClick={(e) => handleDeleteElementButton(e)}>
                            <span className="actions-menu-icon"><i className="fa fa-trash fa-lg"/></span>Delete
                        </Item>
                    }
                    {isUserGranted('ROLE_CREATE_PIC') &&
                        <Item onClick={(e) => handleDuplicatePicButton(e)}>
                            <span className="actions-menu-icon"><i className="fa fa-copy fa-lg"/></span>Duplicate
                        </Item>
                    }
                    {(isUserGranted('ROLE_UPDATE_PIC')  && isUserGranted('ROLE_UPDATE_PROJECT')) &&
                        <Item onClick={(e) => handleManagePicProjectsButton(e)}>
                            <span className="actions-menu-icon"><i className="fa fa-folder fa-lg"/></span>Manage projects
                        </Item>
                    }
                </Menu>
            }
            {type === 'template' &&
                <Menu className="main__pic_actions_menu">
                    {isUserGranted('ROLE_CREATE_PIC') &&
                        <Item onClick={(e) => handleCreatePicFromTemplateButton(e)}>
                            <span className="actions-menu-icon"><i className="fa fa-plus fa-lg"/></span>Create PIC from template
                        </Item>
                    }
                    {isUserGranted('ROLE_UPDATE_PIC') &&
                        <Item onClick={(e) => handleEditTemplateButton(e)}>
                            <span className="actions-menu-icon"><i className="fa fa-edit fa-lg"/></span>Edit
                        </Item>
                    }
                    {isUserGranted('ROLE_DELETE_PIC') &&
                        <Item onClick={(e) => handleDeleteElementButton(e)}>
                            <span className="actions-menu-icon"><i className="fa fa-trash fa-lg"/></span>Delete
                        </Item>
                    }
                    {isUserGranted('ROLE_CREATE_PIC') &&
                        <Item onClick={(e) => handleDuplicateTemplateButton(e)}>
                            <span className="actions-menu-icon"><i className="fa fa-copy fa-lg"/></span>Duplicate
                        </Item>
                    }
                </Menu>
            }
            {type === 'project' &&
                <Menu className="main__pic_actions_menu">
                    <Item onClick={(e) => handleProjectDetailsButton(e)}>
                        <span className="actions-menu-icon"><i className="fa fa-arrow-right"/></span>Project details
                    </Item>
                </Menu>
            }
        </Popover>
    );
}

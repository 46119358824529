import {OutlinedCard} from "../../common/OutlinedCard";
import {Link} from "react-router-dom";
import {
    FARM_EVENT_TYPES,
    getMaterialStatusLabel,
    SMART_FARM_COMMANDS,
    SMART_FARM_DATA_FIELDS
} from "../../../../services/FarmService";
import {Toggle} from "rsuite";
import React, {useEffect, useState} from "react";
import {sendFarmCommandEvent} from "../../../../services/socketEventsService";
import {postFarmEvent} from "../../../../api/apiFarmCommand";

export const MaterialStatusBlock = (props) => {

    const {farmCell, materialStatus, systemCode, setCommandsInProcess, commandsInProcess} = props;

    const getIsMaterialStatusActive = () => {
        if(commandsInProcess[systemCode] && commandsInProcess[systemCode][SMART_FARM_DATA_FIELDS.DEHUMIDIFIER]) {
            return commandsInProcess[systemCode][SMART_FARM_DATA_FIELDS.DEHUMIDIFIER].value > 0;
        } else {
            return materialStatus > 0;
        }
    }

    const [isMaterialStatusActive, setIsMaterialStatusActive] = useState(getIsMaterialStatusActive());

    useEffect(() => {
        setIsMaterialStatusActive(getIsMaterialStatusActive());
    }, [materialStatus]);

    const handleDehumidification = (isActive) => {
        sendFarmCommandEvent(systemCode, SMART_FARM_COMMANDS.DEHUMIDIFIER_ACTIVE, isActive ? 1 : 0);
        setIsMaterialStatusActive(isActive);
        setCommandsInProcess({type: 'add-command', value: {systemCode: systemCode, commandType: SMART_FARM_DATA_FIELDS.DEHUMIDIFIER, commandValue: isActive ? 1 : 0}});

        postFarmEvent(farmCell.id, FARM_EVENT_TYPES.DEHUMIDIFIER, isActive ? 1 : 0, null)
            .then(() => null)
            .catch(() => null);
    }

    return(
        <OutlinedCard>
            <div className="farm__dialog_status_blocks">
                <h2>Materials status</h2>
                <div>
                    <span className="status-label">Dehumidification regeneration:</span>
                    <span className="status-value">{getMaterialStatusLabel(materialStatus)}</span>
                </div>
                <div className="status-toggle">
                    <Toggle checked={isMaterialStatusActive} onChange={isActive => handleDehumidification(isActive)}/>
                    <span className="toggle-label">Activate dehumidification</span>
                </div>
                <div className="material-stock-button">
                    <Link to="/resources" className="button button-link button-outlined-main">Material stock</Link>
                </div>
            </div>
        </OutlinedCard>
    );
}

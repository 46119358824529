import React, {useEffect, useState} from "react";
import {Panel, Table, InputGroup, Input} from 'rsuite';
import {TableWrapper} from "../common/TableWrapper";
import {
    getFormattedMaterialsName,
    getFormattedRoundedDate,
    getTaskStatus,
    getTimestampFromStrDate
} from "../CommonFunctions";
import {TaskDetailsMain} from "../gantt/right-panel/TaskDetailsMain";
import {Drawer, makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    paper: {
        left: "60%",
        background: "#f5f5f5"
    },
});

export const HistoryTable = (props) => {

    const classes = useStyles();
    const {tasks, title, refetchTasks} = props;

    const {Column, HeaderCell, Cell} = Table;

    const [sortColumn, setSortColumn] = useState('date');
    const [sortType, setSortType] = useState('desc');

    const [searchString, setSearchString] = useState('');

    const [rightPartDetailsIsOpen, setRightPartDetailsIsOpen] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState();
    const [tasksData, setTasksData] = useState([]);

    const formattedTask = () => {
        const formattedTasks = [];

        tasks.forEach(task => {
            formattedTasks.push({
                id: task.id,
                date: getFormattedRoundedDate(task.date),
                name: task.name,
                project: task.project ? task.project.name : '-',
                printer: task.printer.name,
                resources: getFormattedMaterialsName(task),
                done: task.done ? task.done : null,
                status: task.status,
                hex_code: task.project ? task.project.color.hex_code : null
            })
        });

        setTasksData(formattedTasks);
    };

    useEffect(() => {
        formattedTask();
    }, [tasks]);

    const getFilteredData = () => {
        return tasksData.filter(data => {
            return (
                data.name.toLowerCase().includes(searchString)
                || data.date.includes(searchString)
                || data.project.toLowerCase().includes(searchString)
                || data.printer.toLowerCase().includes(searchString)
                || data.resources.toLowerCase().includes(searchString)
            );
        })
    }

    /**
     * Data sorting depending on column and type (asc / desc)
     */
    const getSortedData = (data) => {
        if (sortColumn && sortType) {
            return data.sort((a, b) => {
                // Values to sort
                let x = a[sortColumn];
                let y = b[sortColumn];
                // Case : undefined values
                if(x === undefined) {
                    return 1;
                }
                if(y === undefined) {
                    return -1;
                }

                // Get values to compare
                let valueX = 0;
                let valueY = 0;
                if(sortColumn === 'date'){
                    valueX = getTimestampFromStrDate(x, '(\\d{2})\\/(\\d{2})\\/(\\d{4}) - (\\d{2}):(\\d{2})',
                        3, 2, 1, 4, 5);
                    valueY = getTimestampFromStrDate(y, '(\\d{2})\\/(\\d{2})\\/(\\d{4}) - (\\d{2}):(\\d{2})',
                        3, 2, 1, 4, 5);
                }
                else if (typeof x === 'string' && typeof y === 'string') {
                    let i = 0;
                    do {
                        valueX = x.charCodeAt(i);
                        valueY = y.charCodeAt(i);
                        i++;
                    } while (valueX === valueY && i < x.length && i < y.length);
                } else if (typeof x === 'number' && typeof y === 'number') {
                    valueX = x;
                    valueY = y;
                }

                if (sortType === 'asc') {
                    return valueX - valueY;
                } else {
                    return valueY - valueX;
                }
            });
        }
        return data;
    };

    const handleSortColumn = async (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const DateCell = ({rowData, dataKey, ...props}) => {
        const timestamp = getTimestampFromStrDate(rowData.date, '(\\d{2})\\/(\\d{2})\\/(\\d{4}) - (\\d{2}):(\\d{2})',
            3, 2, 1, 4, 5);

        return (
            <Cell {...props} style={{ padding: 0 }}>
                {timestamp === Number.MAX_SAFE_INTEGER ? 'Task in waiting list': rowData.date}
            </Cell>
        )
    }

    const ProjectCell = ({rowData, dataKey, ...props}) => {
        return (
            <Cell {...props} style={{ padding: 0 }}>
                {rowData.hex_code ? <span className="project__name" style={{backgroundColor: rowData.hex_code}}>{rowData.project}</span> : '-'}
            </Cell>
        )
    }

    const StatusCell = ({rowData, dataKey, ...props}) => {
        return(
            <Cell {...props} style={{ padding: 0 }}>
                {getTaskStatus(rowData)}
            </Cell>
        )
    }

    const handleTaskClick = (task) => {
        setSelectedTaskId(task.id);
        setRightPartDetailsIsOpen(true);
    }

    return (
        <>
            <Panel bordered>
                <div className="flex">
                    <h2>{title}</h2>
                    <InputGroup inside className="search-bar-input">
                        <Input placeholder="Search"
                               onChange={(value) => setSearchString(value)}
                        />
                        <InputGroup.Addon className="search-bar-addon-icon">
                            <i className="fa fa-search"/>
                        </InputGroup.Addon>
                    </InputGroup>
                </div>

                <TableWrapper>
                    <Table
                        className="main__history_list_table"
                        style={{cursor: "pointer"}}
                        height={300}
                        data={getSortedData(getFilteredData(tasksData))}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        onRowClick={data => handleTaskClick(data)}
                    >
                        <Column flexGrow={2} verticalAlign="middle" sortable >
                            <HeaderCell><b>Date</b></HeaderCell>
                            <DateCell dataKey="date"/>
                        </Column>
                        <Column flexGrow={6} verticalAlign="middle" sortable>
                            <HeaderCell><b>Name</b></HeaderCell>
                            <Cell dataKey="name" />
                        </Column>
                        <Column flexGrow={2} verticalAlign="middle" sortable>
                            <HeaderCell><b>Project</b></HeaderCell>
                            <ProjectCell dataKey="project" />
                        </Column>
                        <Column flexGrow={2} verticalAlign="middle" sortable>
                            <HeaderCell><b>Printer</b></HeaderCell>
                            <Cell dataKey="printer" />
                        </Column>
                        <Column flexGrow={4} verticalAlign="middle" sortable>
                            <HeaderCell><b>Materials</b></HeaderCell>
                            <Cell dataKey="resources" />
                        </Column>
                        <Column flexGrow={2} verticalAlign="middle" sortable>
                            <HeaderCell><b>Status</b></HeaderCell>
                            <StatusCell dataKey="status" />
                        </Column>
                    </Table>
                </TableWrapper>
            </Panel>

            <Drawer anchor="right" open={rightPartDetailsIsOpen}
                    onClose={() => {setRightPartDetailsIsOpen(false)}}
                    classes={{paper: classes.paper}}>
                <TaskDetailsMain
                    taskId={selectedTaskId}
                    setIsTaskUpdated={() => {}}
                    reloadPageData={refetchTasks}
                    setRightPartDetailsIsOpen={setRightPartDetailsIsOpen}
                />
            </Drawer>
        </>
    );

};
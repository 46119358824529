import React, {useState} from "react";
import Modal from "../common/Modal";
import {changePassword} from "../../api/apiUser";
import {Popover, Whisper} from "rsuite";

export const ResetPasswordMember = ({member, successCallback, errorCallback}) => {

    const [showModal, setShowModal] = useState(false);

    const handleResetPassword = async () => {
        await changePassword({}, member.id)
            .then(() => {
                successCallback('The password has been reset.');
                cancelModal();
            })
            .catch(() => {
                errorCallback('Something went wrong.')
                cancelModal();
            });
    }

    const cancelModal = () => setShowModal(false);

    return (
        <>
            <Whisper placement="top" trigger="hover" speaker={<Popover><span>Reset user password to "<strong>password</strong>"</span></Popover>}>
                <button className="button-link button-icon members__delete" onClick={() => {setShowModal(true)}}>
                    <i className="fa fa-key"/>
                </button>
            </Whisper>
            {showModal &&
                <Modal
                    title="Reset user password"
                    description={`Are you sure you want to reset the password of <strong>${member.firstname} ${member.lastname}</strong>?`}
                    buttons={[
                        {label: `Yes, reset password`, callback: () => handleResetPassword()}
                    ]}
                    cancelCallback={() => cancelModal()}
                />
            }
        </>
    )
}
export function getCurrentDateAtMidnight() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
}

export function getMonthDaysCountFromDate(date) {
    /* Month + 1 -> Next month / Date = 0 -> Last day of previous month */
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export function timestampInSecToDate(timestampInSec) {
    return new Date(timestampInSec * 1000);
}

export function dateToTimestampInSec(date) {
    return Math.floor(date.getTime() / 1000);
}

export function dateToUtcTimestampInSec(date) {
    const utcDateTimestamp = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
    );
    return Math.floor(utcDateTimestamp / 1000);
}

export function dateToShortDayMonthYearString(date) {
    return date.toLocaleString(undefined, {year: "numeric", month: "numeric", day: "numeric"});
}

export function dateToLongMonthYearString(date) {
    const value = date.toLocaleString(undefined, {year: "numeric", month: "long"});
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
}

export function dateToShortDayMonthString(date) {
    return date.toLocaleString(undefined, {month: "numeric", day: "numeric"});
}

export function dateToHourMinuteString(date) {
    return date.toLocaleString(undefined, {hour: "2-digit", minute: "2-digit"});
}

export const TasksToolbarActions = (props) => {
    const {isOffPeakHoursVisible, setIsOffPeakHoursVisible, setIsFirstDisplayedDateUpdated, displayMode} = props;
    const handleClickPeakHours = (e) => {
        setIsFirstDisplayedDateUpdated(false);
        setIsOffPeakHoursVisible(!isOffPeakHoursVisible);
        localStorage.setItem('isOffPeakHoursVisible', !isOffPeakHoursVisible);
    };

    return (
        <div className="gc-tasks__toolbar gc-tasks-toolbar">
            {displayMode === "Day" && 
                <button className="gc-tasks-toolbar__button" onClick={(e) => handleClickPeakHours(e)}>
                    {isOffPeakHoursVisible ? 'Hide Off-peak hours' : 'Show Off-peak hours'} 
                </button>
            }
        </div>
    );
};

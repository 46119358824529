import React, {useState} from "react";
import {Checkbox, Table} from "rsuite";
import {patchMember} from "../../api/apiMember";
import {NOTIFICATION_TYPES} from "../../services/NotificationsService";

export const NotificationManager = (props) => {

    const {user, successCallback, errorCallback} = props;

    const [checkedKeys, setCheckedKeys] = useState(user.notifications_settings);

    const handleCheck = async (setting, checked) => {
        const keys = checked ? [...checkedKeys, setting] : checkedKeys.filter(item => item !== setting);
        setCheckedKeys(keys);

        if(!user.notifications_settings.includes(setting))
            user.notifications_settings.push(setting)
        else
            user.notifications_settings = user.notifications_settings.filter(r => r !== setting)

        await patchMember(user)
            .then(() => successCallback('Preferences saved.'))
            .catch(() => errorCallback('Something went wrong.'));
    };

    const CheckCell = ({rowData, dataKey}) => {
        return (
            <div style={{ lineHeight: '46px' }}>
                {
                    rowData[dataKey] === null ?
                        <Checkbox checked={true} onChange={handleCheck} value={rowData[dataKey]} />
                        : <Checkbox checked={checkedKeys.some(item => item === rowData[dataKey])}
                                    onChange={handleCheck} value={rowData[dataKey]}/>
                }
            </div>
        );
    }

    return (
        <div className="block">
            <h2>Notifications settings</h2>

            <Table
                height={300}
                hover={true}
                autoHeight={true}
                data={NOTIFICATION_TYPES}
                bordered={true}
                cellBordered={true}
            >
                <Table.Column flexGrow={2}>
                    <Table.HeaderCell children=''/>
                    <Table.Cell dataKey="name"/>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Application</Table.HeaderCell>
                    <Table.Cell style={{padding: 0}}>{rowData => <CheckCell rowData={rowData} dataKey="app"/>}</Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.Cell style={{padding: 0}}>{rowData => <CheckCell rowData={rowData} dataKey="email"/>}</Table.Cell>
                </Table.Column>
            </Table>

            <table/>
        </div>
    );
};
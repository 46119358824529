import {
    getCurrentTimestamp,
    getCurrentUser,
    getCustomFormattedDateBeginningByYear, getFormattedFullFrDateWithSeconds
} from "../components/CommonFunctions";
import {
    getFormattedHumidityValue,
    getFormattedPollutionValue,
    getFormattedTemperatureValue,
    getFormattedTvocValue, getTimestampFromTimeValue
} from "../api/apiInfluxdb";
import {sendFarmCommandEvent} from "./socketEventsService";
import {postFarmEvent} from "../api/apiFarmCommand";

/* interval between 2 influxdb requests (in seconds) - for single data */
export const INFLUXDB_SINGLE_REQUEST_INTERVAL = 10;

/* interval between 2 influxdb requests (in seconds) - for data list */
export const INFLUXDB_LIST_REQUEST_INTERVAL = 60;

/* time limit to wait to validate command sent to smart farm (in seconds) */
export const COMMAND_TIMEOUT_LIMIT = 30;

export const CELL_TYPES = {PRINTER: 0, MATERIALS: 1, POST_TREATMENT: 2, BALANCE: 3};

/* time limit beyond which there is a connection error with influxdb  */
export const INFLUXDB_TIMEOUT_LIMIT = 20;

export const LED_COLORS = {
    OFF: {index: 0, color: '#000000'},
    WHITE: {index: 7, color: '#e5e5e5'}, // Status: stand by
    RED: {index: 2, color: '#f04040'},
    GREEN: {index: 3, color: '#40f040'},
    BLUE: {index: 5, color: '#00cac0'},
    ORANGE: {index: 4, color: '#ffa500'} // Status: printing
};

/* farm cell size categories (ex: BIG = (cell height >= 40%)) */
export const CELL_SIZES = {BIG: 40, SMALL: 25, XS: 0};

/* Printer status used in automatic mode (status in influxdb) */
export const SMART_PRINTER_STATUS = {
    STAND_BY: {id: 0, value: 'free', color: LED_COLORS.GREEN.color, icon: 'check_circle'},
    OFF: {id: 2, value: 'off', color: '#b0b0b0', icon: 'block'},
    ON: {id: 3, value: 'on', color: LED_COLORS.GREEN.color, icon: 'check_circle'},
    WORKING: {id: 1, value: 'working', color: LED_COLORS.RED.color, icon: 'autorenew'},
    WARMING_UP: {id: 4, value: 'warming up', color: LED_COLORS.ORANGE.color, icon: 'pending'},
    PRINTING: {id: 5, value: 'printing', color: LED_COLORS.RED.color, icon: 'autorenew'}
};

/* Printer status used in manual mode (status in gantt) */
export const MANUAL_PRINTERS_STATUS = {
    FREE: {id: 1, value: 'free', color: LED_COLORS.GREEN.color, icon: 'check_circle'},
    UPCOMING: {id: 2, value: 'upcoming', color: LED_COLORS.ORANGE.color, icon: 'pending'},
    WORKING: {id: 3, value: 'working', color: LED_COLORS.RED.color, icon: 'autorenew'}
};

/* Unknown printer status (manual / automatic modes) */
export const UNKNOWN_PRINTER_STATUS = {id: -1, value: 'unknown', color: '#e5e5e5', icon: 'help'};

/* Material cells dehumidifier status */
export const MATERIAL_CELL_STATUS = {NO_DATA: -1, INACTIVE: 0, SWIRL: 1, REGENERATION: 2};

/* chart specs for farm dialog graphs */
export const CHARTS_DATA_RANGE = {DAY: 0, WEEK: 1, MONTH: 2, CUSTOM: 3};
export const CHARTS_UNIT_PERIODS = {HOUR: '1h', QUARTER_DAY: '6h', DAY: '1d', WEEK: '1w'}

/* max selectable temperature in smart farm */
export const TEMPERATURE_MAX = 50;

/* time of fan activation if print is running (before opening the door) */
export const FAN_ACTIVATION_TIME_BEFORE_DOOR_OPENING = 30;

/* smart farm data fields used in influxdb */
export const SMART_FARM_DATA_FIELDS = {
    // Cell environment data
    TEMPERATURE: 'temperature',
    HUMIDITY: 'humidity',
    POLLUTION_PM1: 'pm1',
    POLLUTION_PM2_5: 'pm2_5',
    POLLUTION_PM4: 'pm4',
    POLLUTION_PM10: 'pm10',
    TVOC: 'tvoc', // SF V1
    VOC: 'voc_index', // SF V2
    // External environment data (only SF V2)
    TEMPERATURE_EXT: 'temperature_ext',
    HUMIDITY_EXT: 'humidity_ext',
    POLLUTION_EXT_PM1: 'pm1_ext',
    POLLUTION_EXT_PM2_5: 'pm2_5_ext',
    POLLUTION_EXT_PM4: 'pm4_ext',
    POLLUTION_EXT_PM10: 'pm10_ext',
    VOC_EXT: 'voc_index_ext',
    // Other data
    RELAY_ON: 'relay_on',
    LED_COLOR: 'led_color',
    DOOR_CLOSED: 'door_closed',
    DEHUMIDIFIER: 'dehumidifier_active',
    COMMAND_TEMPERATURE: 'command_temperature',
    COMMAND_AIR_EXTRACTION: 'command_air_extraction',
    BUZZER_ON: 'sound_module_active',
    MAINTENANCE_ACTIVE: 'maintenance_active',
    HEATER_ACTIVE: 'heater_active'
};

/* Pollution pm data types */
export const PM_TYPES = [
    {value: 1, label: 'pm1', field: SMART_FARM_DATA_FIELDS.POLLUTION_PM1},
    {value: 2, label: 'pm2.5', field: SMART_FARM_DATA_FIELDS.POLLUTION_PM2_5},
    {value: 3, label: 'pm4', field: SMART_FARM_DATA_FIELDS.POLLUTION_PM4},
    {value: 4, label: 'pm10', field: SMART_FARM_DATA_FIELDS.POLLUTION_PM10}
];

/* printer data fields used in influxdb */
export const PRINTER_DATA_FIELDS = {
    PRINTER_STATUS: 'printer_status'
}

/* smart farm commands names */
export const SMART_FARM_COMMANDS = {
    TEMPERATURE: 'temperature',
    HUMIDITY: 'humidity',
    AIR_EXTRACTION: 'air_extraction',
    RELAY_OFF: 'relay_on',
    LED_COLOR: 'led_color',
    DOOR_CLOSED: 'door_closed',
    ON_OFF: 'on_off',
    STOP_BUZZER: 'sound_module_active',
    DEHUMIDIFIER_ACTIVE: 'dehumidifier_active',
    MAINTENANCE: 'maintenance_active'
};

/* smart farm command values in db */
export const FARM_EVENT_TYPES = {ON_OFF: 1, LED_COLOR: 2, DOOR_CLOSED: 3, TEMPERATURE: 4, AIR_EXTRACTION: 5, RELAY_OFF: 6, DEHUMIDIFIER: 7, MAINTENANCE: 8};

/**
 * Get specific classes depending on printer size
 * @param size: number (CELL_SIZES)
 * @param baseClass: string (= base class name)
 * @returns {string}
 */
export const getClassesDependingOnCellSize = (size, baseClass) => {
    switch (size) {
        case CELL_SIZES.BIG: return baseClass + ' ' + baseClass + '-big';
        case CELL_SIZES.SMALL: return baseClass + ' ' + baseClass + '-small';
        default: return baseClass + ' ' + baseClass + '-xs';
    }
}

/**
 * Get farm element classes depending on its position in the farm structure
 * @param baseClass
 * @param isFirst
 * @param isLast
 * @returns {*}
 */
export const getFarmElementClassesWithMargins = (baseClass, isFirst, isLast) => {
    let classes = baseClass;
    if(isFirst) {
        classes += ' farm-element-first';
    }
    if(isLast) {
        classes += ' farm-element-last';
    }
    return classes;
}

/**
 * Get hexadecimal color value depending on colorType
 * @param colorType: integer (color value)
 * @returns {*|string}
 */
export const getLedHexadecimalColor = (colorType) => {
    for(let [key, value] of Object.entries(LED_COLORS)) {
        if(value.index === colorType) {
            return value.color;
        }
    }
    return LED_COLORS.OFF.color;
}

/**
 * Get material cell dehumidifier status
 * @param dehumidifierActive
 * @returns {number}
 */
export const getMaterialCellStatus = (dehumidifierActive) => {
    switch (dehumidifierActive) {
        case undefined: return MATERIAL_CELL_STATUS.NO_DATA;
        case null: return MATERIAL_CELL_STATUS.NO_DATA;
        case MATERIAL_CELL_STATUS.INACTIVE: return MATERIAL_CELL_STATUS.INACTIVE;
        case MATERIAL_CELL_STATUS.SWIRL: return MATERIAL_CELL_STATUS.SWIRL;
        case MATERIAL_CELL_STATUS.REGENERATION: return MATERIAL_CELL_STATUS.REGENERATION;
        default: return MATERIAL_CELL_STATUS.NO_DATA;
    }
}

/**
 * Get an array of led colors indexes (without off index)
 * @returns {*[]}
 */
export const getLedColorsIndexesWithoutOff = () => {
    let indexes = [];
    for(let [key, value] of Object.entries(LED_COLORS)) {
        if(value.index !== LED_COLORS.OFF.index) {
            indexes.push(value.index);
        }
    }
    return indexes;
}

/**
 * Get an array of led colors hexadecimal colors (without off color)
 * @returns {*[]}
 */
export const getLedColorsColorsWithoutOff = () => {
    let colors = [];
    for(let [key, value] of Object.entries(LED_COLORS)) {
        if(value.index !== LED_COLORS.OFF.index) {
            colors.push(value.color);
        }
    }
    return colors;
}

/**
 * Get string gap between today and filter date change
 * @param filterChangeDate: number (timestamp)
 * @returns {string}
 */
export const getFilterChangeGapFromToday = (filterChangeDate) => {
    // Today at midnight
    const todayAtMidnight = (new Date()).setHours(0, 0, 0, 0);
    // filter change date at midnight
    const filterChangeAtMidnight = (new Date(filterChangeDate * 1000)).setHours(0, 0, 0, 0);

    const differenceInSeconds = filterChangeAtMidnight - todayAtMidnight;
    const absDifferenceInSeconds = Math.abs(differenceInSeconds);

    // Case : change filter date is today
    if(((new Date(filterChangeAtMidnight)).getDate() === (new Date(todayAtMidnight)).getDate()) &&
        (Math.floor(Math.abs(filterChangeAtMidnight - todayAtMidnight)) < 3600 * 24 * 1000)) {
        return 'today';
    }

    // Calculate gap
    let gap;
    if(absDifferenceInSeconds < 3600 * 24 * 31 * 1000) {
        const days = (new Date(absDifferenceInSeconds)).getDate() - 1;
        gap = days + (days > 1 ? ' days' : ' day');
    } else if(absDifferenceInSeconds < 3600 * 24 * 365 * 1000) {
        const months = (new Date(absDifferenceInSeconds)).getMonth();
        gap = months + (months > 1 ? ' months' : ' month');
    } else {
        const years = (new Date(absDifferenceInSeconds)).getFullYear() - 1970;
        gap = years + (years > 1 ? ' years' : ' year');
    }

    return differenceInSeconds > 0 ? 'in ' + gap : gap + ' ago';
}

/**
 * Get printer smart printer status
 * @param printerId
 * @param printersStatus
 * @returns {*|null}
 */
export const getPrinterStatusByPrinterId = (printerId, printersStatus) => {
    if(printersStatus !== null && printersStatus !== undefined) {
        for(let printerStatus of printersStatus) {
            if(printerStatus.id === printerId) {
                return printerStatus;
            }
        }
    }
    return null;
}

/**
 * Get SMART_PRINTER_STATUS for each printer status found in influxdb formatted data, with printer id
 * @param printersData
 * @returns {*[]}, status = {id: printerId, status: SMART_PRINTER_STATUS}
 */
export const getSmartPrinterStatusFromPrintersStatusData = (printersData) => {
    let printersStatus = [];
    for(let [keyData, valueData] of Object.entries(printersData)) {
        let isStatusFound = false;
        for(let [keyStatus, valueStatus] of Object.entries(SMART_PRINTER_STATUS)) {
            if(valueStatus.id === valueData.printer_status) {
                isStatusFound = true;
                printersStatus.push({id: parseInt(keyData), status: valueStatus});
            }
        }
        // Case : status not existing in app
        if(!isStatusFound) {
            printersStatus.push({id: parseInt(keyData), status: UNKNOWN_PRINTER_STATUS})
        }
    }
    return printersStatus;
}

/**
 * Get SMART_PRINTER_STATUS for each printer status found in influxdb formatted data
 * @param printerStatus
 * @returns {*}: PRINTER_STATUS value
 */
export const getManualPrinterStatus = (printerStatus) => {
    // Case : app is in minimal app mode
    if(process.env.REACT_APP_MINIMAL_APP === 'true') {
        return UNKNOWN_PRINTER_STATUS;
    }
    // Check gantt printer status
    if(printerStatus !== undefined && printerStatus !== null) {
        for(let [key, value] of Object.entries(MANUAL_PRINTERS_STATUS)) {
            if(value.value === printerStatus) {
                return value;
            }
        }
    }
    return UNKNOWN_PRINTER_STATUS;
}

/**
 * Merge environment data into one array with header row
 * @param temperatureList
 * @param humidityList
 * @param tvocList
 * @param pollutionList
 * @param vocType
 * @param pollutionLabel
 * @returns {string[][]}
 */
export const mergeEnvironmentDataLists = (temperatureList, humidityList, pollutionList, tvocList, vocType, pollutionLabel) => {
    // Initialize formatted data with temperature list
    let formattedData = [['date', 'temperature']];
    temperatureList.map(temperatureItem => {
        formattedData.push(temperatureItem);
    });
    // Add other lists data
    formattedData = addListToMergeEnvironmentList('humidity', humidityList, formattedData);
    formattedData = addListToMergeEnvironmentList(pollutionLabel ? pollutionLabel : 'pollution', pollutionList, formattedData);
    formattedData = addListToMergeEnvironmentList(vocType === SMART_FARM_DATA_FIELDS.TVOC ? 'tvoc' : 'voc', tvocList, formattedData);

    // Format date
    for(let i = 1; i < formattedData.length; i++) {
        formattedData[i][0] = getFormattedFullFrDateWithSeconds(Math.floor(formattedData[i][0] / 1000));
    }

    return formattedData;
}

/**
 * Add list data to merge list (dates column have to be equivalent)
 * @param listName
 * @param listToAdd
 * @param mergeList
 * @returns {*}
 */
const addListToMergeEnvironmentList = (listName, listToAdd, mergeList) => {
    mergeList[0].push(listName);
    listToAdd.map(item => {
        for(let row of mergeList) {
            if(row[0] === item[0]) {
                row.push(item[1]);
                break;
            }
        }
    });
    return mergeList;
}

export const mergeAndRemoveOldData = (oldData, newData) => {
    const mergedData = {...oldData};

    for (const systemCode in newData) {
        if (!mergedData.hasOwnProperty(systemCode))
            mergedData[systemCode] = {};

        for (const key in newData[systemCode])
            mergedData[systemCode][key] = newData[systemCode][key];

        mergedData[systemCode].time = getCurrentTimestamp() * 1000;
    }

    // Remove old entries with a time value older than 2 minutes
    for (const systemCode in mergedData) {
        const entryTime = mergedData[systemCode].time || 0;
        if (getCurrentTimestamp() * 1000 - entryTime > 60 * 5 * 1000)
            delete mergedData[systemCode];
    }

    return mergedData;
};

/**
 * Create csv data from array
 * @param arrayData
 * @param separator
 * @returns {string}
 */
export const createCsvData = (arrayData, separator = ',') => {
    return 'data:text/csv;charset=utf-8,' + arrayData.map(row => row.join(separator)).join("\n");
}

/**
 * Download csv file from csv formatted data
 * @param csvData
 * @returns {Promise<void>}
 */
export const downloadCsvData = (csvData) => {
    try {
        // Create file name with current date and organization
        const separator = '_';
        const date = getCustomFormattedDateBeginningByYear(getCurrentTimestamp(), separator);
        const organizationName = getCurrentUser().organization.name.toLowerCase().replaceAll(' ', separator);
        const fileName = ['environment_data', organizationName, date].join(separator);

        // Download data
        const encodedUri = encodeURI(csvData);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        // Remove download link
        document.body.removeChild(link);

        return Promise.resolve();
    } catch {
        return Promise.reject('error while creating csv');
    }
}

/**
 * Format influxdb environment data to csv array data
 * @param data
 * @param vocType
 * @param pollutionLabel
 * @returns {(string|string)[][]}
 */
export const formatInfluxdbDataForCsvArray = (data, vocType, pollutionLabel) => {
    let formattedData = [];

    if(data && data.length > 0) {

        // If data, get data values from response
        data.map(value => {
            const timestamp = getTimestampFromTimeValue(value._time);
            const valueData = getCsvArrayDataByEnvironmentType(value);

            // If data needs to be stored in array
            if(valueData !== null) {
                let isTimestampRowExisting = false;
                for(let row of formattedData) {
                    if(row[0] === timestamp) {
                        isTimestampRowExisting = true;
                        // Store value in corresponding column
                        row[valueData.column] = valueData.value;
                        break;
                    }
                }
                if(!isTimestampRowExisting) {
                    // Store value in corresponding column
                    let newRow = [timestamp];
                    newRow[valueData.column] = valueData.value;
                    formattedData.push(newRow);
                }
            }
        });
    }

    // Format date
    for(let row of formattedData) {
        row[0] = getFormattedFullFrDateWithSeconds(Math.floor(row[0] / 1000));
    }

    // Return array with header row
    return [['date', 'temperature', 'humidity', pollutionLabel ? pollutionLabel : 'pollution', vocType === SMART_FARM_DATA_FIELDS.TVOC ? 'tvoc' : 'voc']]
        .concat(formattedData);
}


/**
 * Get csv array column and formatted value for one influxdb data value
 * @param value: influxdb row data
 * @returns {{column: number, value}|null|{column: number, value: number}}
 */
const getCsvArrayDataByEnvironmentType = (value) => {
    const pollutionFields = [
      SMART_FARM_DATA_FIELDS.POLLUTION_PM1, SMART_FARM_DATA_FIELDS.POLLUTION_PM2_5, SMART_FARM_DATA_FIELDS.POLLUTION_PM4,
      SMART_FARM_DATA_FIELDS.POLLUTION_PM10, SMART_FARM_DATA_FIELDS.POLLUTION_EXT_PM1, SMART_FARM_DATA_FIELDS.POLLUTION_EXT_PM2_5,
      SMART_FARM_DATA_FIELDS.POLLUTION_EXT_PM4, SMART_FARM_DATA_FIELDS.POLLUTION_EXT_PM10
    ];

    if(value._field === SMART_FARM_DATA_FIELDS.TEMPERATURE || value._field === SMART_FARM_DATA_FIELDS.TEMPERATURE_EXT)
        return {column: 1, value: getFormattedTemperatureValue(value._value)};
    else if(value._field === SMART_FARM_DATA_FIELDS.HUMIDITY || value._field === SMART_FARM_DATA_FIELDS.HUMIDITY_EXT)
        return {column: 2, value: getFormattedHumidityValue(value._value)};
    else if(pollutionFields.indexOf(value._field) >= 0)
        return {column: 3, value: getFormattedPollutionValue(value._value)};
    else if(value._field === SMART_FARM_DATA_FIELDS.TVOC || value._field === SMART_FARM_DATA_FIELDS.VOC || value._field === SMART_FARM_DATA_FIELDS.VOC_EXT)
        return {column: 4, value: getFormattedTvocValue(value._value)};

    return null;
}

/**
 * Get all printers ids in farm structure
 * @param farmStructure
 * @returns {*[]}
 */
export const getFarmStructurePrintersIds = (farmStructure) => {
    let printersIds = [];
    if(farmStructure !== null && farmStructure.farm_modules) {
        farmStructure.farm_modules.map(module => {
           if(module.farm_cells) {
               module.farm_cells.map(cell => {
                  if(cell.printers) {
                      cell.printers.map(printer => printersIds.push(printer.id));
                  }
               });
           }
        });
    }
    return printersIds;
}

/**
 * Compare environment data with profiles to find if a profile is running
 * @param profiles
 * @param environmentData
 * @param systemType
 * @returns {*|null}
 */
export const findRunningProfileId = (profiles, environmentData, systemType) => {
    for(let profile of profiles) {
        if(profile.system_type === systemType && profile.temperature === parseInt(environmentData.temperature)) {
            return profile.id;
        }
    }
    return null;
}

/**
 * Get material status label depending on material status (index)
 * @param materialStatus
 * @returns {string}
 */
export const getMaterialStatusLabel = (materialStatus) => {
    switch (materialStatus) {
        case MATERIAL_CELL_STATUS.NO_DATA: return 'Off';
        case MATERIAL_CELL_STATUS.INACTIVE: return 'Inactive';
        case MATERIAL_CELL_STATUS.SWIRL: return 'Active';
        case MATERIAL_CELL_STATUS.REGENERATION: return 'Regeneration';
        default: return 'Off';
    }
}

/**
 * Get smart farm main version number depending on complete smart farm version number
 * @param version (ex: 1.0.0)
 * @returns {number}
 */
export const getFarmVersion = (version) => {
    if(version.charAt(0) === '1' || version.charAt(0) === '2') {
        return parseInt(version.charAt(0));
    }
    return 2;
}

/**
 * get an array of environment data influxdb fields depending on smart farm version and system code type (cell / structure)
 * @param isCellData
 * @param farmVersion
 * @returns {{tvoc: string|null, temperature: string|null, humidity: string|null}}
 */
export const getInfluxdbEnvironmentDataFieldsWithoutPollution = (isCellData, farmVersion) => {
    if(farmVersion === 2) {
        if(isCellData) {
            return {temperature: SMART_FARM_DATA_FIELDS.TEMPERATURE, humidity: SMART_FARM_DATA_FIELDS.HUMIDITY, tvoc: SMART_FARM_DATA_FIELDS.VOC};
        } else {
            return {temperature: SMART_FARM_DATA_FIELDS.TEMPERATURE_EXT, humidity: SMART_FARM_DATA_FIELDS.HUMIDITY_EXT, tvoc: SMART_FARM_DATA_FIELDS.VOC_EXT};
        }
    } else if(isCellData) {
        return {temperature: SMART_FARM_DATA_FIELDS.TEMPERATURE, humidity: SMART_FARM_DATA_FIELDS.HUMIDITY, tvoc: SMART_FARM_DATA_FIELDS.TVOC};
    }
    return {temperature: null, humidity: null, tvoc: null};
}

/**
 * Check if influxdb connection is available
 * @param secondsWithoutData
 * @returns {boolean}
 */
export const isInfluxdbConnectionError = (secondsWithoutData) => {
    return secondsWithoutData >= 20;
}

/**
 * Check if all system code buzzer are activated
 * @param smartFarmData
 * @returns {boolean}
 */
export const isAllBuzzerOff = (smartFarmData) => {
    if(smartFarmData) {
        for(let [key, value] of Object.entries(smartFarmData)) {
            if(value[SMART_FARM_DATA_FIELDS.BUZZER_ON]) {
                return false;
            }
        }
        return true;
    }
    return false;
}

/**
 * Activate a farm profile in a specific farm cell
 * @param profile
 * @param systemCode
 * @param farmCellId
 * @param isAutomatic
 * @returns {Promise<void>}
 */
export const activateFarmProfile = (profile, systemCode, farmCellId, isAutomatic = false) => {
    let promises = [];
    // Temperature
    if(profile.temperature !== undefined && profile.temperature !== null) {
        sendFarmCommandEvent(systemCode, SMART_FARM_COMMANDS.TEMPERATURE, profile.temperature);
        promises.push(postFarmEvent(farmCellId, FARM_EVENT_TYPES.TEMPERATURE, profile.temperature, null, isAutomatic));
    }
    return Promise.all(promises);
}

/**
 * Stop any farm profile running in a specific farm cell
 * @param systemCode
 * @param farmCellId
 * @param isAutomatic
 * @returns {Promise<Awaited<unknown>[]>}
 */
export const stopFarmProfile = (systemCode, farmCellId, isAutomatic = false) => {
    let promises = [];
    // Temperature
    sendFarmCommandEvent(systemCode, SMART_FARM_COMMANDS.TEMPERATURE, 0);
    promises.push(postFarmEvent(farmCellId, FARM_EVENT_TYPES.TEMPERATURE, 0, null, isAutomatic));
    return Promise.all(promises);
}

/**
 * Change farm cell led color
 * @param systemCode
 * @param farmCellId
 * @param ledColor
 * @param isAutomatic
 * @returns {Promise<void>}
 */
export const changeFarmCellLedColor = (systemCode, farmCellId, ledColor, isAutomatic = false) => {
    sendFarmCommandEvent(systemCode, SMART_FARM_COMMANDS.LED_COLOR, ledColor);
    return postFarmEvent(farmCellId, FARM_EVENT_TYPES.LED_COLOR, ledColor, null, isAutomatic);
}

/**
 * Check if smart farm is in general maintenance from smart farm data (= if all cells are in maintenance)
 * @param smartFarmData
 * @returns {{date: number, isInMaintenance: boolean}}
 */
export const getSmartFarmDataGeneralMaintenance = (smartFarmData) => {
    let isGeneralMaintenance = true;
    let date = 0;
    if(smartFarmData) {
        for(let [key, cell] of Object.entries(smartFarmData)) {
            if(date === 0  && cell.time) {
                date = Math.floor(cell.time / 1000);
            }
            if(!cell[SMART_FARM_DATA_FIELDS.MAINTENANCE_ACTIVE]) {
                isGeneralMaintenance = false;
                break;
            }
        }
    }
    return {date: date, isInMaintenance: isGeneralMaintenance};
}

/**
 * Check if a maintenance from bdd is running (= no end date)
 * @param maintenance
 * @returns {boolean}
 */
export const isMaintenanceRunning = (maintenance) => {
    return maintenance && !maintenance.end_date;
}

/**
 * Check if maintenance saved in database is corresponding to maintenance data received from smart farm
 * @param savedMaintenance
 * @param farmDataMaintenance
 * @returns {boolean|null}
 */
export const isSavedMaintenanceConfirmedBySmartFarmData = (savedMaintenance, farmDataMaintenance) => {
    if(savedMaintenance && farmDataMaintenance && farmDataMaintenance.date && farmDataMaintenance.isInMaintenance !== null) {
        const isInMaintenance = isMaintenanceRunning(savedMaintenance);
        if((isInMaintenance && !farmDataMaintenance.isInMaintenance && Math.abs(savedMaintenance.start_date - farmDataMaintenance.date) < COMMAND_TIMEOUT_LIMIT) ||
            (!isInMaintenance && farmDataMaintenance.isInMaintenance &&savedMaintenance.end_date && Math.abs(savedMaintenance.end_date - farmDataMaintenance.date) < COMMAND_TIMEOUT_LIMIT)) {
            return null;
        }
        if((isInMaintenance && farmDataMaintenance.isInMaintenance) ||
            (!isInMaintenance && !farmDataMaintenance.isInMaintenance)) {
            return true;
        }
        return false;
    }
    return null;
}


export const getFanSchedulingLocalStorage = () => {
    const isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    let fanScheduling = localStorage.getItem('fan_scheduling');

    if(fanScheduling === null || fanScheduling === undefined || !isJson(fanScheduling))
        return {};

    return JSON.parse(fanScheduling);
}

export const addFanSchedulingLocalStorage = (systemCode) => {
    const fanScheduling = getFanSchedulingLocalStorage();

    // Add the new fan scheduling
    fanScheduling[systemCode] = getCurrentTimestamp() + FAN_ACTIVATION_TIME_BEFORE_DOOR_OPENING;

    localStorage.setItem('fan_scheduling', JSON.stringify(fanScheduling));
    window.dispatchEvent(new Event('fan_scheduling'));
};

export const removeFanSchedulingLocalStorage = (systemCode) => {
    const fanScheduling = getFanSchedulingLocalStorage();

    if(systemCode in fanScheduling)
        delete fanScheduling[systemCode];

    localStorage.setItem('fan_scheduling', JSON.stringify(fanScheduling));

    return Object.keys(fanScheduling).length !== 0;
};

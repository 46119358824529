import {patchMaterial} from "../../../api/apiBackoffice";
import React from "react";
import {Controller, useForm} from "react-hook-form";

export const MaterialEdit = (props) => {

    const {successCallback, errorCallback, materials, materialsData, technologies, actualMaterial, setActualMaterial,
        isCustomMaterialValue, setIsCustomMaterialValue, setRightPartIsOpen, rightPartIsOpen} = props;

    const {reset, handleSubmit, control, setValue} = useForm();

    const handleUpdate = async (data) => {
        const material = addNewDataToMaterial({id: actualMaterial.id}, data);
        await patchMaterial(material)
            .then(() => {
                reset();
                setIsCustomMaterialValue(false);
                setRightPartIsOpen(!rightPartIsOpen);
                successCallback('This Material has been updated.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    }

    const handleMaterialSelectChange = (e) => {
        if(e.target.value === 'custom') {
            setIsCustomMaterialValue(true);
        } else {
            setValue('name', e.target.value);
            return e.target.value;
        }
    }

    // Add data from form to material object
    const addNewDataToMaterial = (material, data) => {
        material.technology = data.technology;
        if(isCustomMaterialValue) {
            material.name = data.name_custom;
            material.material_data = null;
        } else {
            const materialData = JSON.parse(data.name);
            material.name = materialData.name;
            material.material_data = materialData.id;
        }
        return material;
    }

    // Check if materialData is already used by another material
    const checkIfMaterialDataAlreadyUsed = (materialData) => {
        for(let i = 0; i < materials.data.length; i++) {
            if(materials.data[i].material_data && materialData.id === materials.data[i].material_data.id) {
                return true;
            }
        }
        return false;
    }

    // Check if material can be selected
    const checkIfMaterialIsAvailable = (materialData) => {
        if(!actualMaterial.material_data) {
            return checkIfMaterialDataAlreadyUsed(materialData);
        } else {
            return checkIfMaterialDataAlreadyUsed(materialData) &&
                actualMaterial.material_data && materialData.id !== actualMaterial.material_data.id;
        }
    }

    const defineDefaultNameValue = () => {
        for(let i = 0; i < materialsData.data.length; i++) {
            if(actualMaterial.material_data && actualMaterial.material_data.id === materialsData.data[i].id) {
                return JSON.stringify(materialsData.data[i]);
            }
        }
        return 0;
    }

    const handleChooseMaterialInListButtonClick = () => {
        setIsCustomMaterialValue(false);
    }

    return(
        <>
            <h2 style={{"paddingLeft":"10px"}}>Update Material</h2>
            <form onSubmit={handleSubmit(handleUpdate)}>
                <label>Material name :</label>
                <div style={{"display": "flex", "justifyContent": "center", "marginBottom": "20px"}}>
                    {!isCustomMaterialValue &&
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                required: !isCustomMaterialValue,
                                validate: (value) => (value !== 0 || isCustomMaterialValue)
                            }}
                            defaultValue={defineDefaultNameValue()}
                            render={({field}) => (
                                <select id="material-name" {...field}
                                        onChange={(e) => handleMaterialSelectChange(e)}>
                                    <option disabled value={0}>--Select a brand--</option>
                                    {materialsData.data.map(material => (
                                        <option key={material.id} value={JSON.stringify(material)}
                                                disabled={checkIfMaterialIsAvailable(material)}>{material.name}</option>
                                    ))}
                                    <option value="custom">Add a custom value</option>
                                </select>
                            )}
                        />
                    }
                    {isCustomMaterialValue &&
                        <div>
                            <Controller
                                control={control}
                                name="name_custom"
                                rules={{required: isCustomMaterialValue}}
                                defaultValue={actualMaterial.name}
                                render={({field}) => (
                                    <input type="text" {...field}/>
                                )}
                            />
                            <input type="button" value="Choose a brand in list" style={{display: "block", margin: "auto"}}
                                onClick={() => handleChooseMaterialInListButtonClick()}/>
                        </div>
                    }
                </div>
                <Controller
                    control={control}
                    name="technology"
                    rules={{required: true, validate: (value) => value !== 0}}
                    defaultValue={actualMaterial ? actualMaterial.technology.id : 0}
                    render={({field}) => (
                        <label>Technology used:
                            <select name="technology" {...field}>
                                <option value={0}>-- Choose a Technology --</option>
                                {technologies.data && technologies.data.map((techno) => (
                                    <option key={techno.id} value={techno.id}>{techno.name}</option>
                                ))}
                            </select>
                        </label>
                    )}
                />
                <br/>
                <input type="submit" value="Save" style={{display: "block", margin: "auto"}} />
            </form>
        </>
    );
}

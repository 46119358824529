import {getCurrentTimestamp, getCurrentUser} from "../components/CommonFunctions";
import {patchData, postData} from "./tools/apiTools";
import {fetchData} from "./useFetch";

export const maintenancePath = 'farm_maintenances';
const farmStructurePath = 'farm_structures';
const farmCellPath = 'farm_cells';
const userPath = 'users';
export const farmCellsLastMaintenancesPath = farmCellPath + '/last_maintenance';

/**
 * Post maintenance - New maintenance without end date (only start date)
 * @param farmCellId
 * @param farmStructureId
 * @returns {Promise<never>|Promise<*>}
 */
export const postMaintenanceStart = (farmStructureId = null, farmCellId = null, userId) => {
    if(!farmCellId && !farmStructureId) {
        return Promise.reject('No defined farm cell or structure');
    } else {
        const body = {
            farm_structure: (farmStructureId !== undefined && farmStructureId !== null && farmStructureId > 0) ?
                process.env.REACT_APP_API_URI + farmStructurePath + '/' + farmStructureId : null,
            farm_cell: (farmCellId !== undefined && farmCellId !== null && farmCellId > 0) ?
                process.env.REACT_APP_API_URI + farmCellPath + '/' + farmCellId : null,
            start_date: getCurrentTimestamp(),
            user: process.env.REACT_APP_API_URI + userPath + '/' + userId
        };
        return postData(maintenancePath, body);
    }
}

/**
 * Patch maintenance - add endDate
 * @param maintenanceId
 * @returns {Promise<never>|Promise<*>}
 */
export const patchMaintenanceEnd = (maintenanceId) => {
    if(!maintenanceId) {
        return Promise.reject('No maintenance id');
    } else {
        const body = {end_date: getCurrentTimestamp()};
        return patchData(maintenancePath + '/' + maintenanceId, body);
    }
}

/**
 * Get all farm cells with last maintenance data
 * @returns {Promise<any>}
 */
export const getLastMaintenanceForEveryFarmCell = () => {
    return fetchData(farmCellsLastMaintenancesPath)
        .then(response => response.json());
}
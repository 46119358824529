import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useGetData} from "../../api/useFetch";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {ProjectInformation} from "./ProjectInformation";
import {ProjectData} from "./ProjectData";
import {ProjectDescription} from "./ProjectDescription";
import {ProjectFiles} from "./ProjectFiles";
import {AlertMessage} from "../common/AlertMessage";
import {ProjectPics} from "./ProjectPics";

export const Project = () => {

    let {id} = useParams();

    const project = useGetData(`projectData`,'projects/' + id);

    const [alert, setAlert] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(!project.isFetching && isLoading) {
            setIsLoading(false);
        }
    }, [project.isFetching]);

    const successCallback = (message) => {
        project.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        project.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    if(project.isLoading || isLoading) return <Loading/>
    if(project.isError) return <Error errorMessage={project.error.message}/>

    return (
        <div id="main__project">
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}
            <div id="project__infos" className="flex-div">

                <div className="block">
                    <ProjectInformation project={project.data} successCallback={successCallback} errorCallback={errorCallback}/>
                </div>

                <div id="project__figures" className="block">
                    <ProjectData project={project.data}/>
                </div>
            </div>
            <div className="flex-div">
                <div id="project__notes" className="block">
                    <ProjectDescription project={project.data}/>
                </div>
                <div id="project__pics" className="block">
                    <ProjectPics pics={project.data.project_pics ? project.data.project_pics : []} isLoading={project.isFetching}/>
                </div>
            </div>
            <ProjectFiles project={project.data} successCallback={successCallback} errorCallback={errorCallback}/>
        </div>
    );
}


import {readAllNotifications, readNotification} from "../../api/apiUser";
import {getFormattedDateNotification} from "../CommonFunctions";
import React from "react";
import {Popover, Whisper} from "rsuite";

export const NotificationsPanel = (props) => {

    const {notifications, setNotifications} = props;

    const markNotificationAsRead = (e, notification) => {
        e.stopPropagation();
        setNotifications(notifications.map(n => n.id === notification.id ? {...n, read: true} : n));
        readNotification(notification, false);
    };

    return (
        <div id="notifications__panel">

            <div id="notifications__list">
                <div className="notification__header">
                    <h2>Notifications</h2>
                    {
                        notifications.filter(notification => !notification.read).length > 0 &&
                            <Whisper placement="left" trigger="hover" speaker={<Popover><span>Mark all as read</span></Popover>}>
                                <div className="header__read-all" onClick={() => readAllNotifications(notifications)}>
                                    <i className="fas fa-check"/>
                                </div>
                            </Whisper>

                    }
                </div>
                {notifications.map((notification, index) => {
                    const read_class = !notification.read ? 'notification--unread' : '';
                    return (
                        <div onClick={() => readNotification(notification)} key={index} className={`list__item-notification ${read_class}`}>
                            <h3>{notification.title}</h3>
                            <p>{notification.content}</p>
                            <span className="notification__date">{getFormattedDateNotification(notification.creation_date)}</span>
                            <Whisper placement="bottom" trigger="hover" speaker={<Popover><span>Mark as read</span></Popover>}>
                                <div className="notification__read" onClick={(e) => markNotificationAsRead(e, notification)}>
                                    <i className="fas fa-check"/>
                                </div>
                            </Whisper>
                        </div>
                    )
                })}
                {
                    notifications.length === 0 &&
                    <p>You do not have any notification.</p>
                }
            </div>

        </div>
    );

};
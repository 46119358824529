/* Get influxdb window string value depending on selected data range */
import {CHARTS_DATA_RANGE, CHARTS_UNIT_PERIODS} from "../FarmService";

export const getInfluxdbDataWindow = (dataRange, startDate, endDate) => {
    switch (dataRange) {
        case CHARTS_DATA_RANGE.DAY: return CHARTS_UNIT_PERIODS.HOUR;
        case CHARTS_DATA_RANGE.WEEK: return CHARTS_UNIT_PERIODS.QUARTER_DAY;
        case CHARTS_DATA_RANGE.MONTH: return CHARTS_UNIT_PERIODS.DAY;
        case CHARTS_DATA_RANGE.CUSTOM: return getInfluxdbDataWindowFromCustomDataRange(startDate, endDate);
        default: return CHARTS_UNIT_PERIODS.DAY;
    }
}

/* Get influxdb window string value depending on selected data range */
export const getInfluxdbDataWindowFromCustomDataRange = (startDate, endDate) => {
    const intervalBetweenDates = endDate.getTime() - startDate.getTime();
    if(intervalBetweenDates <= 3600 * 24 * 1000) {
        return CHARTS_UNIT_PERIODS.HOUR;
    } else if(intervalBetweenDates <= 3600 * 24 * 1000 * 7) {
        return CHARTS_UNIT_PERIODS.QUARTER_DAY;
    } else if(intervalBetweenDates <= 2678400000) { // interval < 1 month (3600 * 24 * 31 * 1000)
        return CHARTS_UNIT_PERIODS.DAY;
    } else {
        return CHARTS_UNIT_PERIODS.WEEK;
    }
}

export const getInfluxdbStartDate = (dataRange, startDate) => {
    switch (dataRange) {
        case CHARTS_DATA_RANGE.DAY: return '-1d';
        case CHARTS_DATA_RANGE.WEEK: return '-7d';
        case CHARTS_DATA_RANGE.MONTH: return '-30d';
        case CHARTS_DATA_RANGE.CUSTOM: return '-' + getNumberOfDaysBetweenTwoDates(startDate, new Date()) + 'd';
        default: return '-1d';
    }
}

export const getInfluxdbEndDate = (dataRange, endDate) => {
    if(dataRange === CHARTS_DATA_RANGE.CUSTOM) {
        const daysFromToday = getNumberOfDaysBetweenTwoDates(endDate, new Date());
        if(daysFromToday > 0) {
            return '-' + daysFromToday + 'd';
        }
    }
    return 'now()';
}

export const getNumberOfDaysBetweenTwoDates = (startDate, endDate) => {
    return Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
}

/**
 * Get y axis chart max value (max data value + 10), minimum = 20
 * @param data: [] - [[time, value], ...]
 */
export const getMaxChartValue = (data) => {
    let max = 0;
    if(data !== null && data !== undefined && data.length > 0) {
        for(let value of data) {
            if(value[1] > max) {
                max = value[1];
            }
        }
    }
    return (max < 15) ? 15 : max + Math.floor(max / 10);
}
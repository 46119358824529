import {
    dateToTimestampInSec,
} from "../../../../utils/date";

export const TasksCursor = (props) => {
    const {firstDisplayedDate, lastDisplayedDate, setCursorTime, setIsMovableTimeBarRender} = props;
    const handleClickZone = (e, typeEvent) => {
        let totalWidthCursorBar = e.target.offsetWidth;
        let timestampFirstDiplayDate=dateToTimestampInSec(firstDisplayedDate);
        let timestampLastDiplayDate=dateToTimestampInSec(lastDisplayedDate);
        let timestampBetweenTwoDates = timestampLastDiplayDate-timestampFirstDiplayDate;

        let secByPx = Math.round(timestampBetweenTwoDates / totalWidthCursorBar * 100) / 100;
        let rect = e.target.getBoundingClientRect();
        let mousePositionX;
        if (typeEvent === 'touch') {
            mousePositionX = e.targetTouches[0].clientX - rect.left;
        } else {
            mousePositionX = e.clientX - rect.left;
        }
        mousePositionX = String(mousePositionX).split('.')[0];
        let result = timestampFirstDiplayDate+secByPx*mousePositionX;
        setCursorTime(new Date(result*1000));
        setIsMovableTimeBarRender(true);
    };

    const handleMouseLeave = (e) => {
        setTimeout(() => {
            setIsMovableTimeBarRender(false);
            setCursorTime(undefined);

        }, 3000);
    }

    return (
        <div className="gc-tasks-container__cursor gc-tasks-cursor">
            <div 
                className="gc-tasks-cursor__zone"
                onMouseMove={(e)=>handleClickZone(e, 'click')}
                onTouchMove={(e)=>handleClickZone(e, 'touch')}
                onMouseLeave={(e) => handleMouseLeave(e)}
                onTouchEnd={(e) => handleMouseLeave(e)}
            />


        </div>
    );
};

import React, {useEffect, useReducer, useState} from "react";
import {getCurrentTimestamp} from "../../CommonFunctions";
import {
    getFormattedHumidityValue, getFormattedPollutionValue,
    getFormattedTemperatureValue, getFormattedTvocValue,
    getInfluxdbDataBySystemCode,
    getSingleInfluxdbDataBySystemCode, getTimestampFromTimeValue
} from "../../../api/apiInfluxdb";
import {ChartsDataRangeBlock} from "./blocks/ChartsDataRangeBlock";
import {
    CHARTS_DATA_RANGE,
    CHARTS_UNIT_PERIODS,
    createCsvData,
    downloadCsvData,
    formatInfluxdbDataForCsvArray, getInfluxdbEnvironmentDataFieldsWithoutPollution,
    INFLUXDB_LIST_REQUEST_INTERVAL,
    INFLUXDB_SINGLE_REQUEST_INTERVAL, isInfluxdbConnectionError, mergeEnvironmentDataLists, PM_TYPES
} from "../../../services/FarmService";
import {
    getInfluxdbDataWindow,
    getInfluxdbEndDate,
    getInfluxdbStartDate
} from "../../../services/farm/FarmChartsService";
import {HumidityBlock} from "./blocks/HumidityBlock";
import {TemperatureBlock} from "./blocks/TemperatureBlock";
import {PollutionBlock} from "./blocks/PollutionBlock";
import {Loading} from "../../common/Loading";
import {MaterialIcon} from "../../common/icons/MaterialIcon";
import {MaintenanceBlock} from "./blocks/MaintenanceBlock";

export const FarmDialog = (props) => {

    const {isRightPartOpen, setAlert, farmVersion, children, render, systemCode, isCellPanel, setFarmMaintenances} = props;

    const influxdbFieldsWithoutPollution = getInfluxdbEnvironmentDataFieldsWithoutPollution(isCellPanel, farmVersion);

    // Graphics data range
    const [selectedDataRange, setSelectedDataRange] = useState(CHARTS_DATA_RANGE.DAY);
    const [customDataRange, setCustomDataRange] = useState({startDate: null, endDate: null});
    const [unitPeriod, setUnitPeriod] = useState(CHARTS_UNIT_PERIODS.HOUR);

    // Pollution data type
    const [selectedPollutionType, setSelectedPollutionType] = useState(PM_TYPES[0]);

    // Last environment data values
    const [lastTemperature, setLastTemperature] = useState(null);
    const [lastHumidity, setLastHumidity] = useState(null);
    const [lastPollution, setLastPollution] = useState(null);
    const [lastTVOC, setLastTVOC] = useState(null);

    // Environment data list values
    const [temperaturesList, setTemperaturesList] = useState([]);
    const [humidityList, setHumidityList] = useState([]);
    const [pollutionsList, setPollutionsList] = useState([]);
    const [TVOCList, setTVOCList] = useState([]);

    // setInterval intervals
    const [singleEnvironmentDataInterval, setSingleEnvironmentDataInterval] = useState();
    const [environmentDataListInterval, setEnvironmentDataListInterval] = useState();

    const [isLoading, setIsLoading] = useState(true);

    const secondsWithoutDataReducer = (state, action) => {
        if(action.type === 'reset') {
            return 0;
        }
        if(action.type === 'add-time') {
            return state + INFLUXDB_SINGLE_REQUEST_INTERVAL;
        }
        return state;
    }

    // seconds without receiving environment data
    const [secondsWithoutData, setSecondsWithoutData] = useReducer(secondsWithoutDataReducer, 20);

    useEffect(() => {
        return () => {
            clearInterval(singleEnvironmentDataInterval);
            clearInterval(environmentDataListInterval);
        }
    }, []);

    useEffect(() => {
        if(isRightPartOpen) {
            clearInterval(singleEnvironmentDataInterval);
            // if right part is open, get data from influxdb
            getSingleFarmValues();
            setSingleEnvironmentDataInterval(setInterval(() => {
                getSingleFarmValues();
            }, INFLUXDB_SINGLE_REQUEST_INTERVAL* 1000));
        } else {
            // if right part is closed, stop data requests to influxdb
            clearInterval(singleEnvironmentDataInterval);
            clearInterval(environmentDataListInterval);
        }
    }, [isRightPartOpen, selectedPollutionType]);

    useEffect(() => {
        // clear interval and get data lists from influxdb
        clearInterval(environmentDataListInterval);
        if(isRightPartOpen) {
            getFarmValuesList(selectedDataRange, customDataRange.startDate, customDataRange.endDate);
            setEnvironmentDataListInterval(setInterval(() => {
                getFarmValuesList(selectedDataRange, customDataRange.startDate, customDataRange.endDate);
            }, INFLUXDB_LIST_REQUEST_INTERVAL * 1000));
        }
    }, [isRightPartOpen, selectedDataRange, customDataRange, selectedPollutionType]);

    /* Get last data values from smart farm (temperature, humidity) */
    const getSingleFarmValues = () => {
        getSingleInfluxdbDataBySystemCode([systemCode],
            [influxdbFieldsWithoutPollution.temperature, influxdbFieldsWithoutPollution.humidity, influxdbFieldsWithoutPollution.tvoc, selectedPollutionType.field])
            .then(data => {
                let newTemperature = null, newHumidity = null, newCO2 = null, newTVOC = null;

                if(data && data.length > 0) {
                    let isOldValue = true;
                    // If data, get data values from response
                    data.map(value => {
                        const valueDate = new Date(value._time);
                        // Check if value is recent (less than 15 seconds)
                        if(isOldValue && (getCurrentTimestamp() - (Math.floor(valueDate.getTime() / 1000)) < 15)) {
                            isOldValue = false;
                        }
                        switch(value._field) {
                            case influxdbFieldsWithoutPollution.temperature:
                               newTemperature = getFormattedTemperatureValue(value._value);
                               break;
                            case influxdbFieldsWithoutPollution.humidity:
                               newHumidity = getFormattedHumidityValue(value._value);
                               break;
                            case selectedPollutionType.field:
                                newCO2 = getFormattedPollutionValue(value._value);
                                break;
                            case influxdbFieldsWithoutPollution.tvoc:
                                newTVOC = getFormattedTvocValue(value._value);
                                break;
                        }
                    });
                    // Update seconds without data value depending on if received data is recent (less than 15 seconds) or not
                    setSecondsWithoutData({type: isOldValue ? 'add-time' : 'reset'});
                } else {
                    setSecondsWithoutData({type: 'add-time'});
                }

                setLastTemperature(newTemperature);
                setLastHumidity(newHumidity);
                setLastPollution(newCO2);
                setLastTVOC(newTVOC);
            })
            .catch(() => {
                setSecondsWithoutData({type: 'add-time'});
            })
            .finally(() => {
                if(isLoading) {
                    setIsLoading(false);
                }
            });
    }

    /* Get data values in data range from smart farm (temperature, humidity, pollution, tvoc) */
    const getFarmValuesList = (dataRange, startDate, endDate) => {
        const influxdbUnitPeriod = getInfluxdbDataWindow(dataRange, startDate, endDate);
        const influxdbStartDate = getInfluxdbStartDate(dataRange, startDate);
        const influxdbEndDate = getInfluxdbEndDate(dataRange, endDate);
        getInfluxdbDataBySystemCode([systemCode],
            [influxdbFieldsWithoutPollution.temperature, influxdbFieldsWithoutPollution.humidity, influxdbFieldsWithoutPollution.tvoc, selectedPollutionType.field],
            influxdbUnitPeriod, influxdbStartDate, influxdbEndDate)
            .then(data => {
                let newTemperatureList = [];
                let newHumidityList = [];
                let newPollutionsList = [];
                let newTVOCList = [];
                if(data && data.length > 0) {
                    // If data, get data values from response
                    data.map(value => {
                        switch(value._field) {
                            case influxdbFieldsWithoutPollution.temperature:
                                newTemperatureList.push([getTimestampFromTimeValue(value._time), getFormattedTemperatureValue(value._value)]);
                                break;
                            case influxdbFieldsWithoutPollution.humidity:
                                newHumidityList.push([getTimestampFromTimeValue(value._time), getFormattedHumidityValue(value._value)]);
                                break;
                            case selectedPollutionType.field:
                                newPollutionsList.push([getTimestampFromTimeValue(value._time), getFormattedPollutionValue(value._value)]);
                                break;
                            case influxdbFieldsWithoutPollution.tvoc:
                                newTVOCList.push([getTimestampFromTimeValue(value._time), getFormattedTvocValue(value._value)]);
                                break;
                        }
                    });
                }
                setUnitPeriod(influxdbUnitPeriod);
                setTemperaturesList(newTemperatureList);
                setHumidityList(newHumidityList);
                setPollutionsList(newPollutionsList);
                setTVOCList(newTVOCList);
            })
            .catch(() => null);
    }

    /* Download environment data as csv file */
    const handleDownloadEnvironmentData = () => {
        let promise;
        // Case unit period = 1 hour : directly format data present in app
        if(unitPeriod === CHARTS_UNIT_PERIODS.HOUR) {
            promise = Promise.resolve(mergeEnvironmentDataLists(temperaturesList, humidityList, pollutionsList, TVOCList,
                influxdbFieldsWithoutPollution.tvoc, selectedPollutionType ? selectedPollutionType.label : null));
        } else {
            // Case unit period != 1 hour : get new data with one data per hour
            promise = getEnvironmentDataForCsv(selectedDataRange, customDataRange.startDate, customDataRange.endDate)
                .then(data => formatInfluxdbDataForCsvArray(data, influxdbFieldsWithoutPollution.tvoc, selectedPollutionType ? selectedPollutionType.label : null))
                .catch(() => setAlert({date: new Date(), message: 'An error occurred while creating csv', status: 'error'}));
        }
        // Create and download csv
        promise.then(formattedData => {
            const csvData = createCsvData(formattedData);
            downloadCsvData(csvData)
                .then(() => setAlert({date: new Date(), message: 'Environment data file created', status: 'success'}))
                .catch(() => setAlert({date: new Date(), message: 'An error occurred while creating csv', status: 'error'}));
        });
    }

    /* Request influxdb environment data needed for csv export */
    const getEnvironmentDataForCsv = (dataRange, startDate, endDate) => {
        const influxdbStartDate = getInfluxdbStartDate(dataRange, startDate);
        const influxdbEndDate = getInfluxdbEndDate(dataRange, endDate);
        return getInfluxdbDataBySystemCode([systemCode],
            [influxdbFieldsWithoutPollution.temperature, influxdbFieldsWithoutPollution.humidity, influxdbFieldsWithoutPollution.tvoc, selectedPollutionType.field],
            '1h', influxdbStartDate, influxdbEndDate);
    }

    const getChartsDataRangeBlock = () => {
        return (
            <ChartsDataRangeBlock
                selectedDataRange={selectedDataRange} setSelectedDataRange={setSelectedDataRange}
                setCustomDataRange={setCustomDataRange} handleDownloadEnvironmentData={() => handleDownloadEnvironmentData(influxdbFieldsWithoutPollution.tvoc)}
            />
        )
    }

    const getTemperatureBlock = (farmEvents, isCommandEnabled, structureMaintenance, cellMaintenance, temperatureCommandData = undefined,
                                 handleTemperatureChange = undefined, ) => {
        return(
            <TemperatureBlock
                temperature={lastTemperature} temperaturesList={temperaturesList}
                temperatureCommandData={temperatureCommandData} unitPeriod={unitPeriod} farmEvents={farmEvents}
                handleTemperatureChange={handleTemperatureChange} isCommandEnabled={isCommandEnabled}
                structureMaintenance={structureMaintenance} cellMaintenance={cellMaintenance}
            />
        );
    }

    const getHumidityBlock = () => {
        return(
            <HumidityBlock humidity={lastHumidity} humidityList={humidityList} unitPeriod={unitPeriod}/>
        );
    }

    const getPollutionBlock = (isCommandEnabled, structureMaintenance, cellMaintenance, airExtractionCommandData = undefined,
                               handleExtractionChange = undefined, filterChange = undefined) => {
        return(
            <PollutionBlock
                pollution={lastPollution} tvoc={lastTVOC} pollutionList={pollutionsList}
                tvocList={TVOCList} unitPeriod={unitPeriod} airExtractionCommandData={airExtractionCommandData}
                handleExtractionChange={handleExtractionChange} vocType={influxdbFieldsWithoutPollution.tvoc} filterChange={filterChange}
                selectedPollutionType={selectedPollutionType} setSelectedPollutionType={setSelectedPollutionType}
                isCommandEnabled={isCommandEnabled} systemCode={systemCode}
                structureMaintenance={structureMaintenance} cellMaintenance={cellMaintenance}
            />
        );
    }

    const getMaintenanceBlock = (structureMaintenance, cellMaintenance, structureId, cellId, smartFarmDataMaintenance) => {
        return(
            <MaintenanceBlock
                structureMaintenance={structureMaintenance} cellMaintenance={cellMaintenance}
                structureId={structureId} cellId={cellId} systemCode={systemCode} setAlert={setAlert}
                setFarmMaintenances={setFarmMaintenances} smartFarmDataMaintenance={smartFarmDataMaintenance}
            />
        );
    }

    return (
        <div className="farm__dialog">
            <div className="farm-dialog-title-bloc flex">
                <div className="farm-dialog-title">{isCellPanel ? 'Settings' : 'General settings'}
                {
                    isCellPanel && <span className="environment-name">[{systemCode}]</span>
                }
                </div>
            </div>
            {isLoading ?
                <Loading/> :
                <>
                    {/* Warning if no connexion with smart farm */}
                    {isInfluxdbConnectionError(secondsWithoutData) &&
                        <div className="connection-smart-farm-error">
                            <MaterialIcon classes="smart-farm-error-icon" label="error"/>
                            <span className="smart-farm-error-text">Connection with Smart Farm not available</span>
                        </div>
                    }
                    {render(children, {secondsWithoutData, getChartsDataRangeBlock, getTemperatureBlock, getHumidityBlock,
                        getPollutionBlock, getMaintenanceBlock})}
                </>
            }
        </div>
    );
}

import {getFormattedRoundedDate} from "../../../CommonFunctions";
import React, {useEffect, useState} from "react";
import {useGetData} from "../../../../api/useFetch";
import {maintenancePath} from "../../../../api/apiMaintenance";
import {Loading} from "../../../common/Loading";
import {useIsMounted} from "../../../../services/hooks/useIsMounted";

export const MaintenancesHistoryTable = (props) => {

    const {cellId, structureId, isInMaintenance} = props;
    let maintenancesHistoryParams = cellId ? {farmStructure: structureId, farmCell: cellId} : {farmStructure: structureId};

    const isMounted = useIsMounted();

    const maintenancesHistory = useGetData('farm maintenances', maintenancePath + '/history', maintenancesHistoryParams);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(maintenancesHistory.data) {
            maintenancesHistory
                .refetch()
                .then(() => isMounted && setIsLoading(false));
        } else {
            isMounted && setIsLoading(false);
        }
    }, [isInMaintenance]);

    const getMaintenanceUser = (user) => {
        return user ? user.firstname + ' ' + user.lastname : '--';
    }

    if(maintenancesHistory.isLoading || isLoading) return <Loading/>;

    return(
        <div className="farm__dialog_maintenance_history">
            <div className="maintenances-history-title">Maintenances history</div>
            <div className="maintenances-history-body">
                {maintenancesHistory.data && maintenancesHistory.data.length > 0 ?
                    <div className="maintenances-history-table">
                        <table>
                            <thead>
                            <tr>
                                <th>Start date</th>
                                <th>End date</th>
                                <th>User</th>
                                {cellId &&
                                    <th>General maintenance</th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {maintenancesHistory.data.map(maintenance =>
                                <tr key={maintenance.id}>
                                    <td>{getFormattedRoundedDate(new Date(maintenance.start_date)).toString()}</td>
                                    <td>{maintenance.end_date ? getFormattedRoundedDate(new Date(maintenance.end_date)).toString() : '--'}</td>
                                    <td>{getMaintenanceUser(maintenance.user)}</td>
                                    {cellId &&
                                        <td>{maintenance.farm_structure ? 'yes' : 'no'}</td>
                                    }
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div> :
                    <div>No history</div>
                }
            </div>
        </div>
    );
}
import {useEffect, useMemo, useState} from "react";
import {
    dateToHourMinuteString,
    dateToShortDayMonthString,
    dateToTimestampInSec,
    dateToUtcTimestampInSec,
} from "../../../../utils/date";
import {DISPLAY_MODES} from "../Calendar";

export const TasksMovableTimeBar = (props) => {
    const {displayMode, firstDisplayedDate, lastDisplayedDate, cursorTime} = props;
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 50);
        return () => {
            clearInterval(interval);
        };
    }, []);

    /* UTC dates are used to properly handle time changes. */
    const blockWidth = useMemo(() => {
        let firstDateInSec;
        let lastDateInSec;
        let currentDateInSec;
        if (displayMode === DISPLAY_MODES.DAY) {
            firstDateInSec = dateToTimestampInSec(firstDisplayedDate);
            lastDateInSec = dateToTimestampInSec(lastDisplayedDate);
            if (cursorTime) {
                currentDateInSec = dateToTimestampInSec(cursorTime);
            } else {
                currentDateInSec = dateToTimestampInSec(currentDateTime);
            }
        } else {
            firstDateInSec = dateToUtcTimestampInSec(firstDisplayedDate);
            lastDateInSec = dateToUtcTimestampInSec(lastDisplayedDate);
            if (cursorTime) {
                currentDateInSec = dateToUtcTimestampInSec(cursorTime);
            } else {
                currentDateInSec = dateToUtcTimestampInSec(currentDateTime);
            }
        }
        if (currentDateInSec > lastDateInSec) {
            return 100;
        }
        if (currentDateInSec <= firstDateInSec) {
            return 0;
        }
        return ((currentDateInSec - firstDateInSec) / (lastDateInSec - firstDateInSec)) * 100;
    }, [currentDateTime, displayMode, firstDisplayedDate, lastDisplayedDate]);

    const blockText = useMemo(() => {
        switch (displayMode) {
            case DISPLAY_MODES.MONTH:
                if (cursorTime) {
                    return dateToShortDayMonthString(cursorTime);
                } else {
                    return dateToShortDayMonthString(currentDateTime);
                }
            case DISPLAY_MODES.DAY:
            case DISPLAY_MODES.WEEK:
            default:
                if (cursorTime) {
                    return dateToHourMinuteString(cursorTime);
                } else {
                    return dateToHourMinuteString(currentDateTime);
                }
        }
    }, [currentDateTime, displayMode, cursorTime]);

    return (
        <div className="gc-tasks-container__time-bar gc-tasks-movable-time-bar"
        >
            {/* Current date or past dates displayed */}
            {blockWidth > 0 && (
                <div className="gc-tasks-movable-time-bar__block gc-tasks-movable-time-bar-block" style={{width: `${blockWidth}%`}}>
                    {/* Current date displayed */}
                    {blockWidth < 100 && (
                        <>
                            <div className="gc-tasks-movable-time-bar-block__text">{blockText}</div>
                            <div className="gc-tasks-movable-time-bar-block__line"/>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

import React, {useState} from 'react';
import {useGetData} from "../../../api/useFetch";
import {postPrinterBrand, deletePrinterBrand, patchPrinterBrand} from "../../../api/apiBackoffice";
import {Drawer, makeStyles} from "@material-ui/core";
import {Loading} from "../../common/Loading";

export const Printers = () => {

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [alert, setAlert] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [actualBrand, setActualBrand] = useState();

    const printers = useGetData(`printer`, `printer_brands`);
    
    const successCallback = (message) => {
        printers.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        printers.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    const handleCreate = async (name) => {
        await postPrinterBrand(name)
            .then(() => {
                setRightPartIsOpen(!rightPartIsOpen);
                successCallback('This Printer Brand has been created.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    };

    const handleDelete = async (print_brand_Id) => {
        if(window.confirm('Are you sure you want to delete this Printer Brand ?'))
            await deletePrinterBrand(print_brand_Id)
                .then(() => successCallback('This Printer Brand has been deleted.'))
                .catch(() => errorCallback('Something went wrong.'));
    };

    const handleUpdate = async (name, id) => {
        await patchPrinterBrand(name, id)
            .then(() => {
                setRightPartIsOpen(!rightPartIsOpen);
                successCallback('This Printer Brand has been updated.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    };

    return (
        <div className="backoffice_block" style={{display: "block", border: "2px solid lightgrey", padding: "15px"}}>
            <div className="printers_header">
                <h2>Printers Brand</h2>
                <button className="button-link" onClick={() => {
                    setIsUploading(false);
                    setRightPartIsOpen(!rightPartIsOpen);
                }}>
                    <i className="fa fa-plus"/> Add a brand
                </button>
            </div>
            {printers.data ? (
                <table>
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {printers.data.map((printer) => (
                            <tr key={printer.id}>
                                <td>{printer.name}</td>
                                <td>
                                    <button className="button-link" onClick={()=> {
                                        setIsUploading(true);
                                        setActualBrand(printer);
                                        setRightPartIsOpen(!rightPartIsOpen);
                                    }}>
                                        <i className="fas fa-edit" />
                                    </button>
                                    &nbsp;
                                    <button className="button-link" onClick={()=> handleDelete(printer.id)}><i className="fas fa-trash-alt" /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <Loading />
            )}
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)}>

                <div className="backoffice_drawer_content" style={{width:"500px", "marginTop":"30px"}}>
                {!isUploading ? (
                    <>
                        <h2 style={{"paddingLeft":"10px"}}>Add Printer Brand</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleCreate(e.target.name.value)
                        }}>
                            <label>Printer Brand name :
                                <input type="text" name="name" />
                            </label>
                            <br/>
                            <input type="submit" value="Envoyer" style={{display: "block", margin: "auto"}} />
                        </form>
                    </>
                ) : (
                    <>
                        <h2 style={{"paddingLeft":"10px"}}>Update Printer Brand</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleUpdate(actualBrand.name, actualBrand.id);
                        }}>
                            <label>Printer Brand name :
                                <input type="text" name="name" value={actualBrand.name} onChange={(e) => setActualBrand({...actualBrand, ["name"]: e.currentTarget.value})}/>
                            </label>
                            <br/>
                            <input type="submit" value="Envoyer" style={{display: "block", margin: "auto"}} />
                        </form>
                    </>
                )}
                </div>
            </Drawer>
        </div>
    )
}

import {getUserName} from "../components/CommonFunctions";

/**
 * Authenticate user (get user token)
 * @param credentials
 * @returns {Promise<Response>}
 */
export const postAuthenticateUser = (credentials) => {
    return fetch(process.env.REACT_APP_API_TOKEN_AUTH, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    });
}

/**
 * Get user corresponding to token (response data = list of one user)
 * @param userToken
 * @returns {Promise<Response>}
 */
export const getUserByEmailWithToken = (userToken) => {
    const userEmail = getUserName(userToken);
    return fetch(process.env.REACT_APP_API_ADDRESS + `users?email=${userEmail}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    }).then(response => {
            if(response.ok) {
                return response.json();
            }
            throw Error(response.status.toString());
        });
}
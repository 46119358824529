import {Table} from "rsuite";
import React, {useEffect, useState} from "react";
import {getAsyncSortedData} from "../../../services/TablesService";
import {getFormattedDate, isUserGranted} from "../../CommonFunctions";
import {Avatar} from "../../common/Avatar";
import {PicActionsMenuButton} from "./PicActionsMenuButton";
import {TableWrapper} from "../../common/TableWrapper";

export const PicActiveTable = (props) => {

    const {data, handleDeletePic, handleDuplicatePic, handleManagePicProjects, isTableDataLoading} = props;

    // Table states
    const { Column, HeaderCell, Cell } = Table;
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(true);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        setLoading(true);
        if(data !== undefined) {
            setSortedData(data);
            setLoading(false);
        }
    }, [data]);

    const handleSortColumn = (column, type) => {
        setSortColumn(column);
        setSortType(type);
        getAsyncSortedData(sortedData, column, type)
            .then((data) => {
                setSortedData([]);
                setSortedData(data);
            });
    }

    return(
        <TableWrapper classNames="main__pic_table">
            <Table data={sortedData}
                   sortColumn={sortColumn}
                   sortType={sortType}
                   onSortColumn={handleSortColumn}
                   autoHeight={false} height={460}
                   rowKey="id"
                   loading={loading || isTableDataLoading}
                   loadAnimation={true}
            >
                <Column flexGrow={3.5} verticalAlign="middle" sortable>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name"/>
                </Column>
                <Column flexGrow={1.2} verticalAlign="middle">
                    <HeaderCell>Version</HeaderCell>
                    <Cell dataKey="version"/>
                </Column>
                <Column flexGrow={2} verticalAlign="middle" sortable>
                    <HeaderCell>Last update</HeaderCell>
                    <Cell dataKey="lastUpdate">
                        {rowData => getFormattedDate(rowData.lastUpdate)}
                    </Cell>
                </Column>
                <Column flexGrow={1.2} verticalAlign="middle" sortable>
                    <HeaderCell>User</HeaderCell>
                    <Cell dataKey="user">
                        {rowData =>
                            <div className="table-user-avatar">
                                <Avatar user={rowData.user} size="small"/>
                            </div>}
                    </Cell>
                </Column>
                <Column flexGrow={3} verticalAlign="middle" sortable>
                    <HeaderCell>Material 1</HeaderCell>
                    <Cell dataKey="material1"/>
                </Column>
                <Column flexGrow={3} verticalAlign="middle" sortable>
                    <HeaderCell>Material 2</HeaderCell>
                    <Cell dataKey="material2"/>
                </Column>
                <Column flexGrow={3} verticalAlign="middle">
                    <HeaderCell>Projects</HeaderCell>
                    <Cell dataKey="projects">
                        {rowData => {
                            let i = 0;
                            let list = [];
                            if(rowData.project_pics && rowData.project_pics.length > 0) {
                                rowData.project_pics.map(projectPic => {
                                        i++;
                                        list.push(<span key={i}>{projectPic.project.name}</span>);
                                        if(i < rowData.project_pics.length) {
                                            list.push(' | ');
                                        }
                                    }
                                );
                            }
                            return list.length > 0 ? <span>{list}</span> : '--';
                        }}
                    </Cell>
                </Column>
                {(isUserGranted('ROLE_CREATE_PIC') || isUserGranted('ROLE_UPDATE_PIC') || isUserGranted('ROLE_DELETE_PIC')) &&
                    <Column flexGrow={1} verticalAlign="middle" align="left">
                        <HeaderCell></HeaderCell>
                        <Cell dataKey="actions">
                            {rowData =>
                                <PicActionsMenuButton elementId={rowData.id} type="pic" handleManagePicProjects={handleManagePicProjects}
                                                      handleDeletePic={handleDeletePic} handleDuplicatePic={handleDuplicatePic}/>
                            }
                        </Cell>
                    </Column>
                }
            </Table>
        </TableWrapper>
    );
}

import React, {useState} from "react";
import {useGetData} from "../../api/useFetch";
import {shareLibrary, patchOrganizationGroupStockAlert, patchWorkingHours} from "../../api/apiUser"
import {getCurrentUser} from "../CommonFunctions";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {AlertMessage} from "../common/AlertMessage";
import { InputGroup, MaskedInput } from 'rsuite';
import {OrganizationGroupMap} from "./OrganizationGroupMap";

export const Organization = () => {
    let currentUser = getCurrentUser();

    const user = useGetData('profileData','users/'+currentUser.id, true);

    const [alert, setAlert] = useState();
    const [showForm, setShowForm] = useState(false);
    const [limitStock, setLimitStock] = useState('');
    const [openTime, setOpenTime] = useState('');
    const [closeTime, setCloseTime] = useState('');
    const [validateWorkingHours, setValidateWorkingHours] = useState('');

    const OrganizationResult = useGetData(`organizations`, `organizations`, {
        id: currentUser.organization.id
    });

    const getHoursAndMinutesFromSec = (openTime = organization.open_time, closeTime = organization.close_time) => {
        let hStart = Math.floor(openTime / 3600).toString().padStart(2,'0'),
            mStart = Math.floor(openTime % 3600 / 60).toString().padStart(2,'0');
            //sStart = Math.floor(openTime % 60).toString().padStart(2,'0')

        let hClose = Math.floor(closeTime / 3600).toString().padStart(2,'0'),
            mClose = Math.floor(closeTime % 3600 / 60).toString().padStart(2,'0');
            //sClose = Math.floor(closeTime % 60).toString().padStart(2,'0')

        return `${hStart}:${mStart} to ${hClose}:${mClose}`
    }

    if (OrganizationResult.isLoading) {
        return <Loading />;
    }
    const organization = OrganizationResult.data[0];
    if (OrganizationResult.isSuccess && validateWorkingHours === '') {
        setValidateWorkingHours(getHoursAndMinutesFromSec());
    }

    const successCallback = (message) => {
        setAlert({message: message, status: 'success', date: new Date()});
    }

    const errorCallback = (message) => {
        setAlert({message: message, status: 'error', date: new Date()});
    };

    const handleClickShareLibrary = async () => {
        await shareLibrary(user.data)
            .then(() => successCallback('Modifications updated.'))
            .catch(() => errorCallback('Something went wrong.'));
    }
    function getOrganizationInformations(){

        if(user.data.organization){
            return(
                <React.Fragment>
                    <p>Name : <strong>{user.data.organization.name}</strong></p>
                    <p>Status : <strong>Approved</strong></p>
                    {/*<p><input type="checkbox" id="share_pieces" checked={user.data.share_library}/>*/}
                    {/*<label htmlFor="share_pieces" onClick={handleClickShareLibrary}>Share my library <span/></label></p>*/}
                </React.Fragment>
            )
        }
        else
            return <p>You don't belong to any organization.</p>
    }

    const handleSubmitLimitStock = async (e) => {
        e.preventDefault()
        await patchOrganizationGroupStockAlert(user.data.organization.organization_group.id, parseInt(limitStock))
            .then(() => {
                successCallback('Limit stock to alert updated.');
                setShowForm(!showForm);
            })
            .catch(() => errorCallback('Something went wrong.'));
    }

    const handleWorkingHoursUpdate = async () => {
        let openTimeInSec = openTime.split(':')[0] * 3600 + openTime.split(':')[1] * 60;
        let closeTimeInSec = closeTime.split(':')[0] * 3600 + closeTime.split(':')[1] * 60;

        if (
            openTime === '' || closeTime === '' ||
            openTime.split(':')[0] > 23 || closeTime.split(':')[0] > 23 ||
            openTime.split(':')[1] > 59 || closeTime.split(':')[1] > 59 ||
            openTimeInSec > closeTimeInSec
        ){
            setAlert({message: 'Invalid working hours.', status: 'error', date: new Date()});
        } else {
            
            await patchWorkingHours(user.data.organization.id, openTimeInSec, closeTimeInSec).then(() => {
                setAlert({message: 'Working hours updated.', status: 'success', date: new Date()});
                setValidateWorkingHours(getHoursAndMinutesFromSec(openTimeInSec, closeTimeInSec));
            })
            .catch(() =>  setAlert({message: 'Something went wrong.', status: 'error', date: new Date()}));
        }
    }

    if(user.isLoading) return <Loading/>
    if(user.isError) return <Error errorMessage={user.error.message}/>

    return (
        <div id="main__organization">
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}

            <div>
                <div className="block">
                    <h2>Organization associated to the account</h2>
                    {/*<OrganizationGroupMap/>*/}
                    {getOrganizationInformations()}
                </div>

                {user.data.admin &&
                    <>
                        {/* TODO Link with the balance */}
                        {/*<div className="block">*/}
                        {/*    <h2>*/}
                        {/*        Stock alert {' '}*/}
                        {/*        <span style={{display: "inline-block"}} title="When this limit is exceeded, you will receive a notification about the low level of your stock.">*/}
                        {/*            <i className="fas fa-info-circle" />*/}
                        {/*        </span>*/}
                        {/*    </h2>*/}
                        {/*    <p>Actual limit quantity : <strong>{user.data.organization.organization_group.stock_alert <= 0 ? 'None' : user.data.organization.organization_group.stock_alert + ' (g)'}</strong></p>*/}
                        {/*    {showForm ?*/}
                        {/*        <form onSubmit={handleSubmitLimitStock} className="stock-alert--block">*/}
                        {/*            <label>*/}
                        {/*                <input type="text" name="limitStock" placeholder="New limit (g)" onChange={(e) => setLimitStock(e.currentTarget.value)} />*/}
                        {/*            </label>*/}
                        {/*            <div className="stock-alert--button-block">*/}
                        {/*                <input type="submit" value="Change the limit" />*/}
                        {/*                &nbsp;*/}
                        {/*                <button onClick={() => setShowForm(!showForm)}>Cancel</button>*/}
                        {/*            </div>*/}
                        {/*        </form>*/}
                        {/*        :*/}
                        {/*        <button onClick={() => setShowForm(!showForm)}>Set the limit</button>*/}
                        {/*    }*/}

                        {/*</div>*/}
                        <div className="block">
                            <h2>Working Hours</h2>

                            {validateWorkingHours !== '' && <p>Actual working hours: <strong>{validateWorkingHours}</strong></p>}

                            <InputGroup className="working-hours__input">
                                <MaskedInput
                                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                    placeholder="hh:mm (24h)"
                                    onChange={setOpenTime}
                                    value={openTime}
                                />
                                <InputGroup.Addon>to</InputGroup.Addon>
                                <MaskedInput
                                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                    placeholder="hh:mm (24h)"
                                    onChange={setCloseTime}
                                    value={closeTime}
                                />
                            </InputGroup>
                            <button className="working-hours--button" onClick={() => handleWorkingHoursUpdate()}>Update working hours</button>
                        </div>
                    </>
                }
            </div>

            <OrganizationGroupMap/>

        </div>
    )
}

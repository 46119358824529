import React, {useState} from "react";
import {useGetData} from "../../api/useFetch";
import {AddPieceComponent} from './AddPiece';
import {LibraryTableComponent} from "./LibraryTableComponent";
import {LinkProjectComponent} from "./LinkProject"
import {UnlinkProjectComponent} from "./UnlinkProject"
import {getCurrentUser, isUserGranted} from "../CommonFunctions";
import {AlertMessage} from "../common/AlertMessage";

export const Library = () => {

    const [checkedPieces, setCheckedPieces] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [alert, setAlert] = useState();

    const projects = useGetData(`projectsData`, 'projects');

    const pieces = useGetData(
        `piecesData`,
        'pieces',
        {
            'user.id': getCurrentUser().id
        }
    )

    const successCallback = (message) => {
        pieces.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        pieces.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    return (
        <div id="main__library">
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}
            {isUserGranted('ROLE_ADD_PIECE') &&
            <>
                <AddPieceComponent/>
                &nbsp;
            </>
            }
            {isUserGranted('ROLE_UPDATE_PROJECT') &&
            <>
                <LinkProjectComponent
                    checkedPieces={checkedPieces}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    setCheckedPieces={setCheckedPieces}
                    projects={projects}
                    successCallback={successCallback}
                    errorCallback={errorCallback}
                />
                &nbsp;
                <UnlinkProjectComponent
                    checkedPieces={checkedPieces}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    setCheckedPieces={setCheckedPieces}
                    projects={projects}
                    successCallback={successCallback}
                    errorCallback={errorCallback}
                />
            </>
            }

            <LibraryTableComponent
                pieces={pieces}
                checkedPieces={checkedPieces}
                setCheckedPieces={setCheckedPieces}
                setIsChecked={setIsChecked}
                successCallback={successCallback}
                errorCallback={errorCallback}
            />
        </div>
    )
}
export const farmMaintenancesReducerActions = {
    SET_MAINTENANCES: 'set-maintenances',
    SET_CELL_MAINTENANCES: 'set-cell-maintenances',
    START_MAINTENANCE: 'start-maintenance',
    START_GENERAL_MAINTENANCE: 'start-general-maintenance',
    STOP_MAINTENANCE: 'stop-maintenance',
    STOP_GENERAL_MAINTENANCE: 'stop-general-maintenance'
}

export const farmMaintenanceReducer = (state, action) => {
    if(action.type === farmMaintenancesReducerActions.SET_MAINTENANCES) {
        // value = FarmStructure
        return setMaintenancesFromFarmStructure(action.value);
    }
    if(action.type === farmMaintenancesReducerActions.SET_CELL_MAINTENANCES) {
        // value = FarmCell[]
        return setMaintenancesFromFarmCells(state, action.value);
    }
    if(action.type === farmMaintenancesReducerActions.START_MAINTENANCE) {
        // value = {cellId: number, maintenance: FarmMaintenance}
        return startMaintenance(state, action.value);
    }
    if(action.type === farmMaintenancesReducerActions.STOP_MAINTENANCE) {
        // value = {cellId: number, maintenance: FarmMaintenance}
        return stopMaintenance(state, action.value);
    }
    if(action.type === farmMaintenancesReducerActions.START_GENERAL_MAINTENANCE) {
        // value = {structureMaintenance: FarmMaintenance, farmCells: FarmCell[]}
        if(action.value.structureMaintenance) {
            state = startGeneralMaintenance(state, action.value.structureMaintenance);
        }
        if(action.value.farmCells) {
            return setMaintenancesFromFarmCells(state, action.value.farmCells);
        }
    }
    if(action.type === farmMaintenancesReducerActions.STOP_GENERAL_MAINTENANCE) {
        // value = FarmMaintenance
        return stopGeneralMaintenance(state, action.value);
    }
    return state;
}

const setMaintenancesFromFarmStructure = (farmStructure) => {
    let maintenances = {};
    if(farmStructure) {
        maintenances.structure = farmStructure.last_maintenance;
        maintenances.cells = {};
        if(farmStructure.farm_modules) {
            for(let module of farmStructure.farm_modules) {
                if(module.farm_cells) {
                    for(let cell of module.farm_cells) {
                        maintenances.cells[cell.id] = cell.last_maintenance;
                    }
                }
            }
        }
    }
    return maintenances;
}

const setMaintenancesFromFarmCells = (state, farmCells) => {
    if(farmCells) {
        state.cells = {};
        for(let cell of farmCells) {
            state.cells[cell.id] = cell.last_maintenance;
        }
    }
    return state;
}

const startMaintenance = (state, data) => {
    if(data.cellId) {
        state.cells[data.cellId] = data.maintenance;
    }
    return state;
}

const stopMaintenance = (state, data) => {
    if(data.cellId) {
        state.cells[data.cellId] = data.maintenance;
    }
    return state;
}

const startGeneralMaintenance = (state, maintenance) => {
    state.structure = maintenance;
    return state;
}

const stopGeneralMaintenance = (state, maintenance) => {
    state.structure = maintenance;
    return state;
}
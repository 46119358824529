import React from "react";
import {getCurrentUser} from "../components/CommonFunctions";
import {PicCard} from "../components/pic/Pic.class";

const token = localStorage.getItem('token');
const currentUser = getCurrentUser();

const preparePicCards = (picCards) => {

    picCards.map((picCard, index) => {
        picCard.id = null;
        picCard.ranking = index;

        if(picCard.type === PicCard.TYPE_SLICER_CONNECTION){
            picCard.card.printer_brand = !isNaN(parseInt(picCard.card.printer_brand)) ? process.env.REACT_APP_API_URI + `printer_brands/${picCard.card.printer_brand}` : picCard.card.printer_brand;
            let printers = [];
            picCard.card.printers.forEach(printer => {
                printers.push(!isNaN(parseInt(printer)) ? process.env.REACT_APP_API_URI + `printers/${printer}` : printer);
            });
            picCard.card.printers = printers;
            picCard.card.main_printer = !isNaN(parseInt(picCard.card.main_printer)) ? process.env.REACT_APP_API_URI + `printers/${picCard.card.main_printer}` : picCard.card.main_printer;
            picCard.card.printer = !isNaN(parseInt(picCard.card.printer)) ? process.env.REACT_APP_API_URI + `printers/${picCard.card.printer}` : picCard.card.printer;
            picCard.card.material1 = !isNaN(parseInt(picCard.card.material1)) ? process.env.REACT_APP_API_URI + `materials/${picCard.card.material1}` : picCard.card.material1;
            picCard.card.material2 = !isNaN(parseInt(picCard.card.material2)) ? process.env.REACT_APP_API_URI + `materials/${picCard.card.material2}`: picCard.card.material2;
            picCard.card.brand1 = !isNaN(parseInt(picCard.card.brand1)) ? process.env.REACT_APP_API_URI + `resource_brands/${picCard.card.brand1}` : picCard.card.brand1;
            picCard.card.brand2 = !isNaN(parseInt(picCard.card.brand2)) ? process.env.REACT_APP_API_URI + `resource_brands/${picCard.card.brand2}`: picCard.card.brand2;
            picCard.card.color1 = !isNaN(parseInt(picCard.card.color1)) ? process.env.REACT_APP_API_URI + `resource_colors/${picCard.card.color1}`: picCard.card.color1;
            picCard.card.color2 = !isNaN(parseInt(picCard.card.color2)) ? process.env.REACT_APP_API_URI + `resource_colors/${picCard.card.color2}`: picCard.card.color2;
            picCard.card.duration = parseInt(picCard.card.duration);
            picCard.card.build_id = picCard.card.build_id ? picCard.card.build_id.toString() : null;
            picCard.card.farm_profile = !isNaN(parseInt(picCard.card.farm_profile)) ? process.env.REACT_APP_API_URI + `farm_profiles/${picCard.card.farm_profile}` : picCard.card.farm_profile;
        }
    });

    picCards = picCards.filter(picCard => picCard.type !== PicCard.TYPE_UNDEFINED);

    return picCards;
};

export const postPic = async (picData) => {
    const projectPics = picData.project_pics ? formatProjectPicsForPics(picData.project_pics) : [];

    const picCards = preparePicCards(picData.pic_cards);

    let responsePic = await fetch(process.env.REACT_APP_API_ADDRESS + 'pics', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            user: process.env.REACT_APP_API_URI + `users/${currentUser.id}`,
            date_add: Math.floor(Date.now() / 1000),
            last_edit: Math.floor(Date.now() / 1000),
            name: picData.name,
            state: 1,
            edit_number: 0,
            pic_cards: picCards,
            original_pic: picData.original_pic,
            is_template: picData.is_template === true,
            project_pics: projectPics
        }),
        keepalive: true
    });

    responsePic = await responsePic.json();

    return responsePic;
};

export const patchPic = async (picData) => {

    const picCards = preparePicCards(picData.pic_cards);

    let responsePic = await fetch(process.env.REACT_APP_API_ADDRESS + `pics/${picData.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            last_edit: Math.floor(Date.now() / 1000),
            name: picData.name,
            edit_number: picData.edit_number + 1,
            pic_cards: picCards,
        }),
        keepalive: true
    })

    responsePic = await responsePic.json();

    return responsePic;
};

/* Format project pics for pic save request */
const formatProjectPicsForPics = (projectPics) => {
    let projectPicsData = [];
    projectPics.map(projectPic => {
        projectPic.quantity = projectPic.quantity ? projectPic.quantity : 1;
        if(projectPic.project && projectPic.project.id) {
            projectPic.project = process.env.REACT_APP_API_URI + 'projects/' + projectPic.project.id;
        }
        projectPicsData.push(projectPic);
    });
    return projectPicsData;
}

export const patchPicProjects = async (picData) => {
    // Format project pics
    picData.project_pics = formatProjectPicsForPics(picData.project_pics);

    let responsePic = await fetch(process.env.REACT_APP_API_ADDRESS + `pics/${picData.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            project_pics: picData.project_pics
        }),
        keepalive: true
    })

    responsePic = await responsePic.json();

    return responsePic;
};

export const schedulePic = (picData, date, printer) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'tasks', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            user: process.env.REACT_APP_API_URI + `users/${currentUser.id}`,
            date_add: parseInt(new Date().getTime()/1000),
            date: date,
            pic: process.env.REACT_APP_API_URI + `pics/${picData.id}`,
            printer: process.env.REACT_APP_API_URI + `printers/${printer.id}`,
            is_error: false,
            is_resource_validated: false
        })
    });
};

export const deletePic = async (picId) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `pics/${picId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
};

/**
 * Create a task from a selected pic and set a value to waiting_number (number of tasks to do)
 * used to know if these tasks are in waiting or not
 * @param {object} picData 
 * @param {object} printer 
 * @param {function} callback 
 * @param {number} picQuantity
 */
export const scheduleWaitingList = (picData, printer, callback, picQuantity) => {
    fetch(process.env.REACT_APP_API_ADDRESS + `tasks`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            user: process.env.REACT_APP_API_URI+`users/${currentUser.id}`,
            date_add: parseInt(new Date().getTime()/1000),
            pic: process.env.REACT_APP_API_URI+`pics/${picData.id}`,
            printer: process.env.REACT_APP_API_URI+`printers/${printer.id}`,
            waiting_number: picQuantity,
        })
    })
        .then(r => callback())
        .catch(e => console.log(e))
}

export const updateWaitingTask = (waitingPicId, newNumber) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + `tasks/${waitingPicId}`, {
        method:'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({waiting_number: newNumber})
    });
};

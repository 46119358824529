import {useDraggable} from "@dnd-kit/core";
import React, {useMemo, useState} from "react";
import {dateToTimestampInSec, dateToUtcTimestampInSec, timestampInSecToDate} from "../../../../utils/date";
import {Avatar} from "../../../common/Avatar";
import {getFormattedMaterialsName, isSmartFarmUser} from "../../../CommonFunctions";
import {DISPLAY_MODES} from "../Calendar";

//For Custom Tooltip
import Whisper from 'rsuite/Whisper';
import {Popover} from "rsuite";
import {MaterialIcon} from "../../../common/icons/MaterialIcon";

export const TaskDraggable = React.forwardRef((props, refTaskDraggable) => {
    const {displayMode, firstDisplayedDate} = props;
    const {lastDisplayedDate} = props;
    const {draggableId, isDragDisabled, task, handleClickTask, handleMouseUpTask, tasksSelected, refreshPos,
        isExternalPrinterTask, handleDissociateButtonClick, isMaintenance} = props;

    const [isOpenTooltip, setIsOpenTooltip] = useState(false);
    const [isTaskMoved, setIsTaskMoved] = useState(false);
  
    const {attributes, isDragging, listeners, setNodeRef} = useDraggable({
        id: draggableId,
        attributes: {
            roleDescription: "draggable task",
        },
        disabled: isDragDisabled,
    });

    const draggableClassNames = useMemo(() => {
        let classNames = "gc-tasks-cell__task-draggable gc-task-draggable";
        if(task.status === 'progress')
            classNames += " gc-task-progress";
        if(!isExternalPrinterTask && !isMaintenance) {
            if (isDragging) {
                classNames += " gc-task-draggable--dragging";
            } else if (isDragDisabled) {
                classNames += " gc-task-draggable--drag-disabled";
            }
            tasksSelected.map(id => {
                if (id === task.id) {
                    return classNames += " gc-task-draggable--selected";
                } else {
                    return classNames;
                }
            });
        } else if(isMaintenance) {
            return classNames += " gc-task-draggable--maintenance"
        } else {
            return classNames += " gc-task-draggable--external-printer-task";
        }
        return classNames;
    }, [isDragDisabled, isDragging, tasksSelected]);

    /* UTC dates are used to properly handle time changes. */
    const draggableStyle = useMemo(() => {
        let taskDateInSec = task.tempDragDateInSec || task.date;
        let taskEndDateInSec = taskDateInSec + task.print_duration;
        let firstDateInSec;
        let lastDateInSec;
        if (displayMode === DISPLAY_MODES.DAY) {
            firstDateInSec = dateToTimestampInSec(firstDisplayedDate);
            lastDateInSec = dateToTimestampInSec(lastDisplayedDate);
        } else {
            taskDateInSec = dateToUtcTimestampInSec(timestampInSecToDate(taskDateInSec));
            taskEndDateInSec = dateToUtcTimestampInSec(timestampInSecToDate(taskEndDateInSec));
            firstDateInSec = dateToUtcTimestampInSec(firstDisplayedDate);
            lastDateInSec = dateToUtcTimestampInSec(lastDisplayedDate);
        }
        const draggableMarginLeft = ((taskDateInSec - firstDateInSec) / (lastDateInSec - firstDateInSec)) * 100;
        const draggableWidth = Math.max(((taskEndDateInSec - taskDateInSec) / (lastDateInSec - firstDateInSec)) * 100, 1);
        const borderPxTaskFixed = (!isExternalPrinterTask && task.is_fixed_scheduling) ? '4' : '0';
        const borderPxPrintDetection = (!isExternalPrinterTask && task.start_date_detected !== undefined && task.start_date_detected !== null) ? '4' : '0';

        return {
            left: `${draggableMarginLeft}%`,
            width: `${draggableWidth}%`,
            borderLeft: `${borderPxTaskFixed}px solid #f9224b`,
            borderBottom: `${borderPxPrintDetection}px solid #282828`
        };
    }, [displayMode, firstDisplayedDate, lastDisplayedDate, task.tempDragDateInSec, task.date, task.print_duration, refreshPos, task.is_fixed_scheduling]);

        /* UTC dates are used to properly handle time changes. */
        const displayAvatar = useMemo(() => {
            let taskDateInSec = task.tempDragDateInSec || task.date;
            let taskEndDateInSec = taskDateInSec + task.print_duration;
            let firstDateInSec;
            let lastDateInSec;
            if (displayMode === DISPLAY_MODES.DAY) {
                firstDateInSec = dateToTimestampInSec(firstDisplayedDate);
                lastDateInSec = dateToTimestampInSec(lastDisplayedDate);
            } else {
                taskDateInSec = dateToUtcTimestampInSec(timestampInSecToDate(taskDateInSec));
                taskEndDateInSec = dateToUtcTimestampInSec(timestampInSecToDate(taskEndDateInSec));
                firstDateInSec = dateToUtcTimestampInSec(firstDisplayedDate);
                lastDateInSec = dateToUtcTimestampInSec(lastDisplayedDate);
            }
            const draggableWidth = ((taskEndDateInSec - taskDateInSec) / (lastDateInSec - firstDateInSec)) * 100;
            const display = draggableWidth < 4 ? 'none' : 'block';
            
            return {
                display: `${display}`,
            };
        }, []);

    //Function handling the tooltip display
    const handleMouseEnter = (e) => {
        let rect = e.target.getBoundingClientRect().width;
        if (rect < 140) {
            if (tasksSelected.length === 0) {
                return (
                    setIsOpenTooltip(true)
                );
            }
        }
    };
    const handleMouseLeave = () => {
        return setIsOpenTooltip(false);
    };

    const handleTouchEndTask = (e, taskId) => {
        e.preventDefault();
        if(!isTaskMoved) {
            handleClickTask(e, taskId);
        }
        setIsTaskMoved(false);
    }

    return (
        <Whisper
            open={isOpenTooltip}
            trigger="hover"
            placement="top"
            speaker={
                <Popover className="tooltip">
                    <div className="flex">
                        <div className="tooltip-title">{task.name}</div>
                        {task.user && !isSmartFarmUser(task.user) &&
                            <div className="tooltip__avatar">
                                <Avatar user={task.user} size="small" />
                            </div>
                        }
                    </div>
                    <div>{getFormattedMaterialsName(task)}</div>
                </Popover>
            }
        >
            {/* Dragged element ("setNodeRef" cannot be replaced) */}
            <div
                ref={setNodeRef}
                className={draggableClassNames}
                style={draggableStyle}
                onClick={(e) => !isMaintenance && handleClickTask(e, task.id)}
                onTouchEnd={(e) => !isMaintenance && handleTouchEndTask(e, task.id)}
                onTouchMove={(e) => !isMaintenance && setIsTaskMoved(true)}
                onMouseUp={(e) => !isMaintenance && handleMouseUpTask(e, task.id)}
                onMouseEnter={(e) => !isMaintenance && handleMouseEnter(e)}
                onMouseLeave={() => !isMaintenance && handleMouseLeave()}
            >
                {/* Drag trigger */}
                <div className="gc-task-draggable__trigger gc-task-draggable-trigger" {...attributes} {...listeners} >
                    <div className="gc-task-draggable-trigger__content gc-task-draggable-content">
                        <h3 className="gc-task-draggable-content__name">{task.name}</h3>
                        <div className="gc-task-draggable-content__info">{getFormattedMaterialsName(task)}</div>
                        {(task.user && !isExternalPrinterTask) &&
                            <div className="gc-task-draggable-content__avatar" style={displayAvatar}>
                                <Avatar user={task.user} size="small"/>
                            </div>
                        }
                        {(!isExternalPrinterTask && task.start_date_detected !== undefined && task.start_date_detected !== null) &&
                            <div className="gc-task-draggable-content__dissociate">
                                <button onClick={() => handleDissociateButtonClick && handleDissociateButtonClick(task)}>
                                    <MaterialIcon label="link_off"/>
                                </button>
                            </div>
                        }
                    </div>
                </div>

                {/* Element used by the "TasksRow" parent component to get dimensions of the dragged element */}
                <div ref={refTaskDraggable} style={{position: "absolute", top: 0, right: 0, bottom: 0, left: 0}}/>
            </div>
        </Whisper>
    );
});

import * as THREE from "three";

class PieceLoader{

	constructor(_bytes, _successCallback, _errorCallback){
		this._bytes = _bytes;
		this._successCallback = _successCallback;
		this._errorCallback = _errorCallback;
		this._mesh = null;
		this._xrange = [0, 0];
		this._yrange = [0, 0];
		this._zrange = [0, 0];

		this.loadMeshFromFile();
	}

	get mesh(){
		return this._mesh;
	}

	get xmin(){
		return this._xrange[0];
	}

	get xmax(){
		return this._xrange[1];
	}

	get ymin(){
		return this._yrange[0];
	}

	get ymax(){
		return this._yrange[1];
	}

	get zmin(){
		return this._zrange[0];
	}

	get zmax(){
		return this._zrange[1];
	}

	get maxSize(){
		return Math.max(
			this.xmax - this.xmin,
			this.ymax - this.ymin,
			this.zmax - this.zmin,
		);
	}

	get maxSize2D(){
		return Math.max(
			this.xmax - this.xmin,
			this.ymax - this.ymin
		);
	}

	loadMeshFromFile(){
		throw new Error('The loadMeshFromFile() method must be implemented by children loaders.')
	}
}


class STLLoader extends PieceLoader{

	constructor(_bytes, _successCallback = null, _errorCallback = null){
		super(_bytes, _successCallback, _errorCallback);
	}

	loadMeshFromFile(){
		try{
			let geometry = new THREE.Geometry();
			let dataView = new DataView(this._bytes.buffer);

			const POS_TRIANGLES_NUMBER = 80;
			let pos = POS_TRIANGLES_NUMBER;

			let trianglesNumber = dataView.getUint32(pos, true);
			pos += 4;


			for(let i=0; i<trianglesNumber; i+=1){
				// Ignore normal vector
				pos += 12;

				// Vertex (3)
				for(let v=0; v<=2; v++){
					// Vertex coordinates
					let x = dataView.getFloat32(pos+v*12, true);
					let y = dataView.getFloat32(pos+v*12+4, true);
					let z = dataView.getFloat32(pos+v*12+8, true);
					geometry.vertices.push(new THREE.Vector3(x, y, z));

					if(x < this._xrange[0])
						this._xrange[0] = x;

					if(y < this._yrange[0])
						this._yrange[0] = y;

					if(z < this._zrange[0])
						this._zrange[0] = z;

					if(x > this._xrange[1])
						this._xrange[1] = x;

					if(y > this._yrange[1])
						this._yrange[1] = y;

					if(z > this._zrange[1])
						this._zrange[1] = z;
				}

				geometry.faces.push(new THREE.Face3(i*3,i*3+1,i*3+2));

				pos += 36+2;
			}

			geometry.computeFaceNormals();

			let material = new THREE.MeshPhongMaterial({
				color: '#555',
				side: THREE.DoubleSide,
			});


			this._mesh = new THREE.Mesh(geometry, material);

			this._mesh.geometry.center();
		}
		catch(e){
			if(this._errorCallback !== undefined && this._errorCallback !== null)
				this._errorCallback(e);

			console.log(e)
		}


		// geometry.vertices.push(
		// 	new THREE.Vector3(-5, -5, 0),
		// 	new THREE.Vector3(5, -5, 0),
		// 	new THREE.Vector3(5, 5, 0),
		// 	new THREE.Vector3(-5, 5, 1)
		// );
		// geometry.vertices.push(
		//   new THREE.Vector3(-1, -1,  1),  // 0
		//   new THREE.Vector3( 1, -1,  1),  // 1
		//   new THREE.Vector3(-1,  1,  1),  // 2
		//   new THREE.Vector3( 1,  1,  1),  // 3
		//   new THREE.Vector3(-1, -1, -1),  // 4
		//   new THREE.Vector3( 1, -1, -1),  // 5
		//   new THREE.Vector3(-1,  1, -1),  // 6
		//   new THREE.Vector3( 1,  1, -1),  // 7
		// );
		// geometry.faces.push(
		// 	// new THREE.Face3(0, 1, 2, normal),
		// 	// new THREE.Face3(0, 2, 3, normal),
		// 	new THREE.Face3(0, 3, 2),
		// 	new THREE.Face3(0, 1, 3),
		// 	// new THREE.Face3(1, 7, 3, normal),
		// 	new THREE.Face3(1, 5, 7),
		// );

		// geometry.faces.push(
		//   // front
		//   new THREE.Face3(0, 3, 2),
		//   new THREE.Face3(0, 1, 3),
		//   // right
		//   new THREE.Face3(1, 7, 3),
		//   new THREE.Face3(1, 5, 7),
		//   // back
		//   new THREE.Face3(5, 6, 7),
		//   new THREE.Face3(5, 4, 6),
		//   // left
		//   new THREE.Face3(4, 2, 6),
		//   new THREE.Face3(4, 0, 2),
		//   // top
		//   new THREE.Face3(2, 7, 6),
		//   new THREE.Face3(2, 3, 7),
		//   // bottom
		//   new THREE.Face3(4, 1, 0),
		//   new THREE.Face3(4, 5, 1),
		// );

		// this._scene.add(this._mesh);
	}

}


export {STLLoader};
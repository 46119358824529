import ReactHtmlParser from "html-react-parser";
import React from "react";

export const MaintenanceConfirmationModal = (props) => {

    const {title, description, buttons, cancelCallback} = props;

    return(
        <div className="modal__overlay">
            <div className="modal__dialog">
                <div className="dialog__content">
                    <h2 className="content__title">{title}</h2>
                    {
                        description && <p>{ReactHtmlParser(description)}</p>
                    }
                </div>
                <div className="dialog__footer">
                    <button className="button-error" onClick={cancelCallback}>Cancel</button>
                    {
                        buttons && buttons.map((button, index) =>
                            <button key={index} onClick={button.callback} className="btn-white">{ReactHtmlParser(button.label)}</button>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
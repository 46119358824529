import React, {useState} from "react";
import {useGetData} from "../../api/useFetch";
import {ManageRightsComponent} from "./ManageRights";
import {DeleteMemberComponent} from "./DeleteMember";
import {getCurrentUser} from "../CommonFunctions";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {AlertMessage} from "../common/AlertMessage";
import {ResetPasswordMember} from "./ResetPasswordMember";

export const Members = () => {

    let currentUser = getCurrentUser();

    const organization = useGetData('organizationData', 'organizations/' + currentUser.organization.id);
    const members = useGetData('members', 'users', {'organization.id': currentUser.organization.id});

    const [alert, setAlert] = useState();

    const successCallback = (message) => {
        organization.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        organization.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    function getMemberClass(member){
        return member.state ? 'user--unsubscribed' : '';
    }

    function getMemberStatus(member){
        return member.admin ? '(Admin)' : member.state ? '(Unsubscribed)' : '';
    }

    function getMemberActions(member){
        if(!member.admin){
            return (
                <>
                    <ManageRightsComponent member={member} successCallback={successCallback} errorCallback={errorCallback}/>
                    {/*<DeleteMemberComponent member={member} successCallback={successCallback} errorCallback={errorCallback}/>*/}
                    <ResetPasswordMember member={member} successCallback={successCallback} errorCallback={errorCallback}/>
                </>
            )
        }
    }

    if(organization.isLoading || members.isLoading) return <Loading/>
    if(organization.isError || members.isError) return <Error errorMessage={organization.error.message}/>

    return (
        <div id="main__members">
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}
            <div>
                <table id="members__list">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>First Name</th>
                            <th>E-mail</th>
                            <th>Organization</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {members.data.map((member) => (
                            <tr className={getMemberClass(member)} key={member.id}>
                                <td>{member.lastname} {getMemberStatus(member)}</td>
                                <td>{member.firstname}</td>
                                <td>{member.email}</td>
                                <td>{organization.data.name}</td>
                                <td>{getMemberActions(member)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
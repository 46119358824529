import axios from "axios";
import {useQuery} from "react-query";

export const useGetData = (key, path, params = {}, skip = false) => {

    const token = localStorage.getItem('token');

    const getData = async () => {
        let header = token ? {Authorization: `Bearer ${token}`} : null;
        let url = process.env.REACT_APP_API_ADDRESS + path;
        const {data} = await axios.get(url, {
            headers: header,
            params: params
        });

        console.log('Fetching ' + key);
        if (data['hydra:member']) {
            return data['hydra:member'];
        } else {
            return data;
        }
    }
    return useQuery(key, getData, {refetchOnWindowFocus: false, skip: skip});
}

export const postAPIData = (path, body, successCallback, errorCallback) => {

    fetch(process.env.REACT_APP_API_ADDRESS + path, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    })
    .then(r => {
        if(r.ok){
            if(successCallback !== undefined)
                successCallback(r);
            else if(errorCallback !== undefined)
                errorCallback(r);
        }
    })
    .catch(e => {
        if(errorCallback !== undefined)
            errorCallback(e);
    });
}


export const useAPIRequest = (url) => {

    const token = localStorage.getItem('token');

    const getData = async () => {
        const {data} = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (data['hydra:member']) {
            return data['hydra:member'];
        } else {
            return data;
        }
    }

    return useQuery(url, getData, {refetchOnWindowFocus: false});
}


export const downloadAPIFile = (path, fileName, successCallback, errorCallback) => {

    fetch(process.env.REACT_APP_API_ADDRESS + path, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .catch(error => {
            if(errorCallback !== undefined)
                errorCallback(error);
        })
        .then(response => response.json())
        .then(response => {
            const url = response.url;
            const anchor = document.createElement('a');
            anchor.setAttribute('href', url);
            anchor.setAttribute('download', fileName);
            anchor.click();

            if(successCallback !== undefined)
                successCallback(url);
        });
}

export const fetchData = async (path, params=null) => {

    let url = process.env.REACT_APP_API_ADDRESS + path;

    if(params !== null)
        url += '?' + params;

    return fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
}

export const encodeParametersList = (paramName, list) => {
    let encoded = [];

    for(const item of list)
        encoded.push(paramName + '[]=' + item)

    return encoded.join('&')
}

export const encodeObjectToQueryString = (obj, prefix) => {
    let str = [], p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                encodeObjectToQueryString(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}
import React, {useState} from "react";
import {TableComponent} from "./TableComponent";
import {AddPrinterComponent} from "./AddPrinter";
import {useGetData} from "../../api/useFetch";
import {AlertMessage} from "../common/AlertMessage";
import {getCurrentUser, isUserGranted} from "../CommonFunctions";
import {SelectPicker} from "rsuite";
import {Loading} from "../common/Loading";
import {MaterialIcon} from "../common/icons/MaterialIcon";
import {useGetAutomationData} from "../../api/apiConnectivity";

/*PrintersPage with table of all organization printers, and ability to view/edit/add/delete a printer*/
export const Printers = () => {
    const currentUser = getCurrentUser();

    /* Organization selection */
    let selectedOrganization = localStorage.getItem('selected-organization');

    if(selectedOrganization === undefined || selectedOrganization === null || selectedOrganization === 'null')
        selectedOrganization = currentUser.organization.id;
    else
        selectedOrganization = parseInt(selectedOrganization);

    const brands = useGetData('brands', 'printer_brands');
    let models = useGetData('models', 'models');
    const technologies = useGetData('technologies', 'technologies');
    const printers = useGetData(
        `printerData`,
        `printers/printers?page=1&organization_group=${currentUser.organization.organization_group.id}`
    );
    const controllablePrinters = useGetAutomationData(
        'controllablePrinters', 'controllable_printers'
    );

    const [alert, setAlert] = useState();

    const successCallback = (message) => {
        printers.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
        models.refetch();
    }

    const errorCallback = (message) => {
        printers.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    }

    /* Get all accessible organizations */
    const organizationGroup = useGetData('organizationGroup',
        'organization_groups/' + currentUser.organization.organization_group.id +
        '?groups[]=read:OrganizationGroup:Organizations'
    );

    if(organizationGroup.isLoading) return <Loading/>

    const organizations = organizationGroup.data.organizations.map(organization => (
        {label: organization.name, value: organization.id}
    ));

    const handleSelectOrganization = (selectedOrganizationId) => {
        localStorage.setItem('selected-organization', JSON.stringify(selectedOrganizationId));
        window.location.reload();
    };

    return (
        <div id="main__printers">
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}
            {
                isUserGranted('ROLE_ADD_PRINTER') &&
                    <AddPrinterComponent
                        printers={printers}
                        brands={brands} models={models} technologies={technologies}
                        controllablePrinters={controllablePrinters}
                        successCallback={successCallback}
                        errorCallback={errorCallback}
                    />
            }

            &nbsp;

            <SelectPicker
                size="md"
                placeholder="Select an organization"
                data={organizations}
                value={selectedOrganization}
                onChange={handleSelectOrganization}
            />

            <TableComponent
                brands={brands} models={models} technologies={technologies} printers={printers}
                controllablePrinters={controllablePrinters}
                selectedOrganization={selectedOrganization}
                successCallback={successCallback} errorCallback={errorCallback}/>

            <div className="delete-warning-message">
                <MaterialIcon classes="delete-warning-message-icon" label="warning"/>
                <span>You can't delete a printer if it has already been used in the app</span>
            </div>
        </div>
    )
}

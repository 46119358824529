import React, {useState} from "react";
import {useGetData} from "../../api/useFetch";
import {postProject} from "../../api/apiProject";
import {useForm, Controller} from "react-hook-form";
import {Drawer, makeStyles} from '@material-ui/core';
import {getCurrentUser} from "../CommonFunctions";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";

const useStyles = makeStyles({
    paper: {
        left: "50%",
        background: "#f5f5f5"
    },
});

export const AddProjectComponent = (props) => {
    const classes = useStyles();

    const {successCallback, errorCallback} = props;

    const colors = useGetData('colorsData', 'resource_colors');

    const {handleSubmit, control} = useForm();
    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);

    const onSubmit = async (dataProject) => {
        setRightPartIsOpen(false);
        await postProject(dataProject)
            .then(() => successCallback('Project has been added.'))
            .catch(() => errorCallback('Something went wrong.'));
    };

    return (
        <>
            <button className="projects-list__project-item" id="projects__create" onClick={() => setRightPartIsOpen(!rightPartIsOpen)}><p>Create a new project</p></button>
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)} classes={{paper: classes.paper}}>

                <div id="main__create-project">

                    <div className="block">
                        <h2>Create a new project</h2>
                        {
                            colors.isLoading ? <Loading/> :
                            colors.isError ? <Error errorMessage={colors.error.message}/> :

                            <form id="create-project__form" onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="f-name">Name</label>
                                <Controller
                                    control={control}
                                    name="name"
                                    rules={{required: true}}
                                    render={({field}) => (
                                        <input type="text" id="f-name" placeholder="Project name" {...field}/>
                                    )}
                                />

                                <label htmlFor="f-color-id">Color</label>
                                <Controller
                                    control={control}
                                    name="color"
                                    rules={{required: true}}
                                    render={({field}) => (
                                        <select id="f-color-id" {...field}>
                                            <option disabled selected value>Select a color</option>
                                            {colors.data.map((color) => (
                                                <option key={color.id} value={color.id}>{color.color_name}</option>
                                            ))}
                                        </select>
                                    )}
                                />

                                <label htmlFor="f-notes">Description</label>
                                <Controller
                                    control={control}
                                    name="notes"
                                    render={({field}) => (
                                        <textarea id="f-notes" placeholder="Describe the project..." {...field}/>
                                    )}
                                />

                                <button>Create the project</button>
                            </form>
                        }
                    </div>

                </div>

            </Drawer>
        </>
    )

}


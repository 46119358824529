import React, {useEffect, useState} from 'react';
import {useGetData} from "../../../api/useFetch";
import {postTechnology, deleteTechnology, patchTechnology} from "../../../api/apiBackoffice";
import {
    Checkbox,
    Drawer,
    FormControl,
    InputLabel,
    ListItemText,
    makeStyles,
    MenuItem,
    OutlinedInput,
    Select
} from "@material-ui/core";
import {Loading} from "../../common/Loading";
import {Error} from "../../common/Error";

export const Technologies = () => {

    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [alert, setAlert] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [actualTechno, setActualTechno] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState([]);

    useEffect(() => {
        if(actualTechno !== undefined && actualTechno !== null && rightPartIsOpen === true) {
            let actualSelectedBrands = [];
            actualTechno.resource_brands.map(brand => {
                actualSelectedBrands.push(brand.id);
            });
            setSelectedBrands(actualSelectedBrands);
        }
    }, [actualTechno, rightPartIsOpen]);

    const technologies = useGetData('technologies', 'technologies');
    const brands = useGetData('brands', 'resource_brands');

    const successCallback = (message) => {
        technologies.refetch().then(() => {
            setAlert({message: message, status: "success", date: new Date()});
        });
    }

    const errorCallback = (message) => {
        technologies.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
        });
    };

    const handleCreate = async (name, diameter, color, nozzle, unit, brands) => {
        await postTechnology(name, diameter, color, nozzle, unit, selectedBrands)
            .then(() => {
                setRightPartIsOpen(!rightPartIsOpen);
                setSelectedBrands([]);
                successCallback('This Technology has been created.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    };

    const handleDelete = async (techno_Id) => {
        if(window.confirm('Are you sure you want to delete this Technology ?'))
            await deleteTechnology(techno_Id)
                .then(() => successCallback('This Technology has been deleted.'))
                .catch(() => errorCallback('Something went wrong.'));
    };

    const handleUpdate = async (name, diameter, color, nozzle, unit, brands) => {
        await patchTechnology(actualTechno.id, name, diameter, color, nozzle, unit, selectedBrands)
            .then(() => {
                setRightPartIsOpen(!rightPartIsOpen);
                setSelectedBrands([]);
                successCallback('This Technology has been updated.');
            })
            .catch(() => errorCallback('Something went wrong.'));
    };

    const isBrandSelected = (brandId) => {
        for(let i = 0; i < selectedBrands.length; i++) {
            if(selectedBrands[i] === brandId) {
                return true;
            }
        }
        return false;
    }

    const handleSelectedBrandsChange = (brandIdsSelected) => {
        let newSelectedBrands = [];
        brandIdsSelected.forEach((brandId) => {
            newSelectedBrands.push(brandId);
        });
        setSelectedBrands(newSelectedBrands);
    }

    if(technologies.isLoading || brands.isLoading) return <Loading/>;
    if(technologies.isError || brands.isError) return <Error/>;

    return (
        <div className="backoffice_block" style={{display: "block", border: "2px solid lightgrey", padding: "15px"}}>
            <div className="technologies_header">
                <h2>Technologies</h2>
                <button className="button-link" onClick={() => {
                    setIsUploading(false);
                    setRightPartIsOpen(!rightPartIsOpen);
                }}>
                    <i className="fa fa-plus"/> Add a technology
                </button>
            </div>
            {technologies.data ? (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Unity</th>
                            <th>Diameter</th>
                            <th>Color</th>
                            <th>Nozzle</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {technologies.data &&
                        technologies.data.map((techno) => (
                            <tr key={techno.id}>
                                <td>{techno.name}</td>
                                <td>{techno.unit_name}</td>
                                <td>{techno.has_diameter ? "Oui" : "Non"}</td>
                                <td>{techno.has_color ? "Oui" : "Non"}</td>
                                <td>{techno.max_extruders_number}</td>
                                <td>
                                    <button className="button-link" onClick={()=> {
                                        setIsUploading(true);
                                        setActualTechno(techno);
                                        setRightPartIsOpen(!rightPartIsOpen);
                                    }}>
                                        <i className="fas fa-edit" />
                                    </button>
                                    &nbsp;
                                    <button className="button-link" onClick={()=> handleDelete(techno.id)}><i className="fas fa-trash-alt" /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <Loading />
            )}
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)}>

                <div className="backoffice_drawer_content" style={{width:"500px", "marginTop":"30px"}}>
                    {!isUploading ? (
                        <>
                            <h2 style={{"paddingLeft":"10px"}}>Add Technology</h2>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleCreate(e.target.name.value, e.target.diameter.value, e.target.color.value, e.target.nozzle.value, e.target.unit.value, e.target.brands.value);
                            }}>
                                <label>Technology name :
                                    <input type="text" name="name" />
                                </label>
                                <label>Unity :
                                    <select name="unit">
                                        <option value="">-- Choose a unity --</option>
                                        <option value={0}>g</option>
                                        <option value={1}>L</option>
                                    </select>
                                </label>
                                <label>Diameter :
                                    <select name="diameter">
                                        <option value="">-- Is there a Diameter ? --</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </label>
                                <label>Color :
                                    <select name="color">
                                        <option value="">-- Is there a Color ? --</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </label>
                                <label>Nozzle :<br />
                                    <input type="text" name="nozzle" placeholder="Enter the number of Nozzle"/>
                                </label>
                                <div style={{"display":"flex", "justifyContent":"center", "marginBottom":"20px"}}>
                                    <FormControl style={{"width":"300px"}}>
                                        <InputLabel id="printersLabel" className="label-select">Brands</InputLabel>
                                        <Select
                                            multiple
                                            onChange={(e) => handleSelectedBrandsChange(e.target.value)}
                                            value={selectedBrands}
                                            renderValue={() => 'Select associated brands'}
                                            name="brands"
                                        >
                                            {brands.data.map(brand => (
                                                <MenuItem value={brand.id} key={brand.id}>
                                                    <Checkbox checked={isBrandSelected(brand.id)}/>
                                                    <ListItemText>{brand.name}</ListItemText>
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </div>
                                <input type="submit" value="Envoyer" style={{display: "block", margin: "auto"}} />
                            </form>
                        </>
                    ) : (
                        <>
                            <h2 style={{"paddingLeft":"10px"}}>Update Technology</h2>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleUpdate(e.target.name.value, e.target.diameter.value, e.target.color.value, e.target.nozzle.value, e.target.unit.value, e.target.brands.value);
                            }}>
                                <label>Technology name :
                                    <input type="text" name="name" defaultValue={actualTechno.name}/>
                                </label>
                                <label>Unity :
                                    <select name="unit" defaultValue={actualTechno.unit === 'g' ? 0 : 1}>
                                        <option value="">-- Choose a unity --</option>
                                        <option value={0}>g</option>
                                        <option value={1}>L</option>
                                    </select>
                                </label>
                                <label>Diameter :
                                    <select name="diameter" defaultValue={actualTechno.has_diameter ? 1 : 0}>
                                        <option value="">-- Is there a Diameter ? --</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </label>
                                <label>Color :
                                    <select name="color" defaultValue={actualTechno.has_color ? 1 : 0}>
                                        <option value="">-- Is there a Color ? --</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </label>
                                <label>Nozzle :<br />
                                    <input type="text" name="nozzle" placeholder="Enter the number of Nozzle" defaultValue={actualTechno.max_extruders_number}/>
                                </label>
                                <div style={{"display":"flex", "justifyContent":"center", "marginBottom":"20px"}}>
                                    <FormControl style={{"width":"300px"}}>
                                        <InputLabel id="printersLabel" className="label-select">Brands</InputLabel>
                                        <Select
                                            multiple
                                            onChange={(e) => handleSelectedBrandsChange(e.target.value)}
                                            value={selectedBrands}
                                            renderValue={() => 'Select associated brands'}
                                            name="brands"
                                        >
                                            {brands.data.map(brand => (
                                                <MenuItem value={brand.id} key={brand.id}>
                                                    <Checkbox checked={isBrandSelected(brand.id)}/>
                                                    <ListItemText>{brand.name}</ListItemText>
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </div>
                                <input type="submit" value="Envoyer" style={{display: "block", margin: "auto"}} />
                            </form>
                        </>
                    )}
                </div>
            </Drawer>
        </div>
    )
}

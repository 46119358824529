import React, {useEffect, useState} from "react";
import {patchResource} from "../../../api/apiResource";
import {useForm, Controller} from "react-hook-form";
import {Loading} from "../../common/Loading";
import {Error} from "../../common/Error";
import {getCurrentUser} from "../../CommonFunctions";

export const EditResourceComponent = (props) => {

    const {resource, colors, brands, technologies, materials, printers,
        successCallback, errorCallback, setRightPartDetailsIsOpen} = props;

    const user = getCurrentUser();

    const {handleSubmit, control, setValue, getValues} = useForm();
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedTechnology, setSelectedTechnology] = useState(resource.material.technology);
    const [hasDiameter, setHasDiameter] = useState(resource.material.technology.has_diameter);
    const [hasColor, setHasColor] = useState(resource.material.technology.has_color);
    const [filteredTechnologies, setFilteredTechnologies] = useState([]);
    const [initialQuantityWeightWithReel, setInitialQuantityWeightWithReel] = useState(null);
    // const [isWeighOpen, setIsWeighOpen] = useState(false);
    // const [isNewWeight, setIsNewWeight] = useState(false);
    // const [weightMeasure, setWeightMeasure] = useState(null);
    // const [isInitialQuantityWeightMethod, setIsInitialQuantityWeightMethod] = useState(false);

    const onSubmit = async (dataResource) => {
        dataResource.resource_edits = resource.resource_edits ? resource.resource_edits : [];
        dataResource.resource_edits.push({
           date: Math.floor(new Date().getTime() / 1000),
           user: user.id
        });

        await patchResource(dataResource)
            .then(() => {
                successCallback('Material has been updated.')
            })
            .catch((e) => {
                errorCallback('Something went wrong.');
            });
        setRightPartDetailsIsOpen(false);
    }

    useEffect(() => {
        for(let i = 0; i < brands.data.length; i++) {
            if(brands.data[i].id === resource.brand.id) {
                setValue('brand', JSON.stringify(brands.data[i]));
                setSelectedBrand(brands.data[i]);
                setFilteredTechnologies(filterTechnologiesDependingOnBrand(brands.data[i]));
                break;
            }
        }
    }, []);

    const handleBrandChange = (e) => {
        e.preventDefault();
        let brand = JSON.parse(e.target.value);
        setSelectedBrand(brand);

        // Reset technology and material values
        resetTechnologyValue();
        setValue('material', 0);

        // Set technology list depending on selected brand
        setFilteredTechnologies(filterTechnologiesDependingOnBrand(brand));

        return e.target.value;
    }

    const resetTechnologyValue = () => {
        setSelectedTechnology(null);
        setHasDiameter(false);
        setHasColor(false);
        setValue('technology', 0);
    }

    const filterTechnologiesDependingOnBrand = (brand) => {
        if(brand.technologies !== undefined) {
            return technologies.data.filter(technology => {
                for(let i = 0; i < brand.technologies.length; i++) {
                    if(brand.technologies[i].id === technology.id) {
                        return technology;
                    }
                }
            });
        }
    }

    function handleTechnologyChange(e){
        let technology = JSON.parse(e.target.value);

        setSelectedTechnology(technology);
        setHasDiameter(technology.has_diameter);
        setHasColor(technology.has_color);

        //Update the material field value with the first with the same technology
        let firstMaterialWithTechnology;
        materials.data.map((material) => {
            if(material.technology.id === technology.id && !firstMaterialWithTechnology)
                firstMaterialWithTechnology = material.id;
        })
        setValue('material', firstMaterialWithTechnology);

        return e.target.value;
    }

    const handleMaterialChange = (e) => {
        e.preventDefault();
        setValue('material', e.target.value);

        return e.target.value;
    }

    // TODO Link with the printer
    // const handleWeightButtonClick = () =>  {
    //     setIsWeighOpen(!isWeighOpen);
    // }
    //
    // const handleInitialValueInputChoiceClick = () => {
    //     setIsInitialQuantityWeightMethod(!isInitialQuantityWeightMethod);
    // }

    if(colors.isLoading || brands.isLoading || materials.isLoading || technologies.isLoading || printers.isLoading) return <Loading/>;
    if(colors.isError || brands.isError || materials.isError || technologies.isError || printers.isError) return <Error/>;

    return (
        <div>
            <h2>Update a material</h2>

            <h3>Material data</h3>

            {/* TODO Link with the balance */}
            {/*{isWeighOpen &&*/}
            {/*    <WeighResource*/}
            {/*        resource={resource}*/}
            {/*        weighForForm={true}*/}
            {/*        successCallback={successCallback}*/}
            {/*        errorCallback={errorCallback}*/}
            {/*        dialogOpen={isWeighOpen} setDialogOpen={setIsWeighOpen}*/}
            {/*        setWeightMeasure={setWeightMeasure} setIsNewWeight={setIsNewWeight}*/}
            {/*    />*/}
            {/*}*/}

            <form id="add-resource__form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <Controller
                        control={control}
                        name="id"
                        defaultValue={resource.id}
                        render={({field, value}) =>
                            <input type="hidden"{...field} />}
                    />
                    <div>
                        <label htmlFor="f-brand-id">Brand</label>
                        <Controller
                            control={control}
                            name="brand"
                            defaultValue={resource.brand.id}
                            rules={{required: true, validate: (value) => value !== 0}}
                            render={({field}) => (
                                <select id="f-brand-id" {...field} onChange={(e) => field.onChange(handleBrandChange(e))}>
                                    {brands.data.map((brand) => (
                                        <option key={brand.id} value={JSON.stringify(brand)} selected={resource.brand.id === brand.id}>{brand.name}</option>
                                    ))}
                                </select>
                            )}
                        />

                        <label htmlFor="f-technology-id">Technology</label>
                        <Controller
                            control={control}
                            name="technology"
                            rules={{required: true, validate: (value) => value !== 0}}
                            defaultValue={JSON.stringify({id: resource.material.technology.id, has_color: resource.material.technology.has_color, has_diameter: resource.material.technology.has_diameter, diameters: resource.material.technology.diameters})}
                            render={({field}) => (
                                <select id="f-technology-id" {...field}
                                        onChange={(e) => field.onChange(handleTechnologyChange(e))}
                                        disabled={selectedBrand === null}>
                                    <option disabled selected value={0}>Select a technology</option>
                                    {filteredTechnologies.map((technology) => (
                                        <option key={technology.id}
                                                value={JSON.stringify(technology)}
                                                selected={resource.material.technology.id === technology.id}>{technology.name}</option>
                                    ))}
                                </select>
                            )}
                        />

                        <label htmlFor="f-material-id">Material</label>
                        <Controller
                            control={control}
                            name="material"
                            rules={{required: true, validate: (value) => value !== 0}}
                            defaultValue={resource.material.id}
                            render={({field}) => (
                                <select id="f-material-id" {...field}
                                        disabled={selectedTechnology === null}
                                        onChange={(e) => field.onChange(handleMaterialChange(e))}>
                                    {materials.data.map((material) => {
                                        if(selectedTechnology && material.technology.id === selectedTechnology.id)
                                            return <option key={material.id} value={material.id} selected={resource.material.id === material.id}>{material.name}</option>
                                    })}y
                                </select>
                            )}
                        />
                    </div>
                    <div>
                        { hasColor &&
                            <div id="color-zone">
                                <label htmlFor="f-color-id">Color</label>
                                <Controller
                                    control={control}
                                    name="color"
                                    defaultValue={resource.color ? resource.color.id : null}
                                    rules={{required: selectedTechnology.has_color}}
                                    render={({field}) => (
                                        <select id="f-color-id" {...field}>
                                            {colors.data.map((color) => (
                                                <option key={color.id} value={color.id} selected={resource.color ? resource.color.id === color.id : false}>{color.color_name}</option>
                                            ))}
                                        </select>
                                    )}
                                />
                            </div>
                        }
                        { hasDiameter &&
                            <div id="diameter-zone" >
                                <label htmlFor="f-diameter">Filament diameter</label>
                                <Controller
                                    control={control}
                                    name="diameter"
                                    defaultValue={resource.diameter}
                                    render={({field}) => (
                                        <select id="f-diameter" {...field}>
                                            {selectedTechnology.diameters.map((diameter, key) => (
                                                <option key={key} value={key} selected={resource.diameter === key}>{diameter} mm</option>
                                            ))}
                                        </select>
                                    )}
                                />
                            </div>
                        }
                    </div>
                </div>

                <h3>Resource data</h3>

                <div className="form-group">
                    <span>
                        <label htmlFor="f-serial-number">Batch number</label>
                        <Controller
                            control={control}
                            name="serial_number"
                            defaultValue={resource.serial_number}
                            render={({field}) => (
                                <input type="text" id="f-serial-number" placeholder="Batch number" {...field}/>
                            )}
                        />
                    </span>
                </div>
                <button className="btn-white"><i className="fa fa-check"/> Submit</button>
            </form>
        </div>
    )
}

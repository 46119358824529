import React, {useState, useEffect} from "react";
import {getCurrentUser} from "../CommonFunctions";
import {Avatar} from "./Avatar";
import {Badge, Drawer, makeStyles} from "@material-ui/core";
import {Popover, Whisper} from "rsuite";
import {fetchData} from "../../api/useFetch";
import {getSocketNotificationsObservable} from "../../services/socketEventsService";
import {NotificationsPanel} from "./NotificationsPanel";

export const HeaderContent = () => {

    const [notifications, setNotifications] = useState([]);
    const [notificationsPanelVisible, setNotificationsPanelVisible] = useState(false);

    useEffect(() => {
        const user = getCurrentUser();
        fetchData('notifications', `user.id=${user.id}`)
            .then(response => response.json())
            .then(pastNotifications => setNotifications(pastNotifications))
            .catch(() => null);

        const notificationsSubscription = getSocketNotificationsObservable().subscribe({
            next: (notification) => {
                if(notification.user_id === user.id)
                    setNotifications(notifications => [{...notification, read: false}, ...notifications]);
            }
        });

        return(() => {
            notificationsSubscription.unsubscribe();
        });
    }, []);


    return (
        <div id="main-grid__item--header" className="main-grid__item">
            <Drawer anchor="right" open={notificationsPanelVisible} onClose={() => setNotificationsPanelVisible(false)} classes={{paper: makeStyles({paper: {minWidth: '500px'}})().paper}}>
                <NotificationsPanel notifications={notifications} setNotifications={setNotifications}/>
            </Drawer>

            <div id="header">
                {/*<div id="header__search-zone">*/}
                {/*    <input type="text" id="header__search-bar" placeholder="Search on Handddle..."/>*/}
                {/*</div>*/}

                <Whisper placement="bottom" trigger="hover" speaker={<Popover><span>Switch organization</span></Popover>}>
                    <a href="organization" id="header__world"/>
                </Whisper>

                <div id="header__notifications" onClick={() => setNotificationsPanelVisible(true)}>
                    <Badge badgeContent={notifications.filter(n => !n.read).length} color="secondary"
                           data-tip="Loading..." data-event="click focus" data-for="notifications-tooltip">
                        <i className="fas fa-bell fa-2x"/>
                    </Badge>
                </div>

                <Avatar user={getCurrentUser()} isHeader={true} isMenu={true}/>
            </div>
        </div>
    )
}

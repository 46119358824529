import React, {useEffect, useState} from 'react';
import { getCurrentUser} from "../CommonFunctions";
import {useGetData} from "../../api/useFetch";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {GoogleMapMain} from "../common/googleMap/GoogleMapMain";

export const OrganizationGroupMap = (props) => {

    let currentUser = getCurrentUser();

    const handleSelectOrganization = (selectedOrganizationId) => {
        localStorage.setItem('selected-organization', JSON.stringify(selectedOrganizationId));
    };

    const bringToPrinter = (organizationId) => {
        handleSelectOrganization(organizationId);
        window.location = 'printers';
    }

    // Get all accessible organizations
    const organizationGroup = useGetData('organizationGroup',
        'organization_groups/' + currentUser.organization.organization_group.id +
        '?groups[]=read:OrganizationGroup:Organizations'
    );

    const [organizationsMarkers, setOrganizationsMarkers] = useState([]);

    useEffect(() => {
        // Update map markers (one marker per organization)
        if(organizationGroup.data && organizationGroup.data.organizations) {
            let newMarkers = [];
            organizationGroup.data.organizations.map((organization) => {
                newMarkers.push({
                    key: organization.id,
                    color: organization.marker_color,
                    latitude: organization.latitude,
                    longitude: organization.longitude
                })
            });
            setOrganizationsMarkers(newMarkers);
        }
    }, [organizationGroup.data]);

    if(organizationGroup.isLoading) return <Loading />
    if(organizationGroup.isError) return <Error />

    return (
        <div className="organization__map">
            <GoogleMapMain markers={organizationsMarkers} handleMarkerClick={bringToPrinter}/>
        </div>
    );
}

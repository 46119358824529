export const AUTOMATION_TOKEN_KEY = 'automation-token'

export const getAutomationTokenFromLocalStorage = () => {
    return localStorage.getItem(AUTOMATION_TOKEN_KEY);
}

export const PRINTER_BRANDS = {
    MARKFORGED: {'code': 1, 'name': 'markforged'},
    ULTIMAKER: {'code': 2, 'name': 'ultimaker'}
}

import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {postPrinters} from "../../api/apiPrinter";
import {Drawer, makeStyles} from '@material-ui/core';
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";

const useStyles = makeStyles({
    paper: {
        left: "50%"
    }
});

export const AddPrinterComponent = (props) => {
    const classes = useStyles();

    const {printers, brands, models, technologies, controllablePrinters, successCallback, errorCallback} = props;

    const {handleSubmit, setValue, control} = useForm();
    const [rightPartIsOpen, setRightPartIsOpen] = useState(false);
    const [selectedTechnology, setSelectedTechnology] = useState();
    const [hasDiameter, setHasDiameter] = useState(false);
    const [hasSeveralExtruders, setHasSeveralExtruders] = useState(false);
    const [selectedControllablePrinter, setSelectedControllablePrinter] = useState(null);

    const onSubmit = async (dataPrinter) => {
        dataPrinter.controllable_printer_id = selectedControllablePrinter ? selectedControllablePrinter.id_manufacturer : null;
        setSelectedControllablePrinter(null);
        setRightPartIsOpen(false);
        await postPrinters(dataPrinter)
            .then(() => successCallback('Printer has been added.'))
            .catch(() => errorCallback('Something went wrong.'));
    }

    const handleControllablePrinterChange = (e) => {
        let controllablePrinter = JSON.parse(e.target.value);
        // If controllable printer has been selected, handle form fields
        if(controllablePrinter !== 0) {
            // Set form values from controllable printer data
            if(controllablePrinter.printer_name !== undefined) {
                setValue('name', controllablePrinter.printer_name);
            }
            if(controllablePrinter.printer_series !== undefined) {
                setValue('serial_number', controllablePrinter.id_manufacturer);
            }
            if(controllablePrinter.brand !== undefined) {
                for(let i = 0; i < brands.data.length; i++) {
                    if(brands.data[i].printer_brand_data && brands.data[i].printer_brand_data.code === controllablePrinter.brand.code) {
                        setValue('brand', brands.data[i].id);
                        break;
                    }
                }
            }
            if(controllablePrinter.printer_type !== undefined) {
                let modelValue = null;
                // Check if model already exists in models list
                for(let i = 0; i < models.data.length; i++) {
                    if(models.data[i].name === controllablePrinter.printer_type) {
                        modelValue = models.data[i];
                        break;
                    }
                }
                // Case model does not exist : create new model
                if(modelValue === null) {
                    const newModel = {name: controllablePrinter.printer_type, newModel: true};
                    models.data.push(newModel);
                    modelValue = newModel;
                }
                setSelectedControllablePrinter(controllablePrinter);
                setValue('model', JSON.stringify(modelValue));
            }
        } else {
            // Case no controllable printer selected
            setSelectedControllablePrinter(null);
        }
    }

    function handleTechnologyChange(e){
        let technology = JSON.parse(e.target.value);

        setSelectedTechnology(technology);
        setHasDiameter(technology.has_diameter);
        setHasSeveralExtruders(technology.max_extruders_number > 1);

        if(!technology.has_diameter)
            setValue('diameter', 0);

        if(technology.max_extruders_number === 1)
            setValue('extruder', 0);

        return e.target.value;
    }

    const isDiameterNeeded = (value) => {
        if(!value)
            return false;

        return !hasDiameter || value >= 0;
    }

    /* Check if controllable printer si already connected to an existing printer */
    const isControllablePrinterAlreadyConnected = (printer) => {
        for(let i = 0; i < printers.data.length; i++) {
            if (printers.data[i].controllable_printer_id === printer.id_manufacturer) {
                return true;
            }
        }
        return false;
    }

    const getControllablePrintersFlatList = () => {
        let controllablePrintersList = [];
        if(controllablePrinters.data && controllablePrinters.data !== {}) {
            for(let [key, printerBrand] of Object.entries(controllablePrinters.data)) {
                if(printerBrand.data) {
                    for(let controllablePrinter of printerBrand.data) {
                        controllablePrintersList.push(controllablePrinter);
                    }
                }
            }
        }
        return controllablePrintersList;
    }

    return (
        <>
            <button data-navigation="right" className="button-link" id="printers__add-printer" onClick={() => setRightPartIsOpen(!rightPartIsOpen)}><i className="fa fa-plus"/>Add a printer</button>
            <Drawer anchor="right" open={rightPartIsOpen} onClose={() => setRightPartIsOpen(!rightPartIsOpen)} classes={{paper: classes.paper}}>
                {

                    brands.isLoading || models.isLoading || technologies.isLoading || controllablePrinters.isLoading ? <Loading/> :
                    brands.isError || models.isError || technologies.isError ? <Error/> :

                    <div id="right-part__container" className="right-part__container">
                        <div id="main__add-printer">
                            <div className="block">
                                <h2>Add a printer</h2>
                                <form onSubmit={handleSubmit(onSubmit)} id="add-printer__form">

                                    <label htmlFor="f-brand">Connected printer</label>
                                    {/*Controllers are used to pass form data to updated state*/}
                                    <Controller
                                        control={control}
                                        name="controllable_printer"
                                        rules={{required: false}}
                                        render={({field}) => (
                                            <select id="f-controllable-printer" {...field} onChange={(e) => field.onChange(handleControllablePrinterChange(e))}>
                                                <option disabled selected value>Select an option</option>
                                                <option value={0}>No connected printer</option>
                                                {getControllablePrintersFlatList().map((printer) => (
                                                    <option key={printer.brand + '-' + printer.id_manufacturer}
                                                            value={JSON.stringify(printer)}
                                                            disabled={isControllablePrinterAlreadyConnected(printer)}>
                                                        {printer.printer_name + ' - ' + printer.printer_type}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    />
                                    <label htmlFor="f-brand">Printer brand</label>
                                    <Controller
                                        control={control}
                                        name="brand"
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <select id="f-brand" {...field}>
                                                <option disabled selected value>Select an option</option>
                                                {brands.data.map((brand) => (
                                                    <option key={brand.id} value={brand.id}>{brand.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    />

                                    <label htmlFor="f-model">Model</label>
                                    <Controller
                                        control={control}
                                        name="model"
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <select id="f-model" {...field}>
                                                <option disabled selected value>Select an option</option>
                                                {models.data.map((model) => (
                                                    <option key={model.name} value={JSON.stringify(model)}>{model.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    />

                                    <label htmlFor="f-serial-number">Serial number</label>
                                    <Controller
                                        control={control}
                                        name="serial_number"
                                        rules={{required: true}}
                                        defaultValue=""
                                        render={({field}) =>
                                            <input type="text" id="f-serial-number"
                                                   placeholder="Printer serial number" {...field} />}
                                    />

                                    <label htmlFor="f-name">Name</label>
                                    <Controller
                                        control={control}
                                        name="name"
                                        rules={{required: true}}
                                        defaultValue=""
                                        render={({field}) =>
                                            <input type="text" id="f-name"
                                                   placeholder="Printer name" {...field} />}
                                    />

                                    {/* Printable volume */}

                                    <label htmlFor="f-name">Printable height (mm)</label>
                                    <Controller
                                        control={control}
                                        name="printableHeight"
                                        rules={{required: true}}
                                        defaultValue=""
                                        render={({field}) =>
                                            <input type="number" id="f-printableHeight"
                                                   placeholder="Printable height" {...field} />}
                                    />
                                    <label htmlFor="f-name">Printable length (mm)</label>
                                    <Controller
                                        control={control}
                                        name="printableLength"
                                        rules={{required: true}}
                                        defaultValue=""
                                        render={({field}) =>
                                            <input type="number" id="f-printableLength"
                                                   placeholder="Printable length" {...field} />}
                                    />
                                    <label htmlFor="f-name">Printable width (mm)</label>
                                    <Controller
                                        control={control}
                                        name="printableWidth"
                                        rules={{required: true}}
                                        defaultValue=""
                                        render={({field}) =>
                                            <input type="number" id="f-printableWidth"
                                                   placeholder="Printable width" {...field} />}
                                    />

                                    <label htmlFor="f-technology">Technology</label>
                                    <Controller
                                        control={control}
                                        name="technology"
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <select id="f-technology" {...field} onChange={(e) => field.onChange(handleTechnologyChange(e))}>
                                                <option disabled selected value>Select a technology</option>
                                                {technologies.data.map((technology) => (
                                                    <option key={technology.id}
                                                            value={JSON.stringify({id: technology.id, has_diameter: technology.has_diameter, diameters: technology.diameters, max_extruders_number: technology.max_extruders_number})}>
                                                        {technology.name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    />

                                    {
                                        hasDiameter &&
                                        <div id="diameter-zone">
                                            <label htmlFor="f-diameter">Filament diameter</label>
                                            <Controller
                                                control={control}
                                                name="diameter"
                                                rules={{validate: isDiameterNeeded}}
                                                render={({field}) => (
                                                    <select id="f-diameter" {...field}>
                                                        <option disabled selected value>Select a diameter</option>
                                                        <option value="0">1.75 mm</option>
                                                        <option value="1">2.85/3.00 mm</option>
                                                    </select>
                                                )}
                                            />
                                        </div>
                                    }

                                    {
                                        hasSeveralExtruders &&
                                        <div id="dual-extruder-zone">
                                            <Controller
                                                control={control}
                                                name="extruder"
                                                render={({field}) =>
                                                    <input type="checkbox" id="chk-dual-extruder"
                                                           {...field} />}
                                            />
                                            <label htmlFor="chk-dual-extruder"><span className="chk"></span>
                                                Printer bi-nozzle
                                            </label>
                                        </div>
                                    }

                                    {/*Fiber*/}
                                    <div id="is_fiber">
                                        <Controller
                                            control={control}
                                            name="is_fiber"
                                            render={({field}) =>
                                                <input type="checkbox" id="chk-fiber"
                                                       {...field} />}
                                        />
                                        <label htmlFor="chk-fiber"><span className="chk"></span>
                                            Fiber available
                                        </label>
                                    </div>

                                    <button className="btn-white"><i className="fa fa-check"/> Add</button>

                                </form>
                            </div>
                        </div>
                    </div>
                }
            </Drawer>
        </>
    )
}

